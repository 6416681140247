@import "../../../../../common/commonConstant.scss";

.slider-wrapper {
    .slider-question {
        margin-bottom: 10px;
        margin-top: 30px;
        font-size: 16px;
        line-height: 24px;
        font-weight: 700;
        color: $a-black;
        font-family: $sukhmaitext;
    }

    .selected-data {
        display: flex;
        justify-content: center;
        position: fixed;
        bottom: 0px;
        left: 0%;
        width: 100%;
        img {
            width: 100%;
            max-width: 100%;
        }
        &.attitude-type-media {
            img{
                object-fit: contain;
            }
        }
    }

    @media(max-height: 800px){
        .selected-data{
            height: 65%;
            bottom: -15vh;
            img {
                width: 100%;
                object-fit: cover;
            }
        }


    }
}