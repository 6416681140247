@import '../../../common/commonConstant.scss';

.library-profile-container {
  width: 100%;
  min-height: 100vh;
  padding: 0 20px 20px;
  background: url('/images/library/bg_profile.jpg') no-repeat;
  background-size: 100% auto;

  .library-profile-row {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .library-col-profile {
    text-align: center;

    .library-profile-image {
      margin-top: 30%;
      margin-bottom: 20px;
      -webkit-border-radius: 50%;
      -moz-border-radius: 50%;
      border-radius: 50%;
      width: 100px;
      height: 100px;
      border: 4px solid #fff;
      background-color: #5ab8e0;
      overflow: hidden;
    }

    .library-profile-info {
      font-size: 18px;

      .name {
        color: #38539a;
        font-size: 22px;
        font-weight: bold;
      }

      .user-code-tag {
        color: #38539a;
        font-size: 15px;
        background: #fff;
        padding: 5px 15px;
        border-radius: 30px;
      }
    }
  }

  .library-col-content {
    margin-top: 30px;
    width: 100%;
  }

  .library-tab-wrap {
    display: flex;
    width: 100%;
    justify-content: space-between;
  }

  .library-col-content-tab {
    width: calc(50% - 10px);
    text-align: center;
    height: 80px;
    line-height: 80px;
    background: #fff;
    border-radius: 15px;
    font-weight: bold;
    color: #38539a;

    &.active {
      border: 1px solid #38539a;
    }

    .icon {
      margin-right: 10px;
      vertical-align: middle;
    }
  }

  .card-library {
    background-color: #fff;
    border-radius: 15px;
    padding: 20px 15px;
    margin-top: 30px;

    .card-header {
      margin-bottom: 20px;
      font-size: 16px;
      font-weight: bold;
      display: flex;
      justify-content: space-between;

      .icon {
        margin-right: 10px;
      }

      .see-all a {
        color: #38539a;
        font-size: 15px;
      }
    }

    &.card-categories {
      .card-body {
        width: 100%;
        height: auto;
        white-space: nowrap;
        position: relative;
        overflow-x: scroll;
        overflow-y: hidden;
        -webkit-overflow-scrolling: touch;

        .card-item {
          width: 130px;
          float: none;
          display: inline-block;
          margin: 0 5px;
          border: 1px solid #f5f6fa;
          border-radius: 10px;
          overflow: hidden;
        }

        .card-item-img img {
          width: 100%;
          height: 140px;
        }

        .card-desc {
          background-color: #f5f6fa;
          text-align: center;

          .title {
            color: #38539a;
            font-weight: bold;
            font-size: 14px;
            overflow: hidden;
            white-space: nowrap;
            text-overflow: ellipsis;
            margin-left: 15px;
            margin-right: 15px;
          }

          .total-books {
            font-size: 12px;
          }
        }
      }
    }

    &.card-discovery-new {
      .search-input {
        position: relative;
        margin-top: 13px;

        .ant-input-affix-wrapper {
          background: #f5f6fa;
          border: none;
          border-radius: 7px;
          font-size: 12px;
        }

        .ant-input-affix-wrapper > input.ant-input {
          background: #f5f6fa;
        }
      }

      .card-body {
        .card-item {
          width: calc(50% - 10px);
          display: inline-block;
          margin: 10px 5px;
          border-radius: 10px;
          overflow: hidden;
          vertical-align: top;
        }

        .card-item-img {
          height: 180px;
          border: 1px solid #f5f6fa;
          overflow: hidden;
          position: relative;

          img {
            position: absolute;
            height: 100%;
            left: 50%;
            top: 50%;
            transform: translate(-50%, -50%);
          }
        }

        .card-desc {
          margin-top: 10px;

          .title {
            color: #38539a;
            font-weight: bold;
            overflow: hidden;
            white-space: nowrap;
            text-overflow: ellipsis;
            margin-left: 15px;
            margin-right: 15px;
          }

          .desc {
            overflow: hidden;
            white-space: nowrap;
            text-overflow: ellipsis;
            margin-left: 15px;
            margin-right: 15px;
            font-size: 12px;
          }
        }
      }
    }
  }

  .empty-data {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    text-align: center;

    .desc {
      margin-top: 20px;
    }
  }
}
