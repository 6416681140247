@import '../../common/commonConstant.scss';

.awdatepicker-container {
  display: grid;
  grid-template-columns: auto auto auto;
  grid-column-gap: 10px;
  .inline-picker {
    position: relative;
    select{
      outline: none;
      padding: 0;
      border-bottom: 1px solid $black;
      &.placeholder{
        color: #C3C4C5;
        border-bottom: 1px solid $gray2;
      }
    }
  }
}
