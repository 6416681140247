@import "../../common/commonConstant.scss";

.condition-container {
  background: $bggray;
  & > .header {
    text-align: center;
  }
  & > .body {
    margin: 15px 15px 45px 15px;
    padding: 25px 15px;
    background-color: $bgwhite;
    box-shadow: 0px 0.5px 10px rgba(0, 0, 0, 0.05);
    border-radius: 12px;
    .title {
      text-align: center;
      font-weight: bold;
      font-size: 18px;
      margin-bottom: 15px;
    }
    .bold{
      font-weight: bold;
    }
    .condition{
      margin-bottom: 10px;
    }
    .list{
      display: list-item;
      margin-left: 20px;
    }
    .margin-bottom{
      margin-bottom: 15px;
    }
  }
  & > .footer {
    position: fixed;
    bottom: 0;
    width: 100%;
    padding: 15px;
  }
}
