@import '../../../../../common/commonConstant.scss';

.custom-android-camera-container {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  justify-items: center;

  position: absolute;
  bottom: 20px;
  height: auto;
  width: 100%;
  grid-gap: 16px;
}

//to do
.ant-modal-content {
  height: calc(100vh + 7px);
  display: flex;
  flex-direction: column;
  background-color: #6c6c6c;
}

.ant-modal {
  position: unset;
  width: 100%;
  max-width: 100%;
  padding: 0;
  margin: 0;
}

.img-crop-container {
  height: 100% !important;
  overflow-y: auto;
  position: absolute;
  width: 100%;
}

.ant-upload-list-picture .ant-upload-list-item-error,
.ant-upload-list-picture-card .ant-upload-list-item-error {
  border-color: none;
  border: none;
  border-radius: 6px;
}

.ant-upload-list-item-info {
  border-radius: 6px;
}

.ant-upload-list-item:hover {
  pointer-events: none;
}

.add-menu-form-error {
  font-size: 12px;
  line-height: 16px;
  font-weight: 500;
  font-family: $sukhmaitext;
  color: $dark-red2;
}

.ant-modal-header {
  position: absolute;
  top: 15px;
  padding: 17px 24px;
  left: 35%;
  z-index: 999;
  background: none;
  color: $white !important;
  border-bottom: none;
  font-size: 20px;
  font-weight: 800;
  line-height: 28px;
  font-family: $sukhmaibold;

  &::after {
    content: $header;
  }

  .ant-modal-title {
    display: none;
  }
}

.ant-upload.ant-upload-select-picture-card {
  width: 96px;
  height: 96px;
  border-radius: 6px;
}
