@import '../../../common/commonConstant.scss';

.home-bmi-header-container {
    font-family: $sukhmaitext;

    .banner-container {
        overflow-x: hidden;

        .bmi-details-wrapper {
            background-image: url(/images/healthAndWellness/bmi/bmi-details-bg.svg);
            background-repeat: no-repeat;
            background-position: 0 70px;
            width: 100%;
            padding-bottom: 48px;
            height: 100%;

            .bmi-details-container {
                position: relative;
                top: -5px;
            }
        }

    }

    .bmi-details-name,
    .bmi-details-value {
        font-size: 14px;
        font-weight: 500;
        color: $homegrey1;
    }

    .bmi-blue-active .inner-half-circle .circle-heading,
    .bmi-blue-active .inner-half-circle .circle-value,
    .bmi-green-active .inner-half-circle .circle-heading,
    .bmi-green-active .inner-half-circle .circle-value,
    .bmi-yellow-active .inner-half-circle .circle-heading,
    .bmi-yellow-active .inner-half-circle .circle-value,
    .bmi-orange-active .inner-half-circle .circle-heading,
    .bmi-orange-active .inner-half-circle .circle-value,
    .bmi-red-active .inner-half-circle .circle-heading,
    .bmi-red-active .inner-half-circle .circle-value {
        color: $bgwhite !important;
    }

    .bmi-blue-active .inner-half-circle .circle-heading,
    .bmi-green-active .inner-half-circle .circle-heading,
    .bmi-yellow-active .inner-half-circle .circle-heading,
    .bmi-orange-active .inner-half-circle .circle-heading,
    .bmi-red-active .inner-half-circle .circle-heading {
        opacity: 0.7 !important;
    }

    .bmi-blue-active,
    .bmi-green-active,
    .bmi-yellow-active,
    .bmi-orange-active,
    .bmi-red-active {
        .bmi-details-container {
            padding-top: 0;
        }
    }

    .bmi-card-single-blue,
    .bmi-card-single-green,
    .bmi-card-single-yellow,
    .bmi-card-single-orange,
    .bmi-card-single-red {

        .bmi-details-name,
        .bmi-details-value {
            font-weight: 700;
        }

    }

    .bmi-blue-active .inner-half-circle .circle-value,
    .bmi-green-active .inner-half-circle .circle-value,
    .bmi-yellow-active .inner-half-circle .circle-value,
    .bmi-orange-active .inner-half-circle .circle-value,
    .bmi-red-active .inner-half-circle .circle-value {
        font-size: 38px !important;
        font-weight: 800 !important;
        line-height: 38px !important;
    }

    .bmi-blue-active {
        .bmi-card-single {
            &.bmi-card-single-blue {
                background: $bmibgblue !important;

                .bmi-details-name,
                .bmi-details-value {
                    color: $bmiblue !important;
                }
            }
        }
    }


    .bmi-green-active {
        .bmi-card-single {
            &.bmi-card-single-green {
                background: $bmibggreen !important;

                .bmi-details-name,
                .bmi-details-value {
                    color: $bmigreen !important;
                }
            }
        }
    }

    .bmi-yellow-active {
        .bmi-card-single {
            &.bmi-card-single-yellow {
                background: $bmibgyellow !important;

                .bmi-details-name,
                .bmi-details-value {
                    color: $bmiyellow !important;
                }
            }
        }
    }

    .bmi-orange-active {
        .bmi-card-single {
            &.bmi-card-single-orange {
                background: $bmibgorange !important;

                .bmi-details-name,
                .bmi-details-value {
                    color: $bmiorange !important;
                }
            }
        }
    }

    .bmi-red-active {
        .bmi-card-single {
            &.bmi-card-single-red {
                background: $bmibgreen !important;

                .bmi-details-name,
                .bmi-details-value {
                    color: $bmired !important;
                }
            }
        }
    }

    .bmi-details-container,
    .bmi-details-description,
    .bmi-chart {
        background: $bgwhite;
        margin: 0 20px 16px 20px;
        border-radius: 12px;
        padding: 16px;
    }

    .bmi-details-title {
        font-size: 20px;
        font-weight: 800;
        color: $homegrey1;
        margin-bottom: 5px;
        font-family: $sukhmaibold;
    }


    .bmi-blue-active .bmi-progress-blue-img,
    .bmi-green-active .bmi-progress-green-img,
    .bmi-yellow-active .bmi-progress-yellow-img,
    .bmi-orange-active .bmi-progress-orange-img,
    .bmi-red-active .bmi-progress-red-img {
        visibility: initial !important;
    }

    .bmi-blue-active {
        .bmi-details-title {
            color: $bmiblue !important;
        }
    }

    .bmi-green-active {
        .bmi-details-title {
            color: $bmigreen !important;
        }
    }

    .bmi-yellow-active {
        .bmi-details-title {
            color: $bmiyellow !important;
        }
    }

    .bmi-orange-active {
        .bmi-details-title {
            color: $bmiorange !important;
        }
    }

    .bmi-red-active {
        .bmi-details-title {
            color: $bmired !important;
        }
    }
}