@import '../../../common/commonConstant.scss';

.profile-banner-wrapper {
  background: $bgwhiteShade;
  font-family: $sukhmaitext;

  .header-bottom-section {
    height: 52px;
    background: #fff;

    .header-bottom-icons {
      display: flex;
      gap: 16px;
      align-items: center;
      justify-content: flex-end;
    }
  }

  .profile-banner-container {
    position: relative;
    margin-bottom: 10px;

    .profile-img {
      position: absolute;
      bottom: 0;
      left: 50%;
      transform: translate(-50%, 0px);

      .profile-img-dimensions {
        width: 152px;
        height: 152px;
        border-radius: 50%;
      }
    }
  }

  .bg-profile-wrapper {
    background-image: url(/images/healthAndWellness/profile/bg-wave.svg);
    background-repeat: no-repeat;
    background-position: 0 96px;
    width: 100%;
    background-size: contain;
    padding-bottom: 20px;
    height: 100%;
  }
}