@import "../../common/commonConstant.scss";

.placepicker-container {
  .setcenter-button {
    position: fixed;
    z-index: 10;
    margin-top: 10px;
    margin-left: 10px;
    background-color: white;
    width: 40px;
    height: 40px;
    border-radius: 50%;
    display: none;
    justify-content: center;
    align-items: center;
    box-shadow: 0px 5px 15px rgba(0, 0, 0, 0.04);
    &.show {
      display: flex;
    }
  }
  .modal-container {
    position: fixed;
    z-index: 999;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    display: none;
    justify-content: center;
    align-items: center;
    &.show {
      display: flex;
    }
    .card-container {
      max-width: 60vw;
      border-radius: 12px;
      padding: 15px;
      background: white;
      text-align: center;
      .submit-btn {
        margin-top: 10px;
        background: transparent;
        border: none;
        font-size: 16px;
        color: blue;
      }
    }
  }
  .input-container {
    position: fixed;
    width: 100%;
    left: 0;
    right: 0;
    margin: 0;
    bottom: 0;
    height: 60px;
    padding: 15px;
    background: white;
    backdrop-filter: blur(10px);
    opacity: 0.8;
    transition: 0.5s all cubic-bezier(.25,.1,.25,1);
    &:focus-within {
      height: 220px;
    }
    .searchbox-input {
      //default
      box-sizing: border-box;
      border: 1px solid transparent;
      width: 240px;
      height: 32px;
      padding: 0 12px;
      border-radius: 3px;
      // box-shadow: 0 2px 6px rgba(0, 0, 0, 0.3);
      font-size: 14px;
      outline: none;
      text-overflow: ellipses;
      // position: absolute;
      // left: 50%;
      width: 100%;
      color: white;
      background: $gray;
    }
  }

  .address-box {
    position: absolute;
    background: black;
    opacity: 0.6;
    backdrop-filter: blur(5px);
    color: white;
    width: 200px;
    height: 75px;
    font-size: 14px;
    left: 0;
    right: 0;
    top: 0;
    bottom: 125px;
    margin: auto;
    padding: 20px 10px 10px;
    display: none;
    cursor: pointer;
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    -webkit-tap-highlight-color: transparent;
    &.show {
      display: block;
    }
    &:active {
      background-color: #2c2c2c;
    }
    .header {
      position: absolute;
      font-size: 10px;
      top: 5px;
    }
    > .addr {
      overflow: hidden;
      height: 100%;
      font-weight: bold;
    }
    &::after {
      content: "";
      position: absolute;
      bottom: 0;
      left: 50%;
      width: 0;
      height: 0;
      border: 10px solid transparent;
      border-top-color: black;
      border-bottom: 0;
      margin-left: -10px;
      margin-bottom: -10px;
    }
  }
  .custom-marker {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    margin: auto;
    display: none;
    &.show {
      display: block;
    }
  }
}

.pac-container {
  background: transparent;
  box-shadow: none;
  &::after {
    background-image: none;
    height: 0;
    padding: 0;
  }
  .pac-item {
    border-top: none;
    margin-top: 10px;
  }
}
