@import '../../../../common/commonConstant.scss';

.statusbar {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 60px;

    p {
        position: absolute;
        margin: 0;

        &.text-size {
            font-size: 18px;
            font-weight: 800;
        }

        &.text-color {
            color: $black !important;
        }
    }
}