@import "../commonConstant.scss";

.nutrition-bar-container {
    display: flex;
    gap: 24px;

    .nutrition-bar-single,
    .nutrition-bar-header {
        display: flex;
        flex-direction: column;
    }

    .nutrition-bar-single {
        gap: 2px;
        width: 33.33%;

        .nutrition-bar-header {
            gap: 4px;

            .nutrition-bar-name {
                font-size: 12px;
                font-weight: 500;
                color: $black;
                text-align: center;
                line-height: 16px;
            }

            progress {
                width: 100%;
                height: 6px;
                border-radius: 50px;
                background: #E9EDF4;

                .progress-bar {
                    background: linear-gradient(90deg, $flgreen2 0%, $flgreen1 99.62%);
                    border-radius: 16px;
                }
            }
        }

        .nutrition-bar-value-group {
            display: flex;
            justify-content: center;
            margin-top: 4px;
            gap: 4px;

            .nutrition-bar-value,
            .nutrition-bar-value-unit {
                font-size: 12px;
            }

            .nutrition-bar-value {
                font-weight: 700;
            }

            .nutrition-bar-value-unit {
                font-weight: 500;
            }
        }

    }
}