@import "../../../common/commonConstant.scss";

.choosemag-container {
  padding: 15px 25px;
  background: $bggray;
  min-height: 100vh;
  ul {
    list-style-position: inside;
  }
  > .header {
    text-align: center;
    margin-bottom: 15px;
    .title {
      font-size: 20px;
      color: $black;
      margin-bottom: 10px;
    }
    .desc {
      font-size: 14px;
      color: $gray;
      max-width: 70%;
      margin: 0 auto;
    }
  }
  > .body {
    margin-bottom: 10vh;
    .link {
      color: $light-blue;
      text-decoration: underline;
    }
    .radio-input {
      appearance: radio;
      width: auto;
      height: auto;
      border: none;
    }
    .collapse-container {
      background: $bgwhite;
      margin-bottom: 15px;
      box-shadow: 0px 5px 15px rgba(0, 0, 0, 0.04);
      padding: 15px;
      border-radius: 15px;
      .collapse-header {
        background: transparent;
        box-shadow: none;
        padding: 0;
        .title {
          .checkbox-container {
            margin-bottom: 0;
          }
        }
      }
      .collapse-content {
        background: transparent;
        .content-container {
          margin: 0;
          padding: 15px;
          padding-bottom: 5px;
          .visible {
            display: block;
          }
          .hide {
            display: none;
          }
        }
      }
      hr {
        display: none;
      }
      &.active {
        border: 2px solid $medium-blue;
        .collapse-header {
          .title {
            .checkbox-container {
              margin-bottom: 12px;
            }
          }
        }
        hr {
          display: block;
        }
      }
      &.no-content {
        .content-container {
          padding: 0;
        }
        .checkbox-container {
          margin: 0 !important;
        }
      }
      &.member {
        .checkbox-container {
          margin: 0 !important;
        }
      }
    }
    .add-bank-container {
      hr {
        border: 1px solid $gray3;
      }
      .header {
        text-align: center;
        font-family: Sukhumvit Set;
        font-style: normal;
        font-weight: 500;
        font-size: 20px;
        margin-bottom: 15px;
      }
      .card-container {
        .input-container {
          margin-bottom: 10px;
          label {
            color: $gray;
            font-size: 13px;
          }
          .input-description {
            color: $medium-blue;
            font-size: 13px;
            margin-top: 5px;
          }
        }
        .description {
          color: $gray;
          font-size: 13px;
        }
      }
      .images-upload-container {
        // .images {
        //   display: grid;
        //   grid-template-columns: 1fr 1fr;
        //   max-width: 425px;
        //   margin: 0 auto;
        //   grid-gap: 5px;
        //   margin-bottom: 20px;
        //   .image-container {
        //     position: relative;
        //     .preview-image {
        //       width: 100%;
        //       min-width: 150px;
        //       height: 150px;
        //       border-radius: 8px;
        //       border: 1px solid #e6e6e6;
        //     }
        //     .close-btn {
        //       position: absolute;
        //       top: 5px;
        //       right: 5px;
        //     }
        //   }
        // }
        // .images-input {
        //   display: none;
        // }
        .input-container {
          max-width: calc(60vw + 20px);
          margin: 10px auto;
        }
        .upload-btn {
          border-radius: 25px;
          border: 1px solid #2c2c2c;
          padding: 5px 15px;
          display: inline-block;
        }
        .image-name-text {
          font-size: 15px;
          color: $black;
          font-weight: 500;
          margin-left: 15px;
        }
        .upload-text {
          font-size: 13px;
          color: $gray;
          margin-left: 15px;
        }
        @media (max-width: 380px) {
          .images {
            grid-template-columns: 1fr;
          }
        }
      }

      label.input-invalid{
        color: $red !important;
        font-size: 13px;
      }
      input.input-invalid{
        border-bottom: 1px solid $red;
      }
      .react-select__control{
        border-bottom: 1px solid $gray3;
      }
      .react-select__placeholder{
        color:$gray3;
      }
      .input-invalid .react-select__control{
        border-bottom: 1px solid $red;
      }
      .react-select__value-container{
        padding-left: 0;
      }
    }
    .bottom-text {
      text-align: center;
      font-weight: bold;
      font-size: 15px;
      color: $gray;
      margin: 15px 0;
      cursor: pointer;
  		text-decoration: underline;
    }
    .invalid-warning-container{
      margin: 15px;
      display: flex;
      justify-content: center;

      .invalid-warning-text{
        color:$red;
        font-weight: bold;
      }
    }
  }
  > .footer {
    background-color: $bggray;
    position: fixed;
    bottom: 0;
    width: 100%;
    left: 0;
    right: 0;
    padding: 15px;
    &.menu-open {
      position: relative;
      bottom: 0;
      width: 100vw;
      margin: 40vh -25px 0;
    }
  }
  input.radio-input {
    margin-bottom: 5px;
  }
  .Modal {
    backdrop-filter: blur(5px);
    text-align: center;
  }
  .submit-button-container{
    margin-top: 30px;
    margin-bottom: 20px;
  }
  .react-select__indicator-separator{
    display: none;
  }
}
.full-width{
  width: 100%;
}
.book-type-title-width-recommend{
  display: flex;
  justify-content: space-between;

  .book-type-recommend{
    color: white;
    border: 1px $orange solid;
    background-color: $orange;
    border-radius: 8px;
    padding: 1px 8px;
    margin-right: 15px;
  }
}
.book-type-title{
  font-weight: 700;
  display: flex;
  align-items: center;
}
.book-type-subtitle{
  font-size: 14px;
  @media (max-width: 425px) {
    text-align: center;
  }
}