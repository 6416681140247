@import '../commonConstant.scss';


progress {
    border-radius: 7px;
    width: 100%;
    appearance: none;
}

progress::-webkit-progress-bar {
    background-color: $a-circleStroke;
    border-radius: 7px;
    height: 9px;
}

progress {
    &::-webkit-progress-value {
        border-radius: 7px;
        height: 9px;
        background: linear-gradient(90deg, $foodDark 0%, $foodLight 100%);
    }
    &.--water::-webkit-progress-value {
        background: linear-gradient(90deg, $waterDark 0%, $waterLight 100%);
    }
    &.--exercise::-webkit-progress-value,&.--time::-webkit-progress-value  {
        background: linear-gradient(90deg, $exerciseBarDark 0%, $exerciseLight 100%);
        border-radius: 16px;
        height: 9px;
    }
}