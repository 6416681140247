@import '../commonConstant.scss';

#deleteModalAndroid .modal-content {
    border-radius: 2px;
    box-shadow: 0px 3px 6px 0px $a-delete-modal-shadow;
    max-width: 286px;
    margin: auto;
    width: 100%;
    background-color: $bgwhite;
}

#deleteModalAndroid .modal-body {
    padding: 22px 24px 21px 24px;
    height: 67px;
}

#deleteModalAndroid .modal-heading {
    font-size: 16px;
    font-weight: 400;
    margin-bottom: 0;
    color: $a-delete-modal-heading;
    line-height: 24px;
}

#deleteModalAndroid .modal-heading-black {
    color: $a-delete-modal-heading-black;
}

#deleteModalAndroid .modal-footer {
    border-top: none;
    padding: 8px;
    align-items: center;
    gap: 8px;
}

#deleteModalAndroid .btn-link-blue {
    color: $a-btn-android-cancel;
}

#deleteModalAndroid .btn-link-default {
    color: $a-btn-android-cancel;
}

#deleteModalAndroid .btn-link-red {
    color: $a-btn-android-delete;
}

#deleteModalAndroid .btn-link-black {
    color: $a-btn-android-cancel-black;
}

#deleteModalAndroid .btn-link-default,
#deleteModalAndroid .btn-link-red {
    font-size: 14px;
    font-weight: 500;
    padding: 10px 8px;
    margin: 0;
    height: 36px;
    line-height: 16px;
}

#deleteModalios .modal-content {
    background: $bgwhiteShade;
    max-width: 270px;
}

#deleteModalios .modal-content {
    width: 100%;
    border-radius: 14px;
    margin: auto;
}

#deleteModalios .modal-body {
    padding-top: 19px;
    padding-bottom: 15px;
}

#deleteModalios .modal-heading-ios {
    font-size: 18px;
    font-weight: 700;
    font-family: $sukhmaitext;
    margin-bottom: 0;
    line-height: 26px;
    text-align: center;

}

#deleteModalios .modal-heading-exercise {
    max-width: 160px !important;
}

#deleteModalios .modal-footer {
    border-top: 1px solid $a-ios-border;
    padding: 0;
    height: 44px;
    display: flex;
}

#deleteModalios .modal-footer {

    button,
    button:focus,
    button:hover,
    button:active {
        width: 50%;
        margin: 0;
        box-shadow: none;
    }
}

#deleteModalios .btn-link-default,
#deleteModalios .btn-link-red {
    font-size: 17px;
    line-height: 22px;
    height: 42px;
    border-radius: 0;
    font-family: $sukhmaitext;
}

#deleteModalios .btn-link-default {
    color: $a-black;
    font-weight: 400;
    font-family: $sukhmaitext;
}

#deleteModalios .btn-link-red {
    color: $dark-red2;
    font-weight: 600;
    font-family: $sukhmaitext;
}

.delete-modal-panel.open {
    width: 100%;
    height: 100%;
    z-index: 1;
    background-color: $a-backgorund-blur;
    backdrop-filter: blur(5px);
    position: fixed;
    top: 0;
    left: 0;
    font-family: $sukhmaitext;
}

.delete-modal {
    display: inline;
}

.sukhmai-text {
    font-family: $sukhmaitext;
}

.sukhmai-bold-text {
    font-family: $sukhmaibold;
}

.sf-pro-heading {
    font-family: $sfproregular !important;
    font-weight: 590 !important;
}

.btn-link-red-sf-pro-regular {
    font-family: $sfproregular !important;
    color: $dark-red2 !important;
    font-weight: 590 !important;
    font-size: 17px !important;
}

.btn-cancel-sf-pro {
    font-family: $sfproregular !important;
    font-weight: 400 !important;
    font-size: 17px !important;
}

.text-centered {
    display: flex;
    justify-content: center;
}

.font-16 {
    font-size: 16px !important;
}

.btn-cancel-roboto {
    font-family: $robotomedium !important;
    font-size: 14px !important;
    font-weight: 500 !important;
}

.btn-link-red-roboto {
    font-family: $robotomedium !important;
    font-size: 14px !important;
    font-weight: 500 !important;
    color: $dark-red2 !important;
}

.roboto-medium-heading {
    font-family: $robotomedium !important;
}