@import "../../../../../common/commonConstant.scss";

.category-card img {
  height: 68px;
  width: 68px;
  border-radius: 50%;
  border-color: transparent;
  @media(max-width:324px){
    height: 55px;
    width: 55px;
  }
}

.slick-slide img {
  border: none;
}

.category-img-container {
  display: flex;
  justify-content: center;
  img {
    object-fit: cover;
  }
}

.category-card-title {
  font-size: 16px;
  font-weight: 700;
  font-family: $sukhmaitext;
  text-align: center;
  margin-top: 4px;  
  overflow-wrap: break-word;
}

.category-card-active .category-card-title {
  color: $homegreen;
}

.category-card-active img {
  border: 2px solid $homegreen;
}
