@import "../../../../common/commonConstant.scss";

.shimmer-quiz-card-single {
  box-shadow: 0px 4px 20px 0px $challenge-card-shadow;
  background: $a-circle-progress-bg;
  border-radius: 16px;
  overflow: hidden;
  margin-right: 17px;
  margin-bottom: 16px;
  display: flex;
  flex-direction: column;

  .shimmer-quiz-img-container {
    position: relative;

    .youtube-cover-img {
      width: 100%;
      height: 194px;
      border-radius: 16px 16px 0 0;
    }

  }

  .shimmer-quiz-card-description {
    display: flex;
    flex-direction: column;
  }

  .quiz-card-title,
  .quiz-card-time {
    height: 20px;
    margin: 5px 0;
  }

  .quiz-card-title {
    width: 80%;
    margin-top: 10px;
  }

  .quiz-card-time {
    width: 60%;
  }
}

.shimmer {
  animation: shimmer-loading 1s linear infinite alternate;
}

@keyframes shimmer-loading {
  0% {
    background-color: $challenge-carousel-loader-start;
  }
  100% {
    background-color: $challenge-carousel-loader-end;
  }
}
