@import '../../../common/commonConstant.scss';

.hnw-home-header-container{
  background-image: url('/images/challenge/team-dashboard-bg.svg');
  background-repeat: no-repeat;
  background-size: 100%;
  height: 100%;
  font-family: $sukhmaitext;

  .home-page-no-food-log-container {
    box-shadow: 0px 4px 20px 0px $challenge-card-shadow;
    border-radius: 16px;
    display: flex;
    justify-content: space-between;
    background: $a-serviceCard;
    margin: 0 16px;

    .no-data-card-container {
      display: flex;
      align-items: center;
      flex-direction: column;
    }
  }

  .home-page-no-exercise-log-container {
    box-shadow: 0px 4px 20px 0px $challenge-card-shadow;
    border-radius: 16px;
    display: flex;
    justify-content: space-between;
    background: $a-serviceCard;

    .no-data-card-container {
      display: flex;
      align-items: center;
      flex-direction: column;
      .home-exercise-no-img{
        margin-bottom: 4px;
      }
      .home-exercise-no-data, .home-exercise-no-data-subtitle{
        font-size: 16px;
        font-weight: 700;
        line-height: 24px;
        font-family: $sukhmaitext;
        color: $black;

      }
    }
  }

  .home-page-no-water-target-log-container {
    box-shadow: 0px 4px 20px 0px $challenge-card-shadow;
    border-radius: 16px;
    display: flex;
    justify-content: space-between;
    background: $a-serviceCard;

    .no-data-card-container {
      display: flex;
      align-items: center;
      flex-direction: column;
      .water-target-no-img{
        margin-bottom: 8px;
      }
      .home-water-no-data, .home-water-no-data-subtitle{
        font-size: 16px;
        font-weight: 700;
        line-height: 24px;
        font-family: $sukhmaitext;
        color: $black;
      }
    }
  }

  .home-page-no-weight-container {
    box-shadow: 0px 4px 20px 0px $challenge-card-shadow;
    border-radius: 16px;
    display: flex;
    justify-content: space-between;
    background: $a-serviceCard;
    margin: 0 16px;

    .no-data-card-container {
      display: flex;
      align-items: center;
      flex-direction: column;
    }
  }

  .home-page-no-bmi-container {
    box-shadow: 0px 4px 20px 0px $challenge-card-shadow;
    border-radius: 16px;
    display: flex;
    justify-content: space-between;
    background: $a-serviceCard;
    margin: 20px 16px;

    .no-data-card-container {
      display: flex;
      flex-direction: column;
      padding: 16px;

      .home-no-bmi-title {
        display: flex;

        &-bmi {
          font-size: 16px;
          line-height: 24px;
          font-weight: 700;
          color: $black;
          font-family: $gtwalsheimpro;
          margin-right: 5px;
        }

        &-header {
          font-size: 18px;
          line-height: 27px;
          font-weight: 700;
          color: $black;
          font-family: $sukhmaitext;
        }
      }

      .home-bmi-bottom-section {
        display: flex;
        flex-direction: column;
        align-items: center;

        .home-bmi-value {
          font-size: 28px;
          line-height: 48px;
          font-weight: 700;
          color: $black;
          font-family: $gtwalsheimpro;
        }

        .home-bmi-des {
          font-size: 14px;
          line-height: 21px;
          font-weight: 500;
          color: $black;
          font-family: $sukhmaitext;
        }
      }

    }
  }

  .header-bottom {
    padding-top: 20px;
  }

  .challenge-my-bmi-container {
    padding-bottom: 20px;
    margin-top: 16px;
  }

  .bmi-range-slider .bmi-slider-value .bmi-slider-count {
    font-size: 40px;
    font-weight: 700;
    font-family: $gtwalsheimpro;
    line-height: 48px;
    letter-spacing: -0.02em;
    text-align: left;
  }

  .container-fluid {
    padding-left: 16px;
    padding-right: 16px;
  }

  .banner-container {
    overflow-x: hidden;
    margin-bottom: 8px;

    .banner-img-warpper {
      margin-top: 20px;
      position: relative;
      height: 100%;
      overflow: hidden;
      z-index: 2;

      .bg-lg {
        display: block;
      }

      .bg-sm {
        display: none;
      }

      @media (max-width: 324px) {
        .bg-lg {
          display: none;
        }

        .bg-sm {
          display: block;
        }
      }
    }
  }

  .text-center {
    text-align: center;
  }

  .text-right {
    text-align: right;
  }

  .w-100 {
    width: 100%;
  }

  .p-0 {
    padding: 0;
  }

  .pr-0 {
    padding-right: 0;
  }

  .pl-0 {
    padding-left: 0;
  }

  .ml-5 {
    margin-left: 5px;
  }

  .mr-10 {
    margin-right: 10px;
  }

  .position-relative {
    position: relative;
  }

  .green {
    color: $homegreen !important;
  }

  .btn-primary {
    background: $homegreen;
    border-radius: 38px;
    font-size: 16px;
    padding: 6px 8px;
    border-color: $homegreen;
    display: flex;
    font-weight: 700;

    &:focus,
    &:hover,
    &:active {
      background: $homegreen !important;
      border-color: $homegreen !important;
    }
  }

  .btn-primary-bottom {
    padding: 5px 38px;
  }

  .btn-secondary {
    background: $bgwhite;
    border-radius: 38px;
    font-size: 16px;
    padding: 5px 28px;
    border-color: $homegreen;
    display: flex;
    color: $gray8;
  }

  .green-btn {
    border: 1px solid $homegreen1;
    outline: none;
    background: $bgwhite;
    border-radius: 8px;
    color: $homegreen1;
    display: flex;
    align-items: center;
    margin-top: 10px;
    font-size: 12px;
    padding: 4px 10px 4px 6px;
    font-weight: 500;
    height: 24px;
  }

  .red-btn {
    border: 1px solid $homemaroon;
    outline: none;
    background: $bgwhite;
    border-radius: 8px;
    color: $homemaroon;
    display: flex;
    align-items: center;
    margin-top: 10px;
    font-size: 12px;
  }

  .green-btn,
  .red-btn {
    img {
      margin-right: 5px;
    }
  }

  .health-log-section {
    .home-log-container {
      .food-log-container {
        display: flex;
        margin: 12px;
        gap: 12px;

        &.two-cards>div {
          width: 50%;
        }

        &>div {
          flex-grow: 1;
        }
      }
    }
  }

  .drink-excercise-container {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 16px;
    margin-top: 20px;
  }
}