@import "../commonConstant.scss";


.cloud-loader-wrapper {
  width: 100%;
  height: 100%;
  display: flex;
  background: $a-black;
  opacity: 0.7;
  z-index: 800;
  transition: opacity 500ms ease-in;
  position: absolute;
  justify-content: center;
  align-items: center;

  .cloud-loader-wrapper-img {
    width: 126px;
    height: 135px;

    img {
      width: 100%;
      height: 100%;
      object-fit: contain;
    }
  }

}