@import "../../../common/commonConstant.scss";

body::-webkit-scrollbar:has(.challenge-team-dashboard-container) {
    display: none; // Safari and Chrome
}

.challenge-team-dashboard-container {
    background-image: url('/images/challenge/team-dashboard-bg.svg');
    background-repeat: no-repeat;
    background-size: cover;
    min-height: 100vh;
    background-position: center -80px;
    overflow-x: hidden;
    background-attachment: fixed;

    .home-fixed-button {
        border-radius: 6px;
        z-index: 10;
    }


    .challenge-team-dashboard-body {
        &-member-section {
            margin-top: 20px;
        }

        .challenge-team-dashboard-error {
            width: 100%;
            padding: 0 16px;
            margin-top: 17px;

            .rect-badge-container {
                height: 31px;
                padding: 5px 16px;

                .rect-badge-description {
                    display: flex;
                    gap: 1.5px;

                    .rect-badge-left-section {
                        margin-right: 3.15px;

                        img {
                            width: 18px;
                            height: 18px;
                            object-fit: cover;
                        }
                    }

                    .rect-badge-right-section {
                        display: flex;
                        font-size: 14px;
                        line-height: 21px;
                        font-weight: 700;
                        font-family: $sukhmaitext;
                        gap: 1.5px;
                    }
                }
            }


        }
    }

    .rect-badge-detail-section {
        height: 21px;
        font-size: 14px;
        line-height: 21px;
        text-align: center;
        font-weight: 700;
        font-family: $sukhmaitext;
    }

    &-details {
        display: flex;
        justify-content: center;
        margin-top: 40px;
        margin-bottom: 108px;
    }

    .team-dashboard-home-indicator {
        position: fixed;
        bottom: 0;
        width: 100%;

        img {
            width: 100%;
        }

    }
}