@import "../commonConstant.scss";

.progress-outer {
    box-shadow: 2px 2px 4px 0px $progress-bar-shadow inset;
    background: linear-gradient(111.66deg, $background-progres-dark 27.53%, $background-progress-light 108.05%);
    height: 8px;
    border-radius: 13px;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;

    .progress {
      height: 4px;
      width: 100%;
      margin: 0 3px;
      border-radius: 24px;

      .progress-bar.bg-fill {
        background: linear-gradient(270deg, $challenge-progress-gradient1 0%, $challenge-progress-gradient2 100%);
      }
    }
  }