@import '../../common/commonConstant.scss';

.health-norecord-wrapper {
    display: flex;
    gap: 16px;
    width: 50%;

    .health-norecord-info {
        display: flex;
        border-radius: 12px;
        width: 100%;
        flex-direction: column;
        text-align: center;
        box-shadow: 1px 8px 18px 0px $shadowgrey3;

        .health-norecord-img-wrapper {
            display: flex;
            justify-content: center;
            align-items: center;
        }

    }
}