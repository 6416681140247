@import '../../common/commonConstant.scss';
.order-summar-container {
    width: 100%;
}

.body {
    padding: 15px;

    .content-alignment {
        display: flex;
        flex: 1;
        justify-content: space-between;
        flex-direction: row;
        margin-bottom: 15px;
        width: 100%;
    }

    .product-description {
        display: flex;
        flex: 1;
        flex-direction: column;
        justify-content: space-between;
        align-items: flex-start;
        .product-id {
            font-family: $sukhmaitext;
            color: #707070;
            font-size: 12px;
            font-style: normal;
            font-weight: 500;
        }

        .product-name {
            font-family: $sukhmaitext;
            font-size: 14px;
            font-style: normal;
            font-weight: 500;
        }

        .product-qty {
            font-family: $sukhmaitext;
            color: #707070;
            font-size: 12px;
            font-style: normal;
            font-weight: 500;
        }
    }

    .price-container {
        display: flex;
        flex: 1;
        justify-content: end;
        align-items: flex-end;
        .product-price {
            font-family: $sukhmaitext;
            justify-content: right;
            font-size: 16px;
            font-style: normal;
            font-weight: 700;
        }

        .product-pvbv {
            font-family: $sukhmaitext;
            font-size: 12px;
            font-style: normal;
            font-weight: 500;
            color: #707070;
        }
    }

    .summary-divider {
        width: 100%;
        justify-content: center;
        border-top: 1px solid #E4E4E4;
        margin-bottom: 15px;
    }

    .total-alignment {
        display: flex;
        flex: 1;
        flex-direction: column;
        margin-bottom: 15px;
        .total-items-alignment {
            display: flex;
            justify-content: space-between;
        }
        .total-pvbv {
            font-family: $sukhmaitext;
            font-size: 16px;
            font-style: normal;
            font-weight: 500;
            letter-spacing: 0px !important;
            display: flex;
            justify-content: space-between;
            align-items: flex-end;
        }
        .heading {
            font-family: $sukhmaitext;
            display: flex;
            justify-content: space-between;
            align-items: flex-start;
            font-size: 14px;
            font-style: normal;
            font-weight: 500;
            letter-spacing: 0px !important;
        }

        .total-price {
            font-family: $sukhmaitext;
            justify-content: right;
            font-size: 16px;
            font-style: normal;
            font-weight: 700;
            letter-spacing: 0px !important;
        }
    }
}