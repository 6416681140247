@import '../../../common/commonConstant.scss';

.custom-android-camera {
  height: 100vh;
  background-color: black;
  .display-none {
    display: none;
  }
  .open {
    background-color: $a-backgorund-blur;
    backdrop-filter: blur(5px);
    z-index: 99;
  }
  .preview-popup-container {
    display: grid;
    place-items: center;
    padding: 16px;
    .preview-img {
      border-radius: 16px;
      width: 340px;
      height: 600px;
    }
    .preview-action-btn {
      display: flex;
      justify-content: space-between;
    }
  }
  &-container-flash {
    display: flex;
    justify-content: flex-start;
    position: absolute;
    top: 20px;
    width: 100%;
    left: 20px;
  }
  &-container-actions {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    justify-items: center;
    align-items: center;
    position: absolute;
    bottom: 20px;
    height: auto;
    width: 100%;
    grid-gap: 16px;

    &-cancel {
      //styleName: 🇹🇭Thai/body M bold;
      font-family: $sukhmaitext;
      font-size: 16px;
      font-weight: 700;
      line-height: 24px;
      text-align: center;
      color: $white;
    }
  }
}
