@import "../../../../../common/commonConstant.scss";

.month-graph-container {
  &-content {
    background: $white;
    border-radius: 12px;
    padding: 16px 12px;
    gap: 32px;
    box-shadow: 0px 4px 12px 0px $shadowgrey3;

    .month-graph-tabs {
      display: flex;
      justify-content: center;
      margin-top: 12px;
    }

    .legend-wrapper {
      display: flex;
      justify-content: center;
      gap: 20px;

      .legend {
        font-family: $sukhmaitext;
        font-weight: 500;
        font-size: 12px;
        line-height: 16px;
        color: $gray12;

        &::before {
          content: '';
          display: inline-block;
          height: 9px;
          width: 9px;
          border-radius: 50%;
          margin-right: 8px;
        }

        &__food::before {
          content: '';
          background: $foodDark;
        }

        &__exercise::before,
        &__exercise_burn::before {
          background: linear-gradient(180deg, $exerciseDark 0%, $exerciseLight 100%);

        }
      }
    }
  }
}