@font-face {
    font-family: "Sukhumvit Set";
    src: local("Sukhumvit Set"), url(/font/SukhumvitSet-Text.ttf) format("truetype");
}

@font-face {
    font-family: "Sukhumvit Set SemiBold";
    src: local("Sukhumvit Set SemiBold"), url(/font/SukhumvitSet-Bold.ttf) format("truetype");
}

@font-face {
    font-family: "Sukhumvit Set Medium";
    src: local("Sukhumvit Set Medium"), url(/font/SukhumvitSet-Medium.ttf) format("truetype");
}

@font-face {
    font-family: "Sukhumvit Set tadmai bold";
    src: local("Sukhumvit Set Bold"), url(/font/SukhumvitTadmai_Bol.ttf) format("truetype");
}

@font-face {
    font-family: "Sukhumvit Set Extra Bold";
    src: local("Sukhumvit Set Bold3"), url(/font/SUKHUMVITTADMAI-EXTRABOLD.OTF) format("truetype");
}

@font-face {
    font-family: "Sukhumvit Set Extra Medium";
    src: local("Sukhumvit Set Medium"), url(/font/SukhumvitTadmai-Medium.otf) format("truetype");
}
