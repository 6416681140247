@import '../../../common/commonConstant.scss';

.library-category {
  .sub-header {
    position: relative;
    height: 250px;
    overflow: hidden;
    padding: 22px;

    .back-page {
      position: absolute;
      top: 22px;
      left: 22px;
      z-index: 999;
      color: #fff;
    }

    .header-bg {
      position: absolute;
      width: 100%;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);

      &::after {
        content: '';
        background: linear-gradient(
          180deg,
          rgba(0, 23, 83, 0.5) 0%,
          rgba(0, 23, 83, 0.5) 50%,
          rgba(255, 255, 255, 0.5) 100%
        );
        position: absolute;
        width: 100%;
        height: 100%;
        left: 0;
        top: 0;
      }

      img {
        width: 100%;
      }
    }

    .page-title-category {
      position: relative;
      text-align: center;
      color: #fff;
      font-weight: bold;
      font-size: 18px;
    }

    .category-name {
      position: absolute;
      color: #fff;
      text-align: center;
      left: 50%;
      top: 60%;
      transform: translate(-50%, -50%);

      .heading {
        font-size: 24px;
        font-weight: bold;
        color: #fff;
        word-break: break-word;
        text-align: left;
      }
    }
  }

  .library-category-list {
    padding: 22px;

    .select-category {
      padding: 10px;

      label {
        color: #687fce;
        font-size: 14px;
      }

      .ant-select {
        color: #00305e;
        margin-top: 10px;
        width: 100%;
        border-radius: 10px;

        .ant-select-selection-item {
          line-height: 60px;
          font-weight: bold;
          color: #00305e;
        }
      }

      .ant-select:not(.ant-select-customize-input) .ant-select-selector {
        height: 100%;
        border-radius: 10px;
        border-color: #ced8e3;
      }
    }

    .sub-category-item {
      display: flex;
      border-bottom: 2px solid #ced8e3;
      margin: 10px 0;
      padding: 15px 0;
      align-items: center;

      .img {
        width: 25%;
        overflow: hidden;
        height: 75px;
        margin-right: 20px;

        img {
          width: 100%;
        }
      }

      .title {
        color: #00305e;
      }
    }
  }
}

.category-form-wrap {
  background: #fff;
  min-height: 100vh;
  position: relative;

  .ant-form .category-flex-form {
    min-height: inherit;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }

  .ant-image {
    max-height: 375px;
    overflow: hidden;
    text-align: -webkit-center;

    img {
      display: none;

      &.active {
        display: block;
        height: 100%;
        width: 100%;
      }
    }
  }

  .category-name {
    padding: 45px 22px;

    .ant-input {
      border-color: #ced8e3;
      height: 52px;
      border-radius: 10px;
      font-size: 16px;
    }

    .ant-form-item-label {
      color: #00305e;
      font-size: 15px;
    }
  }

  .upload-category-img {
    background: #f9f9f9;
    padding: 22px;

    .ant-upload-hint {
      color: #bcbcbc;
    }

    &.active {
      padding: 0;
      position: relative;

      .ant-form-item-control-input {
        min-height: 0;
      }
    }

    .ant-upload.ant-upload-drag p.ant-upload-drag-icon .anticon {
      font-size: 23px;
      color: #bcbcbc;
    }

    .ant-upload.ant-upload-drag {
      background: #fff;
      border-radius: 15px;
      height: 285px;

      .ant-upload {
        padding: 0;
      }
    }

    .ant-upload.ant-upload-drag:not(.ant-upload-disabled):hover {
      border-color: #d9d9d9;
    }

    .ant-upload-list {
      display: none;
    }

    .upload-file-area.active {
      position: absolute;
      height: 37px;
      width: auto;
      bottom: 20px;
      left: 50%;
      transform: translate(-50%, 0);
      padding: 0 20px;
      border: 1px solid;
    }
  }

  .action {
    width: 100%;
    bottom: 0;
    padding: 0 30px 22px;
    text-align: center;

    .btn {
      background-color: #2c2c2c;
      height: 60px;
      border-radius: 60px;
      width: 100%;
      color: #fff;
      font-size: 18px;
      font-weight: bold;
    }

    .ant-btn-link {
      color: #2c2c2c;
      font-size: 18px;
      font-weight: bold;
    }
  }

  .action-edit-category {
    display: flex;

    .btn-outline {
      border: 1px solid #2c2c2c;
      height: 60px;
      width: 100px;
      text-align: center;
      border-radius: 60px;
      font-size: 18px;
      font-weight: bold;
      margin-left: 10px;
    }
  }
}
