@import '../../../../../common/commonConstant.scss';

.goal-setup-linear-row-thumbnail-component {
  background: $flwhite;
  padding: 8px;
  border-radius: 6px;
  gap: 8px;
  font-size: 16px;
  font-weight: 500;
  font-family: $sukhmaitext;

  &-left {
    height: 48px;
    width: 48px;
    background-color: $bgwhite;
    border-radius: 12px;
  }

  &-right {
    width: 100%;
    overflow: hidden;
    text-overflow: ellipsis;

    &-title {
      font-size: 14px;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      display: inline-block;
      width: 100%;
    }

    &-amount {
      gap: 4px;

      &-value {
        font-weight: 700;
        font-family: $sukhmaitext;
      }

      &-unit {
        color: $gray12;
      }
    }
  }
}