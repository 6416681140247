@import '../../common/commonConstant.scss';


.health-summary-result-wrapper {
    display: flex;
    gap: 16px;
    width: 100%;
    height: 145px;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    border-radius: 12px;

    &.--food {
        background-image: url('/images/healthAndWellness/foodLog/summary-food-bg-img.svg');
    }

    &.--water {
        background-image: url('/images/healthAndWellness/foodLog/summary-water-bg-img.svg');
    }

    .health-summary-result-info {
        display: flex;
        border-radius: 12px;
        width: 100%;
        flex-direction: column;
        text-align: center;
        box-shadow: 1px 8px 18px 0px $shadowgrey3;
        padding: 20px 12px;

        .health-summary-result-img-wrapper {
            display: flex;

            img {
                object-fit: cover;
                width: 24px;
                height: 24px;
            }

            .health-summary-result-name {
                font-size: 20px;
                line-height: 24px;
                font-weight: 700;
                font-family: $sukhmaitext;
                color: $gray12;
                margin-left: 6px;
            }

        }

        .health-summary-result-info-wrapper {
            display: flex;
            flex-direction: column;
            margin-top: 10px;

            .header {
                font-size: 24px;
                font-weight: 800;
                font-family: $sukhmaibold;
                color: $black;
            }

            .info {
                font-size: 16px;
                font-weight: 700;
                font-family: $sukhmaitext;
                color: $gray12;
            }
        }
    }
}