@import "../../../common/commonConstant.scss";

body::-webkit-scrollbar:has(.my-badge-container) {
  display: none; // Safari and Chrome
}

.my-badge-container {
  margin-bottom: 100px;
  margin-top: 8px;

  .badges-header-wrapper {
    padding-top: 0;
    transition: all 0.3s ease;
  }

  .my-badge-title {
    font-size: 20px;
    font-weight: 700;
    line-height: 30px;
    font-family: $sukhmaitext;
    margin-bottom: 20px;
    padding-inline: 16px;
    color: $black;

    &.title-hide {
      display: none;
    }
  }

  .nav-container {
    margin-bottom: 40px;

    .challenge-tab-list {
      padding: 0 16px;
      gap: 0px;

      button {
        width: 50%;
      }
    }

    &.title-hide {
      margin-top: 46px;
    }
  }

  .my-mission-badges-title {
    font-size: 18px;
    font-weight: 700;
    line-height: 27px;
    font-family: $sukhmaitext;
    margin-bottom: 20px;
    padding-inline: 16px;
    color: $black;
  }

  .my-coins-container {
    margin-bottom: 20px;
    padding-inline: 16px;

    .team-height {
      height: 188px !important;
    }

    .my-coins-category-title {
      font-size: 18px;
      font-weight: 700;
      line-height: 27px;
      font-family: $sukhmaitext;
      margin-bottom: 20px;
      color: $black;
    }

    .my-all-badges-container {
      display: flex;
      flex-wrap: wrap;
      row-gap: 16px;
      padding-top: 16px;
      align-items: center;

      .my-badges-single {
        text-align: center;
        width: 33.3333%;
        display: flex;
        flex-direction: column;
        gap: 4px;
        align-items: center;
        height: 165px;

        .progress-count-container {
          display: flex;
          flex-direction: column;
          align-items: center;
          width: 100%;
          gap: 4px;
        }

        .img-badge-container {
          position: relative;

          .badge-img {
            position: absolute;
            top: 50%;
            height: 84px !important;
            left: 50%;
            transform: translate(-50%, -50%);
          }
        }

        .progress-outer {
          width: 85%;

          .progress .progress-bar {
            border-radius: 24px;
          }
        }


        .user-badge {
          align-self: center;
          width: 45%;
          padding: 2px 8px 0px 8px;
          gap: 2px;
          border-radius: 8px;
          box-shadow: 2px 3px 6px 0px $quiz-btn-green-shdw;
          display: flex;
          justify-content: center;
          align-items: flex-start;

          .user-icon {
            width: 14px;
            height: 14px;
          }

          .badge-count {
            font-family: $gtwalsheimpro;
            font-size: 12px;
            font-weight: 400;
            line-height: 18px;
            text-align: center;
          }
        }

        img {
          height: 96px;
        }

        p {
          font-size: 14px;
          line-height: 21px;
          font-weight: 400;
          padding: 0 8px;
          font-family: $sukhmaitext;
          height: 42px;
          margin-bottom: 4px;
        }
      }
    }

    .sticky {
      position: fixed;
      width: 100%;
      z-index: 99;
      top: 0;
      padding-top: 0;
      background: $header-sticky-transparent;
      margin-bottom: 0;
      box-shadow: 0px 4px 20px 0px $challenge-card-shadow;
    }
  }

  .my-mission-badges-container {
    margin-bottom: 20px;
    padding-inline: 16px;
    display: flex;

    .my-coins-category-title {
      font-size: 18px;
      font-weight: 700;
      line-height: 27px;
      font-family: $sukhmaitext;
      margin-bottom: 20px;
    }

    .my-all-badges-container {
      width: 33.3333%;
      text-align: center;
      display: flex;
      flex-direction: column;
      align-items: center;

      .my-badges-single {
        text-align: center;
        display: flex;
        flex-direction: column;
        gap: 4px;
        align-items: center;
        width: 100%;

        .progress-count-container {
          display: flex;
          flex-direction: column;
          align-items: center;
          width: 100%;
          gap: 4px;
        }

        .badge-img-bg {
          width: 92px;
          height: 92px;
        }

        .badge-image {
          width: 82px;
          height: 82px;
          position: absolute;
          transform: translate(0%, 7%);
        }

        .img-badge-container {
          position: relative;

          .badge-img {
            position: absolute;
            top: 50%;
            height: 84px !important;
            left: 50%;
            transform: translate(-50%, -50%);
          }
        }

        .progress-outer {
          width: 85%;

          .progress .progress-bar {
            border-radius: 24px;
          }
        }

        .user-badge {
          align-self: center;
          width: 45%;
          padding: 2px 8px 0px 8px;
          gap: 2px;
          border-radius: 8px;
          box-shadow: 2px 3px 6px 0px $quiz-btn-green-shdw;
          display: flex;
          justify-content: center;
          align-items: flex-start;

          .user-icon {
            width: 14px;
            height: 14px;
          }

          .badge-count {
            font-family: $gtwalsheimpro;
            font-size: 12px;
            font-weight: 400;
            line-height: 18px;
            text-align: center;
          }
        }

        img {
          height: 96px;
        }

        p {
          font-size: 14px;
          line-height: 21px;
          font-weight: 400;
          padding: 0 8px;
          font-family: $gtwalsheimpro;
          height: 42px;
          margin-bottom: 4px;
        }
      }
    }
  }

  .my-coins-container:last-of-type {
    margin-bottom: 0;
  }
}