@import '../../../../../common/commonConstant.scss';

body:has(.food-detail-page.--no_scroll) {
    overflow: hidden;
}

.food-detail-page {
    background: url("/images/healthAndWellness/hnwFoodCalories/food-details-bg.svg");
    background-repeat: no-repeat;
    height: 100vh;
    width: 100%;
    background-position: 0px -30px;
    font-family: $sukhmaitext;
    overflow-y: hidden;

    .food-details-banner {
        backdrop-filter: blur(5px);

        .white-box {
            position: absolute;
            margin-top: 16px;
            border-radius: 0 38px 38px 0;
            padding: 6px 8px;
        }

        span {
            margin-right: 18px;
        }
    }

    .food-details-description-card-container {
        background: $bgwhite;
        border-radius: 24px 24px 0 0;
        padding: 16px 20px 40px 20px;
        box-shadow: 0px -8px 24px 0px $homeblack;
        position: absolute;
        width: 100%;
        bottom: 0;

        &.no-box-shadow {
            box-shadow: none;
            position: relative;
        }

        .description-head-text {
            font-size: 16px;
            font-weight: 700;
            line-height: 24px;
            float: left;
            color: $gray12;
        }

        .category-img-wrapper {
            float: right;
            min-width: 50px;
            height: 24px;
            border: none;
            outline: none;

            .tag-img {
                height: 100%;
            }
        }

        .food-details-description {
            height: 43vh;

            .food-details-title {
                font-size: 24px;
                font-weight: 800;
                margin: 4px 0;
                word-wrap: break-word;
                width: 100%;
                display: -webkit-box;
                -webkit-box-orient: vertical;
            }

            .food-details-quantity-container {
                display: flex;
                justify-content: space-between;
                align-items: center;

                .food-details-quantity-title {
                    font-size: 18px;
                    font-weight: 700;
                    color: $a-gray;
                }
            }

            .food-details-quantity-value-container {
                display: flex;
                align-items: center;

                .food-details-quantity-value {
                    font-size: 24px;
                    font-weight: 800;
                    margin-right: 2px;
                }

                .food-details-quantity-value-unit {
                    font-size: 14px;
                    font-weight: 500;
                    color: $a-gray;
                }
            }

            .line {
                height: 1px;
                background: linear-gradient(to right, transparent 50%, $a-darkgray 50%);
                background-size: 6px 2px, 100% 2px;
            }

            .food-details-amount-energy-container {
                display: flex;
                margin-top: 5px;

                .food-details-amount-container,
                .food-details-energy-container {
                    display: flex;
                    justify-content: space-between;
                    width: 50%;
                    align-items: center;
                }

                .food-details-amount-container {
                    border-right: 1px dotted $a-gray;
                    padding-right: 10px;
                    margin-right: 10px;
                }

                .food-details-amount-container,
                .food-details-energy-container {

                    .food-details-amount-title,
                    .food-details-energy-title,
                    .food-details-amount-value,
                    .food-details-energy-value {
                        font-size: 18px;
                        font-weight: 700;
                    }

                    .food-details-amount-title,
                    .food-details-energy-title {
                        color: $a-gray;
                    }

                    .food-details-amount-value,
                    .food-details-energy-value {
                        color: $a-black;
                    }

                    .food-details-amount-unit,
                    .food-details-energy-unit {
                        font-size: 14px;
                        font-weight: 500;
                        color: $a-gray;
                    }
                }
            }

            .nutrition-chart-container {
                display: flex;
                width: 100%;
                justify-content: space-between;
                margin-top: 20px;

                .nutrition-chart-single {
                    display: flex;
                    flex-direction: column;
                    text-align: center;
                    align-items: center;

                    .nutrition-chart-title-wrapper {
                        display: flex;
                        align-items: center;
                        margin-bottom: 8px;

                        .nutrition-chart-title {
                            font-size: 14px;
                            font-weight: 700;
                            color: $a-gray;
                            line-height: 20px;
                        }
                    }
                }

                .card {

                    &.progress-card {
                        border: none;
                    }

                    &.progress-card-green-1 {
                        &.card {
                            svg {
                                circle {
                                    stroke: $a-circleGreen1;

                                    &:last-of-type {
                                        stroke: $fgreen;
                                    }
                                }
                            }
                        }
                    }

                    &.progress-card-green-2 {
                        &.card {
                            svg {
                                circle {
                                    stroke: $a-circleGreen2;

                                    &:last-of-type {
                                        stroke: $fgreen1;
                                    }
                                }
                            }
                        }
                    }

                    &.progress-card-green-3 {
                        &.card {
                            svg {
                                circle {
                                    stroke: $a-circleGreen3;

                                    &:last-of-type {
                                        stroke: $fgreen2;
                                    }
                                }
                            }
                        }
                    }

                    svg {
                        position: relative;
                        width: 74px;
                        height: 74px;
                        transform: rotate(-90deg);

                        circle {
                            width: 100%;
                            height: 100%;
                            fill: none;
                            stroke: $a-circleStroke;
                            stroke-width: 8;
                            stroke-linecap: round;

                            &:last-of-type {
                                stroke-dasharray: 200px;
                                stroke-dashoffset: calc(200px - (200px * var(--percent)) / 100);
                            }
                        }
                    }
                }
            }


            .consumption-quantity-container {
                margin-top: 24px;

                .consumption-quantity-title {
                    font-size: 18px;
                    font-weight: 700;
                    margin-bottom: 8px;
                    line-height: 26px;
                }

                .service-size-card-container {
                    display: flex;
                    justify-content: space-between;
                    gap: 8px;

                    .service-size-card-single {
                        background: $bmigrey;
                        padding: 10px 12px;
                        border-radius: 12px;
                        text-align: center;
                        color: $a-gray;

                        &.service-size-card-single-active {
                            background: $homegreen;
                            color: $a-serviceCard;
                        }

                        .service-size-card-title {
                            display: flex;
                            align-items: center;
                            margin-bottom: 6px;

                            span {
                                font-size: 14px;
                                font-weight: 500;
                                padding-left: 2px;
                            }
                        }

                        .service-size-card-value {
                            font-size: 18px;
                            font-weight: 700;
                            line-height: 20px;
                        }
                    }

                }
            }

        }
    }

    .description-card-container-height {
        height: 58%;
    }

    .description-card-container-height-full {
        height: 100%;
    }

    .green {
        color: $bmigreen !important;
    }

    .btn-red {
        background: $dark-red2;
        border-radius: 38px;
        font-size: 16px;
        padding: 6px 8px;
        border-color: $dark-red2;
        font-weight: 700;
        height: 48px;
        color: $a-serviceCard;
        font-family: $sukhmaitext;
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .btn-full-width {
        height: 48px;
        font-family: $sukhmaitext;
        align-items: center;
        justify-content: center;
        text-align: center;
    }

    .scroll-content {
        overflow-y: auto;
        height: 38vh;

        @media screen and (max-height: 667px) {
            height: 33vh;
        }

        @media screen and (max-height: 740px) {
            height: 33vh;
        }
    }

    .max-height {
        height: 100%;
    }

    .pb-60 {
        padding-bottom: 60px;
    }

    .modal-picker {
        .liff-numpad-panel.open .numpad-panel {
            height: 255px;
        }
    }

    .modal-picker-wrapper {

        .modal-picker-column:nth-child(1),
        .modal-picker-column:nth-child(2),
        .modal-picker-column:nth-child(3) {
            flex: initial !important;
        }

        .modal-picker-column:nth-child(1),
        .modal-picker-column:nth-child(3) {
            width: 30%;
        }

        .modal-picker-item {
            font-family: $sukhmaitext;
            font-size: 16px;
            font-weight: 500;
            line-height: 22px;
            color: $black;
        }

        &>div:last-of-type {
            z-index: -1;
            background: $a-offwhite;

            &>div {
                background: none !important;
            }
        }
    }

    .footer-container-wrapper {
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        position: fixed;
        bottom: -2px;

        .footer-container-btn-wrapper {
            display: flex;
            align-items: center;
            width: 100%;
            background: $white;
            padding: 12px 20px 22px 20px;
            gap: 8px;

            .btn-green {
                height: 48px;
                font-family: $sukhmaitext;
                font-size: 16px;
                font-weight: 700;
                border-radius: 38px;
                padding: 6px 8px;
                margin-top: 10px;
                display: flex;
                justify-content: center;
                width: 100%;
                align-items: center;
            }

            .btn-red {
                background: $dark-red2;
                border-radius: 38px;
                font-size: 16px;
                padding: 6px 8px;
                border-color: $dark-red2;
                font-weight: 700;
                height: 48px;
                color: $white;
                font-family: $sukhmaitext;
                display: flex;
                justify-content: center;
                align-items: center;
                width: 100%;
            }

            .btn-green,
            .btn-green:focus,
            .btn-green:active,
            .btn-green:hover {
                border: 1px solid rgba(60, 157, 71, 1);
                background: rgba(60, 157, 71, 1);
                color: $white;
                box-shadow: none;
            }
        }
    }
}

@media(max-width:360px) {
    .food-detail-page .footer-container-wrapper {
        bottom: 6px
    }
}