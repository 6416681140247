@import '../commonConstant.scss';

.hnw-panel-delete {
    left: 0;
    bottom: 0;
    width: 100%;
    height: 0px;
    float: right;
    z-index: 2000;
    position: fixed;
    margin-top: 200px;
    margin-right: 34px;
    background: $a-backgroundBlack;
    .select-coupon-panel {
        left: 0;
        bottom: 0;
        width: 100%;
        height: 0px;
        position: fixed;
        background-color: white;
        border-top-left-radius: 20px;
        border-top-right-radius: 20px;
        transition: height 0.8s ease-in-out;
        max-width: 768px;
    
        >:nth-child(1) {
            border-radius: 24px 24px 0 0;
            height: 60%;
            padding: 15px 0 0 0;
            background-image: url("/images/healthAndWellness/assessment/assessment_retry_modal.svg");
            background-repeat: no-repeat;
            background-size: cover;
        }
    
        .btn-close {
            font-family: $sukhmaitext;
            top: 15px;
            right: 20px;
            border: none;
            position: absolute;
            font-size: larger;
            background-color: white;
            letter-spacing: 0px !important;
            border-radius: 38px;
            display: flex;
            padding: 6px 8px;
        }
    }
}

.hnw-panel-delete.open {
    height: 100vh;
    z-index: 100;
    .select-coupon-panel {
        height: 345px;
    }
}

.hdr.hnw-delete-modal-class {
    padding: 16px;
    margin-top: 32px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    .header-text {
        font-size: 18px;
        line-height: 26px;
        font-weight: 800;
        text-align: center;
        font-family: $sukhmaitext;
        color: $black;
    }

    .subheader-text {
        color: $dark-red2;
        font-size: 16px;
        line-height: 24px;
        font-weight: 700;
        text-align: center;
        font-family: $sukhmaitext;
        width: 300px;
    }

    >button {
        display: flex;
        background-color: $bgwhite;
        border-radius: 38px;
        padding: 6px 8px;

        >svg {
            width: 28px;
            height: 28px;
        }

    }
}