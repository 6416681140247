@import '../../../../common/commonConstant.scss';

.vdo-badge-card-container {

    margin-inline: 16px;

    .social-media-details-wrapper {
        display: flex;
        gap: 17px;
        width: 100%;

        .challenge-card-body {
            box-shadow: 0px 4px 20px 0px $challenge-card-shadow;
            border-radius: 16px;
            padding: 16px;
            padding-top: 8px;
            padding-bottom: 8px;
            background: $a-serviceCard;
        }

        .w-50 {
            width: 50%;
        }

        .align-half-cards {
            display: flex;
            justify-content: space-between;
        }

    }

    .no-medal-data-wrapper{

        .challenge-card-body {
            box-shadow: 0px 4px 20px 0px $challenge-card-shadow;
            border-radius: 16px;
            padding: 16px;
            padding-top: 8px;
            padding-bottom: 8px;
            background: $a-serviceCard;
            height: 80px;
            width: 100%;
            display: flex;
            .no-medal-data-card {
                display: flex;
                justify-content: space-between;
                flex-direction: row;
                width: 100%;
                align-items: center;


                .walking-steps-heading{
                    font-size: 17px;
                    font-weight: 700;
                    font-family: $sukhmaitext;
                    color: $black;
                }

                .walking-steps-amt{
                    font-size: 28px;
                    font-weight: 700;
                    font-family: $gtwalsheimpro;
                    color: $homegreen;
                }
            }
        }

    }
}