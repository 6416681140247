@import '../../../common/commonConstant.scss';
@import '../../../common/modal.scss';

// scss-docs-end modal-fullscreen-loop
.challenge-unlock-modal {
  &.--hidden {
    visibility: hidden;
  }

  .modal {
    backdrop-filter: blur(4px);
    background: $homeblack1;

    .challenge-unlock-firework {
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      height: 260px;
      margin: auto;
      z-index: 1;
    }
  }

  .badge-step-modal {
    .modal-content {
      max-width: 320px;
      width: 100%;
      margin: 0 auto;
      background-color: transparent;
      border: none;
      background-image: url('/images/challenge/badge-bg.svg');
      background-repeat: no-repeat;
      background-position: center;
      background-size: contain;

      @media (max-width: 324px) {
        background-size: contain;
      }
    }

    .modal-body {
      text-align: center;
      color: $black;
      font-family: $sukhmaitext;

      .modal-title {
        font-size: 24px;
        font-weight: 800;
        margin-bottom: 8px;
      }

      .modal-subtitle {
        font-size: 18px;
        font-weight: 500;

        @media (max-width: 324px) {
          margin-bottom: 5px;
        }

        &-greeting {
          font-weight: 400;

          &.--en {
            font-family: $gtwalsheimpro;
          }

          &.--thai {
            font-family: $sukhmaitext;
          }
        }
      }

      .badge-img {
        margin: 11px;
        width: 113px;
        position: relative;

        &-wrapper {
          position: relative;
          margin: 20px 0;

          .badge-img-bg {
            position: absolute;
            width: 132px;
            translate: -50% -50%;
            margin: auto;
            top: 50%;
            left: 50%;
            filter: drop-shadow(0px 2px 2px $white);
          }
        }
      }
    }

    .btn-light-green {
      background: linear-gradient(135deg, $challenge-success-green1 -39.29%, $challenge-success-green2 82.14%) padding-box,
        linear-gradient(132.38deg, $challenge-success-green3 -14.45%, $challenge-success-green4 122.44%) border-box;
      border: 4px solid transparent;
      box-shadow: 4px 4px 18px 0px $challenge-green;
      color: $white;
      font-size: 16px;
      font-weight: 800;
      font-family: $sukhmaitext;
      border-radius: 48px;
      display: block;
    }

    .btn-full {
      width: 100%;
    }
  }

  .challenge-share-container {
    margin-bottom: 24px;

    .map-goal-share-section {
      justify-content: center;
      gap: 24px;
    }
  }
}