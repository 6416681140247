@import '../../../../common/commonConstant.scss';

body:has(.activity-goal-details-page.--no_scroll) {
  overflow: hidden;
}

.hnw-goals-activity-container {
  .activity-goal-setting-custom-modal {
    .open {
      z-index: 99;
      .numpad-panel {
        height: calc(100vh - 100px);
      }
    }
    .liff-numpad-panel.open {
      background: $homeblack1;
      backdrop-filter: blur(15px);
    }
  }

  .activity-goal-details-page {
    .header-container {
      position: relative;
      background: $bgwhite;
      border: none;
      .home-title {
        span {
          font-size: 20px;
          font-weight: 800;
          font-family: $sukhmaibold;
        }
      }

      .activity-header-curve-img {
        position: absolute;
        bottom: -16px;
        top: initial;
      }
    }
    .activity-goal-details-container {
      background: url('/images/healthAndWellness/goals/activity/bg-goal-setting.svg');
      background-size: 100%;
      height: calc(100% - 122px);
      background-position: 0 -50px;
      padding-top: 50px;

      .container-fluid {
        padding-left: 16px;
        padding-right: 16px;
      }

      .activity-goal-details-inner-wrapper {
        .activity-main-goal-container {
          display: flex;
          flex-direction: column;
          gap: 12px;
          margin-bottom: 32px;
        }

        .activity-sub-goal-container {
          .activity-exercise-details-card-header {
            font-size: 18px;
            font-weight: 700;
            font-family: $sukhmaitext;
            display: flex;
            gap: 8px;
            align-items: center;
            margin-bottom: 16px;
          }

          .activity-goal-details-list {
            margin-bottom: 16px;

            li {
              display: flex;
              flex-direction: row;
              align-items: center;
            }
          }

          .exercise-goal-card-wrapper {
            display: flex;
            flex-direction: column;
            gap: 16px;
            padding-bottom: 120px;

            .exercise-goal-card {
              box-shadow: 1px 8px 18px 0px rgba(44, 44, 44, 0.1);
              background: #fff;
              border-radius: 12px;
              padding: 12px;
              display: flex;
              flex-direction: column;
              gap: 12px;

              .exercise-goal-card-header {
                display: flex;
                justify-content: space-between;
                align-items: center;

                .exercise-goal-card-header-left {
                  display: flex;
                  align-items: center;
                  gap: 12px;

                  span {
                    font-size: 18px;
                    font-weight: 700;
                    font-family: $sukhmaitext;
                  }
                }
              }

              .exercise-goal-card-body {
                display: flex;
                flex-direction: column;
                gap: 12px;
              }
            }
          }
        }
      }

      &.delete-activity-container {
        padding-top: 5px;

        .activity-sub-goal-container {
          ul {
            font-size: 14px;
          }
        }
      }
    }
    .footer-container-wrapper {
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;
      position: fixed;
      bottom: 0;

      .footer-container-btn-wrapper {
        display: flex;
        align-items: center;
        width: 100%;
        background: #fff;
        padding: 0px 20px 22px 20px;
        gap: 24px;

        .btn-green,
        .btn-disabled {
          height: 48px;
          font-family: $sukhmaitext;
          font-size: 16px;
          font-weight: 700;
          border-radius: 38px;
          padding: 6px 8px;
          margin-top: 10px;
          display: flex;
          justify-content: center;
          width: 48%;
          align-items: center;
        }

        .btn-red {
          background: #d91734;
          border-radius: 38px;
          font-size: 16px;
          padding: 6px 8px;
          border-color: #d91734;
          font-weight: 700;
          height: 48px;
          color: #fff;
          font-family: "Sukhumvit Tadmai";
          display: flex;
          justify-content: center;
          align-items: center;
        }

        .btn-green,
        .btn-green:focus,
        .btn-green:active,
        .btn-green:hover {
          border: 1px solid rgba(60, 157, 71, 1);
          background: rgba(60, 157, 71, 1);
          color: #fff;
          box-shadow: none;
        }
        .btn-red,
        .btn-red:focus,
        .btn-red:active,
        .btn-red:hover {
          border: 1px solid $dark-red2;
          background: $dark-red2;
          color: $white;
          box-shadow: none;
        }
        .btn-disabled,
        .btn-disabled:focus,
        .btn-disabled:active,
        .btn-disabled:hover {
          border: 1px solid #a0abc0;
          background: #a0abc0;
          color: #fff;
          box-shadow: none;
        }
        .form-group {
          label {
            &:before {
              margin-right: 10px;
            }
          }

          input.exercise-goal-checkbox-minus {
            &:checked + label {
              &:before {
                background: #a0abc0;
              }

              &:after {
                content: '–';
                transform: none;
                top: 0;
                left: 6px;
                color: #fff;
                border: none;
              }
            }
          }
        }
      }
    }
  }

  ul {
    margin: 0;
    padding-left: 16px;
  }
}
