@import "../../common/commonConstant.scss";

.hnw-setter-container {
    display: flex;
    flex-direction: column;
    gap: 13px;
    padding: 12px;
    border-radius: 12px;
    background-color: $bgwhite;
    box-shadow: 0px 4px 12px 0px $shadowgrey3;

    &__wrapper {
        display: flex;
        justify-content: space-between;
        align-items: center;
        width: 100%;
        padding-left: 6px;

        &__header {
            display: flex;
            justify-content: flex-start;
            align-items: center;
            gap: 12px;
            width: 100%;
        }
        
        &__values {
            gap: 8px;
            &-input {
                height: 40px;
                width: 100%;
                padding: 8px;
                border-radius: 12px;
                text-align: center;
                color: $theme-green;
                font-weight: 700;
                font-size: 16px;
                height: 40px;
            }
            button:disabled{
                opacity: 0.2;
            }
            &-border-green {
                border: 1px solid $hnw-green;
            }
            &-border-black {
                border: 1px solid $black;
            }
            &-inValid {
                color: $dark-red2;
            }
        }
        &__unit {
            justify-content: center;
            font-family: $sukhmaitext;
            font-size: 16px;
            font-weight: 500;
            line-height: 22px;
            color: $gray12;
        }

        &__quick-access-action{
            .hnw-log-stepper{
                width: -webkit-fill-available;
            }
        }

        &__footer {
            justify-content: start;
            gap: 10px;
            line-height: 16px;

            p {
                margin:0;
                font-family: $sukhmaitext;
                font-weight: 500;
                font-size: 12px;
                color: $hnw-golden;
            }
        }
    }
    &__text {
        font-family: $sukhmaitext;
        font-weight: 700;
        line-height: 26px;
        color: $black;

        &-small {
            font-size: 14px;
        }

        &-large {
            font-size: 18px;
        }
    }

    &__image {
        width: 40px;
        height: 40px;
        border-radius: 6px;
    }

    &__button {
        border: none;
        background: transparent;
    }

    &__numpad {
        liff-panel.open .select-coupon-panel {
            height: 275px;
            border-radius: 12px 12px 0 0;
          }
    }

    &__listCard {
        padding: 12px 6px 2px 6px;
        box-shadow: none;
        border: 1px solid #EDF0F7;
        border-radius: 6px;
    }
}