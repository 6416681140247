@import "../../../../common/commonConstant.scss";

.team-dashboard-footer-container{
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 12px;
    width: 100%;
    border-radius: 16px 16px 0 0;
    padding: 16px 16px 32px 16px;
    height: 92px;
    background: $bgwhite;
    position: fixed;
    bottom: 0;
    width: 100%;
    z-index: 9;
    .challenge-btn-container{
        width: 50%
    }
}