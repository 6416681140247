@import '../commonConstant.scss';

.hnw-two-button-modal {
  bottom: 0;
  height: initial;
  top: initial;

  .liff-numpad-panel.open {
    backdrop-filter: blur(6px);
    background-color: $homeblack1;

    .modal-header {
      border-bottom: none;
      height: 55px;

      .modal-header-content {
        display: flex;
        padding: 8px;
        margin-bottom: 8px;
        justify-content: flex-start;
        gap: 6px;
        border-bottom: 1px solid $a-greyborder;
        width: 100%;
        height: 55px;
        align-items: center;
      }

      button {
        display: flex;
        align-items: center;
      }

      span {
        font-size: 14px;
        font-weight: 700;
        color: $black;
        font-family: $sukhmaitext;
      }

      .btn-green-rounded {
        height: 22px;
        width: 22px;
        border: none;
        background: $homewhite;
        border-radius: 50%;
        display: flex;
        justify-content: center;
        align-items: center;
      }
    }

    .modal-body {
      height: 94px;
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: 16px;
      font-weight: 700;
      font-family: $sukhmaitext;
      color: $black;
      margin-top: 1rem;
      padding: 1rem 0 0 0;
    }

    .modal-footer {
      border-top: none;
      display: flex;
      justify-content: space-between;
      flex-wrap: nowrap;
      gap: 8px;

      button {
        width: 50%;
        height: 48px;
        border-radius: 48px;
        padding: 6px 8px;
        display: flex;
        align-items: center;
        justify-content: center;
        font-weight: 700;
        font-family: $sukhmaitext;

        &.btn-red {
          background: $dark-red2;
          color: $white;
          &:hover,
          &:focus,
          &:active {
            background: $dark-red2 !important;
            border-color: $dark-red2 !important;
            box-shadow: none;
          }
        }

        &.btn-primary {
          background: $a-btn-green;
          color: $homegreen;
          border-color: $a-btn-green;

          &:hover,
          &:focus,
          &:active {
            background: $a-btn-green !important;
            border-color: $a-btn-green !important;
            box-shadow: none;
          }
        }
      }
    }
  }
}