@import "../../../../common/commonConstant.scss";

.shimmer-question-card-single {
    margin-top: 20px;
    box-shadow: 0px 4px 20px 0px $challenge-card-shadow;
    padding: 16px;
    border-radius: 16px;
    background: $a-serviceCard;
  
    .shimmer-question-card-name {
      display: flex;
      flex-direction: column;
      gap: 8px;
      margin-bottom: 16px;
  
      .shimmer-text {
        background-size: 200% 100%;
        height: 20px;
        border-radius: 4px;
      }
  
      .shimmer-title {
        width: 150px;
      }
  
    }
  
    .shimmer-question-card-answer-options {
      display: grid;
      grid-template-columns: repeat(2, 1fr);
      gap: 16px;
      box-sizing: border-box;
  
      .shimmer-answer-option {
        background-size: 200% 100%;
        height: 40px;
        border-radius: 8px;
      }
  
      @media (max-width: 600px) {
        grid-template-columns: 1fr;
      }
    }
  
    .shimmer {
        animation: shimmer-loading 1s linear infinite alternate;
      }
      
      @keyframes shimmer-loading {
        0% {
          background-color: $challenge-carousel-loader-start;
        }
        100% {
          background-color: $challenge-carousel-loader-end;
        }
      }
  }
  
  