@import "../commonConstant.scss";

.circle-home-card {

    background: none;
    position: relative;
    display: flex;
    align-items: center;

    .percent {
        position: relative;
        display: flex;
        justify-content: center;
        align-items: center;

        svg {
            position: relative;
            width: 138px;
            height: 138px;
            transform: rotate(-90deg);
        }

        svg circle {
            width: 100%;
            height: 100%;
            fill: none;
            stroke: $a-circleStroke;
            stroke-width: 10;
            stroke-linecap: round;
        }

        svg circle:last-of-type {
            stroke-dasharray: 390px;
            stroke-dashoffset: calc(390px - 390px * var(--percent) / 100);
            stroke-linecap: round;
            stroke: url(#gradient);
        }

        .number {
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);

            h3 {
                font-weight: 200;
                font-size: 10px;

                span {
                    font-size: 14px;
                }
            }
        }
    }

    .exercise-circle {
        position: relative;
        display: flex;
        justify-content: center;
        align-items: center;

        svg {
            position: relative;
            width: 138px;
            height: 138px;
            transform: rotate(0deg);
        }

        svg circle {
            width: 100%;
            height: 100%;
            fill: none;
            stroke-width: 10;
            stroke-linecap: round;
        }

        svg circle:last-of-type {
            stroke-dasharray: 580px;
            stroke-dashoffset: calc(580px - 580px * var(--percent) / 100);
            stroke-linecap: round;
            stroke: url(#paint0_linear_495_40980);
        }

        .number {
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);

            h3 {
                font-weight: 200;
                font-size: 10px;

                span {
                    font-size: 14px;
                }
            }
        }
    }

    .water-circle {
        position: relative;
        display: flex;
        justify-content: center;
        align-items: center;

        svg {
            position: relative;
            width: 138px;
            height: 138px;
            transform: rotate(0deg);
        }

        svg circle {
            width: 100%;
            height: 100%;
            fill: none;
            stroke-width: 10;
            stroke-linecap: round;
        }

        svg circle:last-of-type {
            stroke-dasharray: 580px;
            stroke-dashoffset: calc(580px - 580px * var(--percent) / 100);
            stroke-linecap: round;
            stroke: url(#paint0_linear_495_40976);
        }

        .number {
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);

            h3 {
                font-weight: 200;
                font-size: 10px;

                span {
                    font-size: 14px;
                }
            }
        }
    }

    .food-circle {
        position: relative;
        display: flex;
        justify-content: center;
        align-items: center;

        svg {
            position: relative;
            width: 138px;
            height: 138px;
            transform: rotate(0deg);
        }

        svg circle {
            width: 100%;
            height: 100%;
            fill: none;
            stroke-width: 10;
            stroke-linecap: round;
        }

        svg circle:last-of-type {
            stroke-dasharray: 580px;
            stroke-dashoffset: calc(580px - 580px * var(--percent) / 100);
            stroke-linecap: round;
            stroke: url(#paint0_linear_495_40960);
        }

        .number {
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);

            h3 {
                font-weight: 200;
                font-size: 10px;

                span {
                    font-size: 14px;
                }
            }
        }
    }
}

.progress-card {
    border: none;
}

.progress-value {
    img {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
    }

    span {
        font-size: 12px;
        text-transform: uppercase;
    }

    .progress-value-inner {
        font-weight: 800;
        font-family: $sukhmaitext;
        font-size: 24px;
        text-align: center;
        line-height: 26px;
    }

    .color-black {
        color: $a-black;
    }

    .color-max {
        color: $greater-max-val;
    }

    .progress-value-unit-container {
        display: flex;
        font-size: 12px;
        font-weight: 500;
        color: $a-gray;
        gap: 2px;

    }

}