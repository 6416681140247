@import "../../../common/commonConstant.scss";

.terms-condition-container {
  background: $bggray1;
  height: calc(100vh - 54px);
  font-weight: 500;
  font-family: $sukhmaitext;

  .col-12 {
    padding: 0 12px;

    .terms-condition-title {
      margin-bottom: 15px;
      font-size: 20px;
      margin-top: 15px;
      font-weight: 800;
      padding: 0 5px;
      font-family: $sukhmaibold;
    }
  }

  .terms-conditions-content-wrapper {
    background-image: url(/images/termsConditions/bg-terms-condition.svg);
    background-repeat: no-repeat;
    background-size: cover;
    padding: 10px 20px;
    padding-top: 0;
    background-position: 0 24px;
    height: 100%;
    overflow-x: hidden;

    .card {
      box-shadow: 0px 4px 12px 0px $shadowgrey3;
      padding: 10px 15px;
      border-radius: 8px;
      background: $bgwhite;
      font-size: 14px;
      color: $black;
      font-weight: 500;
      border: none;
      min-height: calc(100vh - 115px);

      &.card-full {
        height: calc(100vh - 80px);
      }

      .card-content {
        .content-top-text {
          margin-top: 15px;
        }

        .card-list {
          margin-left: 3px;

          .card-list-single {
            display: flex;
            align-items: center;

            .card-list-dot {
              height: 2px;
              width: 2px;
              display: inline-block;
              border-radius: 50%;
              background: $jet-black;
              margin-right: 22px;
            }
          }
        }
      }
    }

    .terms-condition-button-group {
      display: flex;
      margin-top: 12px;
      justify-content: space-between;

      .mr-10 {
        margin-right: 10px;
      }

      .btn-secondary-custom {
        background: $bgwhite;
        border-radius: 38px;
        font-size: 16px;
        padding: 6px 8px;
        border-color: $homegreen;
        display: flex;
        justify-content: center;
        color: $gray8;
      }

      .btn-secondary-custom,
      .btn-primary-tc {
        width: 47%;
      }
    }
  }

  .terms-condition-fixed-button {
    position: fixed;
    display: normal;
    bottom: 10px;
    text-align: center;
    width: 100%;
    display: flex;
    justify-content: center;

    &.--hide {
      display: none;
    }

    button {
      &.btn-primary-tc {
        height: 36px;
        width: 166px;
      }

      img {
        padding-left: 5px;
      }
    }
  }

  .btn-primary-tc {
    background: $homegreen;
    border-radius: 38px;
    font-size: 16px;
    padding: 6px 8px;
    border-color: $homegreen;
    display: flex;
    justify-content: center;

    &:active,
    &:focus,
    &:hover {
      background: $homegreen !important;
      border-color: $homegreen !important;
    }
  }
}