@import "../../../common/commonConstant.scss";

body::-webkit-scrollbar:has(.challenge-list) {
    display: none; // Safari and Chrome
}

.challenge-list {
    padding: 12px 12px 185px;
    display: flex;
    flex-direction: column;
    gap: 12px;
    overflow: hidden;
}

.challenge-tab-list-home {
    display: flex;
    justify-content: flex-start;
    gap: 15px;
    margin-left: 24px;
}