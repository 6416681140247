@import "../../../../common/commonConstant.scss";

.question-card-single {
    margin-top: 20px;
    box-shadow: 0px 4px 20px 0px $challenge-card-shadow;
    padding: 16px;
    border-radius: 16px;
    background: $a-serviceCard;

    .question-card-name {
        font-size: 16px;
        font-weight: 500;
        display: flex;
        gap: 4px;
        padding-bottom: 16px;
        font-family: $sukhmaitext;
    }

    .question-card-answer-options {
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        gap: 16px;
        box-sizing: border-box;
    }

    &.question-card-full {
        .question-card-answer-options {
            grid-template-columns: repeat(1, 1fr);
        }
    }
}