@import "../../../common/commonConstant.scss";

.mysop-container {
  padding: 15px;
  background: $bggray;
  display: flex;
  flex-direction: column;
  flex: 1;
  overflow: scroll;
  > .header {
    margin: 10px 0;
    .title-container {
      position: relative;
      margin-bottom: 10px;
      .title {
        text-align: center;
        font-size: 13px;
      }
      .right {
        position: absolute;
        right: 0;
        top: 0;
      }
    }
    .sort-container {
      display: flex;
      .select-container {
        position: relative;
        max-width: 200px;
        margin-right: auto;
        width: 100%;
      }
    }
  }
  > .body {
    display: flex;
    flex-direction: column;
    flex: 1;
    .no-items {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      flex: 1;
      color: #929497;
    }
    .sopcard-container {
      border-radius: 12px;
      box-shadow: 0px 5px 15px rgba(0, 0, 0, 0.04);
      background: $bgwhite;
      padding: 10px;
      margin-bottom: 10px;
      display: flex;
      flex-direction: column;
      > .card-header {
        display: flex;
        justify-content: space-between;
        align-items: center;
        .id {
          color: $gray;
          font-size: 13px;
        }
        .right-container {
          display: flex;
          align-items: center;
          .count {
            margin-right: 5px;
            font-size: 10px;
          }
          .buy-status {
            background: $gray4;
            color: $gray5;
            padding: 5px 15px;
            border-radius: 15px;
            font-size: 10px;
            &.danger {
              background: $red;
              color: $light-red;
            }
            &.success {
              background-color: $dark-green;
              color: $light-green;
            }
            &.compensated {
              background-color: $brown;
              color: $light-brown;
            }
            &.postponed {
              background-color: $bggray2;
              color: $gray6;
            }
            &.discount {
              background-color: $bggreen;
              color: $light-green2;
            }
          }
        }
      }
      > .card-body {
        // margin: 10px;
        > img {
          width: 75px;
          height: 75px;
          margin: 10px 10px 0 0;
        }
        .body-content {
          display: flex;
          .title {
            display: flex;
            align-items: start;
            div {
              flex: 1;
            }
          }
          .img {
            position: relative;
            flex: 1;
            .tag-sop {
              font-size: .6rem;
              position: absolute;
              background-color: #f1f2ff;
              color: #38539a;
              font-weight: bold;
              padding: 3px 7px;
              border-radius: 100px;
            }
            img {
              width: 100%;
            }
          }
          .content {
            flex: 3;
            margin-top: 0px;
          }
        }
        .content {
          margin: 15px;
          width: 100%;
          .body {
            display: flex;
            align-content: center;
            .price-container {
              margin-right: auto;
              .price {
                font-size: 16px;
                font-weight: bold;
                color: $medium-blue;
              }
              .bonus {
                color: $light-blue;
                font-size: 13px;
              }
            }
            .qty-container {
              background: #fafafa;
              padding: 6px 18px;
              border-radius: 50px;
              display: table;
            }
            .add-btn-container {
              display: flex;
              align-items: center;
              .btn-container {
                .add-btn {
                  position: relative;
                  bottom: 0;
                  padding: 5px;
                  background: $black;
                  border-radius: 8px;
                  border: none;
                  cursor: pointer;
                  transition: 0.3s background cubic-bezier(0.165, 0.84, 0.44, 1),
                    0.3s border cubic-bezier(0.165, 0.84, 0.44, 1);
                  &:active {
                    background: $gray3;
                    transition: 0.3s background cubic-bezier(0.165, 0.84, 0.44, 1),
                      0.3s border cubic-bezier(0.165, 0.84, 0.44, 1);
                  }
                  &.disable {
                    background: $gray4;
                  }
                  &.checked {
                    padding: 1px;
                    background: transparent;
                    border: 1px solid $black;
                  }
                }
              }
            }
          }
          .benefits {
            display: flex;
            padding-top: 5px;
            .tag-benefit {
              height: fit-content;
              font-size: .6rem;
              background-color: #F1F9FD;
              color: #38649A;
              font-weight: bold;
              padding: 3px 12px;
              border-radius: 30px;
            }
            .name-benefit {
              font-size: 12px;
              color: #727272;
              padding-left: 5px;
            }
          }
        }
        .footer-content {
          .see-more {
            display: flex;
            justify-content: center;
            padding-top: 15px;
            text-decoration: underline;
            border-top: 1px solid #ededed;
          }
          .child-products {
            padding: 10px 0;
            border-top: 1px solid #ededed;
          }
          .title-wrapper {
            display: flex;
            justify-content: space-between;
            .title {
              font-weight: bold;
            }
            .qty {
              font-weight: bold;
              color: #38539a;
            }
            .invalid {
              color: #ed174c;
            }
          }
          .variants-wrapper {
            display: flex;
            justify-content: space-between;
            padding-top: 15px;
            .variants-title-wrapper {
              img {
                width: 28px;
                height: 28px;
                margin-right: 15px;
              }
              display: flex;
              padding-bottom: 15px;
            }
          }
        }
      }
    }
  }
}

.mysop-wrapper {
  display: flex;
  flex-direction: column;
  flex: 1;
  max-height: 100vh;
  > .footer {
    width: 100%;
    bottom: 0;
    background-color: #ffffff;
    .footer-invalid-header {
      padding: 5px 0px;
      background-color: #ffe2e2;
      color: #7f3e3e;
      display: flex;
      justify-content: center;
      align-items: center;
    }
    .footer-header {
      padding: 10px;
      display: flex;
      flex: 1;
      justify-content: space-between;
      .title {
        font-weight: 700;
        color: #38539a;
      }
      .edit {
        color: #727272;
      }
    }
    .footer-body {
      padding: 10px;
      padding-top: 0px;
    }
    .footer-footer {
      padding: 10px;
      display: flex;
      justify-content: center;
      border-top: 1px solid #ededed;
      .submit-button {
        background-color: #2c2c2c;
        padding: 10px 30px;
        color: #ffffff;
        border-radius: 100px;
      }
      .submit-button.disabled {
        background-color: #ededed;
        color: #2c2c2c;
      }
    }
  }
}
