@import '../../../common/commonConstant.scss';

.challenge-details-section {

  &.hide-slider {
    display: none;
  }

  .grab-line-top {
    background: $grab-bar-color;
    width: 32px;
    height: 4px;
    border-radius: 100px;
    top: 10px;
    position: relative;
    left: 50%;
    transform: translate(-50%, 0px);
  }

  h5 {
    font-size: 18px;
    font-weight: 700;
    font-family: $gtwalsheimbold;
    padding: 32px 0;
    padding-bottom: 16px;
    margin-bottom: 8px;
  }

  .nav-tabs {
    margin-bottom: 16px;
    border-bottom: none;
    display: flex;
    justify-content: space-between;

    .nav-link {
      height: 44px;
      width: 50%;
      color: $black;
      font-weight: 800;
      font-family: $sukhmaibold;
      font-size: 16px;
      line-height: 24px;
      position: relative;
      border: none;
      background: none;
      padding: 0;

      &.active {
        position: relative;
        border-radius: 32px;
        background:
          linear-gradient(107.74deg, $gray2 27.91%, $white1 98.01%) padding-box,
          linear-gradient(139.18deg, $bmi-slider-bg-2 16.07%, $white2 93.35%) border-box;
        border: 4px solid transparent;
        box-shadow: 4px 4px 18px 0px $challenge-tab-shadow;
      }
    }
  }

  .tab-content {
    padding-bottom: 20px;
  }

  .range-item {
    position: relative;
    margin-top: 32px;

    .range-line {
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      height: 8px;
      width: 100%;
      height: 20px;
      background: linear-gradient(107.74deg, $white 27.91%, $white 98.01%) padding-box, linear-gradient(107.74deg, $gray17 27.91%, $gray18 98.01%) border-box;
      border: 5.5px solid transparent;
      z-index: 0;
      margin: 0;
      border-radius: 50px;
      display: flex;
      align-items: center;

      input {
        position: relative;
        z-index: 2;
        cursor: pointer;
      }
    }

    .range-input {
      input:disabled~.dot-line {
        .active-dot {
          background-color: $gray9;
          border: 4px solid $gray19;
        }
      }

      &.disable-range {
        +ul {
          .list-inline-item {
            span::before {
              background-color: $gray19;
            }

            &.active span::before {
              background-color: $gray10;
            }
          }
        }

        .range-line {
          .active-line {
            background-color: $gray19;
          }
        }
      }

      .dot-line {
        position: absolute;
        top: 50%;
        transform: translate(-4px, -50%);
        width: calc(100% - 20px);
        left: 4px;
        z-index: 1;
      }

      .active-dot {
        display: block;
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        cursor: pointer;
        background-color: $gray20;
        height: 16px;
        width: 16px;
        border: 4px solid green;
        z-index: 11;
        left: 0%;
        border-radius: 50%;
        display: flex;
        align-items: center;
        justify-content: center;
        background: linear-gradient(107.74deg, $gray2 27.91%, $white1 98.01%) padding-box,
          linear-gradient(139.18deg, $bmi-slider-bg-2 16.07%, $white2 93.35%) border-box;
        border: 2.5px solid transparent;
        box-shadow: 1px 1px 2px 0px $bmi-slider-shadow;
      }

      .range-line {
        .active-line {
          position: absolute;
          left: 0;
          width: 0;
          transition: all 0.1s ease;
          background: linear-gradient(270deg, $challenge-progress-gradient1 0%, $challenge-progress-gradient2 100%);
          height: 10px;
          z-index: 9;
          border-radius: 20px;
        }
      }
    }

    ul {
      display: flex;
      flex-wrap: nowrap;
      justify-content: space-between;
      width: 100%;
      height: 0;

      .list-inline-item {
        &:not(:last-child) {
          margin-right: 0;
        }

        span {
          position: relative;
          min-width: 25px;
          text-align: center;
          display: block;
          font-size: 14px;
          padding-top: 4px;
          word-break: break-word;
          color: $gray19;

          &::before {
            content: "";
            display: block;
            position: absolute;
            top: -9px;
            left: 50%;
            transform: translateX(-50%);
            width: 4px;
            height: 4px;
            border-radius: 50%;
            background-color: $homegreen;
          }
        }

        &.active span::before {
          background-color: $white;
        }
      }
    }
  }


  input[type="range"] {
    &::-moz-range-thumb {
      -webkit-appearance: none;
      /* Override default look */
      appearance: none;
      margin-top: -8px;
      /* Centers thumb on the track */
      background-color: $transparent;
      height: 15px;
      width: 15px;
      border: 4px solid $transparent;
      z-index: 11;
      box-shadow: none;
    }

    &::-webkit-slider-thumb {
      -webkit-appearance: none;
      /* Override default look */
      appearance: none;
      margin-top: -8px;
      /* Centers thumb on the track */
      background-color: $transparent;
      height: 24px;
      width: 24px;
      border: 4px solid $transparent;
      box-shadow: none;
      z-index: 11;
    }

    &::-webkit-slider-runnable-track,
    &::-moz-range-progress,
    &::-ms-track,
    &::-moz-range-track,
    &::-ms-fill-lower,
    &::-ms-fill-upper {
      background-color: $challenge-card-shadow1;
    }

    &:focus {

      &::-webkit-slider-thumb,
      &::-moz-range-thumb,
      &::-ms-thumb {
        box-shadow: none !important;
        background-color: $challenge-card-shadow1;
      }
    }

    &:disabled {

      &::-webkit-slider-thumb,
      &::-moz-range-thumb {
        background-color: $transparent;
        border: 4px solid $transparent;
      }
    }
  }

  #accumulated-steps-tab {
    .range-item {
      .form-progress {
        visibility: hidden;
      }
    }
  }

  .accumulated-steps-tab_wrapper {
    background-color: $white;
    border-radius: 16px;
    box-shadow: 0px 4px 20px 0px $challenge-card-shadow;
    padding: 16px;

    .badge-img-wrapper {
      position: relative;

      .badge-img-bg {
        position: absolute;
        width: 36px;
        translate: -50% -50%;
        margin: auto;
        top: 50%;
        left: 50%;
        filter: drop-shadow(0px 1px 2px $gray);
      }

      .accumulated-steps-tab_badge {
        position: relative;
        width: 32px;
      }
    }

    .accumulated-steps-tab_badge-list {
      display: flex;
      justify-content: center;
      gap: 20px;
    }

    .accumulated-steps-challenge-completed {
      &-heading {
        font-family: $sukhmaitext;
        font-weight: 700;
        font-size: 20px;
        line-height: 30px;
        text-align: center;
      }

      &-description {
        font-family: $sukhmaitext;
        font-weight: 500;
        font-size: 14px;
        line-height: 21px;
        text-align: center;
      }
    }
  }

  .accumulated-steps-bottom {
    color: $black;
    box-shadow: 0px 4px 20px 0px $challenge-card-shadow;
    border-radius: 16px;
    background-color: $challenge-slider-bg;
    padding: 16px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: 16px;
    background-image: url('/images/challenge/white-bg.svg');
    background-repeat: no-repeat;
    height: 100%;
    background-size: cover;
    background-position: center;
  }

  .accumulated-steps-bottom .accumalated-steps-left {
    font-family: $sukhmaitext;
    font-size: 14px;
    font-weight: 500;
  }

  .accumulated-steps-bottom .accumalated-steps-left .accumalated-steps-value {
    font-family: $gtwalsheimbold;
    font-weight: 700;
    margin: 0 4px;
  }

  .accumulated-steps-bottom .accumalated-steps-img-container {
    position: relative;

    img {
      height: 84px;
    }
  }

  .take-step-today-section {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .take-step-pace-section,
  .take-step-distance-section,
  .take-step-calories-section,
  .take-step-left-bottom-section {
    display: flex;
    align-items: center;
    gap: 4px;
  }

  .take-step-pace-section {
    .take-step-pace-value {
      font-size: 44px;
      font-weight: 700;
      font-family: $gtwalsheimpro;
      color: $homegreen;
      line-height: 48px;
    }
  }

  .take-step-pace-unit,
  .take-step-distance-unit,
  .take-step-calories-unit {
    font-size: 14px;
    font-weight: 500;
    color: $black;
    margin-top: 16px;
    font-family: $sukhmaitext;
  }

  .take-step-left-bottom-section {
    gap: 14px;
    margin-top: 2px;

    .take-step-distance-section {
      gap: 3px;
    }

    .take-step-distance-value,
    .take-step-calories-value {
      font-size: 20px;
      font-family: $gtwalsheimpro;
      font-weight: 700;
      color: $homegreen;
    }

    .take-step-distance-unit,
    .take-step-calories-unit {
      margin-top: 4px;
    }
  }

  .take-step-today-right-section {
    .card {
      background: none;
      position: relative;
      display: flex;
      align-items: center;
      justify-content: center;
      border: none;

      svg {
        width: 73px;
        height: 73px;

        circle {
          stroke-width: 10;
          fill: transparent;

          &:last-of-type {
            stroke-dasharray: 195px;
            stroke-linecap: round;
            stroke-dashoffset: calc(195px - (195px * var(--percent)) / 100);
          }
        }
      }

      .percent {
        display: flex;
        justify-content: center;
        align-items: center;
      }

      .number {
        position: absolute;

        .progress-value-inner {
          color: $jet-black;
          font-weight: 800;
          font-family: $sukhmaitext;
          font-size: 24px;
          text-align: center;
          line-height: 26px;
        }

        .progress-value-unit-container {
          display: flex;
          font-size: 12px;
          font-weight: 500;
          color: $gray12;
          gap: 2px;
        }
      }

      &.progress-card-green-1 {
        svg {
          circle {
            stroke: $a-circleStroke;
          }
        }
      }
    }

    .outer-circle {
      background-image: url('/images/challenge/outer-circular-bar.svg');
      height: 104px;
      width: 104px;
      background-position: center;
      background-repeat: no-repeat;
      display: flex;
      align-items: center;
      justify-content: center;
      background-size: contain;
    }

    .progress-value {
      font-size: 16px;
      font-weight: 700;
      font-family: $sukhmaitext;
      color: $homegreen;
    }
  }

  .take-step-today-tab {
    margin-top: 32px;
  }

  .total-statistics-container {
    display: flex;
    gap: 14px;
    margin-top: 4px;

    span:first-child {
      font-size: 20px;
      font-weight: 700;
      color: $homegreen;
      font-family: $gtwalsheimbold;
    }

    span:last-of-type {
      font-size: 14px;
      font-weight: 500;
    }

    .total-statistics-col {
      display: flex;
      align-items: baseline;
      gap: 4px;
    }
  }

  .challenge-bottom-indicator {
    height: 34px;
    display: flex;
    align-items: center;
    justify-content: center;

    .challenge-bottom-line {
      height: 5px;
      width: 139px;
      background: $jet-black;
      border-radius: 100px;
      margin-top: 12px;
    }
  }
}