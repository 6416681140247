@import "../../../common/commonConstant.scss";

.carousel-skeleton-container {
  display: flex;
  gap: 10px;
  overflow: hidden;

  .carousel-skeleton-card-container {
    display: flex;
    gap: 8px;
    flex-direction: column;
    flex-shrink: 0;
    padding:10px
  }

  .carousel-skeleton-card-container:first-child {
    flex: 0 0 80%;
  }
  
  .carousel-skeleton-card-container:last-child {
    flex: 0 0 20%;
  }

  .skeleton-card-image {
    height: 173px;
    border-radius: 16px;
  }

  .skeleton-card-details {
    padding: 4px;
  }
  
  .skeleton-text {
    width: 100%;
    height: 0.7rem;
    margin-bottom: 0.5rem;
    border-radius: 0.25rem;
  }

  .skeleton {
    animation: skeleton-loading 1s linear infinite alternate;
  }
  
  @keyframes skeleton-loading {
    0% {
      background-color: $challenge-carousel-loader-start;
    }
    100% {
      background-color: $challenge-carousel-loader-end;
    }
  }
  

}