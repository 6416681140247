@import "../../common/commonConstant.scss";

.hnw-log-stepper {
  display: flex;
  gap: 8px;

  .btn {
    width: 100%;
    height: 45px;
    background: $bmigrey;
    font-family: $sukhmaitext;
    font-size: 18px;
    font-weight: 700;
    line-height: 26px;
    color: $gray12;

    &:focus {
      box-shadow: none;
    }

    &.--btn-active {
      background: $homegreen;
      color: $white;
    }
  }
}