@import '../../../../common/commonConstant.scss';

.home-page-food-log {
    padding: 0 16px;

    .calories-progress-container {
        box-shadow: 0px 4px 20px 0px $challenge-card-shadow;
        background: linear-gradient(132.59deg, $challenge-slider-bg 38.77%, $challenge-slider-bg1 113.8%);
        border-radius: 16px;
        padding: 16px;

        .outer-circle {
            height: 132px;
            width: 142px;
            background-image: url('/images/challenge/outer-circular-bar.svg');
            background-position: center;
            background-repeat: no-repeat;
            display: flex;
            align-items: center;
            justify-content: center;
            background-size: contain;

            .number {
                margin-top: 12px;

                img {
                    width: 24px;
                    height: 40px;
                    transform-origin: 12px 25px;
                }

                .food-values {
                    margin-top: 40px;
                    display: flex;
                    align-items: center;
                    flex-direction: column;

                    .value {
                        font-size: 20px;
                        line-height: 24px;
                        font-weight: 700;
                        font-family: $sukhmaitext;
                        color: $black;

                    }

                    .unit {
                        font-size: 12px;
                        line-height: 24px;
                        font-weight: 500;
                        font-family: $sukhmaitext;
                        color: $black;
                    }
                }


            }

            .percent {
                display: flex;
                justify-content: center;
                align-items: center;

                .svg {
                    position: relative;
                    width: 74px;
                    height: 74px;
                    transform: rotate(-90deg);
                }
            }
        }

        .calories-title {
            font-size: 18px;
            font-weight: 700;
            font-family: $sukhmaitext;
            line-height: 27px;
        }

        .calories-progress-wrapper {
            display: flex;

            .calories-progress-left {
                width: 40%;

                .card .number {
                    position: absolute;
                    top: 50%;
                    left: 50%;
                    transform: translate(-50%, -50%);
                }

                .card {
                    background: none;
                    position: relative;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    border: none;

                    svg {
                        width: 73px;
                        height: 73px;

                        circle {
                            stroke-width: 10;
                            fill: transparent;

                            &:last-of-type {
                                stroke-dasharray: 195px;
                                stroke-linecap: round;
                                stroke-dashoffset: calc(195px - (195px * var(--percent)) / 100);
                            }
                        }
                    }

                    .percent {
                        display: flex;
                        justify-content: center;
                        align-items: center;
                    }

                    .number {
                        position: absolute;

                        .progress-value-inner {
                            color: $jet-black;
                            font-weight: 800;
                            font-family: $sukhmaitext;
                            font-size: 24px;
                            text-align: center;
                            line-height: 26px;
                        }

                        .progress-value-unit-container {
                            display: flex;
                            font-size: 12px;
                            font-weight: 500;
                            color: $gray12;
                            gap: 2px;
                        }
                    }

                    &.progress-card-green-1 {
                        svg {
                            circle {
                                stroke: $a-circleStroke;
                            }
                        }
                    }
                }

                .outer-circle {
                    .number {
                        margin-top: 15px;
                        text-align: center;
                        position: absolute;
                        top: 50%;
                        left: 50%;
                        transform: translate(-50%, -50%);
                    }
                }

                .progress-value {
                    font-size: 16px;
                    font-weight: 700;
                    font-family: $gtwalsheimbold;
                    color: $black;
                    line-height: 12px;
                }

            }

            .calories-progress-right {
                width: 60%;
                display: flex;
                flex-direction: column;
                margin-left: 16px;

                .already-eat-section {
                    margin-bottom: 2px;
                }

                .calories-right-title {
                    font-size: 14px;
                    font-weight: 700;
                    font-family: $sukhmaitext;
                    line-height: 21px;
                }

                .calories-right-value {
                    color: $black;

                    .over {
                        color: $challenge-error-text !important;
                    }

                    .right-value {
                        font-family: $gtwalsheimpro;
                        font-size: 14px;
                        font-weight: 400;
                        line-height: 24px;
                    }
                }

                .calories-right-unit {
                    margin-left: 4px;
                    font-size: 14px;
                    font-weight: 700;
                    font-family: $sukhmaitext;
                    line-height: 24px;
                }

                .calories-right-value span:first-child {
                    font-size: 40px;
                    font-weight: 700;
                    font-family: $gtwalsheimbold;
                    line-height: 48px;
                }

                .calories-right-value span:nth-child(2),
                .calories-right-value span:nth-child(3) {
                    font-size: 14px;
                    font-weight: 500;
                }

                @media (max-width: 390px) {
                    .calories-right-value span:first-child {
                        font-size: 32px;
                    }
                }

            }

        }
    }

    .calories-progress-container .outer-circle .number {
        margin-top: 10px;
        text-align: center;
    }
}