@import "../../../../../common/commonConstant.scss";

.dropdown {
  position: relative;
  display: inline-block;

  .select-styled {
    display: flex;
    justify-content: space-between;
    padding: 4px 4px 4px 12px;
    gap: 4px;
    border: 1px solid $gray12;
    cursor: pointer;
    min-width: 100px;
    text-transform: uppercase;
    font-weight: 600;
    border-radius: 62px;
    font-size: 14px;
    color: $gray12;
    align-items: center;
    height: 32px;

    &-text {
      font-weight: 500;
    }

    &.--centered {
      justify-content: center;
      padding: 0;
    }

    &-white {
      background: $bgwhite;
    }
  }

  .select-green-arrow {
    width: 22px;
    height: 22px;
    background: $homewhite;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .select-options {
    display: block;
    position: absolute;
    top: 100%;
    left: 0;
    right: 0;
    border: 1px solid $gray12;
    background-color: $bgwhite;
    z-index: 9;
    list-style-type: none;
    padding-left: 0;
    margin-top: 5px;
    border-radius: 12px;
    color: $gray12;

    &.--hide {
      display: none;
    }
  }
}