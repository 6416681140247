$color-op : #439f461a;
$color-d : #439F46;
$color-button: #B29530;

%button-style {
    width: 100%;
    border-radius: 5px;
    display: flex;
    align-items: center;
    font-size: 16px;
    justify-content: center;
    background-color: $color-button;
    color: #fff !important;
    height: 48px;
}

@mixin ellipsis($value) {
    display: -webkit-box;
    -webkit-line-clamp: $value;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
}

.color_gold {
    color: #B29530;
}

:root {
    --widthProcess: 0%;
}

.pt-30 {
    margin-top: 30px;
}

.slick-slide img {
    border: 5px solid #fff;
    display: block;
    margin: auto;
}

.title {
    font-size: 16px;
    font-family: 'Sukhumvit Set Bold';
}

.color_d {
    color: $color-d
}

.subtitle {
    font-family: "Sukhumvit Set Medium";
    font-size: 14px;
    line-height: 22px;
}

.hide {
    display: none;
}

.overflow_disable {
    overflow: inherit;
}

.group_sticky_button2 {
    position: sticky;
    bottom: 0;
    margin: 0 -15px;
    background: #fff;
    padding: 15px 15px;
    box-shadow: 0px -1px 0px #F0F0F0, 0px 0px 0px #F0F0F0;

    a {
        @extend %button-style;
    }
}

.group_subdealer_create {
    position: relative;
    display: grid;
    gap: 0px 0;

    .create_subdealer_item {
        display: grid;
        position: relative;
        padding: 10px 15px;
        margin: 0 -15px;
        box-shadow: 0px 0px 0px #F0F0F0, 0px 1px 0px #F0F0F0;

        a {
            color: #000;

            &:hover {
                text-decoration: none;
            }
        }

        .fav {
            color: #000;

            &.active {
                color: $color-d;
            }

            i {
                margin-right: 5px;
            }

            a {
                font-family: 'Sukhumvit Set SemiBold';
                text-decoration: underline;
            }
        }

        .group_title_subdealer {
            display: grid;
            grid-template-columns: 1fr auto;

            .name {
                font-size: 16px;
                line-height: 25px;
                font-family: 'Sukhumvit Set Bold';
                @include ellipsis(1);
            }

            .count {
                color: #000;
                font-size: 16px;
                font-family: 'Sukhumvit Set Bold';

                span {
                    font-family: 'Sukhumvit Set';
                    color: #8F8F8F;
                }
            }
        }

    }

    .group_order_status {
        color: #5A5A5A;
        font-size: 16px;

        span {
            color: #000;
        }
    }

    .group_count_order {
        position: relative;
        color: #8F8F8F;

        .id_order {
            font-family: 'Sukhumvit Set SemiBold';
            color: #000;
        }
    }
}

.update_date_point {
    position: absolute;
    bottom: 4px;
}

.process {
    position: relative;
    width: 100%;
    height: 4px;
    border-radius: 2px;
    background-color: #B2DBB6;

    &::after {
        content: '';
        position: absolute;
        width: var(--widthProcess);
        height: 4px;
        border-radius: 2px;
        background-color: $color-d;
    }

}

.group_slide_point {
    position: relative;

    .group_point_PVL {
        position: relative;
        display: flex;
        column-gap: 15px;

        .g_point {
            flex: 1;
            background-color: $color-op;
            padding: 10px 15px;
            font-size: 14px;
            font-family: 'Sukhumvit Set SemiBold';
            border-radius: 8px;
        }

        .section_point {
            position: relative;
            padding-bottom: 20px;
        }

        .bg_cl {
            background-color: #DCEDDC;
            width: 32px;
            height: 32px;
            position: absolute;
            border-radius: 50px;
            top: 0;
            right: 0;
            text-align: center;
            align-items: center;
            display: flex;
            justify-content: center;
        }

        small {
            font-family: 'Sukhumvit Set';
            color: #636363;
        }
    }
}

.backg {
    background-color: #f2f2f2;
    width: 100%;
    height: 100%;
    max-height: 100%;
    margin: 0;
    padding: 0;
    background-size: 100% 100%;
    max-width: 100%;
    max-height: 100%;
    bottom: 0;
    left: 0;
    margin: auto;
    overflow: auto;
    position: fixed;
    right: 0;
    top: 0;
    -o-object-fit: contain;
    object-fit: contain;

    .card {
        border-radius: 10px;
        border-width: 0px;
        box-sizing: border-box;
        box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.05);
    }

    .card-body {
        font-family: Sukhumvit Set;

        .title-day {
            font-family: Sukhumvit Set;
            font-style: normal;
            font-weight: 600;
            font-size: 16px;
            line-height: 25px;
            color: #898989;
        }

        .title-time {
            font-family: Sukhumvit Set;
            font-style: normal;
            font-weight: 600;
            font-size: 16px;
            line-height: 25px;
            text-align: right;
            color: #000000;
        }
    }
}

.checkTimeStock-page {
    .MuiInputBase-input {
        height: 12px;
    }

    font-family: Sukhumvit Set;
    margin-top: 50px;

    .checkTimeStock-page-title {
        >span {
            font-family: Sukhumvit Set;
            font-style: normal;
            font-weight: bold;
            font-size: 26px;
            line-height: 41px;
            color: #000000;
        }
    }

    .checkTimeStock-detail-title {
        font-family: Sukhumvit Set;
        font-style: normal;
        font-weight: 600;
        font-size: 16px;
        line-height: 25px;
        color: #000000;
        margin-top: 10px;
        margin-bottom: 10px;
    }

    .text-size {
        font-style: normal;
        font-weight: 600;
        font-size: 16px;
        line-height: 19px;
        text-align: center;
        color: #000000;
    }

    .text-sizes {
        margin-top: -10px;
        font-family: Sukhumvit Set;
        font-style: normal;
        font-weight: 600;
        font-size: 12px;
        line-height: 19px;
        text-align: center;
        color: #696969;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
    }

    .status {
        background: #007aff;
        border-radius: 15px;
        justify-content: center;
        align-items: center;
        text-align: center;
        height: 26px;

        .textsa {
            font-family: Sukhumvit Set;
            font-style: normal;
            font-weight: 600;
            font-size: 12px;
            line-height: 19px;
            color: #fff;
        }
    }
}

.checkTimeStock-body-page {
    .head {
        font-family: Sukhumvit Set;
        font-style: normal;
        font-weight: bold;
        font-size: 18px;
        line-height: 29px;
        color: #114B33;
        margin-top: 10px;
    }

    .card-subtitle {
        font-family: Sukhumvit Set;
        font-style: normal;
        font-weight: 600;
        font-size: 14px;
        line-height: 22px;
        color: #9c9c9c;
    }

    .card-title {
        font-family: Sukhumvit Set;
        font-style: normal;
        font-weight: bold;
        font-size: 18px;
        line-height: 29px;
        color: #114B33;
    }

    .card-text {
        font-family: Sukhumvit Set;
        font-style: normal;
        font-weight: 600;
        font-size: 14px;
        line-height: 22px;
        color: #555555;
    }

    .card-link {
        font-family: Sukhumvit Set;
        font-style: normal;
        font-weight: 600;
        font-size: 14px;
        line-height: 22px;
        color: #114B33;
        margin-left: 5px;
        margin-right: 5px;
    }

    .location {
        margin-top: 10px;
        background: rgba(0, 186, 52, 0.1);
        border-radius: 15px;
        text-align: center;
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        padding: 6px 12px 5px;
        height: 30px;

        >svg {
            margin-top: -4px;
            margin-right: 5px;
        }

        .text {
            font-family: Sukhumvit Set;
            font-style: normal;
            font-weight: 600;
            font-size: 12px;
            line-height: 19px;
            /* identical to box height */

            text-align: right;

            color: #00ba34;
        }
    }

    .checkTimeStock-detail-body {
        padding-top: 10px;

        .title-name {
            font-family: Sukhumvit Set;
            font-style: normal;
            font-weight: 600;
            font-size: 16px;
            line-height: 25px;
            color: #898989;
        }

        .title-detail {
            font-family: Sukhumvit Set;
            font-style: normal;
            font-weight: 600;
            font-size: 16px;
            line-height: 25px;
            text-align: right;
            color: #000000;
        }
    }
}

.detail {
    font-weight: bold;
}

.divpadding {
    padding-left: 35px !important;
    padding-right: 25px !important;
    margin-top: 10px;
}

.divpadding3 {
    padding-left: 35px !important;
    padding-right: 30px !important;
    margin-top: 10px;
}

.divpadding2 {
    padding-left: 40px;
    padding-right: 25px;
    margin-top: 10px;
}

.input-description {
    color: #707070;
    font-size: 14px;
    margin-top: 5px;
}

.lablefont {
    font-size: 16px;
    font-weight: bold;
}

.selecygender {

    background-color: rgb(242, 247, 237);
    border: 2px solid rgba(0, 0, 0, 0.1);
    box-shadow: rgba(0, 0, 0, 0.1) 0px -2px 10px;
    height: 43px;
    width: 96px;
    border-radius: 6px;
    margin-top: 14px;
}

.spandis {
    color: #83C346;
    font-size: 20px;
    margin-left: 10px;
    font-family: Sukhumvit Set Extra Medium;
}

.containerAssesment {
    /* height: 75vh; */
    /* background-color: #ededed; */
    width: 100%;
    width: 100%;
    padding-right: 15px;
    padding-left: 15px;
    margin-right: auto;
    margin-left: auto;
}

.card {
    position: relative;
    display: flex;
    flex-direction: column;
    min-width: 0;
    word-wrap: break-word;
    background-color: #fff;
    background-clip: border-box;
    border: 1px solid rgba(0, 0, 0, .125);
    border-radius: 0.25rem;
}

.mb-3,
.my-3 {
    margin-bottom: 1rem !important;
}

.form-control {
    display: block;
    width: 100%;
    height: calc(1.5em + 0.75rem + 2px);
    padding: 0.375rem 0.75rem;
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5;
    color: #495057;
    background-color: #fff;
    background-clip: padding-box;
    border: 1px solid #ced4da;
    border-radius: 0.25rem;
    transition: border-color .15s ease-in-out, box-shadow .15s ease-in-out;
}

.page-transition {
    transition: transform 0.3s ease-out;
}