@import "../commonConstant.scss";

.white-box {
  background-color: $a-white;
  height: 52px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 4px 0px 12px;
  position: sticky;
  top: 0px;
  z-index: 2;

  .left-icon,
  .right-icons {
    display: flex;
    align-items: center;
  }

  .left-label {
    font-size: 16px;
    line-height: 20px;
    font-weight: 700;
    font-family: $sukhmaitext;
    color: $a-black;
    padding-left: 4px;
  }

  .right-icons > * {
    padding: 0px 10px;
  }

}


