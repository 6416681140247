@import "../../../../common/commonConstant.scss";

.hnw-goals-weight-container {
  background: url('/images/healthAndWellness/goals/weight/bgGoalSetting.svg');
  background-position-y: -100px;
  width: 100%;
  background-size: cover;
  background-attachment: fixed;
  background-repeat: no-repeat;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  .hnw-goals-weight-header {
    width: 100%;
  }

  .hnw-goals-weight-details-container {
    padding: 16px;
    flex-grow: 1;

    .hnw-goals-weight-details-wrapper {
      margin-top: 48px;
      padding: 12px;
      border-radius: 12px;
      background: $white;
      box-shadow: 0px 4px 12px 0px $shadowgrey3;
    }

    .hnw-goals-weight-details {

      &__current-weight {
        padding-bottom: 12px;
      }

      &__target-weight {
        padding-top: 12px;
        border-top: 1px solid $a-dividerColor;

        &-setting-wrapper {
          margin-top: 24px;
        }

        &-setting-stepper {
          margin-top: 12px;
        }

        &-setting-description {
          font-family: $sukhmaitext;
          font-size: 16px;
          font-weight: 500;
          line-height: 22px;
          color: $gray12;
        }

        &-setting-text {
          span {
            font-family: $sukhmaitext;
            font-size: 16px;
            font-weight: 500;
            line-height: 22px;
            color: $gray12;
            margin-right: 5px;

            &:nth-child(2) {
              font-size: 18px;
              font-weight: 700;
              line-height: 26px;
              color: $homegreen;
            }
          }
        }
      }

      &-suggestion {
        text-align: center;
        margin-top: 24px;

        &-tooltip {
          background-color: $white;
          border-radius: 12px;
          display: flex;
          align-items: center;
          text-align: left;
          position: relative;


          &::before {
            content: '';
            display: block;
            position: absolute;
            transform: translate(-50%, -100%);
            left: 50%;
            top: 0px;
            border: 12px solid transparent;
            border-bottom: 16px solid $white;
          }

          &_image {
            &-wrapper {
              position: relative;
              margin-right: 32px;

              :nth-child(2) {
                position: absolute;
                bottom: 0;
                right: -12px;
              }
            }

          }

          img {
            border-radius: 12px;
          }

          &-text {
            font-family: $sukhmaitext;
            font-size: 16px;
            font-weight: 500;
            line-height: 22px;
            color: $black;
          }

          &-value {
            font-family: $sukhmaitext;
            font-size: 16px;
            font-weight: 700;
            line-height: 22px;
            color: $homegreen;

            &.--has-error {
              color: $dark-red3;
            }
          }
        }

        &-description {
          display: inline-block;
          text-align: left;
          font-family: $sukhmaitext;
          font-size: 14px;
          line-height: 20px;
          color: $black;
          margin: 24px 48px 0 48px;

          li::marker {
            font-size: 8px;
          }
        }
      }
    }
  }

  .hnw-goals-weight-footer {
    position: relative;

    img {
      width: 100%;
    }

    .btn {
      font-family: $sukhmaitext;
      width: calc(100% - 32px);
      height: 48px;
      position: absolute;
      top: 15%;
      bottom: 0;
      left: 0;
      right: 0;
      background: $homegreen;
      margin: auto;
      display: block;
      border-radius: 38px;
      font-size: 16px;
      padding: 6px 8px;
      color: $white;
      font-weight: 700;

      &:disabled {
        background-color: $gray13;
      }
      &:hover,
      &:active,
      &:focus {
        box-shadow: none;
      }
    }
  }

  .hnw-goals-numpad-modal {
    .liff-panel.open .select-coupon-panel {
      height: 300px;
      border-radius: 12px 12px 0 0;
    }
  }
}