@import "../../../../common/commonConstant.scss";

.question-card-details-wrapper {
    display: flex;
    padding: 0 16px;
    margin-top: 8px;
    gap: 32px;

    .quiz-amount-wrapper,
    .time-wrapper {
        display: flex;
        align-items: center;
        gap: 4px;

        .quiz-amount-value,
        .time-value {
            font-size: 16px;
            font-weight: 700;
            font-family: $sukhmaitext;
        }

        .time-value-green {
            color: $quiz-time-val;
        }
    }
}