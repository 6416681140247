@import '../../../../common/commonConstant.scss';

.home-composition-change-container {
    padding: 0px 16px;

    >:nth-child(2){
        margin-bottom: 20px !important;
    }

    .align-half-cards {
        display: flex;
        justify-content: space-between;
        margin-bottom: 17px;
        gap: 17px;
        height: 131px;

        .home-card-body {
            box-shadow: 0px 4px 20px 0px $challenge-card-shadow;
            border-radius: 16px;
            padding: 16px;
            display: flex;
            justify-content: space-between;
            background: $a-serviceCard;

            .team-rect-card-container {
                .team-composition-value {
                    .team-composition-heading-font {
                        font-size: 40px;
                        line-height: 48px;
                        font-weight: 700;
                        font-family: $gtwalsheimbold;
                        color: $a-black;
                        margin-right: 4px;
                    }

                    .team-composition-unit-font {
                        line-height: 21px;
                    }

                }
            }
        }

        .w-50 {
            width: 50%;
        }
    }

}