@import "../../../../../common/commonConstant.scss";

.supplement-list-nav {
  .nav-tabs {
    border-bottom: none;
    width: 100%;
    display: flex;
    flex-wrap: nowrap;
    justify-content: space-between;
    margin-bottom: 16px;
  }

  .nav-tabs .nav-link {
    width: auto;
    padding: 0;
    height: 50px;
    color: $black;
    font-weight: 700;
    font-family: $sukhmaitext;
    border: none;
    line-height: 20px;
    padding: 18px 16px 18px 12px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 14px;
  }

  .nav-tabs .nav-link img {
    padding-right: 5px;
  }

  .nav-tabs .nav-link:focus,
  .nav-tabs .nav-link:hover,
  .nav-tabs .nav-link:focus-visible {
    border: none;
    background: transparent;
    outline: none;
    box-shadow: none;
  }

  .nav-tabs .nav-item.show .nav-link,
  .nav-tabs .nav-link.active {
    border: none;
    background: transparent;
    position: relative;
  }

  .nav-tabs .nav-link.active:before {
    position: absolute;
    bottom: 0px;
    content: " ";
    width: 100%;
    background: $homegreen;
    height: 4px;
    left: 0;
    right: 10%;
    border-radius: 10px;
  }

  .nav-tabs .nav-link img.nav-tabs-normal-active-img {
    display: none;
  }

  .nav-tabs .nav-link.active img.nav-tabs-normal-img {
    display: none;
  }

  .nav-tabs .nav-link.active img.nav-tabs-normal-active-img {
    display: block;
  }

  .nav-tabs .nav-item.show .nav-link span,
  .nav-tabs .nav-link.active span {
    color: $homegreen;
  }
}

nav.supplement-list-nav {
  position: static;
  display: flex;
  align-items: center;
  padding: 0;
  gap: 16px;
}

nav.supplement-list-nav.sticky {
  position: fixed;
  top: 0;
  width: 100%;
  background: $white;
  left: 0;
  z-index: 9;
  border-bottom: 1px solid $gray13;
  padding-left: 20px;
  padding-right: 0;

  @media (max-width: 324px) {
    gap: 4px;
    padding-left: 10px;
    padding-right: 10px;
  }
}

nav.supplement-list-nav.sticky .nav-link {
  width: auto;

  &:first-child {
    @media (max-width: 324px) {
      margin-right: 10px;
    }
  }
}

.input-search-sticky,
.list-sticky {
  width: 24px;
  height: 24px;
  display: none;
}

.list-sticky button {
  background: none;
  border: none;
  padding: 0;
  display: flex;
}

nav.supplement-list-nav.sticky .nav-tabs {
  width: calc(100% - 80px);
  margin-bottom: 0;
  flex: 1;
}

nav.supplement-list-nav.sticky .input-search-sticky,
nav.supplement-list-nav.sticky .list-sticky {
  display: flex;
  align-items: center;
}

@media (max-width: 324px) {
  .nav-tabs .nav-link img {
    height: 15px;
  }

  nav.supplement-list-nav.sticky .nav-tabs .nav-link {
    padding: 18px 9px;
  }
}

@media (min-width: 375px) {
  .nav-tabs .nav-link {
    width: 50%;
  }
}