@import "../../common/commonConstant.scss";

.progressbar-container {
  position: relative;
  height: 20px;
  width: 100%;
  border-radius: 50px;
  background: $bglightgray;
  .filler {
    background: $medium-blue;
    height: 100%;
    border-radius: inherit;
    transition: width 0.2s ease-in;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
  }
}
