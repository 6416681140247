@import "../../../../common/commonConstant.scss";

.hnw-goals-water-container {
  background: url('/images/healthAndWellness/goals/water/water_goal_bg.svg');
  background-position: center;
  width: 100%;
  background-size: cover;
  background-attachment: fixed;
  background-repeat: no-repeat;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  .hnw-goals-water-header {
    width: 100%;
  }

  .hnw-goals-water-details-container {
    padding: 20px;
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    gap: 24px;

    &__card-wrapper {
      margin-top: 24px;
    }

    &__tooltip-wrapper {
      display: flex;
      justify-content: center;

      &__main {
        display: flex;
        width: 100%;
        flex-direction: column;
        gap: 8px;
        padding: 16px 24px;
        border-radius: 12px;

        &-header {
          font-family: $sukhmaitext;
          font-weight: 700;
          font-size: 14px;
          line-height: 20px;
        }

        &-details {
          display: flex;
          align-items: center;
          justify-content: space-evenly;
        }

        &-quantity {
          color: $hnw-light-blue;
          font-size: 24px;
          font-weight: 800;
          font-family: $sukhmaitext;
          line-height: 26px;
        }
        &-unit {
          color: $gray12;
          font-size: 16px;
          font-weight: 500;
          font-family: $sukhmaitext;
          
        }
      }
    }

    &__suggestions-wrapper {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        align-items: center;
        gap: 24px;
        margin-top: 12px;

        &-suggestion-list {
            img {
                margin-left: 5px;
            }

            &-text {
                margin-left:5px;
            }
        }
    }
  }

  .hnw-goals-water-footer {
    position: relative;

    img {
      width: 100%;
    }

    .btn {
      font-family: $sukhmaitext;
      width: calc(100% - 32px);
      height: 48px;
      position: absolute;
      top: 15%;
      bottom: 0;
      left: 0;
      right: 0;
      background: $homegreen;
      margin: auto;
      display: block;
      border-radius: 38px;
      font-size: 16px;
      padding: 6px 8px;
      color: $white;
      font-weight: 700;

      &:disabled {
        background-color: $gray12;
      }

      &:hover,
      &:active,
      &:focus {
        box-shadow: none;
      }
    }
  }
}