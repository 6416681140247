@import '../../../common/commonConstant.scss';

.floating-widget {
  position: fixed;
  cursor: move;
  z-index: 99;
  bottom: 200px;
  right: 20px;
  touch-action: none;
  user-select: none;
  pointer-events: auto;
  .auto-sync-widget-btn {
    background: linear-gradient(180deg, $gray2 0%, $white1 100%);
    border-radius: 10px;
    box-shadow: 4px 4px 18px 0px $challenge-button-tab;
    height: 40px;
    width: 40px;
    border: 2.5px solid transparent;
    background-clip: padding-box;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0;
  }
}
