@import '../../../../../common/commonConstant.scss';

.error-panel {
    left: 0;
    bottom: 0;
    width: 100%;
    position: fixed;
}

.error-panel.open {
    height: 100vh;
    z-index: 2000;
    transition: opacity .15s linear;
    display: block;
    backdrop-filter: blur(20px);
}

.modal-dialog-center-warning {
    display: flex;
    align-items: center;
    min-height: calc(100% - 0.5rem * 2);
    position: relative;
    width: auto;
}

.modal-content-warning {
    max-width: 326px;
    margin: auto;
    border-radius: 16px;
    position: relative;
    display: flex;
    flex-direction: column;
    width: 100%;
    background-color: $bgwhite;
}

.modal-body-warning {
    position: relative;
    flex: 1 1 auto;
    text-align: center !important;
    padding: 16px;
}

.line-id-message-wrapper {
    display: flex;
    flex-direction: column;
    gap: 24px;
    align-items: center;
    text-align: center !important;
}

.warning-text {
    font-size: 16px;
    font-weight: 500;
    line-height: 24px;
    color: $a-errorNotification;
    padding: 0 24px;
    font-family: $sukhmaitext;
}

.modal-footer-warning {
    padding: 8px 16px 16px 16px;
    border: none;
    display: flex;
    flex-shrink: 0;
    flex-wrap: wrap;
    align-items: center;
    justify-content: flex-end;
}

.btn-footer-warning {
    height: 44px;
    cursor: pointer;
    width: 100%;
    background: linear-gradient(135deg, $a-darkgreenError -39.29%, $a-lightgreenError1 82.14%) padding-box, linear-gradient(132.38deg, $a-lightgreenError2 -14.45%, $a-darkgreenError2 122.44%) border-box;
    border: 4px solid transparent;
    box-shadow: 4px 4px 18px 0px $a-lightgreenError3;
    color: $a-serviceCard;
    font-size: 16px;
    font-weight: 700;
    font-family: $sukhmaitext;
    border-radius: 48px;
    margin: 0;
}