@import "../../../common/commonConstant.scss";

.create-creditcard-container {
  .modal-container{
    text-align: center;
  }
  & > .body {
    background: $bgwhite;
    box-shadow: 0px 5px 15px rgba(0, 0, 0, 0.04);
    border-radius: 12px;
    margin: 15px;
    padding: 25px 15px;
    .title {
      text-align: center;
      font-weight: bold;
      font-size: 16px;
      margin-bottom: 20px;
    }
    label{
      font-size: 13px;
      color: $gray;
    }
    .bottom{
      display: flex;
      div{
        width: 100%;
      }
      div:first-child{
        margin-right: 5px;
      }
    }
    .relative{
      position: relative;
      .question-mark{
        position: absolute;
        right: 3px;
        bottom: 2px;
      }
      .visa{
        position: absolute;
        right: 30px;
        bottom: 10px;
        filter: grayscale(100%);
        &.active{
          filter: none;
        }
      }
      .mastercard{
        position: absolute;
        right: 5px;
        bottom: 10px;
        filter: grayscale(100%);
        &.active{
          filter: none;
        }
      }
    }
  }
  & > .footer {
    position: fixed;
    bottom: 0;
    width: 100%;
    padding: 15px;
  }
}
