@import '../commonConstant.scss';

.health-log-food-card {
    box-shadow: 1px 8px 18px 0px $a-card-box-shadow;
    padding: 8px;
    border-radius: 12px;
    background: $a-circle-progress-bg;
    position: relative;

    .bg-food {
        width: 100%;
    }
}

.health-log-food-card {
    height: 200px;
}

.health-log-food-card {
    position: relative;
    overflow: hidden;
}

.add-food-content-section {
    padding: 0;
}

.inner-food-description {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    text-align: center;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.inner-food-description h6 {
    font-size: 16px;
    font-weight: 700;
    font-family: $sukhmaitext;
    color: $a-black;
    line-height: 24px;
    padding: 0 24px;
}

.health-log-food-card-header,
.health-log-food-card-body {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.health-log-food-card-header {
    margin-bottom: 8px;
}

.health-log-food-card-header-left {
    display: flex;
    width: 50%;
    align-items: center;
    gap: 4px;
}

.health-log-food-card-header-left h6 {
    font-size: 16px;
    font-weight: 700;
    line-height: 24px;
    font-family: $sukhmaitext;
    margin-bottom: 0;
}

.btn-icon-round {
    height: 24px;
    width: 24px;
    background: $a-neutral;
    border: none;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.health-log-food-card-body {
    gap: 8px;
}

.health-log-food-card-body-left {
}

.health-log-food-card-nutrition-single-info-title,
.health-log-food-card-nutrition-single-info-goal,
.health-log-food-card-nutrition-single-info-unit {
    color: $a-gray;
}

.progress-card-green-1.card {
    svg {
        circle {
            stroke: $a-circleStroke;
        }
    }
}

.progress-card-green-2.card {
    svg {
        circle {
            stroke: $a-circleGreen2;
        }
    }
}

.progress-card-green-3.card {
    svg {
        circle {
            stroke: $a-circleGreen3;
        }
    }
}

.health-log-food-card-body-right {
    width: 60%;
    display: flex;
    flex-direction: column;
}

.health-log-food-card-nutrition-single-info {

    .health-log-food-card-nutrition-single-info-inner-container,
    .health-log-food-card-nutrition-single-info-right,
    .health-log-food-card-nutrition-unit-container {
        display: flex;
        font-size: 14px;
    }

    .health-log-food-card-nutrition-single-info-right,
    .health-log-food-card-nutrition-single-info-inner-container,
    .health-log-food-card-nutrition-unit-container {
        gap: 2px;
    }

    .health-log-food-card-nutrition-single-info-inner-container {
        justify-content: space-between;
        margin-bottom: 4px;
        align-items: center;
    }

    .health-log-food-card-nutrition-single-info-left {
        display: flex;
        align-items: center;
        gap: 2px;
    }
}