@import "../../common/commonConstant.scss";

.hnw-setter-list-container {
    display: flex;
    flex-direction: column;
    border-radius: 12px;
    box-shadow: 1px 8px 18px 0px $flblack;
    gap: 12px;
    padding: 12px;
    background-color: $bgwhite;
    width: 100%;

    &__header {
        display: flex;
        justify-content: space-between;

        &__left{
            display: flex;
            justify-content: center;
            gap: 12px;
            align-items: center;
            &-img{
                border-radius: 50px;
                height: 40px;
                width: 40px;
            }

            &-text {
                font-family: $sukhmaitext;
                font-weight: 700;
                font-size: 18px;
                line-height: 26px;
            }
        }

        &-cancelBtn {
            color: $dark-red2;
            border: 1px solid $dark-red2;
            border-radius: 38px;
            padding: 6px 8px;
            background: transparent;
            width: 74px;
        }
    }

    &__list {
        display: flex;
        flex-direction: column;
        gap: 13px;

        .hnw-setter-container__text{
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
            display: inline-block;
        }

        &__card-container {
            display: flex;
            gap: 8px;

            &>div:first-child {
                width: auto;
                gap: 0px;
            }

            &>div:last-child {
                flex-grow: 1;
                overflow: hidden;
                white-space: nowrap;
                text-overflow: ellipsis;
            }

        }
    }

    &__btn {
        display: inline-flex;
        align-items: center;
        justify-content: center;
        padding: 12px;
        background-color: $bgwhite;
        border: 1px solid $hnw-green;
        border-radius: 6px;
        font-family: $sukhmaitext;
        font-size: 14px;
        font-weight: 700;
        line-height: 20px;
    }
}
