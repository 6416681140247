@import "../../../../../common/commonConstant.scss";

.hnw-goals-weight-details {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 6px 0px;

  &-heading {
    font-family: $sukhmaitext;
    font-size: 16px;
    font-weight: 500;
    line-height: 22px;
    color: $black;
  }

  &-value {
    font-family: $sukhmaitext;
    font-weight: 700;
    line-height: 20px;
    font-size: 16px;
    color: $theme-green;

    &.--no-value {
      font-size: 14px;
      color: $dark-red3;
    }
  }
}