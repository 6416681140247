@import '../../../../../common/commonConstant.scss';

.assessment-header-vector {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  position: fixed;
  top: 0;

  .header-parent {
    background-color: $a-white;
    display: inline-block;
    width: 100%;
    padding-bottom: 16px;

    .header-progress-base {
      flex-direction: column;
      justify-content: flex-start;
      align-items: flex-start;
      display: flex;
      border-radius: 40px;
      gap: 8px;

      .title {
        color: $a-black;
        font-size: 20px;
        font-family: $sukhmaibold;
        font-weight: 800;
        line-height: 28px;
        padding-top: 16px;
        padding-left: 20px;
      }

      .progress-parent {
        padding: 6px 18px;
        border-radius: 40px;
        align-self: stretch;
        justify-content: flex-start;
        align-items: center;
        gap: 4px;
        display: inline-flex;

        .activity-div {
          width: 24px;
          height: 24px;
          justify-content: center;
          align-items: center;
          display: flex;
        }

        .spacer-div {
          flex: 1 1 0;
          height: 4px;
          background: $a-lightgray;
          border-radius: 10px;

          &.active {
            background: $a-pri-green;
          }
        }
      }
    }
  }

  .header-curve-bg {
    top: -2px;
    position: relative;
    width: calc(100vw + 20px) !important;
  }
}

@media (min-width: 390px) {
  .header-parent {
    background-position: 0 -20px;
    /*Changed*/
  }
}