@import "../../../../../common/commonConstant.scss";

.recommended-exercise-container-wrapper {
    .header {
        margin-bottom: 16px;
        margin-top: 32px;
        font-size: 20px;
        font-weight: 800;
        line-height: 28px;
        font-family: $sukhmaibold;
        color: $black;
    }
}