@import "../../../common/commonConstant.scss";

body::-webkit-scrollbar:has(.challenge-map-wrapper) {
  display: none; // Safari and Chrome
}

.challenge-map-wrapper {
  .map-details-header {
    width: calc(100% - 32px);
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    margin: auto;
    position: fixed;
    top: 16px;
    z-index: 2;
    left: 16px;

    .challenge-date {
      box-shadow: 0px 4px 20px 0px $challenge-dashboard-green-shadow;
      background: $challenge-white-shadow1;
      backdrop-filter: blur(4px);
      padding: 3px 12px;
      border-radius: 8px;
      font-size: 16px;
      color: $black;
      font-family: $sukhmaitext;

      span:last-of-type {
        font-family: $gtwalsheimpro;
        font-weight: 700;
        margin-left: 4px;
      }
    }

    .btn-white-linear {
      border-radius: 10px;
      height: 48px;
      width: 48px;
      display: inline-block;
      background: linear-gradient(107.74deg, $gray2 27.91%, $white1 98.01%) padding-box,
        linear-gradient(139.18deg, $bmi-slider-bg-2 16.07%, $white2 93.35%) border-box;
      border: 3.5px solid transparent;
      box-shadow: 4px 4px 18px 0px $challenge-button-tab;
      display: flex;
      align-items: center;

      &:focus,
      &:active,
      &:visited {
        outline: none;
        border: 3.5px solid transparent;
        box-shadow: 4px 4px 18px 0px $challenge-button-tab;
      }
    }
  }

  .challenge-map-exit-btn {
    border: none;
    position: absolute;
    z-index: 1;
    top: 0;
    right: 0;
    padding: 0;
  }

  .App {
    overflow-x: hidden;
  }

  .d-none {
    display: none;
  }

  .container {
    width: 100%;
    height: 100vh;
    padding: 0;
  }

  .header {
    width: 100%;
    z-index: 9;
  }

  .lg-container {
    position: relative;
    margin: 1rem;
    padding: 0;
    overflow: hidden;
    margin-top: 100px;
  }

  .lg-hotspot {
    position: absolute;
    margin: 0;
    padding: 0;
    z-index: 0;

    &:hover &__button,
    &:active &__button {
      border-color: $challenge-hotspot;
    }

    &:hover &__button:after,
    &:active &__button:after {
      background-color: $challenge-hotspot;
    }

    &--selected {
      z-index: 999;

      .lg-hotspot__label {
        opacity: 1;
      }
    }

    &__button {
      height: 30px;
      width: 30px;
      padding: 0px;
      border-radius: 100%;
      border: 1px solid $challenge-orange;
      box-shadow: 0 2px 4px $a-delete-modal-shadow1;
      z-index: 999;
      animation: button-pulse 7s ease-in-out infinite;
      cursor: pointer;

      &:after {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        pointer-events: none;
        content: "";
        display: block;
        height: 12px;
        width: 12px;
        border-radius: 100%;
        border: 3px solid white;
        background-color: $challenge-orange;
        transition: border-color 1s linear;
      }
    }

    &__label {
      position: absolute;
      padding: 0 0 1.1em 0;
      width: 16em;
      max-width: 50vw;
      background-color: white;
      box-shadow: 0 2px 4px $a-delete-modal-shadow1;
      font-family: "Open Sans", sans-serif;
      font-size: 14.5px;
      line-height: 1.45em;
      z-index: -1;
      border-radius: 2px;
      user-select: none;
      opacity: 0;
      transition: all 0.1s linear;

      h4 {
        margin: 0;
        padding: 0.65em 24px;
        background-color: #555;
        font-size: 1.1em;
        font-weight: normal;
        letter-spacing: 0.02em;
        color: white;
        border-radius: 2px 2px 0 0;
      }

      p {
        margin: 0;
        padding: 1.1em 24px 0 24px;
        color: #333;
      }
    }

    &--top-left,
    &--top-right,
    &--bottom-right,
    &--bottom-left {
      .lg-hotspot__label {
        top: 24px;
        left: 24px;
      }
    }
  }

  img.description {
    position: absolute;
    bottom: 0;
    width: 100%;
  }

  .pin-img {
    position: absolute;
    height: 10px;
    width: 10px;
    pointer-events: none;
    transform-origin: center;
    visibility: hidden;
  }

  .main-point-img {
    position: absolute;
    width: 48px;
    height: 48px;
    transform: translate(-50%, -100%);
    pointer-events: all;
  }

  .main-point-badge {
    position: absolute;
    translate: -50%;
    height: 52px;
    scale: 1.2;
  }

  .cloud-fading-img {
    height: 75px;
    position: absolute;
    transform: translate(-50%, -20px);
    filter: drop-shadow(0 0px 10px $white);

    &.cloud-fading-usa {
      height: 100px;
      translate: -20px;
    }
  }

  .cloud-fading-amway {
    display: none;
  }

  .transitioning {
    opacity: 0;
    transition: opacity 0.5s ease-in-out;
  }

  .user-hotspot {
    position: absolute;
    transition: top 0.5s ease-in-out, left 0.5s ease-in-out;
  }

  .fade-out {
    animation: fadeOut 1s forwards;
  }

  @keyframes fadeOut {
    from {
      opacity: 1;
    }

    to {
      opacity: 0;
    }
  }

  .steps-input-wrapper {
    display: flex;
    z-index: 9;
    position: relative;

    @media (min-width: 768px) {
      position: absolute;
      top: 0;
    }

    input {
      background: $white;
      height: 74px;
      font-size: 24px;
      border-radius: 32px;
      border-image-source: linear-gradient(139.18deg, $bmi-slider-bg-2 16.07%, $white2 93.35%);
      padding: 12px;

      &:focus,
      &:focus-visible,
      &:active {
        outline: none;
        border: none;
        box-shadow: none;
      }
    }

    .move-button,
    .move-step-button {
      font-size: 28px;
      padding: 20px;
      box-shadow: 4px 4px 18px 0px $flblack;
      border: 4px solid;
      color: $black;
      border-radius: 32px;
      font-weight: 700;
    }
  }

  @media (max-width: 768px) {

    .description,
    .header {
      display: block;
    }

    .lg-image {
      width: auto;
      height: 100vh;
    }

    .react-transform-wrapper {
      position: fixed;
      max-width: 100%;
      max-height: 100vh;
    }

    .cloud-fading-img {
      height: 75px;

      &.cloud-fading-usa {
        height: 75px;
      }
    }

    .steps-input-wrapper {
      justify-content: center;
      margin-top: -96px;
      gap: 4px;

      .move-button,
      .move-step-button {
        font-size: 15px;
        padding: 6px 10px;
      }

      input {
        height: 50px;
        font-size: 16px;
        width: 130px;
      }
    }
  }
}