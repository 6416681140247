@import "../../common/commonConstant.scss";

.hnw-tooltip-container {
    background-color: $white;
    border-radius: 12px;
    position: relative;

    &::before {
    content: '';
    display: block;
    position: absolute;
    transform: translate(-50%, -100%);
    left: 50%;
    top: 0px;
    border: 12px solid transparent;
    border-bottom: 16px solid $white;
    }
}