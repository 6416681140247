@import '../../common/commonConstant.scss';

.promotion-react-select__control {
  border-color: #EDEDED !important;
  border-style: solid !important;
  border-radius: 10px !important;
  height: 40px;
  margin-block: 10px;
}

.promotion-react-select__value-container {
  font-family: $sukhmaitext;
  letter-spacing: 0px !important;
  bottom: 5px;
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
}

.promotion-react-select__indicators{
  height: 40px;
  .promotion-react-select__dropdown-indicator {
    color: #2C2C2C;
  }
}

.promotion-react-select__indicator-separator {
  background-color: transparent !important;
}

.amway-address-promotion-button {
  display: block;
  margin: 0 auto;
  font-family: 'Sukhumvit Set';
  width: 50%;
  height: 44px;
  color: #ffffff;
  border-radius: 22px;
  border: 2px solid #3a539b;
  font-weight: bold;
  font-size: 16px;
  background-color: #3a539b;
}

.amway-address-promotion-button:disabled {
  display: block;
  margin: 0 auto;
  font-family: 'Sukhumvit Set';
  width: 50%;
  height: 44px;
  color: #ffffff;
  border-radius: 22px;
  border: 2px solid #c5c9d3;
  font-weight: bold;
  font-size: 16px;
  background-color: #c5c9d3;
}