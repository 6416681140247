@import "../commonConstant.scss";

.challenge-header-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 56px;
    padding: 12px 16px;
    width: 100%;
    top: 0;
    z-index: 1000;
    background: $bgwhite;
    box-shadow: 0px 4px 20px 0px $challenge-card-shadow;
    backdrop-filter: blur(12px);

    .challenge-header-left {
        display: flex;
        align-items: center;
        justify-content: flex-start;
        gap: 8px;
    }

    .challenge-header-right {
        display: flex;
        align-items: center;
        justify-content: flex-end;
        gap: 8px;
    }

    &-badge {
        .rect-badge-container {
            padding: 5px 8px;

            .rect-badge-title {
                font-size: 14px;
                line-height: 21px;
                font-weight: 700;
                font-family: $sukhmaitext;
                white-space: nowrap;
            }
        }
    }

    &-date {
        font-size: 14px;
        line-height: 21px;
        font-weight: 500;
        font-family: $sukhmaitext;
        color: $black;
        white-space: nowrap;

    }

    &-icon {
        background:
            linear-gradient(107.74deg, $header-sticky-white 27.91%, $white1 98.01%) padding-box,
            linear-gradient(139.18deg, $bmi-slider-bg-2 16.07%, $white2 93.35%) border-box;
        border-radius: 10px;
        box-shadow: 4px 4px 18px 0px $challenge-button-tab;
        height: 40px;
        position: relative;
        width: 40px;
        border: 2.5px solid transparent;
        background-clip: padding-box;
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 0;

        img {
            height: 24px;
            width: 24px;
        }
    }


    &.dashboardSticky {
        position: fixed;
        display: flex;
        justify-content: space-between;
        align-items: center;
        height: 56px;
        padding: 8px 16px;
        width: 100%;
        top: 0;
        z-index: 1000;
        background: $challenge-carousel-bg;
        transition: top 0.3s ease-in-out;
    }

    &.quizSticky {
        position: fixed;
        width: 100%;
        z-index: 99;
        top: 0;
        padding-top: 0;
        background: $header-sticky-transparent;
        margin-bottom: 0;
        box-shadow: 0px 4px 20px 0px $challenge-card-shadow;
        transition: top 0.3s ease-in-out;
    }
}