@import '../../../common/commonConstant.scss';

.content-list-wrap {
  &.sub-content {
    .hl-header {
      padding: 0;
    }

    .sub-header {
      position: relative;
      height: 250px;
      overflow: hidden;
      padding: 22px;

      .header-bg {
        position: absolute;
        width: 100%;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);

        &::after {
          content: '';
          background: linear-gradient(180deg,
              rgba(0, 23, 83, 0.5) 0%,
              rgba(0, 23, 83, 0.5) 50%,
              rgba(255, 255, 255, 0.5) 100%);
          position: absolute;
          width: 100%;
          height: 100%;
          left: 0;
          top: 0;
        }

        img {
          width: 100%;
        }
      }

      .category-name {
        position: absolute;
        color: #fff;
        text-align: center;
        left: 50%;
        top: 60%;
        transform: translate(-50%, -50%);

        .heading {
          font-size: 24px;
          font-weight: bold;
          color: #fff;
          word-break: break-all;
          text-align: left;
        }
      }
    }
  }

  .hl-header {
    background: linear-gradient(180deg, #5a5d67 0%, #2c2c2c 100%);
    padding: 22px 22px 60px;

    .back-page {
      position: absolute;
      top: 22px;
      left: 22px;
      z-index: 999;
      color: #fff;
    }

    .edit-category {
      position: absolute;
      color: #fff;
      right: 22px;
      font-size: 18px;
      z-index: 999;
    }

    .page-title-category {
      position: relative;
      text-align: center;
      color: #fff;
      font-weight: bold;
      font-size: 18px;
    }
  }

  .content-list {
    padding: 40px 22px;
    background: #fff;
    border-radius: 30px 30px 0 0;
    margin-top: -30px;
    position: relative;
    min-height: calc(100vh - 250px);

    &.card-discovery-new {
      .search-input {
        position: relative;
        margin-top: 13px;

        .ant-input-affix-wrapper {
          background: #f5f6fa;
          border: none;
          border-radius: 7px;
          font-size: 12px;
        }

        .ant-input-affix-wrapper>input.ant-input {
          background: #f5f6fa;
        }
      }

      .card-body {
        .card-item {
          width: calc(50% - 10px);
          display: inline-block;
          margin: 10px 5px;
          border-radius: 10px;
          overflow: hidden;
          vertical-align: top;
        }

        .card-item-img {
          height: 180px;
          border: 1px solid #f5f6fa;
          overflow: hidden;
          position: relative;

          img {
            position: absolute;
            height: 100%;
            left: 50%;
            top: 50%;
            transform: translate(-50%, -50%);
          }
        }

        .card-desc {
          margin-top: 10px;

          .title {
            color: #38539a;
            font-weight: bold;
            overflow: hidden;
            white-space: nowrap;
            text-overflow: ellipsis;
            margin-left: 15px;
            margin-right: 15px;
          }

          .desc {
            font-size: 12px;
            overflow: hidden;
            white-space: nowrap;
            text-overflow: ellipsis;
            margin-left: 15px;
            margin-right: 15px;
          }
        }
      }
    }
  }

  .empty-data {
    text-align: center;
    margin-top: 60px;

    .desc {
      margin-top: 20px;
    }
  }
}

.view-content-wrap {
  background-color: #fafafa;

  .content-heading-hl {
    position: relative;

    .view-content-image {
      width: 100%;
    }

    .btn-share {
      position: absolute;
      bottom: 20px;
      left: 50%;
      transform: translate(-50%, 0px);
      background: #38539a;
      color: #fff;
      height: 36px;
      font-size: 16px;
      font-weight: bold;
      width: 120px;
      border-radius: 36px;
    }
  }

  .content-box {
    padding: 22px;

    .content-desc {
      padding: 20px;
      background-color: #fff;
      border-radius: 10px;
      box-shadow: 1px 1px 3px rgba(0, 0, 0, 0.15);
      text-align: left;

      .title {
        font-size: 24px;
        font-weight: bold;
        margin-bottom: 5px;
        color: #2c2c2c;
        word-break: break-all;
        text-align: left;
      }

      .category-name {
        color: #38539a;
        margin-bottom: 5px;
        font-size: 16px;
        word-break: break-all;
        text-align: left;

        .icon {
          margin-right: 8px;
          width: 12px;
        }
      }

      .content-date {
        color: #8b8b8b;
        font-size: 12px;
        margin-bottom: 25px;
      }

      .desc {
        margin-top: 10px;
      }

      .library-ql-editor {
        box-sizing: border-box;
        cursor: text;
        line-height: 1.42;
        height: 100%;
        outline: none;
        overflow-y: unset;
        tab-size: 4;
        -moz-tab-size: 4;
        text-align: left;
        white-space: pre-wrap;
        word-wrap: break-word;
      }
    }
  }
}

.upload-content-img {
  background: #f9f9f9;
  padding: 22px;

  &.active {
    padding: 0;
    position: relative;

    .ant-form-item-control-input {
      min-height: 0;
    }
  }

  .ant-upload.ant-upload-drag p.ant-upload-drag-icon .anticon {
    font-size: 23px;
    color: #bcbcbc;
  }

  .ant-upload.ant-upload-drag {
    background: #fff;
    border-radius: 15px;
    height: 285px;

    .ant-upload {
      padding: 0;
    }
  }

  .ant-upload.ant-upload-drag:not(.ant-upload-disabled):hover {
    border-color: #d9d9d9;
  }

  .ant-upload-list {
    display: none;
  }

  .upload-file-area.active {
    position: absolute;
    height: 37px;
    width: auto;
    bottom: 20px;
    left: 50%;
    transform: translate(-50%, 0);
    padding: 0 20px;
    border: 1px solid;
  }

  .ant-upload-hint {
    color: #bcbcbc;
  }
}

.add-new-content {
  text-align: center;
  margin: 20px;

  .btn-outline {
    border: 1px solid #2c2c2c;
    height: 60px;
    border-radius: 60px;
    padding: 0 60px;
    font-size: 18px;
    font-weight: bold;
  }
}

.content-form-wrap {
  background: #fff;
  min-height: 100vh;
  position: relative;

  .ant-image {
    max-height: 375px;
    overflow: hidden;
    text-align: -webkit-center;

    img {
      display: none;

      &.active {
        display: block;
        height: 100%;
        width: 100%;
      }
    }
  }

  .category-name {
    padding: 45px 22px;

    .ant-input {
      border-color: #ced8e3;
      height: 52px;
      border-radius: 10px;
      font-size: 16px;
    }

    .ant-form-item-label {
      color: #00305e;
      font-size: 15px;
    }
  }

  .upload-content-img {
    background: #f9f9f9;
    padding: 22px;

    &.active {
      padding: 0;
      position: relative;

      .ant-form-item-control-input {
        min-height: 0;
      }
    }

    .ant-upload.ant-upload-drag p.ant-upload-drag-icon .anticon {
      font-size: 23px;
      color: #bcbcbc;
    }

    .ant-upload.ant-upload-drag {
      background: #fff;
      border-radius: 15px;
      height: 285px;

      .ant-upload {
        padding: 0;
      }
    }

    .ant-upload.ant-upload-drag:not(.ant-upload-disabled):hover {
      border-color: #d9d9d9;
    }

    .ant-upload-list {
      display: none;
    }

    .upload-file-area.active {
      position: absolute;
      height: 37px;
      width: auto;
      bottom: 20px;
      left: 50%;
      transform: translate(-50%, 0);
      padding: 0 20px;
      border: 1px solid;
    }
  }

  .action {
    text-align: center;
    padding: 0 60px 22px;

    .btn {
      background-color: #2c2c2c;
      height: 60px;
      border-radius: 60px;
      width: 100%;
      color: #fff;
      font-size: 18px;
      font-weight: bold;
    }

    .ant-btn-link {
      color: #2c2c2c;
      font-size: 18px;
      font-weight: bold;
    }
  }
}

.view-content-wrap {
  .action {
    padding: 0 22px 22px;
    text-align: center;
    display: flex;
    flex-direction: column;
    align-items: center;

    .btn-share {
      background: #38539a;
      color: #fff;
      height: 36px;
      font-size: 16px;
      font-weight: bold;
      width: 120px;
      border-radius: 36px;
    }

    .action-edit-content {
      display: flex;
      margin-bottom: 15px;

      .btn {
        background-color: #2c2c2c;
        height: 60px;
        border-radius: 60px;
        width: 100%;
        color: #fff;
        font-size: 18px;
        font-weight: bold;
        margin-right: 10px;
      }

      .btn-outline {
        border: 1px solid #2c2c2c;
        height: 60px;
        width: 100px;
        text-align: center;
        border-radius: 60px;
        font-size: 18px;
        font-weight: bold;
      }
    }

    .ant-btn-link {
      color: #2c2c2c;
      font-size: 18px;
      font-weight: bold;
      margin-top: 20px;
    }
  }
}

.edit-content-detail-wrap {
  padding: 22px;

  .ant-form-item-label {
    label {
      color: #00305e;
      font-size: 15px;
    }
  }

  .input-label {
    color: #00305e;
    font-size: 15px;
  }

  .ant-input {
    border-color: #ced8e3;
    height: 52px;
    border-radius: 10px;
  }

  .ck-wrap {
    margin-top: 8px;
    margin-bottom: 20px;
  }

  .ck-rounded-corners .ck.ck-editor__top .ck-sticky-panel .ck-toolbar {
    border-radius: 10px;
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
  }

  .ck-rounded-corners .ck.ck-editor__main>.ck-editor__editable,
  .ck.ck-editor__main>.ck-editor__editable.ck-rounded-corners {
    border-radius: 10px;
    border-top-left-radius: 0;
    border-top-right-radius: 0;
    height: 300px;
  }

  .ant-input[disabled] {
    background-color: #ced8e3;
    color: #6d7386;
    border: 1px solid #8fa0b1;
  }
}