@import "../../../common/commonConstant.scss";

.choosepayment-container {
  background: $bggray;
  min-height: 100vh;
  input[type="checkbox"] {
    appearance: checkbox;
    transform: scale(1);
    padding: 0;
    margin: 0;
    border: none;
    width: auto;
    display: inline-block;
  }
  .header{
    text-align: center;
    padding-top: 20px;
  }
  .body {
    background: $bgwhite;
    box-shadow: 0px 5px 15px rgba(0, 0, 0, 0.04);
    border-radius: 12px;
    margin: 15px;
    padding: 10px;
    .payment-choice {
      display: flex;
      label{
        font-size: 14px;
        .description{
          color: $gray2;
        }
      }
    }
  }
  .center{
    text-align: center;
  }
  .footer{
    position: fixed;
    bottom: 0;
    background: $bgwhite;
    border-top-left-radius: 12px;
    border-top-right-radius: 12px;
    text-align: center;
    width: 100%;
    padding: 20px 10px;
    box-shadow: 0px -5px 15px rgba(0, 0, 0, 0.04);
    .header-text{
      padding-bottom: 10px;
    }
    .content{
      padding-bottom: 10px;
      .total-text{
        color: $gray;
      }
      .amount-text{
        color: $medium-blue;
        font-weight: bold;
      }
    }
  }
}
