@import '../../../../common/commonConstant.scss';

.my-badge-list-container {
    padding: 0px 16px 17px;

    .badge-container-heading {
        font-family: $sukhmaitext;
        font-size: 18px;
        font-weight: 700;
        line-height: 27px;
        margin-bottom: 20px;
        .heading-number {
            font-family: $gtwalsheimpro;
        }
    }

    .challenge-card-body {
        box-shadow: 0px 4px 20px 0px $challenge-card-shadow;
        border-radius: 16px;
        padding: 16px;
        display: flex;
        flex-direction: column;
        background: $a-serviceCard;

        .badge-rect-container .my-all-badges-container .my-badges-single p {
                font-family: $sukhmaitext;
            }
    }
}