@import "../commonConstant.scss";

.challenge-btn-container {
    height: 44px;
    border-radius: 10px;
    gap: 8px;
    display: flex;
    align-items: center;
    justify-content: center;

    &-title {
        height: 24px;
        font-size: 16px;
        font-weight: 800;
        line-height: 24px;
        text-align: center;
        color: $black;
        font-family: $sukhmaibold;
    }

    &.successBtn {
        border-radius: 80px;
        height: 44px;
        display: inline-block;
        background: linear-gradient(135deg, $challenge-success-green1 -39.29%, $challenge-success-green2 82.14%) padding-box, linear-gradient(132.38deg, $challenge-success-green3 -14.45%, $challenge-success-green4 122.44%) border-box;
        border: 4px solid transparent;
        box-shadow: 4px 4px 18px 0px $challenge-green;
        display: flex;
        align-items: center;
        justify-content: center;

        .challenge-btn-container-title {
            color: $a-circleStroke;
            height: 24px;
            font-size: 16px;
            font-weight: 800;
            line-height: 24px;
            text-align: center;
            font-family: $sukhmaibold;
        }
    }

    &.detailBtn {
        width: max-content;
        border-radius: 48px;
        background-color: $a-btn-green;
        padding: 0 24px;

        .challenge-btn-container-title {
            color: $challenge-success-green;
            height: 24px;
            font-size: 16px;
            font-weight: 800;
            line-height: 24px;
            text-align: center;
            font-family: $sukhmaibold;
        }
    }
    &.secondaryBtn {
        border-radius: 80px;
        height: 44px;
        display: inline-block;
        background:
            linear-gradient(107.74deg, $gray2 27.91%, $white1 98.01%) padding-box,
            linear-gradient(139.18deg, $bmi-slider-bg-2 16.07%, $white2 93.35%) border-box;
        border: 2.5px solid transparent;
        box-shadow: 4px 4px 18px 0px $challenge-tab-shadow;
        display: flex;
        align-items: center;
        justify-content: center;
    }

}