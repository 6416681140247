.react-pdf__Page__canvas {
  width: 100% !important;
  height: auto !important;
}

.react-pdf__Page {
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
}

.pdf-container {
  background-color: grey;
}

button.download-btn {
  background: #000;
  color: #fff;
  border: none;
  padding: 10px 20px;
  border-radius: 20px;
  font-size: 16px;
}

.top-bar {
  position: fixed;
  width: -webkit-fill-available;
  background: transparent;
  padding: 20px;
  text-align: right;
  z-index: 1 !important;
}

.download-btn img{
  margin-left: 8px;
}