@import "../commonConstant.scss";

.challenge-share-container {
    .map-goal-share-section {
        display: flex;
        gap: 16px;

        .share-title {
            font-size: 16px;
            font-family: $sukhmaitext;
            color: $black;
            font-weight: 700;
        }
    }
}