@import "../../../common/commonConstant.scss";


body::-webkit-scrollbar:has(.quiz-video-details-page) {
    display: none; // Safari and Chrome
}

.quiz-video-details-page {
    background-image: url('/images/challenge/team-dashboard-bg.svg');
    background-repeat: no-repeat;
    background-size: 100%;
    height: 100%;

    .container-fluid {
        padding-left: 16px;
        padding-right: 16px;

        .quiz-video-container {
            padding-top: 14px;
            padding-bottom: 20px;

            .quiz-video-title-wrapper {
                display: flex;
                align-items: center;
                justify-content: space-between;

                .quiz-video-title {
                    font-size: 18px;
                    font-family: "Sukhumvit Tadmai";
                    font-weight: 700;
                    line-height: 27px;
                }
            }

            .video-listing-container {
                position: relative;
                margin-top: 20px;
                margin-bottom: 60px;

                .video-image-container {
                    position: relative;
                    display: flex;
                    flex-direction: column;
                    gap: 16px;

                    .quiz-card-single {
                        width: 100% !important;
                        margin-right: 0;
                        margin-bottom: 0;

                        .quiz-img-container {
                            .play-icon {
                                height: 70.59px;
                                width: 70.59px;

                                .play-img {
                                    content: url('/images/challenge/play-bg.svg');
                                    width: 24.51px;
                                    height: 29.41px;
                                }
                            }
                        }

                        .quiz-card-title,
                        .quiz-card-time {
                            background: $challenge-carousel-bg;
                            font-size: 16px;
                            font-weight: 500;
                            padding: 4px 12px;
                            line-height: 24px;
                            display: inline-block;
                            font-family: $sukhmaitext;
                        }

                        .quiz-card-time {
                            .quiz-duration {
                                font-family: $gtwalsheimpro;
                                font-weight: 400;
                            }
                        }
                    }
                }
            }

        }
    }
}