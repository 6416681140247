@import "../../../../common/commonConstant.scss";

.asm {
    min-height: 96.3vh;
    display: flex;
    flex-direction: column;
    background-repeat: no-repeat;
    background-position-y: 80%;
    background-size: cover;

    &-hdr {
        display: flex;
        align-items: center;
        width: fit-content;
        padding: 6px 8px;
        height: 36px;
        margin-top: 16px;
        border-radius: 0 38px 38px 0;
        background-color: $bgwhite;
        cursor: pointer;

        &-t1 {
            font-weight: 700;
            font-size: 16px;
            line-height: 20px;
            color: $black;
            font-family: $sukhmaitext;
        }

        img {
            width: 24px;
            height: 24px;
            margin-right: 4px;
            border: 1.5px;
            color: $black;
            padding-right: 4px;
        }
    }

    &-ftr {
        margin-top: auto;
        padding: 16px;

        &-ctn {
            padding: 0 0 16px 0;
            background-color: $bgwhite;
            border-radius: 12px;
            gap: 24px;
            min-height: 218px;

            &-hdr {
                padding: 12px 0;
                text-align: center;
                background-color: $a-offwhite;
                text-align: center;
                color: $black;
                font-size: 18px;
                line-height: 26px;
                font-weight: 800;
                font-family: $sukhmaibold;
                border-top-left-radius: 12px;
                border-top-right-radius: 12px;
                display: flex;
                justify-content: center;
                align-items: center;

                &-t1 {
                    margin-right: 8px;
                }

                img {
                    width: 22px;
                    height: 22px;
                }
            }

            &-body {
                display: flex;
                justify-content: center;
                gap: 48px;
                padding: 0 24px;
                margin-top: 24px;
                width: 100%;

                &-left {
                    gap: 24px;
                    margin-bottom: 6px;

                    &-up {
                        display: flex;
                        align-items: center;

                        &-t1 {
                            font-size: 14px;
                            line-height: 20px;
                            font-weight: 700;
                            color: $a-gray;
                            font-family: $sukhmaitext;
                        }

                        img {
                            width: 24px;
                            height: 24px;
                            padding-right: 4px;
                        }
                    }

                    &-down {
                        font-size: 18px;
                        line-height: 26px;
                        font-weight: 800;
                        font-family: $sukhmaibold;
                        color: $black;
                    }
                }

                &-right {
                    gap: 24px;
                    margin-bottom: 6px;

                    &-up {
                        display: flex;
                        align-items: center;

                        &-t1 {
                            font-size: 14px;
                            line-height: 20px;
                            font-weight: 700;
                            color: $a-gray;
                            font-family: $sukhmaitext;
                        }

                        img {
                            width: 24px;
                            height: 24px;
                            padding-right: 4px;
                        }
                    }

                    &-down {
                        font-size: 18px;
                        line-height: 26px;
                        font-weight: 800;
                        font-family: $sukhmaibold;
                        color: $black;
                    }
                }


            }

            &-btn {
                display: flex;
                align-items: center;
                justify-content: center;
                margin-top: 24px;
                cursor: pointer;

                .btnClass {
                    width: 80%;
                    height: 48px;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    padding: 6px 6px;
                    border-radius: 38px;
                    border: 1px;
                    gap: 4px;
                    background-color: $a-green;
                    color: $bgwhite;
                    text-align: center;
                    font-family: $sukhmaitext;
                    font-weight: 700;
                    font-size: 16px;
                    line-height: 20px;
                }

                &-sec {
                    display: flex;

                    .btnClass {
                        width: 188px;
                    }

                    &-lbtn {
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        margin-right: 16px;
                        background-color: $a-btn-green;
                        border-radius: 38px;
                        width: 105px;

                        img {
                            padding-right: 4px;
                        }

                        &-t1 {
                            font-size: 16px;
                            line-height: 20px;
                            font-weight: 700;
                            color: $a-green;
                            font-family: $sukhmaitext;
                        }
                    }
                }
            }
        }
    }
}

.asm-modal-ctn {
    &.retryModalClass {
        .liff-panel.open .select-coupon-panel {
            height: 345px;

            .header {
                border-radius: 24px 24px 0 0;
                height: 60%;
                padding: 15px 0 0 0;
                background-image: url("/images/healthAndWellness/assessment/assessment_retry_modal.svg");
                background-repeat: no-repeat;
                background-size: cover;

                .hdr {
                    padding: 16px;
                    margin-top: 24px;
                    display: flex;
                    flex-direction: column;
                    justify-content: center;
                    align-items: center;

                    .header-text {
                        font-family: $sukhmaibold;
                        font-size: 18px;
                        line-height: 26px;
                        font-weight: 800;
                        text-align: center;
                        color: $black;
                    }

                    .subheader-text {
                        color: $dark-red2;
                        font-size: 16px;
                        line-height: 24px;
                        font-weight: 700;
                        text-align: center;
                        font-family: $sukhmaitext;
                        width: 300px;
                    }
                }

                >button {
                    display: flex;
                    background-color: $bgwhite;
                    border-radius: 38px;
                    padding: 6px 8px;

                    >svg {
                        width: 28px;
                        height: 28px;
                    }

                }

            }
        }
    }

    &.desClass {
        .liff-panel.open .select-coupon-panel {
            height: 95%;
            overflow-y: scroll;

            >:nth-child(1) {
                background: none;

                >button {
                    display: flex;
                    border: 1px solid $black;
                    padding: 6px 6px;
                    border-radius: 38px;

                    >svg {
                        width: 24px;
                        height: 24px;
                    }
                }

            }
        }
    }

    &.modalClass {
        .liff-panel.open .select-coupon-panel {
            height: 305px;

            > :nth-child(1) {
                border-radius: 24px 24px 0 0;
                height: 55%;
                padding: 15px 0 0 0;
                background-image: url("/images/healthAndWellness/assessment/assessment_retry_modal.svg");
                background-repeat: no-repeat;
                background-size: cover;

                .hdr {
                    padding: 16px;
                    margin-top: 24px;

                    .header-text {
                        font-family: $sukhmaibold !important;
                        font-size: 18px;
                        line-height: 26px;
                        font-weight: 800;
                        text-align: center;
                        color: $black;
                    }

                    .subheader-text {
                        font-size: 16px;
                        line-height: 24px;
                        font-weight: 700;
                        text-align: center;
                        font-family: $sukhmaitext;
                        color: $black;
                    }
                }

                >button {
                    display: flex;
                    background-color: $bgwhite;
                    border-radius: 38px;
                    padding: 6px 8px;

                    >svg {
                        width: 28px;
                        height: 28px;
                    }

                }
            }
        }
    }
}