@import '../../../../../common/commonConstant.scss';

.bg-darkbrown {
  background: rgba(26, 26, 26, 0.6);
}

.activity-goal-card-container {
  display: flex;
  flex-direction: column;
  height: calc(100vh - 100px);

  .activity-goal-card-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 16px 0;

    .activity-goal-card-header-left {
      font-size: 14px;
      font-weight: 700;
      color: #d91734;
      font-family: $sukhmaitext;
    }

    .activity-goal-card-header-right {
      .btn-primary {
        width: 96px;
        height: 28px;
        display: flex;
        justify-content: center;
        align-items: center;
        font-family: $sukhmaitext;
        background: #3c9d47;
        border-radius: 38px;
        font-size: 14px;
        padding: 6px 8px;
        border-color: #3c9d47;
        font-weight: 700;
        color: #fff;
      }

      .btn-disabled {
        width: 96px;
        height: 28px;
        display: flex;
        justify-content: center;
        align-items: center;
        font-family: $sukhmaitext;
        background: $gray13;
        border-radius: 38px;
        font-size: 14px;
        padding: 6px 8px;
        border-color: $gray13;
        font-weight: 700;
        color: #fff;
      }

      .btn-primary:focus,
      .btn-primary:hover,
      .btn-primary:active {
        background: #3c9d47 !important;
        border-color: #3c9d47 !important;
      }

      .btn-disabled:focus,
      .btn-disabled:hover,
      .btn-disabled:active {
        background: $gray12 !important;
        border-color: $gray12 !important;
      }
    }
  }

  .activity-goal-card-body {
    display: flex;
    gap: 24px;
    flex-wrap: wrap;
    margin-top: 16px;
    padding-bottom: 24px;
    overflow-y: scroll;

    .activity-goal-card-single {
      box-shadow: 1px 8px 18px 0px rgba(44, 44, 44, 0.1);
      border-radius: 12px;
      overflow: hidden;
      width: 46%;
      display: flex;
      flex-direction: column;

      @media (max-width: 324px) {
        width: 45.5%;
      }

      .activity-goal-card-img {
        width: 100%;
        height: 128px;
        object-fit: cover;
      }

      .activity-goal-card-img.filter-grayscale {
        filter: grayscale(1);
      }

      .activity-goal-card-description {
        padding: 8px 10px;
        width: 100%;

        .activity-goal-card-title {
          height: 40px;
          font-size: 14px;
          font-weight: 500;
          font-family: $sukhmaitext;
          text-overflow: ellipsis;
          overflow: hidden;
          white-space: nowrap;
          color: $black;

          &-text {
            position: relative;
            top: 50%;
            transform: translateY(-50%);
            text-overflow: ellipsis;
            overflow: hidden;
            white-space: nowrap;
          }
        }

        .activity-goal-card-bottom {
          display: flex;
          align-items: center;
          color: $gray12;
          font-size: 12px;
          font-weight: 500;
          gap: 2px;
          height: 24px;
          margin-top: 8px;
          border-top: 1px solid $bmigrey;
          font-family: $sukhmaitext;

          .selected-title,
          .selected-value {
            line-height: 16px;
          }
        }
      }

      &.activity-goal-card-active {
        border: 2px solid #80d26c;
      }

      &.activity-goal-card-disabled {
        border: 2px solid $gray12;

        .activity-goal-card-img {
          color: $gray12 !important;
        }

      }
    }
  }
}