@import "../../../common/commonConstant.scss";

.user-profile-wrapper {
  position: absolute;

  .user-hotspot.pin & {
   translate: -50% -50%;
  }

  .user-rank {
    width: 20px;
    height: 20px;
    position: absolute;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
    background-color: $white;
    font-family: $gtwalsheimbold;
    color: $black;
    font-size: 16px;
    left: -4px;
    top: -4px;
    z-index: 1;
  }

  .user-chat-bubble {
    background-color: $white;
    height: 36px;
    border-radius: 16px;
    padding: 8px;
    position: absolute;
    width: max-content;
    top: -56px;
    transform: translateX(-50%);
    left: 50%;

    &::after {
      content: '';
      border: 10px solid transparent;
      border-top-color: $white;
      position: absolute;
      bottom: -18px;
      left: 50%;
      transform: translate(-50%);
    }

    &.--hide {
      display: none;
    }
  }

  .user-img {
    width: 40px;
    height: 40px;
    border-radius: 50%;
    border: 2.5px solid $white;

    &.--is-leader {
      border-color: $challenge-crown-img-border;
    }

    &.--no_image {
      content: url('/images/challenge/default_challenge_profile.svg');
      position: absolute;
    }
  }

  .user-image-badge {
    position: absolute;
    bottom: -8px;
    right: -8px;
    width: 28px;
  }
}