@import "../../../../../common/commonConstant.scss";

.exercise-listing-container {
    .activity-category-container {
        .container-fluid {
            padding: 0 15px;
            .row {
                .col-12 {
                    padding: 0;


                    .activity-list-container {
                        padding: 0 20px;
                        margin-bottom: 8px;


                        .activity-list-container-wrapper {
                            width: 100% !important;
                            overflow: hidden;
                            position: relative;
                            &.slick-slider {
                                margin-top: 22px;

                                .slick-slide {
                                  padding: 0 4px;
                                }
                              }
                        }

                        .header {
                            margin-bottom: 16px;
                            margin-top: 32px;
                            font-size: 20px;
                            font-weight: 800;
                            line-height: 28px;
                            font-family: $sukhmaibold;
                            color: $black;
                        }

                        .activity-list-card-wrapper {
                            display: flex;
                            gap: 30px;
                            justify-content: space-around;

                            .activity-list-card-single {
                                text-align: center;
                                display: flex;
                                flex-direction: column;
                                align-items: center;
                                justify-content: center;
                                position: relative;

                                .activity-list-card-title {
                                    font-size: 14px;
                                    font-weight: 500;
                                    line-height: 20px;
                                    margin-top: 5px;
                                    font-family: $sukhmaitext;
                                }

                                .activity-list-card-round {
                                    display: none;
                                }

                                &.activity-list-card-active {
                                    .activity-list-card-title {
                                        color: $homegreen;
                                        font-weight: 700;
                                        font-family: $sukhmaitext;
                                    }

                                    .activity-list-card-round {
                                        display: block;
                                        height: 62px;
                                        width: 62px;
                                        border: 2px solid $homegreen;
                                        border-radius: 50%;
                                        position: absolute;
                                        bottom: 27px;
                                        z-index: -1;
                                        left: 50%;
                                        transform: translate(-50%, 5px);
                                    }
                                }
                            }
                        }
                    }

                    .exercise-list-container-banner {
                        margin-bottom: 16px;

                        img {
                            width: 100%;
                        }
                    }

                    .recommended-exercise {
                        padding: 0 20px;
                    }

                }
            }
        }
    }
}