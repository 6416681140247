@import "../commonConstant.scss";

.challenge-slider-container {
    background: $bgwhite;
    -webkit-backdrop-filter: blur(10px);
    backdrop-filter: blur(10px);
    border-radius: 16px 16px 0 0;
    padding: 0 16px;
    position: fixed;
    z-index: 799;
    width: 100%;
    bottom: 0;
    max-height: 80%;
    min-height: 3%;

    &-grab {
        cursor: move;
        padding: 4px;
        height: 20px;
        text-align: center;
    }

    &-content {
        height: 100%;
        padding-bottom: 20px;
    }
}