@import "../../../../common/commonConstant.scss";

.green {
  color: $a-green;
}

// ----------------- banner ----------------
.search-result-container {
  font-family: $sukhmaitext;
  background-color: $bgwhite;
}

.search-banner-card {
  box-shadow: 0px 4px 12px 0px $shadowgrey3;
  border-radius: 12px;
  padding: 8px;
  width: 100%;
  background: $bgwhite;

  @media (min-width: 415px) {
    padding: 12px;
  }

  &-wrapper {
    width: 100%;
    position: absolute;
    bottom: 20px;
    padding: 12px;

    @media (min-width: 415px) {
      padding: 12px 32px;
    }
  }
}

.search-banner-wrapper-container {
  position: relative;
  background: $bgwhiteShade;
}

.search-banner-title-container {
  position: absolute;
  transform: translate(-50%, 20px);
  left: 50%;

  .search-banner-title {
    font-size: 40px;
    font-weight: 800;
    color: $theme-red;
    font-family: $sukhmaitext;
  }

  .search-banner-subtitle {
    font-size: 16px;
    font-weight: 700;
    color: $a-black;
    font-family: $sukhmaitext;
  }
}

.profile-details {
  display: grid;
  grid-template-columns: 1fr 1fr;
  justify-content: space-between;
  gap: 4px;
}

.profile-details-text-wrapper {
  display: flex;
  align-items: baseline;
}

.profile-details-single-container {
  display: flex;
  align-items: center;
}

.profile-details-heading,
.profile-details-value {
  font-size: 16px;
  font-weight: 500;
  padding-left: 4px;
  font-family: $sukhmaitext;
}

.profile-details-heading {
  color: $gray12;
  font-weight: 700;
}

.profile-details-value {
  color: $a-black;
}

// ------------------ description ----------------
.lifestyle-suggestions-title {
  font-size: 24px;
  font-weight: 800;
  margin-bottom: 5px;
  font-family: $sukhmaitext;
}

.lifestyle-suggestions-description {
  font-size: 16px;
  font-weight: 700;
  font-family: $sukhmaitext;
}

.bg-container {
  background-image: url(/images/assesment/bg-wave-2.svg);
  background-repeat: no-repeat;
  background-size: 100%;
  background-color: $bgwhiteShade;
  padding-top: 35px;
  padding-bottom: 45px;
}

@media (min-width: 400px) {
  .bg-container {
    padding-bottom: 60px;
  }
}

@media (max-width: 324px) {
  .bg-container {
    padding-bottom: 35px;
    padding-top: 25px;
  }
}

// -------------- answer cards ---------------
.your-answer-title {
  font-size: 24px;
  font-weight: 800;
  margin-bottom: 16px;
  margin-top: 20px;
  font-family: $sukhmaitext;
}

.category-general-icon {
  width: 24px;
  height: 24px;
  background: $bgwhite;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.category-general-title {
  font-size: 18px;
  font-weight: 800;
  margin-left: 8px;
  font-family: $sukhmaitext;
}

@media (max-width: 362px) {
  .category-general-title {
    font-size: 16px;
    font-family: $sukhmaitext;
  }
}

.category-general-header {
  display: flex;
  align-items: center;
  background-repeat: no-repeat;
  background-size: 100%;
  height: 50px;
  padding-left: 12px;
  padding-right: 12px;
}

.category-general-container {
  border-radius: 24px;
  overflow: hidden;
  margin-bottom: 16px;
}

.category-general-health-container {
  background: $a-offwhite;

  .category-general-header {
    background-image: url(/images/healthAndWellness/assessment/health_card_header.svg);
  }
}

.category-general-activity-container {
  background: $a-activity-bg;

  .category-general-header {
    background-image: url(/images/healthAndWellness/assessment/activity_card_header.svg);
  }
}

.category-general-attitude-container {
  background: $a-sliderBgColor;

  .category-general-header {
    background-image: url(/images/healthAndWellness/assessment/attitude_card_header.svg);
  }
}

.category-general-food-container {
  background: $light-green2;

  .category-general-header {
    background-image: url(/images/healthAndWellness/assessment/food_card_header.svg);
  }
}

.category-general-sleep-container {
  background: $light-turquoise;

  .category-general-header {
    background-image: url(/images/healthAndWellness/assessment/sleep_card_header.svg);
  }
}

.category-general-stress-container {
  background: $light-sunset-yellow;

  .category-general-header {
    background-image: url(/images/healthAndWellness/assessment/stress_card_header.svg);
  }
}

.category-general-tag {
  background: $bgwhite;
  width: 100%;
  justify-content: space-between;
  border-radius: 24px;
  display: flex;
  align-items: center;
  padding: 8px 10px 8px 14px;

  span {
    font-size: 12px;
    font-weight: 700;
    margin-right: 5px;
    font-family: $sukhmaitext;
  }
}

.category-general-row {
  padding: 9px 12px;
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(calc(50% - 5px), max-content));
  align-items: flex-start;
  gap: 10px;
}

.product-slider-wrapper-container {
  img {
    width: 100%;
  }

  .products-slider-container {
    background: $bggray;
    padding-top: 24px;
  }
}

// ------------ search result footer ---------------

.search-result-footer {
  background-image: url(/images/healthAndWellness/assessment/assessment-score-footer.svg);
  background-repeat: no-repeat;
  background-size: 100%;
  width: 100%;
  position: sticky;
  bottom: 0;

  &-btns {
    justify-content: space-between;
    margin-bottom: 20px;
    margin-top: 40px;
  }

  .btn-black-outline,
  .btn-primary-outline {
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 38px;
    height: 48px;
    padding: 6px 8px;
    font-size: 16px;
    font-weight: 700;
    width: 49%;
    font-family: $sukhmaitext;
  }

  .btn.btn-black-outline {
    border: 1px solid $black;
  }

  .btn.btn-primary-outline {
    border: 1px solid $theme-green;
  }

  .score-footer-btn {
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 38px;
    height: 48px;
    padding: 6px 8px;
    font-size: 16px;
    font-weight: 700;
    width: 49%;
    font-family: $sukhmaitext;
    border: none !important;

    &.home-btn {
      background-color: $a-btn-green;
      color: $homegreen;
      gap: 4px;
    }

    &.retry-btn {
      background-color: $homegreen;
      color: $bgwhite;
      gap: 4px;
    }
  }
}