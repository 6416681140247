@import "../../../../common/commonConstant.scss";

.assesment-carousel-container {
    overflow: hidden;
    .assessment-carousel-list {
        padding: 12px;
        width: 92%;
        .slick-slide {
            padding:10px;
            img {
                border: none;
            }
        }
    }
}


.assessment-card-container {
    box-shadow: 0px 4px 20px 0px rgba(50, 100, 76, 0.15);
    border-radius: 16px;
    overflow: hidden;
    background: linear-gradient(111.54deg, rgba(255, 255, 255, 0.9) 4.76%, rgba(255, 255, 255, 0.36) 104.39%);
    margin: 0 17px 16px 0px;

    &-image {
        width: 100%;
        object-fit: cover;
        height: 174px;
    }

    &-content {
        display: flex;
        justify-content: space-between;
        height: 66px;
        align-items: center;
        padding: 0 16px;
        background: rgba(255, 255, 255, 0.9);


        &-title {
            font-family: $sukhmaitext;
            font-size: 16px;
            font-weight: 400;
            line-height: 24px;
            width: 70%;
        }

        &-tag {
            height: 32px;
            padding: 0px 16px;
            border-radius: 48px;
            background: #F2F7F1;
            color: #3C9D47;
            font-family: $sukhmaibold;
            border: none;
        }
    }
}