@import "../../../../../common/commonConstant.scss";

// ----------- product cards ------------
.product-title {
  font-size: 24px;
  font-weight: 800;
  margin-bottom: 20px;
  font-family: $sukhmaitext;
}

.products-recommend-container {
  margin-bottom: 0;
  overflow: auto;

  .slick-slide {
    padding: 7px;
  }
}

.products-recommend-card {
  background: $bgwhite;
  box-shadow: 1px 8px 18px 0px $flblack;
  border-radius: 8px;
  margin-left: 0;
  margin-bottom: 16px;
  padding: 0 12px 12px 12px;
}

.product-card-tag {
  width: fit-content;
  display: flex;
  column-gap: 4px;
  align-items: center;
  border-radius: 24px;
  padding: 2px 6px 2px 3px;

  span {
    font-size: 12px;
  }
}

.product-card-green-tag {
  background: $a-offwhite;

  span {
    color: $dark-green1;
  }
}

.product-card-blue-tag {
  background: $bglightblue;

  span {
    color: $medium-blue;
  }
}

.product-card-yellow-tag {
  background: $a-sliderBgColor;

  span {
    color: $dark-yellow;
  }
}

.product-card-light_green-tag {
  background: $light-green2;

  span {
    color: $light-green1;
  }
}

.product-card-cyan-tag {
  background: $light-turquoise;

  span {
    color: $dark-green;
  }
}

.product-card-orange-tag {
  background: $light-sunset-yellow;

  span {
    color: $dark-red;
  }
}

.product-card-pink-tag {
  background: $a-babypink;

  span {
    color: $red;
  }
}

.product-card-tag .category-general-icon {
  width: 14px;
  height: 14px;

  img {
    border: none;
    height: 100%;
    width: 100%;
  }

  font-family: $sukhmaitext;
}

.products-recommend-card-title {
  font-size: 14px;
  font-weight: 500;
  margin-top: 10px;
  height: 45px;
  font-family: $sukhmaitext;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  -webkit-line-clamp: 2;
  line-clamp: 2;
}

.products-recommend-price {
  font-size: 16px;
  font-family: $sukhmaitext;

  .products-recommend-price-fix {
    font-weight: 700;
  }

  .products-recommend-price-dynamic {
    font-weight: 800;
  }
}

.products-recommend-pvbv {
  display: flex;
  font-size: 12px;
  font-family: $sukhmaitext;
}

.products-recommend-pv-title,
.products-recommend-bv-title {
  color: $gray1;
}

.products-recommend-pv-value,
.products-recommend-bv-value {
  color: $black;
}

.products-recommend-pv {
  border-right: 1px solid $gray2;
  padding-right: 5px;
}

.products-recommend-bv {
  padding-left: 5px;
}

.products-recommend-cart-container {
  background: $black;
  height: 30px;
  width: 30px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;

  .products-recommend-cart__shopping-icon {
    border-color: transparent;
    width: auto;
  }

  &.selected {
    background: $a-darkgreen;
  }
}

.products-recommend-price-cart-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 16px;
}

.products-recommend-img-container {
  text-align: center;

  img {
    width: 100%;
  }
}