@import '../../../../common/commonConstant.scss';

.composition-card-container {
    padding: 0 16px;
    margin-top: 40px;


    .composition-change-heading {
        font-family: $sukhmaitext;
        font-size: 18px;
        font-weight: 700;
        line-height: 27px;
        text-align: left;
        color: $a-black;
    }

    .composition-change-message {
        font-family: $sukhmaitext;
        font-size: 14px;
        font-weight: 500;
        line-height: 21px;
        text-align: left;
        color: $a-black;
        margin-bottom: 20px;
    }

    .challenge-card-body {
        box-shadow: 0px 4px 20px 0px $challenge-card-shadow;
        border-radius: 16px;
        padding: 16px;
        display: flex;
        justify-content: space-between;
        background: $a-serviceCard;
    }

    .w-50 {
        width: 50%;
    }

    .align-half-cards {
        display: flex;
        justify-content: space-between;
        margin-bottom: 15px;
        gap: 17px;
    }
}