@import '../../../common/commonConstant.scss';

.before-after-container {
  background: $header-sticky-transparent;
  box-shadow: 0px 4px 20px 0px $challenge-card-shadow;
  border-radius: 16px;
  padding: 16px;
  padding-top: 0;
  .pointer {
    cursor: pointer;
  }
  .before-after-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 48px;
    .before-after-header-left {
      display: flex;
      align-items: center;
      font-size: 16px;
      font-weight: 700;
      font-family: $sukhmaitext;
    }
    .before-after-header-right {
      button {
        padding: 0;
      }
    }
  }
  .before-after-row {
    margin-bottom: 8px;
    &:last-of-type {
      margin-bottom: 0;
    }
        .before-after-heading {
          font-family: $sukhmaitext;

          .before-after-title {  
            font-size: 14px;
            font-weight: 700;
            color: $a-black;
            line-height: 21px;
            margin-bottom: 8px;
          }
    
          .before-after-subtitle {
            font-size: 12px;
            font-weight: 500;
            color: $gray8;
            line-height: 18px;
            margin-bottom: 8px;
          }
        }
    .before-after-image-container {
      display: grid;
      grid-template-columns: repeat(3, 1fr);
      gap: 8px;

      .my-dashboard-step-container {
        .steps-skeleton-card-container {
          padding: 0;
        }
      }
      .before-after-image-single {
        background: $challenge-nomember-bg;
        border-radius: 8px;
        height: 132px;
        overflow: hidden;
        .before-after-img-uploaded {
          display: none;
        }
        .before-after-image-single-inner {
          height: 100%;
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;
          gap: 4px;
          text-align: center;
        }
        p {
          font-size: 14px;
          font-weight: 700;
          font-family: $sukhmaitext;
          padding: 0 10px;
        }
        &.before-after-image-uploaded {
          .before-after-image-single-inner {
            display: none;
          }
          .before-after-img-uploaded {
            display: block;
            width: 100%;
          }
        }
      }
    }
  }
}
