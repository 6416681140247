@import "../../../../../common/commonConstant.scss";

.asm-hist {
    background: url('/images/healthAndWellness/assessment/assessment_history_bkg.svg');
    background-repeat: no-repeat;
    min-height: 100vh;
    background-size: cover;
    background-position: right 180px;

    &.no-history-log {
        background: url('/images/healthAndWellness/assessment/assessment_no_history_bkg.svg');
        background-repeat: no-repeat;
        min-height: 100vh;
        background-size: cover;
        background-position: right 180px;
    }

    &-log {
        padding: 16px 20px;
        gap: 16px;
        display: flex;
        flex-direction: column;

        &-hdr {
            justify-content: space-between;
            font-size: 20px;
            line-height: 28px;
            font-weight: 800;
            font-family: $sukhmaitext;
            color: $black;
        }

        &-body {
            &-ctn {
                border-radius: 12px;
                background: $bgwhite;
                box-shadow: rgba(0, 0, 0, 0.2) 0 2px 8px;
                padding: 12px 16px;

                &-in1 {
                    display: flex;
                    align-items: center;
                    justify-content: space-between;
                    padding: 12px 0;
                    display: flex;


                    &-hr1,
                    &-hr2 {
                        border-bottom: 1px solid $a-greyborder;
                    }

                    &-left {
                        width: 200px;
                        display: flex;
                        height: 20px;
                        font-size: 14px;
                        line-height: 20px;
                        font-weight: 500;
                        font-family: $sukhmaitext;
                        color: $a-gray;

                        .left-t1 {
                            width: 100px;
                        }
                    }

                    &-right {
                        display: flex;
                        align-items: center;
                        justify-content: flex-end;
                        gap: 8px;

                        img {
                            width: 40px;
                            height: 40px;
                            object-fit: contain;
                        }

                        .right-t1 {
                            font-size: 14px;
                            font-weight: 500;
                            line-height: 20px;
                            font-family: $sukhmaitext;
                            color: $black;
                            text-align: right;
                        }
                    }
                }

                &-in1:not(:last-child) {
                    border-bottom: 1px solid $a-gray;
                }
            }

            &-img {
                margin-top: 60px;
            }
        }

    }
}