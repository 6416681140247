@import "../../commonConstant.scss";

.user-info-carousel {
    .slick-list {
        overflow: initial;
    }

    .slick-slide {
        padding: 0;
    }

    .card-ongoing-container-info {
        box-shadow: 0px 4px 20px 0px $challenge-card-shadow;
        background: $a-serviceCard;
        border-radius: 16px;
        padding: 16px;
        padding-bottom: 9px;
        position: relative;
        margin-bottom: 40px;

        .team-leader-card {
            position: absolute;
            right: 20px;
            top: -15px;
            .rect-badge-title{
                font-size: 14px;
                line-height: 21px;
                font-weight: 700;
                font-family: $sukhmaitext;
            }
        }

        .rank-card {
            height: 28px;
            .rank-card-image {
                margin: 0;
            }
        }

        .card-ongoing-description {
            text-align: center;
            margin-bottom: 7px;

            .card-ongoing-name {
                font-size: 18px;
                font-weight: 700;
                font-family: $sukhmaitext;
                line-height: 27px;
            }

            .card-ongoing-number {
                font-size: 14px;
                font-weight: 400;
                color: $user-id-color;
                line-height: 21px;
                font-family: $gtwalsheimpro;
            }
        }

        .card-ongoing-contact-details {
            display: flex;
            margin: 12px 0;

            .card-ongoing-contactleft,
            .card-ongoing-contactright {
                display: flex;
                align-items: center;
                gap: 4px;
                width: 50%;
                img {
                    margin: 0
                }

                .card-ongoing-contact-name {
                    font-size: 14px;
                    font-weight: 400;
                    color: $black;
                    font-family: $gtwalsheimpro;
                    line-height: 21px;
                }
            }
            .card-ongoing-contactright {
                margin-left: 20px;
            }
        }

        .card-ongoing-steps-details {
            display: flex;

            .card-ongoing-steps-details-left,
            .card-ongoing-steps-details-right {
                width: 50%;
            }

        }
        hr.rounded-dividing-line {
        width: 3px;
        height: 90px;
        border: none;
        box-shadow: 1px 1px 4px 0px $card-divider-line inset;
        border-radius: 5px;
        margin: 0;
        margin-right: 32px;
      }

        .card-ongoing-steps-details-left-col1,
        .card-ongoing-steps-details-right-col1 {
            font-size: 18px;
            font-weight: 700;
            font-family: $sukhmaitext;
            color: $black;
        }

        .card-ongoing-steps-details-left-col2,
        .card-ongoing-steps-details-right-col2 {
            font-size: 28px;
            font-weight: 700;
            font-family: $gtwalsheimbold;
            line-height: 40px;
        }

        .card-ongoing-steps-details-left-col3,
        .card-ongoing-steps-details-right-col3 {
            font-size: 14px;
            font-weight: 500;
            font-family: $sukhmaitext;
        }

        .card-ongoing-steps-details-left-col3{
            &-title{
                margin-right: 4px;
            }
        }

        .card-ongoing-bmi-details {
            font-size: 18px;
            font-weight: 700;
            font-family: $sukhmaitext;
            padding-top: 16px;
        }

        .active-color {
            color: $black;
        }

        .bmi-green {
            color: $bmigreen;
        }

        .disable-color {
            color: $disabled-color;
        }
    }
}