@import '../../../../common/commonConstant.scss';

.bmi-details-description {
    margin-bottom: 0 !important;
    box-shadow: 0px 4px 12px 0px $homeblack;

    .bmi-details-description-title {
        font-size: 16px;
        font-weight: 700;
        margin-bottom: 20px;
        margin-top: 10px;
        font-family: $sukhmaibold;

    }

    p {
        font-family: $sukhmaitext;
        font-size: 14px;
        font-weight: 500;
        line-height: 20px;
    }
}