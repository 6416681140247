@import '../../common/commonConstant.scss';

.search-input-container {
    position: relative;

    .search-input {
        padding-left: 40px;
        height: 40px;
        border-radius: 10px;
        color: $black;
        font-weight: 500;

        &:not(:valid)~.close-icon {
            display: none;
        }

        &:focus {
            outline: none;
            box-shadow: none;
            border: 1px solid $homegrey3;
        }

        &:before {
            content: url("/images/icon-search.svg");
            position: absolute;
            left: 50%;
            margin-left: -8px;
            top: -16px;
        }

        &input {
            &::placeholder {
                color: $homegrey3;
            }

            &:focus {
                outline: none;
                box-shadow: none;
            }
        }
    }

    .close-icon {
        position: absolute;
        right: 8px;
        top: 50%;
        transform: translate(0px, -50%);
    }

    .search-img {
        position: absolute;
        top: 50%;
        left: 8px;
        transform: translate(0px, -50%);
        z-index: 1;
    }
}