@import '../../../../common/commonConstant.scss';

.accordian-container {
    box-shadow: 0px 4px 12px 0px $homeblack;
    margin: 14px 20px;
    border-radius: 12px;
    padding: 0 8px;
    background: $bgwhite;
    margin-bottom: 115px;

    .accordian-single {
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 12px 8px;
        height: 48px;
        border-bottom: 1px solid $homegrey3;

        &:last-of-type {
            border-bottom: none;
        }

        .accordian-title {
            font-size: 16px;
            font-weight: 500;
            color: $black;
        }

        .accordian-right-btn {
            width: 24px;
            height: 24px;
            background: $homewhite;
            display: flex;
            align-items: center;
            border-radius: 50%;
            justify-content: center;
        }
    }

}

.asm-modal-ctn {
    &.retryModalClass {
        .liff-panel.open .select-coupon-panel {
            height: 345px;

            >:nth-child(1) {
                border-radius: 24px 24px 0 0;
                height: 60%;
                padding: 15px 0 0 0;
                background-image: url("/images/healthAndWellness/assessment/assessment_retry_modal.svg");
                background-repeat: no-repeat;
                background-size: cover;

                .hdr {
                    padding: 16px;
                    margin-top: 24px;
                    display: flex;
                    flex-direction: column;
                    justify-content: center;
                    align-items: center;

                    .header-text {
                        font-size: 18px;
                        line-height: 26px;
                        font-weight: 800;
                        text-align: center;
                        font-family: $sukhmaitext;
                        color: $black;
                    }

                    .subheader-text {
                        color: $dark-red2;
                        font-size: 16px;
                        line-height: 24px;
                        font-weight: 700;
                        text-align: center;
                        font-family: $sukhmaitext;
                        width: 300px;
                    }
                }

                >button {
                    display: flex;
                    background-color: $bgwhite;
                    border-radius: 38px;
                    padding: 6px 8px;

                    >svg {
                        width: 28px;
                        height: 28px;
                    }

                }

            }
        }
    }

    &.desClass {
        .liff-panel.open .select-coupon-panel {
            height: 95%;
            overflow-y: scroll;

            >:nth-child(1) {
                background: none;

                >button {
                    display: flex;
                    border: 1px solid $black;
                    padding: 6px 6px;
                    border-radius: 38px;

                    >svg {
                        width: 24px;
                        height: 24px;
                    }
                }

            }
        }
    }

    &.modalClass {
        .liff-panel.open .select-coupon-panel {
            height: 305px;

            > :nth-child(1) {
                border-radius: 24px 24px 0 0;
                height: 55%;
                padding: 15px 0 0 0;
                background-image: url("/images/healthAndWellness/assessment/assessment_retry_modal.svg");
                background-repeat: no-repeat;
                background-size: cover;

                .hdr {
                    padding: 16px;
                    margin-top: 24px;

                    .header-text {
                        font-size: 18px;
                        line-height: 26px;
                        font-weight: 800;
                        text-align: center;
                        font-family: $sukhmaitext;
                        color: $black;
                    }

                    .subheader-text {
                        font-size: 16px;
                        line-height: 24px;
                        font-weight: 700;
                        text-align: center;
                        font-family: $sukhmaitext;
                        color: $black;
                    }
                }

                >button {
                    display: flex;
                    background-color: $bgwhite;
                    border-radius: 38px;
                    padding: 6px 8px;

                    >svg {
                        width: 28px;
                        height: 28px;
                    }

                }
            }
        }
    }
}