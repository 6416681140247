@import '../../../../common/commonConstant.scss';

.team-steps-container {
    padding: 17px 16px;
    // margin-top: 40px;
    &-header{
        display: flex;
        justify-content: space-between;
        margin-bottom: 17px;
        &-right{
            .rect-badge-container{
                padding: 3px 12px;
                .rect-badge-detail-section{
                    font-size: 16px;
                    line-height: 24px;
                    font-family: $sukhmaitext;
                    font-weight: 500;
                }
                .rect-badge-unit-section{
                    font-size: 16px;
                    line-height: 24px;
                    font-family: $sukhmaibold;
                    font-weight: 700;
                }
            }
        }
        .rect-badge-description{
            display: flex;
            justify-content: center;
            align-items: center;
            gap: 4px;
            .rect-badge-title{
                font-size: 16px;
                line-height: 24px;
                font-weight: 500;
                font-family: $sukhmaitext;
                color: $challenge-badge-black;

            }
            .rect-badge-value{
                font-size: 16px;
                line-height: 24px;
                font-weight: 700;
                font-family: $gtwalsheimbold;
                color: $challenge-badge-black;
                padding-top: 3px;

            }
        }
    }
    .team-name {
        font-family: $sukhmaitext;
        font-size: 20px;
        font-weight: 700;
        line-height: 30px;
        text-align: left;
        color: $a-black;
    }

    .challenge-card-body {
        box-shadow: 0px 4px 20px 0px $challenge-card-shadow;
        border-radius: 16px;
        padding: 20px 16px;
        display: flex;
        justify-content: flex-start;
        background: $a-serviceCard;
        gap: 16px;
        grid-gap: 16px;
        >:nth-child(1) {
            width: 60%;
        }
    }
    hr.rounded-dividing-line {
        width: 3px; 
        height: 94px;
        border: none;
        border-radius: 5px;
        margin: 0;
        box-shadow: 1px 1px 4px 0px $card-divider-line inset;
      }
}