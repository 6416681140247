@import '../../../../common/commonConstant.scss';

/************** Excercise Details Page Css Start *********************/

body:has(.excercise-details-page.--no_scroll) {
  overflow: hidden;
}

.excercise-details-page {
  background: url('/images/healthAndWellness/exercise-log/exercise-details-bg.svg');
  background-repeat: no-repeat;
  background-size: 100%;
  height: 100%;
  background-position: 5px -118px;

  @media (min-width:414px) {
    background-position: 5px -145px;
  }

  .excercise-details-container {
    padding-bottom: 125px;

    .excercise-details-header {
      &.headerClass {
        height: 126px;
        padding-bottom: 32px;
      }

      display: flex;
      align-items: center;
      justify-content: center;
      padding-top: 36px;
      padding-bottom: 8px;
      gap: 8px;
      background: $white;

      .excercise-details-title {
        font-size: 20px;
        font-weight: 800;
        font-family: $sukhmaitext;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        display: inline-block;
      }
    }


    .excercise-details-tab-container {
      .supplement-list-nav {
        background-color: $white;
      }

      .tab-content {
        padding: 0;
      }

      .hnw-setter-container {
        margin-bottom: 16px;
      }

      .excercise-details-title-wrapper {
        display: flex;
        align-items: center;
        gap: 16px;
        margin-bottom: 16px;
        position: relative;

        .excercise-details-card-bg {
          position: absolute;
          height: 100%;
          width: 100%;
          object-fit: cover;
          object-position: center;
        }

        .excercise-details-card {
          box-shadow: 1px 8px 18px 0px rgba(44, 44, 44, 0.1);
          background: $white;
          border-radius: 12px;
          position: relative;

          img {
            border-radius: 12px;
            height: 96px;
            width: 96px;
          }
        }

        .excercise-details-title {
          font-size: 20px;
          font-weight: 800;
          font-family: $sukhmaitext;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
          display: inline-block;

          position: relative;
        }
      }

      .excercise-details-list,
      .excercise-sub-details-container ul {
        font-size: 14px;
        margin: 0;
        padding-left: 16px;
      }

      .excercise-details-list {
        margin-bottom: 12px;

      }

      .excercise-sub-details-container {
        .excercise-sub-details-header {
          font-size: 18px;
          font-weight: 700;
          font-family: $sukhmaitext;
          display: flex;
          gap: 8px;
          align-items: center;
          margin-bottom: 16px;
          margin-top: 30px;
        }

        ul {
          li {
            margin-bottom: 16px;
          }
        }
      }

      .excercise-info-card {
        box-shadow: 0px 4px 12px 0px rgba(0, 0, 0, 0.15);
        background: $white;
        border-radius: 12px;
        padding: 0 16px;
        margin-bottom: 16px;

        .excercise-info-card-single {
          display: flex;
          justify-content: space-between;
          border-bottom: 1px solid $gray13;
          width: 100%;
          padding: 12px 0;
          align-items: center;

          &:last-of-type {
            border-bottom: none;
          }

          .excercise-info-card-left {
            display: flex;
            align-items: center;
            width: 80%;

            img {
              height: 24px;
              width: 24px;
            }

            span {
              font-size: 16px;
              font-weight: 500;
              padding-left: 5px;
              color: $gray12;
            }
          }

          .excercise-info-card-right {
            color: $gray13;
            font-weight: 500;
            width: 20%;

            .line-custom-input {
              border: none;
              background: transparent;
              color: $black;
              font-size: 16px;
              font-weight: 700;
              text-align: right;
              font-family: $sukhmaitext;
              width: 100%;
            }
          }
        }
      }
    }
  }

  .footer-container-wrapper {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    position: fixed;
    bottom: -2px;

    .footer-container-btn-wrapper {
      display: flex;
      align-items: center;
      width: 100%;
      background: $white;
      padding: 12px 20px 22px 20px;
      gap: 8px;

      .btn-green,
      .btn-disabled {
        height: 48px;
        font-family: $sukhmaitext;
        font-size: 16px;
        font-weight: 700;
        border-radius: 38px;
        padding: 6px 8px;
        margin-top: 10px;
        display: flex;
        justify-content: center;
        width: 48%;
        align-items: center;
        width: #{'calc(100% - 109px)'};
      }

      .btn-red {
        background: $dark-red2;
        border-radius: 38px;
        font-size: 16px;
        padding: 6px 8px;
        border-color: $dark-red2;
        font-weight: 700;
        height: 48px;
        color: $white;
        font-family: $sukhmaitext;
        display: flex;
        justify-content: center;
        align-items: center;
        width: 101px;
      }

      .btn-green,
      .btn-green:focus,
      .btn-green:active,
      .btn-green:hover {
        border: 1px solid rgba(60, 157, 71, 1);
        background: rgba(60, 157, 71, 1);
        color: $white;
        box-shadow: none;
      }

      .btn-red,
      .btn-red:focus,
      .btn-red:active,
      .btn-red:hover {
        border: 1px solid $dark-red2;
        background: $dark-red2;
        color: $white;
        box-shadow: none;
      }

      .btn-disabled,
      .btn-disabled:focus,
      .btn-disabled:active,
      .btn-disabled:hover {
        border: 1px solid $gray12;
        background: $gray12;
        color: $white;
        box-shadow: none;
      }
    }
  }
}

/************** Excercise Details Page Css End *********************/

/******************** Modal CSS Start *********************/
#deleteModalios {
  .modal-heading {
    max-width: 190px;
    text-align: center;
    margin: auto;
    font-size: 18px;
  }

  .modal-footer {

    .btn-link-black,
    .btn-link-red {
      font-size: 18px;
      font-weight: 700;
      font-family: $sukhmaitext;
    }

    .btn:last-of-type {
      border-left: 1px solid rgba(60, 60, 67, 0.36);
    }
  }
}

#syncDeviceModal {
  bottom: 0;
  height: initial;
  top: initial;

  .modal-dialog {
    margin: 0;
    max-width: 100%;

    .modal-header {
      justify-content: flex-end;
      border-bottom: 0;
      padding-bottom: 0;
      padding-right: 20px;
      margin-top: 16px;
      height: 44px;

      button {
        background: $white;
        border: none;
        height: 26px;
        width: 26px;
        border-radius: 50%;
        display: flex;
        align-items: center;
        justify-content: center;
      }
    }

    .modal-content {
      border-radius: 0;
      border: none;
      border-top-left-radius: 24px;
      border-top-right-radius: 24px;
      padding: 0px;

      .modal-body {
        padding: 16px 20px 48px 20px;

        .modal-heading {
          margin-bottom: 14px;
        }

        .devices-list-wrapper {
          display: flex;
          flex-direction: column;
          gap: 16px;

          .devices-single {
            display: flex;
            justify-content: space-between;
            align-items: center;

            .device-single-left {
              display: flex;
              align-items: center;
              gap: 9px;

              .device-name {
                font-size: 16px;
                font-weight: 700;
                font-family: $sukhmaitext;
                color: $black;
              }
            }

            .btn-icon-round {
              background: $homewhite;
            }
          }
        }

        .btn-red {
          margin-top: 14px;
        }
      }
    }
  }
}

#syncSuccessModal {
  .modal-content {
    max-width: 227px;
    background: $white;
    width: 100%;
    border-radius: 14px;
    margin: auto;

    .modal-body {
      padding: 14px;
      text-align: center;

      .modal-heading {
        margin-bottom: 0;
        margin-top: 8px;
        font-size: 18px;
        font-weight: 800;
        line-height: 26px;
      }
    }
  }
}

#loadingSyncModal {
  bottom: 0;
  height: initial;
  top: initial;

  .modal-dialog {
    margin: 0;
    max-width: 100%;

    .modal-header {
      justify-content: flex-end;
      border-bottom: 0;
      padding-bottom: 0;
      padding-right: 20px;
      margin-top: 16px;
      height: 44px;

      button {
        background: $white;
        border: none;
        height: 26px;
        width: 26px;
        border-radius: 50%;
        display: flex;
        align-items: center;
        justify-content: center;
      }
    }

    .modal-content {
      border-radius: 0;
      border: none;
      border-top-left-radius: 24px;
      border-top-right-radius: 24px;
      padding: 0px;

      .modal-body {
        padding: 16px 20px 48px 20px;

        .modal-heading {
          margin-bottom: 14px;
        }

        .devices-list-wrapper {
          display: flex;
          flex-direction: column;
          gap: 16px;

          .devices-single {
            display: flex;
            justify-content: space-between;
            align-items: center;

            .device-single-left {
              display: flex;
              align-items: center;
              gap: 9px;

              img {
                height: 50px;
                width: 50px;

                @media (max-height: 484px) {
                  height: 30px;
                  width: 30px;
                }
              }

              .device-name {
                font-size: 16px;
                font-weight: 700;
                font-family: $sukhmaitext;
                color: $black;
              }
            }

            .btn-icon-round {
              background: $homewhite;

              img {
                height: 11px;
              }
            }
          }
        }
      }
    }
  }
}

/******************** Modal CSS End *********************/

.exercise-log-mb {
  position: relative;
  top: -200px;
}

.set-transition {
  transition: 0.2s ease-in-out;
}