@import '../../../../common/commonConstant.scss';

.challenge-my-activity-reward-container {
    border-radius: 16px;
    padding: 11px 15px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 16px 17px;
    background: $bgwhite;
    box-shadow: 0px 4px 20px 0px $challenge-card-shadow;

    .reward-title {
        font-size: 18px;
        font-weight: 700;
        font-family:$sukhmaitext;
    }

    .reward-subtitle {
        font-size: 14px;
        font-weight: 500;
    }

    .btn-green-linear {
        width: 129px;
        color: $a-circleStroke;
        font-size: 16px;
        font-weight: 700;
        font-family:$sukhmaitext;
    }

    @media (max-width: 362px) {
        .btn-green-linear {
            width: 90px;
        }
    }
}