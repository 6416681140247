@import '../commonConstant.scss';

.liff-numpad-panel {
    left: 0;
    bottom: 0;
    width: 100%;
    height: 0px;
    float: right;
    z-index: 2000;
    position: fixed;
    margin-top: 200px;
    margin-right: 34px;

    .numpad-panel {
        left: 0;
        bottom: 0;
        width: 100%;
        height: 0px;
        position: fixed;
        background-color: $bgwhite;
        border-top-left-radius: 20px;
        border-top-right-radius: 20px;
        transition: height 0.2s ease-in-out;
        max-width: 768px;

        &>.container-fluid {
            border-bottom: 1px solid $homegrey3;
        }

        .btn-close {
            font-family: $sukhmaitext;
            top: 15px;
            right: 20px;
            border: none;
            position: absolute;
            font-size: larger;
            background-color: $bgwhite;
            letter-spacing: 0px !important;
            border-radius: 38px;
            display: flex;
            padding: 6px 8px;
        }
    }
}

body:has(.liff-numpad-panel.open) {
    overflow: hidden;
}

.liff-numpad-panel.open {
    height: 100vh;
    z-index: 999;

    .numpad-panel {
        height: 250px;
    }
}