@import "../../common/commonConstant.scss";

.assessment-image-card {
    box-shadow: 0px 4px 20px 0px $challenge-card-shadow;
    border-radius: 16px;
    overflow: hidden;
    background: linear-gradient(111.54deg, $challenge-slider-bg 4.76%, $challenge-self-grey 104.39%);
    margin: 0 17px 16px 0px;
    width: 320px;

    .assessment-card-image {
        width: 100%;
        background-size: cover;
        object-fit: cover;
    }

    .card-container-details {
        display: flex;
        justify-content: space-between;
        height: 66px;
        align-items: center;
        padding: 0 16px;
    }
}