@import "../commonConstant.scss";

.circular-chart {
  position: absolute;
  top: 0;
  width: 100%;
  height: 100%;

  svg {
    height: 35px;
    width: 35px;

    circle {
      width: 100%;
      height: 100%;
      fill: none;
      stroke-width: 3.5px;

      &:nth-of-type(2) {
        stroke-dasharray: 100;
        stroke-dashoffset: calc(100 - (var(--percent1) + var(--percent2)));
      }

      &:nth-of-type(3) {
        stroke-dasharray: 100;
        stroke-dashoffset: calc(100 - var(--percent2));
      }
    }
  }
}