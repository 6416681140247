@import "../../../../common/commonConstant.scss";

.goal-search-history-page {
    background: url("/images/healthAndWellness/goals/goals-search-bg.svg") $bgwhite;
    background-repeat: no-repeat;
    width: 100%;
    background-position: 0 -95px;
    font-family: $sukhmaitext;
    margin-bottom: 40px;
    background-size: 100%;

    .back-container {
        display: flex;
        padding: 16px 20px 16px 12px;
        gap: 73px;

        .back-wrapper {
            display: flex;
            align-items: center;
            padding: 6px 8px;
            gap: 4px;

            .back-title {
                font-size: 16px;
                font-weight: 700;
                line-height: 20px;
                text-align: center;
            }
        }
    }

    .header-wrapper {
        background-color: $bgwhitetranslucent;
        backdrop-filter: blur(2px);
        padding-top: 16px;
        padding-bottom: 0px;
    }

    .banner-container {
        .search-img-container {
            background-image: url("/images/healthAndWellness/foodCalories/food-list/food-header-curve.svg");
            background-repeat: no-repeat;
            margin-top: 20px;
            background-position: 0 40px;
        }

        .search-category-container {
            margin-top: 20px;
            margin-bottom: 14px;

            .dietary-supplement-section {
                background: $white;
                box-shadow: 0px 4px 12px 0px $shadowgrey3;
                border-radius: 12px;
                margin-bottom: 16px;
                gap: 0;
            }

            .sh-category-title {
                font-size: 20px;
                font-weight: 800;
                margin: 20px 0;
            }

            .category-carousel-container {
                overflow-x: auto;
                overflow-y: hidden;
                margin-top: 5px;
                display: flex;
                gap: 8px;
                flex-wrap: nowrap;

                .category-card {
                    display: flex !important;
                    align-items: center;
                    justify-content: space-between;
                    border: 1px solid $homegrey3;
                    border-radius: 29px;
                    padding: 4px 8px 4px 4px;
                    gap: 8px;

                    &.active {
                        background-color: $fgreen2;
                        color: $bgwhite;
                    }

                    .sh-category-card-title {
                        font-size: 14px;
                        font-weight: 700;
                        text-align: center;
                        line-height: 20px;
                        white-space: nowrap;
                    }

                    img {
                        height: 24px;
                        width: 24px;
                    }
                }
            }

            .history-keyword-container {
                display: flex;
                align-items: center;
                flex-wrap: wrap;
                gap: 12px;

                .history-keyword-content {
                    display: flex;
                    align-items: center;
                    padding: 4px 10px;
                    border-radius: 24px;
                    background-color: $homewhite;
                    gap: 4px;

                    &.active {
                        background-color: $fgreen2;
                        color: $bgwhite;

                        img {
                            -webkit-filter: brightness(10);
                            filter: brightness(10);
                        }
                    }

                    .sh-category-card-title {
                        font-size: 12px;
                        font-weight: 700;
                        text-align: center;
                        line-height: 20px;
                        white-space: nowrap;
                    }

                    img {
                        height: 24px;
                        width: 24px;
                    }
                }
            }

            .product-search_results-container {
                @media (min-width: 325px) {
                    .dietary-supplement-right-description-sm {
                        display: none;
                    }
                }

                @media (max-width: 324px) {
                    .dietary-supplement-section-wrapper {
                        display: flex;
                        flex-wrap: wrap;
                        gap: 10px;
                        justify-content: space-between;
                    }

                    .dietary-supplement-section {
                        width: 47%;
                        flex-direction: column;
                        justify-content: space-between;
                        padding: 0;
                    }

                    .dietary-supplement-left-img {
                        width: 100%;
                        justify-content: center;
                        padding: 8px 0;
                    }

                    .dietary-supplement-left-img img {
                        width: 100%;
                       height: 128px;
                        object-fit: cover;
                    }

                    .dietary-supplement-left-title {
                        display: none;
                    }

                    .dietary-supplement-left-section {
                        padding-bottom: 0;
                        height: 128px;
                    }

                    .dietary-supplement-right-section {
                        width: 100%;
                        padding: 0 8px 8px 8px;
                    }

                    .dietary-supplement-right-nutrition-slots,
                    .dietary-supplement-right-details,
                    .dietary-supplement-section-wrapper .line {
                        display: none;
                    }

                    .dietary-supplement-right-title {
                        font-weight: 500;
                        margin-bottom: 8px;
                        margin-top: 0;
                    }
                }
            }

            .product-suggestion-container {
                .dietary-supplement-section-wrapper {
                    display: flex;
                    flex-wrap: wrap;
                    gap: 10px;
                    justify-content: space-between;
                }

                .dietary-supplement-section {
                    width: 47%;
                    flex-direction: column;
                    justify-content: space-between;
                    padding: 0;
                }

                .dietary-supplement-left-img {
                    width: 100%;
                    justify-content: center;

                    height: 128px;
                    padding: 0;

                    &.--food {
                        .--no_image {
                            content: url("/images/healthAndWellness/foodCalories/food-details/food-default-suggest-card.svg");
                        }
                    }
                    &.--supplement {
                        .--no_image {
                            content: url("/images/healthAndWellness/foodCalories/food-details/supplement-default-suggest-card.svg");
                        }
                    }
                }

                .dietary-supplement-left-img img {
                    width: 100%;
                    height: 128px;
                    object-fit: cover;
                    border-radius: 12px 12px 0 0;
                }

                .dietary-supplement-left-title {
                    display: none;
                }

                .dietary-supplement-left-section {
                    padding-bottom: 0;
                    height: 128px;
                }

                .dietary-supplement-right-section {
                    width: 100%;
                    padding: 0;
                }

                .dietary-supplement-right-details {
                    border-top: 1px dashed $gray14;
                    margin: 8px 12px;
                    height: 24px;
                    padding: 0;

                    .dietary-supplement-right-weight-title,
                    .dietary-supplement-right-weight-value,
                    .dietary-supplement-right-calories-title,
                    .dietary-supplement-right-calories-value {
                        font-size: 12px;
                    }

                    .dietary-supplement-right-weight-section {
                        border-right: none;
                        justify-content: normal;
                        column-gap: 2px;
                    }

                    .dietary-supplement-right-weight-value {
                        border-left: 1px dashed $gray14;
                        padding-left: 2px;
                    }

                    .dietary-supplement-right-weight-unit {
                        display: none;
                    }

                    .dietary-supplement-right-calories-section {
                        border-right: none;
                        justify-content: normal;
                        column-gap: 2px;
                    }

                    .dietary-supplement-right-calories-value {
                        border-left: 1px dashed $gray14;
                        padding-left: 2px;
                    }

                    .dietary-supplement-right-calories-unit {
                        display: none;
                    }
                }

                .dietary-supplement-right-nutrition-slots,
                .dietary-supplement-section-wrapper .line {
                    display: none;
                }

                .dietary-supplement-right-title {
                    font-weight: 500;
                    margin: 8px 10px;
                    height: 40px;
                    display: flex;
                    align-items: center;
                    span {
                        overflow: hidden;
                        text-overflow: ellipsis;
                        display: -webkit-box;
                        -webkit-box-orient: vertical;
                        -webkit-line-clamp: 2;
                      }
                }


                @media (min-width: 325px) {
                    .dietary-supplement-right-description-sm {
                        display: none;
                    }
                }

                @media (max-width: 324px) {

                    .dietary-supplement-right-nutrition-slots,
                    .dietary-supplement-right-details,
                    .dietary-supplement-section-wrapper .line {
                        display: none;
                    }
                }
            }
        }
    }

    .empty-list-state {
        margin-top: 35px;

        img {
            width: 100%;
        }
    }
}