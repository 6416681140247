@import "../../../../../common/commonConstant.scss";

.box-container {
    .box-question {
        margin-bottom: 10px;
        margin-top: 30px;
        font-size: 16px;
        font-weight: 700;
        line-height: 24px;
        color: $a-black;
        font-family: $sukhmaitext;
    }
}