@import "../../common/commonConstant.scss";
.hnw-checkbox-container {
    display: flex;
    gap: 12px;
    align-items: center;
    width: 100%;
    
    &-text {
        font-size: 16px;
        font-weight: 700;
        font-family: $sukhmaitext;
        line-height: 20px;
    }
}