@import "../commonConstant.scss";

.nutrition-chart-heading {
    display: flex;
    justify-content: space-between;
    margin-bottom: 26px;
    .nutrition-chart-heading-font {
        font-family: $sukhmaitext;
        font-weight: 700;
        font-size: 18px;
        line-height: 26px;
        color: $a-black;
    }
}

.nutrition-card-container {
    padding: 20px;

    .card {
        box-shadow: 1px 8px 18px 0px $nutrition-card-shadow;
        border: 1px solid $bgwhite;
        border-radius: 12px;
        overflow: hidden;
        padding: 20px;
    
        progress {
            border-radius: 7px;
            width: 100%;
        }
    
        progress::-webkit-progress-bar {
            background-color: #F0F0F0;
            border-radius: 7px;
            height: 9px;
        }
    
        progress::-webkit-progress-value {
            background: linear-gradient(to right, #6CC949, #9EDC7C);
            border-radius: 7px;
            height: 9px;
        }
    
        .nutrition-card-heading {
            display: flex;
            justify-content: flex-end;
            margin-bottom: 12px;
        }
    
        .heading-font {
            font-family: $sukhmaitext;
            font-weight: 500;
            font-size: 14px;
            line-height: 20px;
            color: #A0ABC0
        }
    
        .padding-small {
            padding-right: 11px;
        }
    
        .padding-large {
            padding-right: 16px;
        }
    
        .bar-nutrition {
            display: flex;
            justify-content: space-between;
        }
    
        .bar-nutrition-values {
            display: flex;
            justify-content: space-between;
    
            .values-font {
                font-family: $sukhmaitext;
                font-weight: 700;
                font-size: 14px;
                line-height: 20px;
                color: $a-black;
                width: 62px;
                display: flex;
                justify-content: flex-end;
            }
        }
    
    }
}
