@import "../commonConstant.scss";
.challenge-tab-list {
    display: flex;
    justify-content: space-between;
    gap: 20px;
    width: 100%;

    &__nav-link {
        height: 44px;
        color: $black;
        font-size: 16px;
        font-weight: 700;
        font-family: $sukhmaitext;
        padding: 0px 26px;
        position: relative;
        border: none;
        background: none;

        &-active {
            position: relative;
            border-radius: 32px;
            padding: 0px 20px;
            background:
            linear-gradient(107.74deg, #E6E6E6 27.91%, #FEFEFE 98.01%) padding-box,
            linear-gradient(139.18deg, #FBFBFB 16.07%, #DCDCDC 93.35%) border-box;
            border: 4px solid transparent;
            box-shadow: 4px 4px 18px 0px $challenge-tab-shadow;
        }
    }
    &.nav-sticky{
        position: fixed;
        width: 100%;
        z-index: 1000;
        background: $challenge-carousel-bg;
        backdrop-filter: blur(12px);
        transition: top 0.3s ease-in-out;
    }
}
