@import "../../../common/commonConstant.scss";

.policy-container {
    background: $bggray1;
    height: calc(100vh - 54px);
    font-weight: 500;
    font-family: $sukhmaitext;

    .col-12 {
        padding: 0 12px;
    }

    .policy-title {
        margin-bottom: 15px;
        font-size: 20px;
        margin-top: 15px;
        font-weight: 800;
        padding: 0 5px;
        font-family: $sukhmaibold;
    }

    .policy-content-wrapper {
        background-image: url(/images/termsConditions/bg-terms-condition.svg);
        background-repeat: no-repeat;
        background-size: cover;
        padding: 10px 20px;
        padding-top: 0;
        background-position: 0 24px;
        height: 100%;
        overflow-x: hidden;

        .card {
            box-shadow: 0px 4px 12px 0px $shadowgrey3;
            padding: 10px 15px;
            border-radius: 8px;
            background: $bgwhite;
            font-size: 14px;
            color: $black;
            font-weight: 500;
            border: none;
            min-height: calc(100vh - 115px);

            @media (max-width: 376px) {
                height: 100%;
            }

            .card-title {
                display: flex;
                justify-content: space-between;
                align-items: center;
                border-bottom: 1px solid $homegrey3;

                p {
                    font-size: 14px;
                    font-weight: 500;
                }

                .switch {
                    position: relative;
                    display: inline-block;
                    width: 60px;
                    height: 34px;

                    @media (max-width:320px) {
                        width: 70px;
                    }

                    input {
                        opacity: 0;
                        width: 0;
                        height: 0;

                        &:checked {
                            +.slider {
                                background-color: $homegreen;

                                &:before {
                                    -webkit-transform: translateX(26px);
                                    -ms-transform: translateX(26px);
                                    transform: translateX(26px);
                                }
                            }
                        }

                        &:focus {
                            +.slider {
                                box-shadow: 0 0 1px $homegreen;
                            }
                        }
                    }

                    .slider {
                        position: absolute;
                        cursor: pointer;
                        top: 0;
                        left: 0;
                        right: 0;
                        bottom: 0;
                        background-color: $homegrey3;
                        -webkit-transition: .4s;
                        transition: .4s;

                        &:before {
                            position: absolute;
                            content: "";
                            height: 26px;
                            width: 26px;
                            left: 4px;
                            bottom: 4px;
                            background-color: white;
                            -webkit-transition: .4s;
                            transition: .4s;
                        }

                        &.round {
                            border-radius: 34px;

                            &:before {
                                border-radius: 50%;
                            }
                        }

                    }

                }
            }

            .card-content {
                .content-top-text {
                    margin-top: 15px;
                }

                .card-list {
                    margin-left: 3px;

                    .card-list-single {
                        display: flex;
                        align-items: center;

                        .card-list-dot {
                            height: 2px;
                            width: 2px;
                            display: inline-block;
                            border-radius: 50%;
                            background: $jet-black;
                            margin-right: 22px;
                        }
                    }
                }
            }
        }
    }
}