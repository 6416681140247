@import '../../../../common/commonConstant.scss';

.challenge-my-bmi-container {
    padding: 0px 16px 40px;

    .challenge-card-body {
        box-shadow: 0px 4px 20px 0px $challenge-card-shadow;
        border-radius: 16px;
        padding: 16px;
        display: flex;
        flex-direction: column;
        background: $a-serviceCard;

        .card-ongoing-bmi-details {
            font-size: 18px;
            font-weight: 700;
            font-family: $sukhmaitext;

            .bmi-heading {
                font-size: 16px;
                font-weight: 700;
                font-family: $gtwalsheimbold;
                line-height: 24px;
            }

            .bmi-heading-thai {
                font-size: 18px;
                font-weight: 700;
                font-family: $sukhmaitext;
                line-height: 27px;
            }
        }
    }
}