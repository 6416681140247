@import "../commonConstant.scss";

.box-container {
  
  .box-div {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
  }
  
  .box {
    position: relative;
    width: calc(50% - 10px);
    background-color: $a-white;
    border-radius: 16px;
    overflow: hidden;
    cursor: pointer;
    margin: 5px;
    height: 100px;  
  }
  
  .selected-box-border {
    border: 4px solid $a-green;
  }
  
  .box-text {
    color: $a-black;
    // padding: 10px;
    font-size: 14px;
    font-weight: 800;
  }
  
  .selected-number {
    position: absolute;
    bottom: 0;
    left: 0;
    background-color: $a-green;
    color: $a-white;
    padding: 6px 10px;
    border-radius: 0 12px 0 0;
    font-size: 14px;
    font-weight: 800;
  }

  .box-image {
    height: 100%;
    width: 100%;
  }

}


