@import '../../../../../common/commonConstant.scss';

.goal-setup-box-component {
  background: rgba(242, 247, 241, 1);
  padding: 6px 4px;
  border-radius: 6px;
  &-title,
  &-unit {
    font-size: 14px;
    font-weight: 500;
    color: #a0abc0;
  }
  &-value {
    font-size: 16px;
    font-weight: 700;
    font-family: $sukhmaitext;
    color: #2c2c2c;
  }
}
