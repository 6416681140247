@import "../../common/commonConstant.scss";

.otpinput-container {
  position: relative;
  overflow: hidden;
  input {
    display: inline-block;
    width: 40px;
    margin-right: 5px;
    text-align: center;
    transition: border 0.5s cubic-bezier(0.165, 0.84, 0.44, 1);
    border: none;
    border-bottom-style: solid;
    border-width: 1px;
    border-color: #2c2c2c;
    background-color: transparent;
    color: #2c2c2c;
    font-size: 16px;
    &:placeholder-shown {
      border-color: #969696;
    }
  }
  &.focus {
    input {
      border-color: #2c2c2c;
    }
  }
  &.loading {
    input {
      color: #d3d3d3;
      border-color: #d3d3d3;
    }
  }
  &.invalid {
    animation: shake 0.5s linear;
    input {
      color: #cb5454;
      border-color: #cb5454;
    }
    &.loading {
      input {
        color: #e7b5b5;
        border-color:#e7b5b5;
      }
    }
  }
  &.disable {
    input {
      color: #d3d3d3;
      border-color: #d3d3d3;
    }
  }
  .shimmer-container {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    animation: loading 2.5s infinite;
    z-index: 2;
    mix-blend-mode: lighten;
    display: none;
    &.loading {
      display: block;
    }
  }
  .shimmer {
    width: 30%;
    height: 100%;
    background: #fafafa;
    opacity: 0.8;
    transform: skew(-20deg);
    position: relative;
    filter: blur(10px);
  }

  .invalid-message {
    color: #cb5454;
    font-size: 12px;
    margin-top: 5px;
  }

  .link {
    color: $light-blue;
    text-decoration: underline;
    font-size: 14px;
    margin-top: 10px;
    &.disable {
      opacity: 0.5;
    }
    &.countdown-shown {
      opacity: 0.5;
    }
  }
}

.countdown {
  margin-top: 5px;
  font-size: 12px;
  color: #cb5454;
}

@keyframes loading {
  0% {
    transform: translateX(-100%);
  }
  20% {
    transform: translateX(-30%);
  }
  100% {
    transform: translateX(120%);
  }
}

@keyframes shake {
  8%,
  41% {
    transform: translateX(-10px);
  }
  25%,
  58% {
    transform: translateX(10px);
  }
  75% {
    transform: translateX(-5px);
  }
  92% {
    transform: translateX(5px);
  }
  0%,
  100% {
    transform: translateX(0);
  }
}
