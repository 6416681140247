@import '../../../common/commonConstant.scss';

.auto-sync-body {
  width: auto;
  height: auto;
  border-radius: 16px;
  margin: 0;
  padding: 16px;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 24px;

  &-title {
    font-family: $sukhmaitext;
    font-size: 18px;
    font-weight: 700;
    line-height: 27px;
    text-align: center;
  }

  &-info {
    font-family: $sukhmaitext;
    font-size: 14px;
    font-weight: 500;
    line-height: 21px;

    text-align: center;

    .phone-number-green-1 {
      color: $homegreen;
      font-family: $sukhmaitext;

      font-size: 20px;
      font-weight: 800;
      line-height: 30px;
      text-align: center;
    }

    .phone-number-green-fail-2 {
      color: $homegreen;
      font-family: $sukhmaitext;

      font-size: 20px;
      font-weight: 800;
      line-height: 30px;
      text-align: center;
    }

    .abo-id-not-found-err {
      color: $challenge-body-key-id-error;
    }
  }

  &-input-field {
    width: 242px;
    padding: 5px 3px 5px 3px;
    gap: 8px;
    text-align: center;
    background: linear-gradient(90.34deg, $bggray 0.29%, $bggray1 98.19%);
    border-radius: 10px;
    .auto-sync-input-box {
      font-family: $gtwalsheimpro;
      font-size: 18px;
      font-weight: 500;
      line-height: 27px;
      text-align: center;
      border-bottom: none;
    }
    .auto-sync-input-box.error-value {
      color: $challenge-body-key-id-error;
    }
  }

  &-action-box {
    display: flex;
    align-items: center;
    width: 100%;
    .btn-outline-black,
    .btn-green {
      font-family: $sukhmaitext;
      width: 50%;
      font-weight: 700;
      .text-color-white {
        color: $a-circleStroke;
      }
    }

    .btn-outline-black {
      color: $black;
    }
  }
}
