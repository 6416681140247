@import "../../../common/commonConstant.scss";

body::-webkit-scrollbar:has(.badge-details-container) {
  display: none; // Safari and Chrome
}

.badge-details-container {
  .badge-details-body.dashboardSticky {
    margin-top: 56px;
  }

  &.badge-scroll {
    overflow-y: auto;
  }

  &.no-scroll {
    overflow-y: hidden;
  }

  &.others-badge {
    margin-bottom: 85px;
  }

  .badge-details-inner {
    margin-bottom: 21px;

    .badge-title {
      font-size: 30px;
      font-weight: 700;
      line-height: 45px;
      font-family: $sukhmaitext;
      margin-bottom: 8px;
    }

    p {
      font-weight: 500;
      font-family: $sukhmaitext;
      margin-bottom: 0;
      font-size: 16px;
    }
  }

  .badge-details-calendar-container {
    box-shadow: 0px 4px 18px 0px $flblack;
    background-color: $white;
    margin: 0 16px;
    padding: 0 12px;
    border-radius: 12px;

    .custom-calendar {
      .react-calendar__viewContainer {
        .react-calendar__tile:disabled {
          display: none;

          &.react-calendar__month-view__days__day {
            display: block;
          }
        }
      }
    }

    .badge-values {
      margin-bottom: 0;
      padding-bottom: 28px;
      margin-top: 14px;
    }
  }

  .social-media-section {
    display: flex;
    justify-content: center;
    margin: 28px 0;

    .badge-details-challenge-bar {
      width: 100%;
      padding-inline: 25px;

      .steps-progress-container {
        display: flex;
        justify-content: space-between;
        margin-top: 5px;

        .steps-progress-values {
          .normal-steps-font {
            font-family: $gtwalsheimpro;
            font-size: 16px;
            font-weight: 400;
            line-height: 24px;
          }

          .steps-font {
            font-family: $sukhmaitext;
            font-size: 16px;
            font-weight: 500;
            line-height: 24px;
            margin-left: 4px;
          }
        }


        .bold-steps-font {
          font-family: $gtwalsheimbold;
          font-size: 18px;
          font-weight: 700;
          line-height: 27px;
        }
      }

      .progress-outer {
        height: 20px;
        width: 100%;
        box-shadow: 4px 4px 5.3px 0px $progress-gradient-lg inset;
        background: linear-gradient(107.74deg, $bmi-bar-bg-1 27.91%, $bmi-bar-bg-2 98.01%);

        .progress {
          height: 8px;
          width: 95%;
          color: $challenge-users-loader-middle;

          .progress-bar.bg-fill {
            border-radius: 24px;
          }
        }
      }
    }
  }

  .badge-flipper {
    padding-left: 0px;
    padding-right: 0px;
    margin-bottom: 10px;
    perspective: 1000px;

    .card-wrapper {
      text-align: center;
      color: $a-progress-bar-bg;
      width: 100%;
      height: 288px;
      transition: 0.6s;
      transform-style: preserve-3d;
      position: relative;
      background-color: transparent;

      .badge-frontImg-lg {
        padding: 22.4px 0;
        height: 280px;
        filter: drop-shadow(2px -2px 2px $a-card-box-shadow);
      }

      .back-img-container {
        position: relative;

        .back-img-content {
          position: absolute;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
          color: $back-img-content;
          font-size: 18px;
          font-weight: 700;
          font-family: $sukhmaitext;
        }
      }

      .card-front,
      .card-back {
        width: 100%;
        -webkit-backface-visibility: hidden;
        backface-visibility: hidden;
        position: absolute;
        top: 0;
        left: 0;
      }

      .card-front {
        z-index: 2;
        transform: rotateY(0deg);
        height: 100%;
      }

      .card-back {
        transform: rotateY(180deg);
        height: 100%;

        .back-img-value {
          p {
            margin-bottom: 0;
            font-size: 18px;
            font-weight: 700;
          }
        }
      }
    }

    .badge-details-description {
      .badge-note {
        font-family: $sukhmaitext;
        font-weight: 500;
        font-size: 14px;
        color: $gray1;
        margin: 20px 0;
      }
    }
  }

  .badge-flipper.flip .card-wrapper {
    transform: rotateY(180deg);
  }

  .badge-team-data {
    margin-top: 40px;

    .challenge-badge-detail {
      margin-inline: 16px;

      &.badge-scroll {
        margin-bottom: 90px;
      }


      &-heading {
        font-family: $sukhmaitext;
        font-size: 18px;
        font-weight: 700;
        line-height: 27px;
        text-align: left;
        margin-bottom: 8px;
      }

      &-list {
        margin-top: 16px;
        display: flex;
        flex-direction: column;

        &-users {
          display: flex;
          align-items: center;
          padding: 10px 0;
          gap: 48px;

          &-profile {
            img {
              width: 32px;
              height: 32px;
            }

            &.badge-received {
              img {
                width: 32px;
                height: 32px;
                opacity: 0.5;
                box-shadow: 1px 8px 18px -8px $chalenge-profile-shadow;
              }
            }

            position: relative;
            display: inline-block;

            .profile-circle-img {
              display: block;
              height: 32px;
              width: 32px;
              border-radius: 50%;

            }

            .dashboard-slider-leader-img {
              position: absolute;
              top: 50%;
              left: 50%;
              transform: translate(-50%, -50%);

              .profile-img {
                height: 32px;
                width: 32px;
                border-radius: 50%;
              }

              .btn-icon-crown {
                height: 20px;
                width: 20px;
                border-radius: 50%;
                background: $bgwhite;
                position: absolute;
                left: 20px;
                bottom: -4px;
                box-shadow: 1px 2px 3px 0px $crown-icon-shadow;
                display: flex;
                align-items: center;
                justify-content: center;

                img {
                  width: 16px;
                  height: 16px;
                }
              }
            }
          }

          &-name {
            font-size: 16px;
            font-family: $sukhmaitext;
            color: $black;
            font-weight: 500;
            text-overflow: ellipsis;
            overflow: hidden;
            white-space: nowrap;
            width: 100%;
            text-align: left;

            &.badge-received {
              color: $gray8;
            }
          }

          &-date {
            display: flex;
            justify-content: flex-end;
            font-size: 14px;
            font-family: $sukhmaitext;
            color: $black;
            font-weight: 400;
            min-width: 20%;
          }

          &-date-progress {
            display: flex;
            align-items: center;
            width: 104px;

            .progress-outer {
              width: 57px;
              margin-right: 8px;
            }

            .user-badge-progress-percent {
              font-family: $gtwalsheimpro;
              font-size: 14px;
              font-weight: 400;
              line-height: 21px;
            }
          }
        }
      }
    }
  }

  .badge-values {
    text-align: center;
    margin-top: 21px;
    margin-bottom: 25px;

    .badge-current-values {
      font-family: $gtwalsheimpro;
      font-size: 20px;
      font-weight: 700;
      line-height: 24px;
    }

    .green-font {
      color: $hnw-green;
    }

    .badge-total-values {
      font-family: $gtwalsheimpro;
      font-size: 14px;
      font-weight: 400;
      line-height: 21px;
      margin-right: 6px;
    }

    .badge-unit {
      font-family: $sukhmaitext;
      font-size: 14px;
      font-weight: 500;
      line-height: 21px;
    }
  }
}

.quiz-and-video-button {
  position: fixed;
  z-index: 1;
  bottom: 0;
  width: 100%;
  padding: 16px;
  margin-bottom: 8px;

 .secondaryBtn {
    .challenge-btn-container-title {
      color: var(--color-neutral-neutral-400, $anstype-inner-bg);
    }
  }
}