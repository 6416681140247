@import "../../../../common/commonConstant.scss";

.asm-not-com-ctn {
    border-radius: 12px;
    display: flex;
    flex-direction: column;
    margin: 14px;
    box-shadow: 0px 4px 12px 0px $homeblack;
    background: $bgwhite;
    padding: 16px;
    gap: 16px;

    .not-com-header {
        font-size: 18px;
        line-height: 26px;
        font-weight: 800;
        font-family: $sukhmaibold;
        color: $black;
    }

    .not-com-footer {
        display: flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;
        margin-top: auto;

        .btn1 {
            width: 100%;
            height: 36px;
            display: flex;
            justify-content: center;
            align-items: center;
            padding: 6px 6px;
            border-radius: 38px;
            border: 1px;
            gap: 4px;
            background-color: $a-green;
            text-align: center;

            span {
                font-family: $sukhmaitext;
                font-weight: 700;
                font-size: 16px;
                line-height: 20px;
                color: $bgwhite;
            }

        }
    }

    .not-com-body-content {
        position: relative;
        padding: 16px 0;

        .body-content {
            position: absolute;
            right: 20px;
            text-align: right;
            display: flex;
            flex-direction: column;
            gap: 8px;
            transform: translate(10px, -50%);
            top: 50%;
            align-items: flex-end;

            &-title {
                font-size: 16px;
                line-height: 22px;
                font-weight: 500;
                font-family: $sukhmaitext;
                color: $black;
            }

            &-btn {
                background: $a-orange;
                width: 108px;
                height: 43px;
                border-radius: 6px;
                color: $bgwhite;
                display: flex;
                align-items: center;
                justify-content: center;
                font-size: 20px;
                line-height: 28px;
                font-weight: 800;
                font-family: $sukhmaibold;
            }

            &-subtitle {
                font-size: 16px;
                line-height: 24px;
                font-weight: 700;
                font-family: $sukhmaitext;
            }
        }
    }
}