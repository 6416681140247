@import "../commonConstant.scss";

.vertical-slider-container {
  display: flex;
  justify-content: space-between;
  padding: 8px 20px;
  gap: 32px;

  .custom-range-slider {
    -webkit-appearance: none;
    background: $a-white;
    outline: none;
    border: 2px solid $a-lightgray;
    border-radius: 11px;
    transform: rotate(90deg) translate(158px, 160px);
    height: 20px;
    position: absolute;
    left: 0;
    top: 0;
    width: 330px;
  }

  .custom-range-slider::-webkit-slider-thumb {
    -webkit-appearance: none;
    appearance: none;
    width: 40px;
    height: 40px;
    border-radius: 50%;
    background-color:$a-green;
    cursor: pointer;
    position: relative;
    background: url('/images/healthAndWellness/icon/slider-indicator-icon.svg');
  }

  .custom-range-vertical {
    position: relative;
  }

  .custom-range-label-left {
    display: flex;
    flex-direction: column;
    gap: 55px;

    .range-label-left {
      display: flex;
      height: 40px;
      width: 40px;
      flex: 1;
      font-size: 14px;
      font-weight: 700;
      font-family: $sukhmaitext;

      &:first-child {
        padding-top: 5px;
      }

      img {
        height: 100%;
        width: 100%;
        object-fit: contain;
      }
    }
  }

  .custom-range-label {
    width: calc(100% - 128px);
    display: flex;
    flex-direction: column;
    gap: 47px;
    justify-content: space-around;

    .range-label {
      display: inline-block;
      line-height: 21px;
      padding-top: 12px;
      padding-bottom: 12px;
      font-size: 13px;
      font-weight: 700;
      font-family: $sukhmaitext;
      line-height: 21.14px;

      &:nth-of-type(2) {
        padding-top: 16px;
      }
      &:nth-of-type(3) {
        padding-top: 6px;
      }
      &:last-child {
        padding-top: 0;
      }

    }

  }

  .custom-range-dots {
      display: flex;
      flex-direction: column;
      gap: 85px;
      .range-dots {
          height: 10px;
          width: 10px;
          border-radius: 50%;
          background: $a-lightgray;
          opacity: 0.8;
          pointer-events: none;
          display: inline-block;
          &:first-child {
            margin-top: 18px;
          }
          &:last-child {
            margin-bottom: 18px;
          }
      }
      .range-hide-dot {
        visibility: hidden;
      }
  }

}

@media (max-width: 376px) {
  .vertical-slider-container {
    padding: 8px 0;
  }
}

@media (max-width: 324px) {
  .vertical-slider-container {
    .range-label {
      font-size: 11px;
    }
  }
}

