@import "../../../common/commonConstant.scss";

.choose-address-container {
  display: flex;
  flex-direction: column;
  flex: 1;
  background: $bggray;
  .description {
    font-size: 13px;
    color: $gray;
  }
  .center {
    text-align: center;
    padding: 15px;
  }
  > .header {
    padding: 20px 0 10px;
    text-align: center;
  }
  > .body {
    display: flex;
    flex-direction: column;
    flex: 1;
    margin: 15px;
    padding: 15px;
    border-radius: 12px;
    box-shadow: 0px 5px 15px rgba(0, 0, 0, 0.04);
    background: $bgwhite;
    .amway-button {
      font-family: "Sukhumvit Set", "Helvetica", Arial, sans-serif;
    }
    .addresses-wrapper {
      display: flex;
      flex-direction: column;
      flex: 1;
      .address-container {
        display: flex;
        margin: 10px 0;
      }
      .address-primary-tag {
        color: #38539A;
      }
    }
  }
  > .footer {
    width: 100%;
    text-align: center;
    position: fixed;
    bottom: 0;
    padding: 15px;
  }
}
