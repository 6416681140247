@import "../../common/commonConstant.scss";

.recommended-exercise-container {
    .recommended-exercise-card-single {
        box-shadow: 1px 8px 18px 0px rgba(44, 44, 44, 0.1);
        background: $white;
        border-radius: 12px;
        padding: 12px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        gap: 8px;
        margin-bottom: 16px;

        .recommended-exercise-card-details {
            width: calc(100% - 96px);
            display: flex;
            flex-direction: column;
            gap: 4px;

            .recommended-exercise-card-name {
                font-size: 18px;
                font-weight: 700;
                line-height: 26px;
                font-family: $sukhmaitext;
                color: $black;
                white-space: nowrap;
                overflow: hidden;
                text-overflow: ellipsis;
                display: inline-block;

            }

            .recommended-exercise-card-type {
                font-size: 14px;
                font-weight: 500;
                color: $black;
                font-family: $sukhmaitext;
                line-height: 20px;
            }

            .recommended-exercise-card-time {
                font-size: 14px;
                font-weight: 500;
                color: $gray12;
                display: flex;
                align-items: center;
                gap: 4px;
                font-family: $sukhmaitext;
            }

            .recommended-exercise-duration,
            .recommended-exercise-unit {
                font-size: 14px;
                line-height: 20px;
                font-weight: 500;
                font-family: $sukhmaitext;
                color: $gray12;
            }

            .activity-icon-wrapper {
                display: flex;
                align-items: center;
                gap: 8px;

                .activity-icon-list {
                    display: flex;
                    align-items: center;
                    gap: 4px;

                    img {
                        width: 16px;
                        height: 16px;
                    }

                    .activity-icon-value {
                        font-weight: 700;
                        font-size: 14px;
                        color: $black;
                        line-height: 20px;
                        font-family: $sukhmaitext;
                    }
                }
            }

        }
    }

}