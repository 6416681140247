@import '../../../../common/commonConstant.scss';

.challenge-my-steps-container {
    padding: 0px 16px 17px;

    .challenge-card-body {
        box-shadow: 0px 4px 20px 0px $challenge-card-shadow;
        border-radius: 16px;
        padding: 16px;
        display: flex;
        background: $a-serviceCard;

        hr.rounded-dividing-line {
            width: 3px;
            height: 94px;
            border: none;
            box-shadow: 1px 1px 4px 0px $card-divider-line inset;
            border-radius: 5px;
            margin: 0;
            margin-left: 66px;
            margin-right: 16px;
        }

        .total-steps {
            .average-steps-qty {
                margin-right: 0 !important;
            }
        }

        .step-card-left .step-card-left-col2 {
            height: 44px;
        }
    }
}