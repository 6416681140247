@import "../../../common/commonConstant.scss";

.sopdetail-container {
  padding: 15px 25px;
  background: $bggray;
  min-height: 100vh;
  text-align: center;
  > .header {
    margin: 10px 0;
    .title-container {
      position: relative;
      margin-bottom: 10px;
      .title {
        text-align: center;
        font-size: 18px;
      }
      .right {
        position: absolute;
        right: 0;
        top: 0;
      }
    }
    .sort-container {
      display: flex;
      .select-container {
        position: relative;
        max-width: 200px;
        margin-right: auto;
        width: 100%;
      }
    }
  }
  > .body {
    img {
      margin: 0 auto;
    }
    > .header {
      text-align: left;
      border-bottom: 1px solid $gray;
      .title {
        font-weight: bold;
        font-size: 20;
      }
      .sub-title {
        .info-container {
          display: flex;
          .id {
            margin-right: auto;
            font-size: 16px;
            color: $gray;
          }
          .status-container {
            display: flex;
            align-items: center;
            .count {
              margin-right: 5px;
              font-size: 10px;
            }
            .buy-status {
              background: $gray4;
              color: $gray5;
              padding: 5px 15px;
              border-radius: 15px;
              font-size: 10px;
              &.danger {
                background: $red;
                color: $light-red;
              }
              &.success {
                background-color: $dark-green;
                color: $light-green;
              }
              &.compensated {
                background-color: $brown;
                color: $light-brown;
              }
              &.postponed {
                background-color: $bggray2;
                color: $gray6;
              }
              &.discount {
                background-color: $bggreen;
                color: $light-green2;
              }
            }
          }
        }
        .payment-container {
          display: flex;
          .price-info {
            margin-right: auto;
            .price {
              color: $medium-blue;
              font-size: 24px;
              font-weight: bold;
            }
            .bonus {
              color: $light-blue;
              font-size: 13px;
            }
          }
        }
      }
    }
    .body {
      text-align: left;
      .choice-header {
        display: flex;
        border-bottom: 1px solid $gray;
        padding: 10px 5px;
        > .header {
          margin-right: auto;
        }
      }
      .content-container {
        .choice-container {
          display: flex;
          align-items: center;
          margin: 10px 0;
          .name {
            margin-right: auto;
          }
        }
      }
    }
    .footer {
      position: fixed;
      bottom: 0;
      left: 0;
      right: 0;
      background-color: $bggray;
      padding: 15px;
      .flex {
        display: flex;
        justify-content: space-evenly;
        position: relative;
        .input-number-container {
          margin-right: 10px;
        }
        .register-next-button {
          max-width: 200px;
          min-width: 150px;
          flex: 1;
          margin: 0;
        }
      }
    }
  }
}
