@import "../../../common/commonConstant.scss";

.choosebank-container {
  background: $bggray;
  .header {
    text-align: center;
    padding: 20px 0;
    font-size: 20px;
  }
  .body {
    margin: 15px;
    padding: 15px;
    background: $bgwhite;
    box-shadow: 0px 5px 15px rgba(0, 0, 0, 0.04);
    border-radius: 12px;
    .collapse-header {
      padding: 15px;
      .title {
        display: flex;
        justify-content: center;
        img{
          margin-right: 5px;
        }
      }
    }
  }
  .footer{
    position: fixed;
    bottom: 0;
    padding: 15px;
    width: 100%;
    background: $bggray;
  }
}
