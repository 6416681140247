@import '../../../common/commonConstant.scss';

body:has(.exercise-log-home-container.--no_scroll) {
  overflow: hidden;
}

.exercise-log-home-container {
  padding-bottom: 120px;
  
  .white-box {
    width: 100%;
    position: fixed;
  }

  .exercise-log-custom-modal {
    .open {
      background-color: $a-backgorund-blur;
      backdrop-filter: blur(5px);
      z-index: 99;

      .numpad-panel {
        height: calc(100vh - 300px);

        .devices-list-wrapper {
          overflow-y: auto;
        }
      }
    }

    .hnw-exercise-time-log-container {
      border-radius: 0;
      border: none;
      border-top-left-radius: 24px;
      border-top-right-radius: 24px;
      padding: 0px;

      .modal-body {
        padding: 16px 20px 48px 20px;

        .modal-heading {
          margin-bottom: 14px;
          font-size: 24px;
          font-weight: 800;
          font-family: $sukhmaitext;
          border-bottom: 0;
        }

        .devices-list-wrapper {
          display: flex;
          flex-direction: column;
          gap: 16px;

          .devices-single {
            display: flex;
            justify-content: space-between;
            align-items: center;

            .device-single-left {
              display: flex;
              align-items: center;
              gap: 9px;

              .device-name {
                font-size: 16px;
                font-weight: 700;
                font-family: $sukhmaitext;
                color: $black;
              }
            }

            .btn-icon-round {
              background: $homewhite;

              .add-img {
                width: 32px;
                height: 32px;
                margin-right: 6px;
              }
            }
          }
        }

        .btn-red {
          margin-top: 14px;
        }
      }

      .modal-header {
        border-bottom: none;
      }
    }
  }

  .activity-category-container {
    .container-fluid {
      .row {
        .col-12 {
          padding: 0;

          .your-goal-container {
            position: relative;
            padding: 0 20px;

            .header {
              margin-bottom: 48px;
              font-size: 20px;
              font-weight: 800;
              line-height: 28px;
              font-family: $sukhmaibold;
              color: $black;
            }

            .goal-img {
              position: absolute;
              bottom: 1px;
              z-index: 1;
            }

            .your-goal-card {
              position: relative;
              background-image: url("/images/healthAndWellness/exercise-log/goal-curve.svg");
              background-repeat: no-repeat;
              background-position: bottom;
              background-size: 100%;
              border-radius: 12px;

              .your-goal-card-wrapper {
                position: relative;
                display: flex;
                box-shadow: 1px 8px 18px 0px rgba(44, 44, 44, 0.1);
                border-radius: 12px;
                padding: 10px;
                justify-content: flex-end;
                max-width: 100%;

                .your-goal-details {
                  width: 70%;
                  z-index: 1;

                  .your-goal-description {
                    display: flex;
                    flex-direction: column;
                    font-size: 14px;
                    font-weight: 700;
                    line-height: 20px;
                    font-family: $sukhmaitext;
                    color: $black;
                    margin-bottom: 17px;
                  }

                  .btn-primary {
                    border-radius: 38px;
                    font-size: 14px;
                    font-family: $sukhmaitext;
                    height: 28px;
                    padding: 6px 12px 6px 8px;
                    align-items: center;
                    gap: 4px;
                    background-color: $homegreen;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    width: 141px;
                    border-color: $homegreen;
                    cursor: pointer;
                  }
                }
              }

              .goal-curve-img {
                position: absolute;
                bottom: 0;
                left: 0;
                right: 0;
                width: 100%;
                border-bottom-left-radius: 12px;
                border-bottom-right-radius: 12px;
                object-fit: cover;
                height: 64px;
                margin: 0;
              }
            }
          }

          .activity-list-container {
            padding: 0 20px;

            .activity-list-container-wrapper {
              width: 100% !important;
              overflow: hidden;
              position: relative;

              &.slick-slider {
                margin-top: 22px;

                .slick-slide {
                  padding: 0 4px;
                }
              }
            }

            .header {
              margin-bottom: 16px;
              margin-top: 32px;
              font-size: 20px;
              font-weight: 800;
              line-height: 28px;
              font-family: $sukhmaibold;
              color: $black;
            }

            .activity-list-card-wrapper {
              display: flex;
              gap: 30px;
              justify-content: space-around;

              .activity-list-card-single {
                text-align: center;
                display: flex;
                flex-direction: column;
                align-items: center;
                justify-content: center;
                position: relative;

                .activity-list-card-title {
                  font-size: 14px;
                  font-weight: 500;
                  line-height: 20px;
                  margin-top: 5px;
                }

                .activity-list-card-round {
                  display: none;
                }

                &.activity-list-card-active {
                  .activity-list-card-title {
                    color: $homegreen;
                    font-weight: 700;
                    font-family: $sukhmaitext;
                  }

                  .activity-list-card-round {
                    display: block;
                    height: 62px;
                    width: 62px;
                    border: 2px solid $homegreen;
                    border-radius: 50%;
                    position: absolute;
                    bottom: 27px;
                    z-index: -1;
                    left: 50%;
                    transform: translate(-50%, 5px);
                  }
                }
              }
            }
          }

          .recommended-exercise {
            padding: 0 20px;
          }

          .exercise-goal-container,
          .exercise-daily-activity-container {
            padding: 0 20px;
          }
        }
      }
    }
  }

  .exercise-overview-banner-container {
    background: url('/images/healthAndWellness/foodLog/exercise-log-home-bg.svg');
    background-position: center;
    width: 100%;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: left -24px;
    height: 350px;
    margin-bottom: 32px;
    padding-top: 52px;

    @media(max-width:465px) {
      background-position: left -82px;
    }

    @media(max-width:428px) {
      background-position: left -64px;
    }

    @media(max-width:394px) {
      background-position: left -50px;
    }

    @media(max-width:390px) {
      background-position: left -36px;
    }

    @media(max-width:370px) {
      background-position: left -24px;
    }

    @media(max-width:324px) {
      background-position: left -20px;
    }


    .exercise-overview-banner-content {
      padding: 20px;

      .exercise-overview-banner-header {
        display: flex;
        justify-content: space-between;
        align-items: center;

        .exercise-overview-heading {
          font-family: $sukhmaitext;
          font-weight: 700;
          font-size: 18px;
          line-height: 26px;
          color: $black;
        }

        .dropdown {
          .select-styled {
            background: $bgwhite;
          }
        }

      }
    }
  }

  .exercise-chart-vector {
    width: 100%;
    object-fit: cover;
  }
}