@import "../../../../common/commonConstant.scss";

.home-profile-container {
    display: flex;
    align-items: flex-start;
    gap: 17px;

    .food-profile-left {
        margin-top: 18px;

    .food-profile-img-container {
        height: 68px;
        width: 68px;
        background:
            linear-gradient(107.74deg, $gray2 27.91%, $white1 98.01%) padding-box,
            linear-gradient(139.18deg, $bmi-slider-bg-2 16.07%, $white2 93.35%) border-box;
        border: 5px solid transparent;
        box-shadow: 4px 4px 18px 0px $challenge-button-tab;
        border-radius: 16px;
        display: flex;
        align-items: center;
        justify-content: center;

        img {
            width: 56px;
            height: 56px;
            box-shadow: 0px 1px 2px 0px $a-delete-modal-shadow inset;
            box-shadow: 0px 1px 0px 0px $profile-white;
            border-radius: 11px;
        }
    }
}

    .home-profile-details {
        margin-top: 9px;

        .food-profile-title {
            font-size: 16px;
            font-weight: 500;
            font-family: $sukhmaitext;
        }

        .food-profile-subtitle {
            font-size: 20px;
            font-weight: 700;
            line-height: 30px;
            font-family: $sukhmaitext;
        }

        .rect-badge-container {
            background: $black;
            color: $white;
            width: fit-content;
            padding: 3px 8px 3px 6px;
            border-radius: 8px;
            display: flex;
            gap: 8px;


            .rect-badge-icon {
                width: 16px;
                height: 16px;
            }

            .rect-badge-title {
                font-size: 14px;
                line-height: 21px;
                font-weight: 700;
                font-family: $sukhmaitext;

            }

        }
    }
}