@import "../../common/commonConstant.scss";

.input-number-container {
  display: inline-flex;
  > input {
    width: 100%;
    max-width: 75px;
    min-width: 15px;
    border-top: 1px solid $black;
    text-align: center;
    background: $bgwhite;
    padding: 5px 0;
    height: auto;
  }
  button{
    border: transparent;
    padding: 0 10px;
    background: $bgwhite;
    font-size: 20px;
    &:first-child{
      border-top-left-radius: 20px;
      border-bottom-left-radius: 20px;
      border: 1px solid $black;
      border-right: transparent;
    }
    &:last-child{
      border-top-right-radius: 20px;
      border-bottom-right-radius: 20px;
      border: 1px solid $black;
      border-left: transparent;
    }
    &.disable{
      color:$gray2;
    }
  }
}
