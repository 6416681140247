@import "../../common/commonConstant.scss";

.hnw-success-animation-overlay {
  position: fixed;
  top: 0;
  height: 100%;
  width: 100%;
  z-index: 100;
  background-color: $homeblack1;
  backdrop-filter: blur(5px);

  &.--hide {
    display: none;
  }
}

.hnw-success-animation-container {
  background-color: $bgwhite;
  padding: 20px;
  margin: 0 auto;
  margin-top: 50%;
  transform: translateY(50%);
  width: 60%;
  box-shadow: 1px 8px 18px 0px $shadowgrey3;
  border-radius: 12px;
}