@import './font-family.scss';

//color
$white: #ffffff;
$widget-border: #ccc;
$jet-black: #000;
$gray: #929497;
$gray1: #949494;
$black: #2c2c2c;
$gray2: #e6e6e6;
$gray3: #c3c4c5;
$gray4: #ededed;
$gray5: #9ea0a3;
$gray6: #f8f8e8;
$gray7: #525252;
$gray8: #707070;
$gray9: #d9d9d9;
$gray10: #777777;
$gray11: #f4f4f4;
$gray12: #a0abc0;
$gray13: #cbd2e0;
$gray14: #ececec;
$gray15: #d6d6d6;
$gray16: #1A1A1A99;
$gray17: #E7E7E7;
$gray18: #D6D6D6;
$gray19: #b1b1b1;
$gray20: #ddd;
$medium-blue: #38539a;
$light-blue: #537ce5;
$success: #1ec100;
$value-green: #67ba6d;
$theme-green: #80d26c;
$theme-red: #fe6767;
$red: #cb5454;
$red2: #a93131;
$dark-red2: #d91734;
$dark-blue: #38539a;
$light-red: #ffe2e2;
$dark-yellow: #e8ab0d;
$dark-green: #396e75;
$dark-green1: #73793b;
$secondary-green: #546223;
$light-green: #e5fdff;
$light-green1: #61c049;
$light-green2: #f0fae5;
$cyan: #7abfde;
$light-turquoise: #cfeef0;
$light-sunset-yellow: #fbe9d9;
$brown: #a66c4a;
$light-brown: #fff1e9;
$dark-brown: #a65523;
$orange: #f2994a;
$orange1: #fae0ae;
$orange2: #9c5800;
$light-brown: #fff1e8;
$dark-orange: #a65523;
$blue1: #3853a3;
$light-orange: #fff1e8;
$dark-red: #7f3e3e;

//background
$bgwhite: #ffffff;
$bgwhitetranslucent: #ffffff99;
$bggreytranslucent: #d6d6d6a6;
$bgwhiteShade: #f8f8f8;
$bggray: #fafafa;
$bggray1: #f5f5f5;
$bggray2: #62625a;
$bggray3: #f4f4f4;
$bggreen: #73783f;
$bggreen1: #949434;
$bggreen2: #eeeec6;
$green1: #546223;
$bglightblue: #f1f2ff;
$bglightgray: #dfe2f5;
$bgpromo: #f0f3f7;
$shadowgrey: #0000000a;
$shadowgrey1: #e4e4e4;
$shadowgrey2: #0000001a;
$shadowgrey3: #00000026;
$purple: #7f3e3e;
$purple: #7f3e3e;
$transparent: #0000;

$sukhmaibold: 'Sukhumvit Tadmai New Bold';
$sukhmaitext: 'Sukhumvit Tadmai';
$sfproregular: 'SF Pro Regular';
$sfpromedium: 'SF Pro Medium';
$robotoregular: 'Roboto Regular';
$robotomedium: 'Roboto Medium';
$gtwalsheimpro: 'GT Walsheim Pro';
$gtwalsheimbold: 'GT Walsheim Bold';

$homegreen: #3c9d47;
$homegreen1: #67ba6d;
$homegreen2: #009640;
$graphgreen: #61c68a;
$homemaroon: #a9424580;
$homeMaroonDark: #7D2014;
$homeMaroon1: #E46051;
$homeMaroonLight1: #EFA39B;
$homeMaroonLight2: #FCEBE9;
$homegrey: #a0abc0;
$homegrey1: #717d96;
$homegrey2: #99a2b5;
$homegrey3: #cbd2e0;
$homegrey4: #cbd2e080;
$homegrey5: #839D98;
$homewhite: #f7f9fc;
$homeblack: #00000026;
$homeblack1: #00000099;
$homered: #a94245;
$profileblue: #82c2fd;
$profileyellow: #ffdb66;
$profileorange: #ffb966;
$profilered: #cc6060;
$profilered1: #ffd8d8;
$profilewhite: #eeeeee;
$bmiwhite: #f8f8f8;
$bmiblue: #77bdfd;
$bmigreen: #67ba6d;
$bmiyellow: #ffcc27;
$bmiorange: #ffa552;
$bmired: #e76c6c;
$bmibgblue: #e2eefc;
$bmibggreen: #dcefdd;
$bmibgyellow: #dcefdd;
$bmibgorange: #dcefdd;
$bmibgreen: #dcefdd;
$bmigrey: #edf0f7;
$hnwblack: #3a3a3a;
$fl-green: #6cc949;
$flyellow: #d3aa15;
$flgreen1: #6bc948;
$flgreen2: #9fdc7d;
$flblue: #39b0ff;
$flblue1: #61d0ff;
$flwhite: #f2f7f1;
$flblack: #2c2c2c1a;
$flgreen3: #9DDB7B;

//Assessment
$a-offwhite: #e7f4d7;
$a-activity-bg: #e9ecff;
$a-gray: #a0abc0;
$a-green: #3c9d47;
$a-chipBgColor: #edf9df;
$a-boxBgColor: #ffd9d8;
$a-sliderBgColor: #faf2db;
$a-lightgray: #edf0f7;
$a-darkgray: #cbd2e0;
$a-healthTitleClr: #101828;
$a-healthValue: #3c9d47;
$a-black: #2c2c2c;
$a-white: #ffffff;
$a-greyborder: #cbd2e0;
$a-pri-green: #80d26c;
$a-neutral: #f7f9fc;
$a-neutral-h1: #717d96;
$a-orange: #ff8a00;
$a-backgroundBlack: #131313d6;
$a-darkgreen: #107f47;
$a-babypink: #fff6f6;
$a-foodQtyColor: #3c9d47;
$a-dividerColor: #cbd2e0;
$a-btnSalad: #a8d493;
$a-circleGreen1: #c6e5ca;
$a-circleGreen2: #e3f1d7;
$a-circleGreen3: #e5eedb;
$a-circleStroke: #f0f0f0;
$a-serviceCard: #fff;
$a-btnOther: #969aaf;
$a-btnPlant: #94ccae;
$a-btnSeafood: #96d3c8;
$a-btnSoup: #f4a999;
$a-btnSteak: #967766;
$a-btnGrilled: #e45454;
$a-btnFried: #d48a55;
$a-btnBeverage: #90bfcd;
$a-btnMeat: #eba587;
$a-btnBoiled: #f0c779;
$a-btnCurry: #ff8d74;
$a-errorNotification: #a66400;
$a-darkgreenError: #057036;
$a-darkgreenError2: #16570A;
$a-lightgreenError1: #51ba62;
$a-lightgreenError2: #abecb0;
$a-lightgreenError3: #bedec3;

// Food calories
$fgreen: #73c97d;
$fgreen1: #99e160;
$fgreen2: #a7d172;
$home-green-dark: #5BAE61;
$home-green-light1: #6CCA49D1;
$home-green-light2: #F4FFEE0A;
$a-babypink: #fff6f6;
$a-btn-green: #f2f7f1;
$foodDark: #68c846;
$foodLight: #9fdc7d;
$foodBarLight: #A1DD7F;
$waterDark: #39B0FF;
$waterBarlight: #4EC1FF;
$waterLight: #9dddff;
$waterCircleLight: #4ABFFF;
$exerciseDark: #FF4337;
$exerciseBarDark: #FF9494;
$exerciseLight: #FF9d66;
$dark-red3: #D91734;
$light-exercise: #ffc6a6;

//Food Log
$btn-primary: 'ตกลง';
$btn-default: 'ย้อนกลับ';
$header: 'ปรับแต่งรูป';

$a-progress-bar-bg: #FFFFFF;
$a-circle-progress-bg: #fff;
$a-circle-gradient1: #6cc949;
$a-progress-bar-gradient1: #9fdc7d;
$a-progress-bar-gradient2: #6bc948;
$a-card-box-shadow: rgba(44, 44, 44, 0.1);

$a-picker-bg: #e7f4d7;
$a-picker-white: white;
$a-picker-dark: rgba(255, 255, 255, 0.3);
$a-delete-modal-shadow: rgba(0, 0, 0, 0.1);
$a-delete-modal-shadow1: rgba(0, 0, 0, 0.2);
$modal-shadow: rgba(0, 0, 0, 0.175);
$a-delete-modal-heading: rgba(60, 60, 67, 0.6);
$a-btn-android-cancel: rgba(88, 86, 214, 1);
$a-btn-android-cancel-black: $black;
$a-delete-modal-heading-black: $black;
$a-btn-android-delete: rgba(217, 23, 52, 1);
$a-backgorund-blur: rgba(0, 0, 0, 0.35);
$a-ios-border: rgba(60, 60, 67, 0.36);
$nutrition-card-shadow: rgba(44, 44, 44, 0.1);
$a-modal-lightgrey: lightgrey;

// Health and Wellness
$hnw-green: #3C9D47;
$hnw-golden: #D3AA15;
$hnw-light-blue: #72C3E6;

$noDataDark: #FF1C2D;
$noDataLight: #FFD1D1;
$foodDark: #68C846;
$foodLight: #9FDC7D;
$foodGreen: #72CC50;
$exerciseOrange: #FF8359;
$greater-max-val: #EB8593;
$fl-green: #6cc949;
$exercise-circle-stroke: #FDB287;
$food-circle-stroke: #6AC947;
$mild-green: #F5FAEF;

//challenge
$challenge-green: #BEDEC3;
$challenge-detail-green: #EAFFF3;
$challenge-white-shadow: #FFFFFFEE;
$challenge-white-shadow1: #FFFFFFCC;
$challenge-white-translucent: #FFFFFFE5;
$challenge-success-green: #008F3C;
$challenge-dashboard-green: #32644C;
$challenge-gray: #EBEBEB;
$challenge-dashboard-green-shadow: #32644C26;
$challenge-carousel-bg: rgba(255, 255, 255, 0.8);
$challenge-carousel-card-bg: rgba(255, 255, 255, 0.79);
$challenge-carousel-loader-start: hsl(200, 20%, 80%);
$challenge-carousel-loader-end: hsl(200, 20%, 95%);
$challenge-users-loader-bg: #e0e0e0;
$challenge-users-loader-middle: #f0f0f0;
$challenge-error-bg: #FCEBE9;
$challenge-error-text: #DD3825;
$challenge-nomember-bg: #F2F2F2;
$challenge-warning-bg: #F8E5D1;
$challenge-ongoing-text: $black;
$challenge-overlay-bg: rgba(0, 0, 0, 0.5);
$challenge-warning-text: #A66400;
$challenge-tab-shadow: rgba(44, 44, 44, 0.2);
$challenge-slider-bg: rgba(255, 255, 255, 0.9);
$challenge-slider-bg1: rgba(255, 255, 255, 0.45);
$challenge-progress-shadow: #C9C9C980;
$challenge-progress-gradient1: #9BDA79;
$challenge-progress-gradient2: #6BC948;
$challenge-footer-text: #777D79;
$grab-bar-color: #d1d5d2;
$challenge-swiper-card-bg: #578741;
$challenge-card-shadow: rgba(50, 100, 76, 0.15);
$challenge-card-shadow1: #f0f0f000;
$challenge-button-tab: rgba(44, 44, 44, 0.25);
$challenge-hotspot: #ff774c;
$challenge-orange: #ff6000;
$challenge-crown-img-border: #dbb67c;
$challenge-btn-outline-red: rgba(221, 56, 37, 1);
$challenge-modal-grey: #565656;

$crown-icon-shadow: rgba(179, 179, 179, 0.4);
$challenge-success-green1: #057036;
$challenge-success-green2: #51BA62;
$challenge-success-green3: #ABECB0;
$challenge-success-green4: #16570A;
$card-divider-line: rgba(116, 116, 116, 0.3);
$card-divider-line1: rgba(116, 116, 116, 0.2);
$user-card-divider-line: rgba(116, 116, 116, 0.8);
$bmi-bar-box-shadow: rgba(144, 144, 144, 0.5);
$bmi-bar-bg-1: #EFEFEF;
$bmi-bar-bg-2: #F7F7F7;
$bmi-bar-gradient-blue: #77BDFD;
$bmi-bar-gradient-green: #67BA6D;
$bmi-bar-gradient-yellow: #FFCC27;
$bmi-bar-gradient-orange: #FFA552;
$bmi-bar-gradient-peach: #CC6060;
$bmi-bar-input-shadow-1: #0005;
$bmi-bar-input-shadow-2: #fff3;
$bmi-slider-shadow: rgba(26, 66, 43, 0.4);
$bmi-slider-bg-1: #AFCFBC;
$bmi-slider-bg-2: #FBFBFB;
$bmi-slider-bg-3: #C0D8CA;
$bmi-slider-bg-4: #FBFBFB9C;
$user-id-color: #777D79;
$disabled-color: #A7ABA8;
$profile-leader-frame: '#DBB67C';
$bmi-max-members: #8F8F8F;
$header-sticky-transparent: rgba(255, 255, 255, 0.8);
$quiz-answer-green: rgba(242, 247, 241, 1);
$quiz-answer-text: rgba(0, 143, 60, 1);
$quiz-answertype-shadow: rgba(107, 201, 72, 0.4);
$quiz-answertype-bg: rgba(242, 242, 242, 1);
$quiz-answer-inner: rgba(60, 157, 71, 1);
$quiz-btn-light-active: rgba(190, 222, 195, 1);
$quiz-answertype-inner: rgba(11, 186, 85, 1);
$quiz-btn-green-shdw: rgba(44, 44, 44, 0.2);
$white1: #FEFEFE;
$white2: #DCDCDC;
$anstype-inner-bg: rgba(167, 171, 168, 1);
$quiz-card-time-bg: rgba(255, 255, 255, 0.8);
$quiz-time-val: rgba(16, 127, 71, 1);
$quiz-btn-red : #E06E6E;
$quiz-btn-red1 : #C83A3A;
$quiz-btn-light-red : #FCAEAE;
$quiz-btn-light-red1 : #AD2323;
$quiz-brown : rgba(228, 96, 81, 0.3);
$quiz-answer-red-inner : rgba(228, 96, 81, 1);
$bmi-low: #E4EEFC;
$bmi-normal: #EAFFF3;
$bmi-fat-1: #FDF4D7;
$bmi-fat-2: #FFF1E0;
$bmi-fat-3: #F9E6E5;
$bmi-low-text: #83BBFB;
$bmi-normal-text: #008F3C;
$bmi-fat-1-text: #F9CE3A;
$bmi-fat-2-text: #FFA552;
$bmi-fat-3-text: #DF726E;
$arrow-up: #0BBA55;
$arrow-bg: #E2FFEF;
$video-play-icon: rgba(255, 255, 255, 0.5);
$progress-bg-fill: rgba(209, 0, 0, 1);
$dark-brown-grey: rgba(118, 118, 118, 1);
$sku-text: #777d79;
$inactive-badge: #E0E0E0;
$inactive-badge-content: #5E5E5E;
$progress-bar-shadow: rgba(107, 201, 72, 0.2);
$background-progres-dark: rgba(193, 241, 171, 0.15);
$background-progress-light: rgba(228, 255, 217, 0.36);
$challenge-body-key-id-error: #FF2D2D;
$header-sticky-white: #E6E6E6;
$square-float-color: rgba(44, 44, 44, 1);
$profile-square-boxshw: #2C2C2C40;
$home-profile-green: #002F14;
$challenge-reward-lightgreen: #B5DEA9;
$challenge-reward-darkgreen: #A6D38E;
$challenge-reward-lightblue: #B8CBDC;
$challenge-reward-darkblue: #A2BBD1;
$progress-gradient-lg: rgba(107, 201, 72, 0.25);
$back-img-content: #a9afb2;
$challenge-badge-black: #000000;
$chalenge-profile-shadow: rgba(44, 44, 44, 0.3);
$profile-white: rgba(255, 255, 255, 1);
$challenge-self-grey: rgba(255, 255, 255, 0.36);
$home-exercise-circle:#FF997A;
$home-exercise-circle2:#FF9591;
$home-cart-red:#D10000;
$home-exercise-gray: #75757533;
$home-exercise-circle3: #FFCDB2;


:export {
  sukhmaiText: $sukhmaitext;
  gtWalsheimpro: $gtwalsheimpro;
  success: $success;
  profileblue: $profileblue;
  homegreen1: $homegreen1;
  homeMaroonDark: $homeMaroonDark;
  homeMaroonLight1: $homeMaroonLight1;
  homeMaroonLight2: $homeMaroonLight2;
  homeGreenDark: $home-green-dark;
  homeGreenLight1: $home-green-light1;
  homeGreenLight2: $home-green-light2;
  homegrey5: $homegrey5;
  graphgreen: $graphgreen;
  profileyellow: $profileyellow;
  profileorange: $profileorange;
  profilered: $profilered;
  themePrimary: $theme-green;
  themeDanger: $theme-red;
  homegrey3: $homegrey3;
  gray7: $gray7;
  homegrey: $homegrey;
  white: $white;
  markerbg: $a-offwhite;
  gray15: $gray15;
  bmigrey: $bmigrey;
  noDataDark: $noDataDark;
  noDataLight: $noDataLight;
  foodDark: $foodDark;
  foodLight: $foodLight;
  foodBarLight: $foodBarLight;
  waterDark: $waterDark;
  waterLight: $waterLight;
  waterCircleLight: $waterCircleLight;
  waterBarlight: $waterBarlight;
  flGreen: $fl-green;
  exerciseDark: $exerciseDark;
  exerciseBarDark: $exerciseBarDark;
  exerciseLight: $exerciseLight;
  foodGreen: $foodGreen;
  exerciseOrange: $exerciseOrange;
  flGreen: $fl-green;
  exerciseCircleStroke: $exercise-circle-stroke;
  foodCircleStroke: $food-circle-stroke;
  challengeWhiteTranslucent: $challenge-white-translucent;
  challengeSuccessGreen: $challenge-success-green;
  challengeDetailGreen: $challenge-detail-green;
  challengeErrorText: $challenge-error-text;
  challengeErrorBg: $challenge-error-bg;
  flgreen1: $flgreen1;
  flgreen2: $flgreen2;
  grayFrame: $a-progress-bar-bg;
  profileLeaderFrame: $profile-leader-frame;
  bmiLow: $bmi-low;
  bmiNormal: $bmi-normal;
  bmiFat1: $bmi-fat-1;
  bmiFat2: $bmi-fat-2;
  bmiFat3: $bmi-fat-3;
  bmiLowText: $bmi-low-text;
  bmiNormalText: $bmi-normal-text;
  bmiFat1Text: $bmi-fat-1-text;
  bmiFat2Text: $bmi-fat-2-text;
  bmiFat3Text: $bmi-fat-3-text;
  darkRed: $dark-red3;
  arrowUp: $arrow-up;
  inactiveBadgeContent: $inactive-badge-content;
  homeExerciseCircle: $home-exercise-circle;
  homeExerciseCircle2: $home-exercise-circle2;
  lightBlue: $hnw-light-blue;
  homeExerciseCircle3: $home-exercise-circle3;
  flgreen3: $flgreen3;
  flgreen4: $challenge-progress-gradient1;
}