@import '../commonConstant.scss';

.hnw-footer-main {
    position: fixed;
    bottom: -6px;
    width: calc(100% + 18px);
    left: -9px;
    z-index: 9;
    overflow: hidden;

    &-img {
        width: 100%;
    }

    &__bottom-wrapper {
        display: flex;
        position: absolute;
        z-index: 10;
        top: 40%;
        width: 100%;
        padding: 0 15px;
        

        .footer-tab {
            width: 20%;
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: space-between;
            gap: 8px;
            cursor: pointer;

            &-name {
                font-size: 12px;
                font-weight: 700;
                font-family: $sukhmaitext;
                color: $challenge-footer-text;
                transition: color 0.5s ease;
            }

            &-active {

                .footer-tab-name {
                    color: $hnw-green;
                }
                img {
                    visibility: hidden;
                }
            }
        }
    }
}

