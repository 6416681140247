@import "../../../common/commonConstant.scss";

body::-webkit-scrollbar:has(.quiz-page) {
    display: none; // Safari and Chrome
}

.quiz-page {
    background-image: url('/images/challenge/team-dashboard-bg.svg');
    background-repeat: no-repeat;
    background-size: 100%;
    height: 100%;

    .container-fluid {
        padding-left: 16px;
        padding-right: 16px;

        .quiz-tab-container {
            width: 100%;
            overflow: hidden;

            .slick-initialized .slick-slide {
                padding: 0;
            }

        }
    }

    .quiz-video-container {
        padding-top: 14px;
        padding-bottom: 20px;

        &.quiz-container-padding {
            padding-bottom: 0;
        }

        .container-fluid {
            padding-left: 16px;
            padding-right: 16px;
        }

        .quiz-card-container-wrapper {
            padding-left: 0px;
            padding-right: 0px;

            .quiz-card-container {
                width: 100%;
                overflow: hidden;
                padding-left: 16px;

                .slick-initialized .slick-slide {
                    display: block;
                    padding: 0px;
                }
            }
        }
    }

    .question-card-container {
        box-shadow: 0px 4px 20px 0px $challenge-card-shadow;
        background: $a-serviceCard;
        border-radius: 16px 16px 0 0;
        padding-top: 16px;
        padding-bottom: 130px;

        &.question-card-answer-container {
            box-shadow: none;
            background: transparent;
        }
        &.quiz-container-padding {
            padding-top: 0px;
        }

        .question-card-title {
            font-size: 18px;
            font-weight: 700;
            font-family: $sukhmaitext;
        }
    }

    .slick-slide img {
        border: none;
    }

    .home-fixed-button {
        border-radius: 6px;
        z-index: 10;
    }

    .quiz-footer-bottom {
        padding-top: 16px;
        padding-bottom: 32px;
        box-shadow: 0px 4px 20px 0px $challenge-card-shadow;
        background: $a-serviceCard;
        border-radius: 16px 16px 0 0;
        position: fixed;
        width: 100%;
        z-index: 9;
        bottom: 0;
    }
}

.header-wrapper {
    padding-top: 0;
    transition: all 0.3s ease;

    &.sticky {
        position: fixed;
        width: 100%;
        z-index: 99;
        top: 0;
        padding-top: 0;
        background: $header-sticky-transparent;
        margin-bottom: 0;
        box-shadow: 0px 4px 20px 0px $challenge-card-shadow;
    }
}