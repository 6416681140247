@import "../../commonConstant.scss";

.team-rect-card-container {
    .challenge-rect-card-container {
        width: 100%;

        .walking-steps {
            display: flex;
            flex-direction: column;

            .average-steps {
                .average-steps-heading {
                    font-family: $sukhmaitext;
                    font-size: 14px;
                    font-weight: 500;
                    line-height: 21px;
                    text-align: left;
                    color: $a-black;
                    padding-right: 4px;
                }

                .average-steps-qty {
                    font-family: $gtwalsheimpro;
                    font-size: 14px;
                    font-weight: 400;
                    line-height: 21px;
                    text-align: left;
                    color: $a-black;
                    padding-right: 4px;
                }

                .average-steps-unit {
                    font-family: $sukhmaitext;
                    font-size: 14px;
                    font-weight: 500;
                    line-height: 21px;
                    text-align: left;
                    color: $a-black
                }
            }

        }

        .walking-steps-heading {
            font-family: $sukhmaitext;
            font-size: 18px;
            font-weight: 700;
            line-height: 27px;
            text-align: left;
            color: $a-black;

            &.bodyClass {
                font-size: 16px;
                font-weight: 800;
                line-height: 24px;
                font-family: $sukhmaibold;
            }

            &-qty {
                font-size: 28px;
                font-weight: 700;
                line-height: 48px;
                font-family: $gtwalsheimpro;
            }

            &-unit {
                font-size: 14px;
                font-weight: 500;
                line-height: 21px;
                font-family: $sukhmaitext;
            }
        }

        .walking-steps-amt {
            font-family: $gtwalsheimpro;
            font-size: 28px;
            font-weight: 700;
            line-height: 48px;
            letter-spacing: -0.02em;
            text-align: left;
            color: $hnw-green;
            padding-right: 4px;
        }

        .walking-steps-unit {
            font-family: $sukhmaitext;
            font-size: 14px;
            font-weight: 500;
            line-height: 21px;
            text-align: left;
            color: $a-black;
        }

        .card-flex-align {
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            width: 100%;

            .challenge-card-top {
                display: flex;
                justify-content: space-between;
                flex-direction: row;

                .btn-icon-round {
                    img {
                        width: 8px;
                        height: 13px;
                    }

                }

                .vdo-card-icon {
                    img {
                        width: 21px;
                        height: 21px;
                    }

                }

            }

            .challenge-card-bottom {
                display: flex;
                justify-content: end;
                flex-direction: row;
                align-items: baseline;

                .total-medals-font {
                    font-family: $gtwalsheimpro;
                    font-size: 16px;
                    font-weight: 500;
                    line-height: 48px;
                    letter-spacing: -0.02em;
                    text-align: right;
                    color: $a-black
                }
            }

        }

        .w-48 {
            width: 48%;
        }
    }

    .youtube-details-header {
        display: flex;
        align-items: center;
        justify-content: space-between;
    }

    .team-composition-heading {
        line-height: 27px;
        font-size: 18px;
        font-weight: 700;
        font-family: $sukhmaitext;
        color: $black;
    }
    .rect-badge-container {
        width: max-content;
        padding: 1px 9px 1px 6px;
        height: 24px;

        .rect-badge-description{
            display: flex;
            justify-content: center;
            align-items: center;
            gap: 5px;
            .rect-badge-icon{
                width: 12px;
                height: 12px;
            }
            .rect-badge-title{
                font-size: 14px;
                line-height: 21px;
                font-weight: 700;
                font-family: $sukhmaitext;

            }
            .rect-badge-value{
                font-size: 14px;
                line-height: 21px;
                font-weight: 500;
                font-family: $sukhmaitext;
            }
        }
    }

    .youtube-details-header .arrow-left {
        height: 13px;
    }

    .youtube-description {
        font-size: 18px;
        font-weight: 700;
        font-family: $sukhmaitext;
        display: flex;
        flex-direction: column;

        .rect-badge-container {
            width: max-content;
            padding: 1px 9px 1px 6px;

            .rect-badge-description{
                display: flex;
                justify-content: center;
                align-items: center;
                gap: 5px;
                .rect-badge-icon{
                    width: 12px;
                    height: 12px;
                }
                .rect-badge-title{
                    font-size: 14px;
                    font-weight: 700;
                    font-family: $sukhmaitext;

                }
                .rect-badge-value{
                    font-size: 14px;
                    font-weight: 500;
                    font-family: $sukhmaitext;
                }
            }
        }
    }

    .step-card-left {
        height: 100%;
        .step-card-left-col1 {
            font-size: 18px;
            font-weight: 700;
            font-family: $sukhmaitext;

            &.bodyClass {
                font-size: 16px;
                font-weight: 800;
                line-height: 24px;
                font-family: $sukhmaibold;
                height: 50%;
                letter-spacing: -0.02em;
            }
        }

        .step-card-left-col2 {
            span:first-child {
                font-size: 28px;
                font-weight: 700;
                color: $hnw-green;
                font-family: $gtwalsheimbold;
                margin-right: 4px;
                line-height: 48px;
            }

            span:last-of-type {
                font-size: 14px;
                font-weight: 500;
                color: $black;
                font-family: $sukhmaitext;
            }
            height: 50%;
        }

        .step-card-left-col3 {
            span:first-child {
                margin-right: 4px;
            }
            span:last-child {
                margin-left: 4px;
            }


            font-size: 14px;
            font-weight: 400;
            color: $black;
            font-family: $sukhmaitext;

            .average-steps-qty {
                font-size: 14px;
                font-weight: 400;
                color: $black;
                font-family: $gtwalsheimpro;
            }
        }
    }
    .badge-content-align {
        text-align: right;
        line-height: 20px;

        span:first-child {
            font-size: 24px;
            font-weight: 700;
            font-family: $gtwalsheimpro;
            color: $hnw-green;
        }

        span:last-of-type {
            font-size: 16px;
            font-weight: 700;
            color: $a-black;
            font-family: $gtwalsheimpro;
        }
        .badge-divide-font {
            font-size: 24px;
            font-weight: 700;
            font-family: $gtwalsheimbold;
            color: $black;
        }
    }
    .team-composition-value {
        height: 48px;
        .team-composition-heading-font {
            font-size: 28px;
            font-weight: 700;
            font-family: $gtwalsheimbold;
            color: $a-black;
            margin-right: 4px;

        }

        .team-composition-unit-font {
            font-size: 14px;
            font-weight: 500;
            color: $a-black;
        }

        .composition-bmi-fonts {
            .composition-slash {
                font-size: 24px;
                font-weight: 700;
                font-family: $gtwalsheimbold;
                color: $bmi-max-members;
            }

            .composition-max-team-members {
                font-size: 16px;
                font-weight: 700;
                font-family: $gtwalsheimbold;
                color: $bmi-max-members
            }
        }
    }
    .badge-icon {
        width: 12px;
        height: 12px;
    }
    .active-color {
        color: $black !important;
    }

    .bmi-green {
        color: $bmigreen;
    }

    .disable-color {
        color: $disabled-color !important;
    }
}