@import "../../../common/commonConstant.scss";

.challenge-carousel-main {

    .slick-slide {
        padding: 10px;

        img {
            border: none;
        }
    }

    .carousel-title {
        font-family: $sukhmaitext;
        font-size: 18px;
        font-weight: 700;
        padding-left: 10px;
    }

    .carousel-content-list {
        width: 92%;
    }

    .challenge-tag-wrapper {
        position: absolute;
        bottom: 70px;
        left: 10px;

        &.align-card-1 {
            left: 40px;
        }

        &.align-card-2 {
            left: 60px;
        }

        &.align-card-3 {
            left: 85px;
        }

        &.align-card-4 {
            left: 110px;
        }
    }

    .card-container-list {
        box-shadow: 0px 4px 20px 0px $challenge-card-shadow;
        border-radius: 16px;
        position: sticky;

        .card-image-list {
            height: 240px;
            width: 100%;
            border-radius: 16px;
            object-fit: cover;
        }

        .card-container-details {
            padding: 8px;
            background-color: $challenge-carousel-card-bg;
            border-radius: 0 0 16px 16px;
            position: absolute;
            bottom: 0;
            width: 100%;
            backdrop-filter: blur(6px);
            -webkit-backdrop-filter: blur(6px);


            &-title {
                font-family: $sukhmaibold;
                font-size: 16px;
                font-weight: 700;
                line-height: 24px;
                overflow: hidden;
                white-space: nowrap;
                text-overflow: ellipsis;
            }

            &-description {
                font-family: $sukhmaitext;
                font-size: 14px;
                font-weight: 500;
                line-height: 21px;
                min-height: 21px;
            }
        }

    }

    .challenge-profiles {
        position: absolute;
        bottom: 70px;
        margin-left: 8px;
        display: flex;
        justify-content: space-between;
        width: auto;
    }

    .challenge-invite-img {
        position: absolute;
        top: 0px;
        right: 5px;
    }

    .card-container-inactive {
        opacity: 0.65;
    }

}