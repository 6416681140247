@import '../../../common/commonConstant.scss';

.modal-dialog {
  margin: 0;
  max-width: 100%;

  .modal-challenge-content {
    border-radius: 0;
    border-top-left-radius: 24px;
    border-top-right-radius: 24px;
    border: none;

    .modal-challenge-header {
      display: flex;
      justify-content: space-between;
      padding: 16px;
      padding-top: 25px;
      align-items: center;
      height: auto;

      .title {
        font-family: $sukhmaitext;
        font-size: 20px;
        font-weight: 700;
        line-height: 24px;
        text-align: left;
      }

      .modal-close-action {
        border: none;
        background: none;
        height: 32px;
        width: 32px;
        img {
          height: 32px;
          width: 32px;
        }
      }
    }

    .modal-card-container {
      display: flex;
      flex-wrap: wrap;
      justify-content: center;
      .modal-card-single {
        display: flex;
        flex-direction: column;
        align-items: center;
        width: 40%;

        img {
          height: 68px;
          width: 68px;
          border-radius: 50%;
        }

        .modal-card-title {
          font-size: 16px;
          font-weight: 700;
          font-family: $sukhmaitext;
          margin: 10px 0;
        }
      }
    }
  }
}
