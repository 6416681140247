@import '../../common/commonConstant.scss';

.health-record-wrapper {
    display: flex;
    gap: 16px;
    width: 50%;
    background: white;
    background-repeat: no-repeat;
    background-position: top;
    background-size: cover;
    border-radius: 12px;
    box-shadow: 1px 8px 18px 0px $shadowgrey3;

    &.--food {
        background-image: url('/images/healthAndWellness/foodLog/record-food-top.svg');
    }

    &.--water {
        background-image: url('/images/healthAndWellness/foodLog/record-water-top.svg');
    }

    &.--exercise {
        background-image: url('/images/healthAndWellness/foodLog/record-exercise-top.svg');
    }

    &.--steps {
        background-image: url('/images/healthAndWellness/foodLog/record-steps-top.svg');
    }

    .health-record-info {
        display: flex;
        border-radius: 12px;
        width: 100%;
        margin-top: 32%;
        flex-direction: column;
        text-align: center;
        justify-content: end;
        padding: 0 0 12px;

        .health-record-info-wrapper {
            .health-record-info-content {
                .left {
                    font-family: $sukhmaitext;
                    font-size: 16px;
                    font-weight: 700;
                    line-height: 24px;
                    color: $black;
                }

                .right {
                    font-family: $sukhmaitext;
                    font-size: 12px;
                    font-weight: 500;
                    line-height: 16px;
                    color: $gray12;
                    margin-left: 2px;
                }
            }

            .health-record-name {
                font-family: $sukhmaitext;
                font-size: 14px;
                font-weight: 500;
                line-height: 16px;
                color: $gray12;
            }
        }


    }
}