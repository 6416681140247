@import "../../../../../common/commonConstant.scss";

.hnw-tabs {
  background-color: $a-lightgray;
  padding: 2px;
  border-radius: 8px;
  display: flex;
  justify-content: center;
  gap: 4px;

  .htw-tab-item {
    font-family: $sukhmaitext;
    font-size: 12px;
    line-height: 16px;
    color: $gray12;
    border-radius: 6px;
    width: 64px;
    height: 28px;
    display: flex;
    justify-content: center;
    align-items: center;

    &.--active {
      font-weight: 700;
      background-color: $white;
      color: $black;
    }
  }
}