@import '../../../../../common/commonConstant.scss';

.health-title {
  font-size: 20px;
  line-height: 28px;
  font-weight: 800;
  font-family: $sukhmaibold;
  margin-bottom: 18px;
  margin-top: 30px;
}

.health-container {
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  justify-content: flex-start;

  .health-question {
    color: $a-black;
    font-family: $sukhmaitext;
    margin-bottom: 10px;
    margin-top: 10px;
    font-size: 16px;
    line-height: 24px;
    font-weight: 700;
    color: $a-black;
  }

  .health-input-parent-div {
    box-shadow: 0px 4px 12px 0px rgba(0, 0, 0, 0.15);
    border-radius: 12px;
    align-items: center;
    padding: 0px 16px;
    justify-content: space-around;
    background-color: $a-white;


    .input-div {
      padding: 12px 0px;
      justify-content: space-between;
      align-items: center;
      border-bottom: 1px solid $a-darkgray;
      display: flex;

      input {
        width: 100px;
        text-align: right;

        &::-ms-input-placeholder {
          /* Edge 12-18 */
          color: $dark-red2;
          font-family: $sukhmaitext;
        }

        &::placeholder {
          font-family: $sukhmaitext;
          color: $dark-red2;
        }
      }

      .title-div {
        align-items: center;
        color: $a-black;
        font-size: 16px;
        font-family: $sukhmaitext;
        font-weight: 500;
        line-height: 22px;

        gap: 4px;
        align-items: center;
        display: inline-flex;
        justify-content: center;
      }

      .value-div {
        font-weight: 700;
        color: $a-healthValue;
        font-size: 16px;
        font-family: $sukhmaitext;
        line-height: 24px;
      }

      .input-tag {
        text-align: right;
        border: none;
        width: 80px;
        font-size: 16px;
        line-height: 24px;
        font-weight: 700;
        color: $a-healthValue;
        height: 24px;
        font-family: $sukhmaitext;

        &.input-empty {
          color: $dark-red2;
        }
      }

      .customer-value {
        color: $a-darkgray;
        font-size: 16px;
        font-family: $sukhmaitext;
        font-weight: 500;
        line-height: 22px;
      }
    }
  }
}

.health-container-modal {
  .liff-panel.open .select-coupon-panel {
    height: 300px;
    border-radius: 12px 12px 0 0;
  }
}

@media (max-width: 376px){
  .health-mb-130 {
    margin-top: -130px;
  }

  .set-transition {
    transition: 0.2s ease-in-out;
  }
}

@media (max-width: 415px) and (max-height: 737px) {
  .health-mb-130 {
    margin-top: -130px;
  }

  .set-transition {
    transition: 0.2s ease-in-out;
  }
}