@import '../../../../common/commonConstant.scss';

/********************* DASHBOARD SWIPER SLIDER CSS START *********************/


.dashboard-slider-outer {
  .dashboard-slider-wrapper {
    transition: bottom 0.3s ease;
    padding-top: 40px;

    &:first-child {
      padding-top: 0px;
    }

    .dashboard-slider-title {
      font-size: 18px;
      font-weight: 700;
      font-family: $sukhmaitext;
      color: $black;
      padding-bottom: 20px;
      margin-bottom: 0;
    }

    .dashboard-slider-container {
      display: flex;
    }
  }
}
