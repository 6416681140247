@import '../../common/commonConstant.scss';

.home-header-bottom-wrapper {
    display: flex;
    gap: 10px;

    .search-input-container {
        position: relative;
        width: calc(100% - 90px);

        .search-img {
            position: absolute;
            top: 50%;
            transform: translateY(-50%);
            left: 9px;
        }

        .input-search-shadow {
            background: linear-gradient(90.34deg, $bggray 0.29%, $bggray1 98.19%);
            box-shadow: 1px 4px 6px 0px $a-card-box-shadow inset;
            border: none !important;
            height: 40px;
            width: 100%;
            padding-left: 42px;
            font-family: $sukhmaitext;
            font-size: 14px;
            font-weight: 500;
            line-height: 21px;
            text-align: left;
            color: $challenge-footer-text;
            border-radius: 10px;
        }

        input::placeholder {
            color: $challenge-footer-text;
        }
    }

    .header-bottom-right {
        display: flex;
        align-items: center;
        gap: 10px;

        .home-cart-icon .circle-notification {
            position: absolute;
            background-color: $home-cart-red;
            border-radius: 50%;
            display: inline-block;
            bottom: 26px;
            right: -6px;
            justify-content: center;
            align-items: center;
            display: flex;
            height: 16px;
            width: 16px;
        }
    }
}