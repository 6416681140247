@import '../../../../../common/commonConstant.scss';

.calendar-header-wrapper {
  position: sticky;
  top: 0;
  z-index: 99;

  .calendar-header {
    height: 36px;
    background-color: $white;

    &-left {
      display: flex;
      align-items: center;
      gap: 12px;

      span {
        font-family: $sukhmaitext;
        font-size: 18px;
        font-weight: 500;
        color: $black;
      }
    }

    &-right {
      display: flex;
      align-items: center;
      justify-content: end;
      gap: 20px;
    }
  }
}