@import '../../common/commonConstant.scss';

.home-fixed-button {
    box-shadow: 0px 4px 12px 0px $homeblack;
    position: fixed;
    padding: 10px;
    background: $homeblack1;
    bottom: 0px;
    text-align: center;
    width: 64px;
    border-top-left-radius: 8px;
    border-top-right-radius: 8px;
    transform: translate(-50%, 0);
    height: 48px;
    z-index: 1;

    img {
        margin-top: 5px;
    }
}