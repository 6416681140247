@import "../../../common/commonConstant.scss";

body::-webkit-scrollbar:has(.challenge-default-main) {
    display: none; // Safari and Chrome
}

.challenge-default-main {
    background: url("/images/challenge/no-challenge-bg.svg");
    background-repeat: no-repeat;
    background-size: cover;
    height: 100vh;

    .bg-overlay {
        background: $challenge-overlay-bg;
        height: 100vh;
        width: 100%;
        display: flex;
        align-items: flex-start;
        justify-content: center;
        backdrop-filter: blur(2px);
    }

    .challenge-default-text {
        display: flex;
        flex-direction: column;
        gap: 4px;
        align-items: center;
        color: $white;
        font-size: 18px;
        font-family: $sukhmaitext;
        font-weight: 800;
        transform: translateY(48vh);

        .text-large {
            font-size: 28px;
        }
    }

}