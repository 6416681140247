@import "../../common/commonConstant.scss";

.dietary-supplement-section {
  display: flex;
  gap: 4px;

  .dietary-supplement-left-img {
    height: calc(100% - 16px);
    display: flex;
    align-items: center;
    width: 80px;
    padding: 0 8px;

    &.--food {
      img.--no_image {
        content: url("/images/healthAndWellness/foodCalories/food-details/food-default-suggest-card.svg");

        @media (min-width: 325px) {
          content: url("/images/healthAndWellness/foodCalories/food-details/food-default-suggest-card_small.svg");
        }
      }
    }

    &.--supplement {
      img.--no_image {
        content: url("/images/healthAndWellness/foodCalories/food-details/supplement-default-suggest-card.svg");

        @media (min-width: 325px) {
          content: url("/images/healthAndWellness/foodCalories/food-details/supplement-default-suggest-card_small.svg");
        }
      }
    }

    img {
      width: 100%;
      border-radius: 50%;
      height: 64px;
      object-fit: cover;
    }
  }

  .dietary-supplement-left-section {
    padding-bottom: 8px;
  }

  .dietary-supplement-right-section {
    width: calc(100% - 80px);
    padding: 8px;
  }

  .dietary-supplement-right-title {
    font-size: 14px;
    font-weight: 700;
    font-family: $sukhmaitext;
    line-height: 24px;
    margin-top: 4px;
    display: inline;
    -webkit-line-clamp: 1;
    text-overflow: ellipsis;
    overflow: hidden;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    word-wrap: break-word;
  }

  .dietary-supplement-right-title span {
    /* height: 48px; */
    /* display: flex;
      align-items: center; */
  }

  .dietary-supplement-right-weight-section,
  .dietary-supplement-right-calories-section {
    display: flex;
    align-items: center;
    width: 50%;
    justify-content: space-between;
  }

  .dietary-supplement-right-weight-title,
  .dietary-supplement-right-weight-unit,
  .dietary-supplement-right-calories-title {
    color: $gray12;
    font-size: 14px;
    font-weight: 500;
    font-family: $sukhmaitext;
  }

  .dietary-supplement-right-weight-value,
  .dietary-supplement-right-calories-value {
    font-size: 16px;
    font-weight: 700;
    font-family: $sukhmaitext;
    color: $black;
  }

  .dietary-supplement-right-calories-value.green {
    color: $theme-green;
  }

  .dietary-supplement-right-details {
    display: flex;
    gap: 4px;
  }

  .line {
    height: 1px;
    background: linear-gradient(to right, transparent 50%, $gray13 50%);
    background-size:
      6px 2px,
      100% 2px;

    @media (max-width: 324px) {
      display: none;
    }
  }

  .vertical-line {
    width: 1px;
    background: linear-gradient(to bottom, transparent 50%, $gray13 50%);
    background-size:
      2px 6px,
      100% 2px;

    @media (max-width: 324px) {
      display: none;
    }
  }

  .dietary-supplement-right-nutrition-slots {
    display: flex;
    width: 100%;
    justify-content: space-between;
    margin-top: 5px;
  }

  .dietary-supplement-right-nutrition-slot {
    display: flex;
    flex-direction: column;
    text-align: center;
  }

  .dietary-supplement-right-nutrition-title-wrapper {
    display: flex;
    align-items: center;
  }

  .dietary-supplement-right-nutrition-value {
    font-size: 16px;
    font-weight: 700;
    color: $black;
    font-family: $sukhmaitext;
    line-height: 24px;
  }

  .dietary-supplement-left-title,
  .dietary-supplement-right-nutrition-title,
  .dietary-supplement-right-nutrition-unit {
    font-size: 12px;
    font-weight: 500;
    color: $homegrey;
    line-height: 16px;
  }

  .dietary-supplement-right-description-sm {
    display: flex;
    width: 100%;
    flex-wrap: wrap;
    justify-content: space-between;
    margin-bottom: 2px;
  }

  .dietary-supplement-right-description-title {
    display: flex;
    align-items: center;
  }

  .dietary-supplement-right-description-title span {
    color: $homegrey;
    width: 100%;
  }

  .dietary-supplement-right-description-value,
  .dietary-supplement-right-description-title span {
    font-size: 12px;
    font-weight: 500;
  }

  @media (min-width: 325px) {
    .dietary-supplement-right-description-sm {
      display: none;
    }
  }

  @media (max-width: 324px) {
    .dietary-supplement-left-img {
      width: 100%;
      justify-content: center;
      padding: 8px 0;
    }

    .dietary-supplement-left-img img {
      width: 100%;
      border-radius: unset;
      height: auto;
      object-fit: cover;
    }

    .dietary-supplement-left-title {
      display: none;
    }

    .dietary-supplement-left-section {
      padding-bottom: 0;
    }

    .dietary-supplement-right-section {
      width: 100%;

      .dietary-supplement-right-weight-title,
      .dietary-supplement-right-weight-value,
      .dietary-supplement-right-calories-title,
      .dietary-supplement-right-calories-value {
        font-size: 12px;
      }
    }

    .dietary-supplement-right-nutrition-slots,
    .dietary-supplement-right-details,
    .dietary-supplement-section-wrapper .line {
      display: none;
    }

    .dietary-supplement-right-title {
      font-weight: 500;
      margin-bottom: 5px;
    }
  }
}