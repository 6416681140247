@import "../../../common/commonConstant.scss";

.cart-container {
  background: $bggray;
  padding: 15px;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  .header {
    text-align: center;
  }
  .body {
    margin-bottom: 20vh;
    .items {
      padding: 0;
      margin-bottom: 15px;
      .card-header {
        padding: 5px 15px;
        border-bottom: 1px solid $gray2;
      }
      .card-body {
        .item-container {
          display: flex;
          margin: 15px;
          &:not(:last-child) {
            border-bottom: 1px solid $gray2;
            margin-bottom: 0;
          }
          img {
            margin: auto 0;
            height: 80px;
            width: 80px;
          }
          .item-detail {
            padding: 0 15px 15px 15px;
            .flex {
              display: flex;
              align-items: center;
              .item-price-container {
                margin-right: auto;
              }
            }
            .input-number-container {
              height: 40px;
            }
          }
        }
      }
    }
    .point-discount{
      background: $bglightblue;
      color: $medium-blue;
      margin-bottom: 15px;
    }
    .address{
      margin-bottom: 15px;
    }
  }
  .footer {
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    background: $bgwhite;
    width: 100%;
    .discount {
      background: $gray4;
      padding: 5px 15px;
    }
  }
  .empty {
    margin: auto;
  }
}
