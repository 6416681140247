@import '../../../common/commonConstant.scss';

.goal-setting-home-container{
  .goal-setting-home-header {
    position: fixed;
    background: $white;
    z-index: 9;
    width: 100%;
    top: 0;
    .header-bottom-section {
      height: 52px;
      background: $white;

      .header-bottom-icons {
        display: flex;
        gap: 16px;
        align-items: center;
        justify-content: flex-end;
      }
    }
  }

  .goal-setting-container {
    margin-bottom: 30px;
    color: $black;

    .goal-setting-heading {
      gap: 4px;
      position: relative;
      margin-bottom: 16px;

      .goal-setting-title {
        font-family: $sukhmaitext;
        font-size: 20px;
        font-weight: 1000;
        line-height: 28px;
        text-align: left;
      }
    }

    padding: 75px 20px 20px 20px;
    gap: 16px;
  }
}

