@import "../../../../common/commonConstant.scss";

.shimmer-home-profile-container {
    display: flex;
    align-items: center;
    padding-bottom: 30px;
    margin-top: 18px;
  }

  .shimmer-square-float-btn {
    width: 68px;
    height: 68px;
    border-radius: 16px;
    overflow: hidden;
    flex-shrink: 0;
    position: relative;
  }

  .shimmer-img {
    width: 100%;
    height: 100%;
  }

  .shimmer-home-profile-details {
    flex-grow: 1;
    margin-left: 16px;
  }

  .shimmer-text {
    width: 100%;
    height: 20px;
    margin-bottom: 8px;
  }

  .shimmer-greeting {
    width: 100%;
    height: 24px;
  }

  .shimmer-name {
    width: 100%;
    height: 30px;
  }

  .shimmer {
    animation: shimmer-loading 1s linear infinite alternate;
  }

  @keyframes shimmer-loading {
    0% {
      background-color: $challenge-carousel-loader-start;
    }
    100% {
      background-color: $challenge-carousel-loader-end;
    }
  }


