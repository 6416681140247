@import "../../../../common/commonConstant.scss";

.quiz-card-single {
    box-shadow: 0px 4px 20px 0px $challenge-card-shadow;
    background: $a-circle-progress-bg;
    border-radius: 16px;
    overflow: hidden;
    margin-right: 17px;
    margin-bottom: 16px;
    display: flex;
    flex-direction: column;

    .quiz-img-container {
        position: relative;

        .youtube-cover-img {
            width: 100%;
        }

        .play-icon {
            position: absolute;
            left: 50%;
            top: 50%;
            transform: translate(-50%, -50%);
            background: $video-play-icon;
            height: 46.81px;
            width: 46.81px;
            display: flex;
            align-items: center;
            justify-content: center;
            border-radius: 50%;
            box-shadow: 4px 4px 18px 0px $challenge-button-tab;
            backdrop-filter: blur(6px);

            .play-img {
                width: 16.25px;
                height: 19.5px;
            }
        }

        .video-status-tag-wrapper {
            top: 12px;
            left: 12px;
            position: absolute;
        
            .rect-badge-container {
                padding: 5px 8px;
    
                .rect-badge-title{
                    font-size: 14px;
                    line-height: 21px;
                    font-weight: 500;
                    font-family: $sukhmaitext;
                }
                &.yet-to-watch-badge {
                    background: #F8F8E7CC;
                    color: #62625A;
                }
                &.watch-unsuccessful-badge {
                    background: #FFF1E8CC;
                    color: #A65523;
                }
                &.watch-success-badge {
                    background: #008F3CCC;
                    color: #FFFFFF;
                }
            }
        }
    }

    .progress {
        height: 4px;
        border-radius: 0px;

        .bg-fill {
            background-color: $progress-bg-fill;
        }

        .bg-unfill {
            background-color: $challenge-carousel-bg;
        }
    }

    .quiz-card-description {
        display: flex;
        flex-direction: column;
    }

    .quiz-card-title,
    .quiz-card-time {
        background: $challenge-carousel-bg;
        font-size: 14px;
        font-weight: 500;
        padding: 4px 12px;
        line-height: 21px;
        display: inline-block;
        font-family: $sukhmaitext;
    }

    .quiz-card-time {
        .quiz-duration {
            font-family: $gtwalsheimpro;
            font-weight: 400;
        }
    }

    .quiz-card-title {
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        padding-bottom: 0px;
        padding-top: 7px;
    }

    .quiz-card-time {
        padding-top: 0;
    }
}