@import '../commonConstant.scss';

.health-log-excercise-card {
    box-shadow: 1px 8px 18px 0px $a-card-box-shadow;
    padding: 8px;
    border-radius: 12px;
    background: $a-circle-progress-bg;
    position: relative;

    .bg-excercise {
        width: 100%;
    }
}

.health-log-excercise-card {
    width: 50%;
}

.health-log-excercise-card {
    height: 200px;
}

.health-log-excercise-card {
    position: relative;
    overflow: hidden;
}

.add-excercise-content-section {
    padding: 0;
}

.inner-excercise-description {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    text-align: center;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.inner-excercise-description h6 {
    font-size: 16px;
    font-weight: 700;
    font-family: $sukhmaitext;
    color: $a-black;
    line-height: 24px;
    padding: 0 24px;
}

.health-log-excercise-card-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.health-log-excercise-card-header-left {
    display: flex;
    width: 50%;
    align-items: center;
    gap: 4px;
}

.health-log-excercise-card-header-left h6 {
    font-size: 16px;
    font-weight: 700;
    line-height: 24px;
    font-family: $sukhmaitext;
    margin-bottom: 0;
    white-space: nowrap;
    overflow: visible;
}

.health-log-excercise-card-header {
    margin-bottom: 8px;
}

.btn-icon-round {
    height: 24px;
    width: 24px;
    background: $a-neutral;
}

.excercise-circle-chart {
    display: flex;
    justify-content: center;
}