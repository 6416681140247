@import "../commonConstant.scss";

.challenge-reward-card-container {
  .reward-card-single {
    border-radius: 16px;
    box-shadow: 0px 4px 20px 0px $challenge-card-shadow;
    overflow: hidden;
    background: linear-gradient(90.74deg, $challenge-reward-lightgreen 0%, $challenge-reward-darkgreen 100%);
    &.team-tab{
      background: linear-gradient(90.74deg, $challenge-reward-lightblue 0%, $challenge-reward-darkblue 100%);
    }
  }

  .reward-card-single .reward-img-container {
    max-height: 150px;
    height: 100%;
    position: relative;
    z-index: 1;
  }

  .reward-card-single .reward-img-container .reward-img-inner-container {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;

    img {
      height: 150px;
      object-fit: contain;
    }
  }

  .reward-card-single .reward-img-container .reward-img-inner-container .reward-overlay {
    background: linear-gradient(360deg, rgba(0, 0, 0, 0.2) 0%, rgba(255, 255, 255, 0) 100%);
    background-blend-mode: multiply;
    position: absolute;
    bottom: 0px;
    width: 100%;
    height: 52px;
    z-index: 9;
  }

  .reward-card-single .reward-img-container .reward-tag {
    position: absolute;
    bottom: 8px;
    left: 8px;
    background: $bgwhite;
    border-radius: 8px;
    height: 25px;
    padding: 0 8px;
    font-size: 15px;
    font-weight: 500;
    display: flex;
    align-items: center;
    gap: 4px;
    z-index: 1;

    img {
      width: 18px;
      height: 18px;
    }
  }

  .reward-card-single .reward-img-container .reward-tag .reward-tag-title {
    color: $black;
    font-size: 14px;
    font-weight: 500;
    font-family: $sukhmaitext;

    &.rejected {
      color: $challenge-error-text;
    }

    &.approved {
      color: $challenge-success-green;
    }
  }

  .reward-card-single .reward-description {
    background: $bgwhite;
    padding: 8px;
  }

  .reward-card-single .reward-description .reward-card-title {
    font-size: 16px;
    font-weight: 700;
    font-family: $sukhmaitext;
    height: 48px;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 2;
  }

  .reward-card-single .reward-description .price-range {
    font-size: 14px;
    font-weight: 500;
    display: flex;
    gap: 4px;
    line-height: 21px;
    margin-top: 5px;
    font-family: $sukhmaitext;
  }


}