@import '../../../../common/commonConstant.scss';

.pull-right {
  float: right;
}

.bg-darkbrown {
  background: $dark-brown-grey;
}

.display-none {
  display: none;
}

.black-color{
  color: #2C2C2C
}

.grey-color{
  color: #777D79
}

/***************** BUTTONS CSS START  ******************/
.btn-green-linear {
  border-radius: 80px;
  height: 44px;
  display: inline-block;
  background: linear-gradient(135deg, $challenge-success-green1 -39.29%, $challenge-success-green2 82.14%)
      padding-box,
    linear-gradient(132.38deg, $challenge-success-green3 -14.45%, $challenge-success-green4 122.44%) border-box;
  border: 4px solid transparent;
  box-shadow: 4px 4px 18px 0px $quiz-btn-light-active;
}

.btn-white-linear {
  background: linear-gradient(180deg, $gray2 0%, $white1 100%);
  border-radius: 10px;
  box-shadow: 4px 4px 18px 0px $challenge-button-tab;
  height: 40px;
  position: relative;
  width: 40px;
  border: 2.5px solid transparent;
  background-clip: padding-box;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0;

  img {
    left: 50%;
    position: absolute;
    top: 50%;
    transform: translate(-50%, -50%);
  }
}

.btn-green-linear {
  border-radius: 48px;
  display: inline-block;
  background: linear-gradient(135deg, $challenge-success-green1 -39.29%, $challenge-success-green2 82.14%)
      padding-box,
    linear-gradient(132.38deg, $challenge-success-green3 -14.45%, $challenge-success-green4 122.44%) border-box;
  border: 4px solid transparent;
  box-shadow: 2px 3px 6px 0px $challenge-tab-shadow;
  height: 48px;
  font-size: 16px;
  font-weight: 700;
  font-family: $sukhmaitext;
}

.btn-sm {
  height: 32px;
  padding: 0 16px;
}

.btn-light-green {
  background: $quiz-answer-green;
  color: rgba(0, 143, 60, 1);
  font-size: 16px;
  font-weight: 700;
  font-family: $sukhmaitext;
}

.btn-rounded {
  border-radius: 48px;
}

.challenge-picture-header-wrapper {
  padding-top: 0;
  transition: all 0.3s ease;

  .screen-title {
    font-family: $sukhmaitext;

    font-size: 18px;
    font-weight: 700;
    line-height: 27px;
    text-align: center;
  }

  .header-top {
    height: 54px;
    object-fit: contain;
    background: $white;

    .header-top-img {
      width: 100%;
    }
  }

  .home-header {
    position: relative;
  }

  &.sticky {
    position: fixed;
    width: 100%;
    z-index: 99;
    top: 0;
    padding-top: 0;

    .quiz-header-bottom {
      background: $header-sticky-transparent;
      margin-bottom: 0;
    }
  }
}

.challenge-header-bottom {
  background: $white;
  padding: 12px 16px;
  margin-bottom: 17px;
  box-shadow: 0px 4px 20px 0px $challenge-card-shadow;

  .picture-header-title {
    font-size: 18px;
    font-weight: 700;
    font-family: $sukhmaitext;
  }
}

/****************** HEADER CSS END  ******************/

body::-webkit-scrollbar:has(.picture-after-before-page) {
  display: none; // Safari and Chrome
}

.picture-after-before-page {
  background-image: url(/images/challenge/team-dashboard-bg.svg);
  background-repeat: no-repeat;
  background-size: 100%;
  height: 100vh;
  background-position: top;
}

.after-before-body-container {
  padding-bottom: 50px;

  .after-before-title {
    font-size: 22px;
    font-weight: 700;
    font-family: $sukhmaitext;
    line-height: 33px;
    padding: 8px 0;
  }

  .after-before-header {
    display: flex;
    margin-bottom: 20px;

    .after-before-header-col {
      width: 50%;
      font-size: 18px;
      font-weight: 700;
      font-family: $sukhmaitext;
      text-align: center;
    }
  }

  .picture-after-before-container {
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    gap: 13px;

    .picture-after-before-row {
      display: flex;
      gap: 17px;
      position: relative;

      .picture-col {
        background: $white;
        box-shadow: 0px 4px 20px 0px $challenge-card-shadow;
        width: 50%;
        height: 280px;
        border-radius: 16px;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        gap: 4px;
        overflow: hidden;

        img {
          height: 48px;
        }

        p {
          font-size: 16px;
          font-weight: 700;
          font-family: $sukhmaitext;
          margin-bottom: 0;
          text-align: center;
          max-width: 100px;
          width: 100%;
        }
      }

      .steps-skeleton-card-container {
        padding: 0;
      }

      .picture-col-details {
        height: 100%;
        display: flex;
        box-shadow: 0px 4px 20px 0px $challenge-card-shadow;
        flex-direction: column;
        position: relative;
        width: 50%;
        border-radius: 16px;
        overflow: hidden;
        .picture-col-img {
          border-radius: 16px;
          height: 280px;
          width: 100%;
          object-fit: cover;
        }

        .picture-col-description {
          display: flex;
          justify-content: space-between;
          padding: 8px 12px 13px 12px;
          gap: 5px;
          position: absolute;
          background: $white;
          width: 100%;
          bottom: 0;
          border-bottom-left-radius: 16px;
          border-bottom-right-radius: 16px;

          .picture-col-title {
            font-size: 16px;
            font-weight: 700;
            font-family: $sukhmaitext;
          }

          .picture-col-date {
            font-size: 14px;
            font-weight: 500;
          }

          .picture-col-right {
            img {
              height: 24px;
            }
          }
        }
      }
    }
  }
}
