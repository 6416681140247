@import "../../../common/commonConstant.scss";

.confirmpayment-container {
  padding: 25px 15px;
  ul {
    list-style-position: inside;
  }
  > .header {
    text-align: center;
    font-size: 20px;
  }
  .body {
    margin-bottom: 10px;
    .card-container {
      padding: 0;
      margin-bottom: 15px;
      .card-header {
        font-weight: bold;
        border-bottom: 1px solid $gray;
        padding: 5px 15px;
      }
      .card-body {
        padding: 15px;
      }
    }
    .price-card {
      .card-body {
        display: flex;
        flex-direction: column;
        .product {
          display: flex;
          justify-content: space-between;
          .price {
            color: $medium-blue;
            font-weight: bold;
          }
        }
      }
    }
    .bankacc-card {
      .card-body {
        display: flex;
        flex-direction: row;
        .left {
          img{
            width: 50px;
            height: 50px;
          }
        }
        .right {
          margin-left: 15px;
        }
      }
    }
    .addr-card {
      .card-body {
        .header {
          color: $light-blue;
        }
      }
    }
    .info-card {
      .personal-info {
        .label {
          color: $gray;
          font-size: 13px;
        }
      }
    }
    .remark {
      text-align: center;
      color: $gray;
    }
  }
  .footer {
    width: 100%;
    .link {
      display: inline-block;
      color: $light-blue;
      text-decoration: underline;
    }
    .checkbox-container {
      input:checked {
        & ~ .checkmark {
          border: 2px solid $gray;
        }
      }
    }
  }
  .Modal{
    text-align: center;
  }
}

.payment-consent-container {
  padding: 15px;
  .card-header {
    text-align: center;
    font-size: 20px;
    font-weight: bold;
    margin-bottom: 15px;
  }
  .card-body {
    .header {
      font-weight: bold;
    }
  }
  .card-footer{
    position: fixed;
    bottom: 0;
    left: 0;
    width: 100%;
    padding: 10px;
    background: $bggray;
  }
}
