@import '../../../../common/commonConstant.scss';

.my-challenge-day-container {
    display: flex;
    margin-inline: 16px;
    margin-bottom: 2px;
    position: relative;
    justify-content: space-between;

    &.noHeaderBadge{
        height: 31px;
        justify-content: flex-end;
    }

    .team-leader-card {
        .rect-badge-title{
            font-size: 14px;
            line-height: 21px;
            font-weight: 700;
            font-family: $sukhmaitext;
        }
    }

    .rect-badge-description {
        display: flex;
        justify-content: center;
        align-items: center;
        gap: 4px;

        .rect-badge-title {
            font-size: 16px;
            line-height: 24px;
            font-weight: 500;
            font-family: $sukhmaitext;

        }

        .rect-badge-value {
            font-size: 16px;
            line-height: 24px;
            font-weight: 700;
            font-family: $gtwalsheimpro;

        }
    }
    .disable-color {
        color: $disabled-color;
    }
    .active-color {
        color: $black;
    }
}