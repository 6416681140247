@import '../commonConstant.scss';

.hnw-date-selector {
  .modal-picker-wrapper {

    .modal-picker-column:nth-child(1),
    .modal-picker-column:nth-child(2),
    .modal-picker-column:nth-child(3) {
      flex: initial !important;
    }

    .modal-picker-column:nth-child(1),
    .modal-picker-column:nth-child(3) {
      width: 30%;
    }

    .modal-picker-item {
      font-family: $sukhmaitext;
      font-size: 16px;
      font-weight: 500;
      line-height: 22px;
      color: $black;
    }

    &>div:last-of-type {
      z-index: -1;
      background: $a-offwhite;

      &>div {
        background: none !important;
      }
    }
  }
}

.modal-picker {
  .liff-numpad-panel.open {
    background: $homeblack1;
    backdrop-filter: blur(5px);

    .numpad-panel {
      height: 300px;

      &:has(.month-view) {
        height: 328px;
      }

      &:has(.month-view.--no-header) {
        height: 276px;
      }
    }
  }

  .calendar-date-modal-header {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 48px;
    margin: 8px 0 12px;

    &_label {
      font-size: 16px;
      font-weight: 700;
      font-family: $sukhmaitext;
      color: $homegreen;
    }

    &-icon {
      width: 40px;

      &.--right {
        transform: rotateY(180deg);
      }

      &.--hide {
        visibility: hidden;
      }
    }
  }

  &-body {
    width: 100%;
    padding: 0px 4px 0 16px;
    display: flex;
    flex-wrap: wrap;
    gap: 12px;

    .date-picker-item {
      padding: 6px 12px;
      font-size: 16px;
      font-weight: 700;
      font-family: $sukhmaitext;
      border-radius: 38px;
      text-align: center;

      &.previous-value {
        background: $a-neutral;
        color: $homegreen;
      }

      &.current-value {
        background: $homegreen;
        color: $white;
      }

      &.next-value {
        border: 1px solid $a-neutral;
        background: $white;
        color: $gray13;
      }
    }

    &.week-view {
      .date-picker-item {
        width: calc(50% - 12px);
      }
    }

    &.month-view {
      margin-top: 12px;

      .date-picker-item {
        width: calc(33% - 12px);
      }
    }
  }
}

.picker-window {
  position: absolute;
  background: $a-picker-bg;
  height: 42px;
  left: 0;
  top: 50%;
  transform: translateY(-65%);
  width: 100%;
  pointer-events: none;
  z-index: 0;
}

.picker {
  display: inline-grid;
  border-radius: 15px;
  background: transparent;
  font-size: calc(0.5rem + 2.4vw);
  position: relative;
  font-weight: 400;
  width: 100%;
  text-align: center;
  justify-content: center;
}

.picker:before,
.picker:after {
  content: "";
  position: absolute;
  width: 100%;
  height: 2.2em;
  z-index: 2;
}

.picker:before {
  top: 0em;
  background: linear-gradient($a-picker-white, $a-picker-dark);
}

.picker:after {
  bottom: 0em;
  height: 2.4em;
  background: linear-gradient($a-picker-dark, $a-picker-white);
}

.picker ul {
  -ms-overflow-style: none;
  max-height: 12em;
  overflow-y: scroll;
  width: 100%;
  margin: 0;
  -ms-scroll-snap-type: y mandatory;
  scroll-snap-type: y mandatory;
  padding-bottom: 3em;
  padding-top: 2em;
  z-index: 1;
}

.picker ul::-webkit-scrollbar {
  display: none;
}

.picker li {
  scroll-snap-align: center;
  height: 42px;
  line-height: 42px;
  text-align: right;
  word-spacing: 0.2em;
}

.picker-day {
  padding-right: 0.5em;
}

.picker-day span {
  padding-left: 0.56em;
}