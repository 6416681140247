@import '../../../../common/commonConstant.scss';

.evaluation-results-description {
    box-shadow: 0px 4px 12px 0px $homeblack;
    background: $bgwhite;
    margin: 26px 20px 14px 20px;
    border-radius: 12px;
    padding: 16px;

    .evaluation-results-description-title {
        color: $black;
        font-size: 18px;
        font-weight: 800;
        margin-bottom: 16px;
        font-family: $sukhmaibold;
    }

    .evaluation-results-content {
        display: flex;
        font-size: 16px;
        font-weight: 700;
    }


    .evaluation-results-info {
        background: $profilered1;
        padding: 12px;
        border-radius: 12px;
        font-size: 14px;
        font-weight: 500;
        margin-top: 12px;
        margin-bottom: 8px;
    }

    .button-group {
        display: flex;
        justify-content: space-between;
    }

    .btn-green {
        border: 1px solid $homegreen;
        background: $homegreen;
        color: $bgwhite;
    }

    button {
        &.btn-outline {
            border: 1px solid $black;
            background: $bgwhite;
            color: $black;
        }
    }

    .btn-green,
    .btn-outline {
        font-size: 16px;
        font-weight: 700;
        border-radius: 38px;
        padding: 6px 8px;
        margin-top: 10px;
        display: flex;
        justify-content: center;
        width: 48%;
    }

    .btn-green,
    .btn-outline {
        img {
            padding-right: 8px;
        }
    }
}