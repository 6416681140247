@import '../../../../common/commonConstant.scss';

.profileDetails_header {
  font-family: $sukhmaitext;
  font-size: 18px;
  font-weight: 800;
  line-height: 26px;
  letter-spacing: 0px !important;
  text-align: center;
}

.sop_profile_details {
  padding: 16px;

  .profile_details {
    border-radius: 10px;
    background: $orange1;
    padding: 10px 16px;

    .profile_type {
      font-family: $sukhmaitext;
      font-size: 14px;
      font-weight: 700;
      line-height: 20px;
      letter-spacing: 0px !important;
      text-align: left;
      color: $orange2;
    }

    .profile-status-tag {
      display: flex;
      align-items: center;
      padding: 2px 8px;
      background: $bglightblue;
      border-radius: 24px;
      font-family: $sukhmaitext;
      font-size: 16px;
      font-weight: 700;
      line-height: 14px;
      letter-spacing: 0px !important;
      text-align: left;
      color: $medium-blue;
      margin-bottom: 5px;
      justify-content: center;
    }

    .incart-status {
      color: $green1;
      background-color: $light-green2;
    }

    .payment-pending-status {
      background-color: $light-brown;
      color: $dark-orange;
    }

    .purchased-status {
      background-color: $light-green2;
      color: $dark-green1;
      justify-content: center;
      align-items: center;

      div {
        margin-right: 5px;
        align-items: center;
        display: flex;

        svg {
          width: 13px !important;
        }
      }
    }

    .postpone-status {
      color: $dark-green;
      justify-content: center;
      align-items: center;

      div {
        margin-right: 5px;
        align-items: center;
        display: flex;

        svg {
          width: 13px !important;
        }
      }
    }
  }

  .profile_details.flex {
    background-color: $light-green;

    .profile_type {
      color: $dark-green;
    }
  }

  .profile_details.basic {
    background-color: $bggreen2;

    .profile_type {
      color: $bggreen1;
    }
  }

  .monthly_benefits {
    margin-top: 20px;
    background: $bglightblue;
    border-radius: 10px;

    .benefit {
      padding: 16px;
      align-items: center;

      li {
        font-family: $sukhmaitext;
        font-size: 14px;
        font-weight: 500;
        line-height: 20px;
        letter-spacing: 0px !important;
        text-align: left;
        color: $blue1;
        margin-left: 10px;

        span {
          position: relative;
          left: -10px;
        }
      }
    }

    .month_section {
      background: $bgwhite;
      border-radius: 10px;
      border: 1px solid $bglightblue;
      padding: 10px 16px 16px 16px;

      .month_view {
        margin-top: 10px;
      }

      .elements {
        height: 34px;
        width: 34px;
        border: 2px solid rgba(56, 83, 154, .16);
        border-style: dashed;
        border-radius: 32px;
        color: #38539A;
        font-weight: bold;
        font-family: $sukhmaitext;
        justify-content: center;
        display: flex;
        align-items: center;
        margin-right: 5px;
      }

      .months {
        font-family: $sukhmaitext;
        font-size: 14px;
        font-weight: 500;
        line-height: 20px;
        letter-spacing: 0px !important;
        text-align: center;
        color: black;
        margin-top: 5px;
      }

      .months.active {
        font-family: $sukhmaitext;
        font-size: 14px;
        font-weight: 700;
      }
    }
  }
}

.profile_details_products {
  background: $gray11;
  padding: 16px;
  min-height: 300px;

  .header {
    justify-content: space-between;
    display: flex;
    font-family: $sukhmaitext;
    font-size: 16px;
    font-weight: 700;
    line-height: 24px;
    letter-spacing: 0px !important;
    text-align: right;
    padding: 0px;
  }

  .product_tiles {
    background: $bgwhite;
    margin-top: 10px;
    border-radius: 10px;
    padding: 10px 16px;
    img {
      width: 100%;
    }

    .sku {
      font-family: $sukhmaitext;
      font-size: 12px;
      font-weight: 500;
      line-height: 16px;
      letter-spacing: 0px !important;
      text-align: left;
      color: $gray8;
    }

    .identifier {
      font-family: $sukhmaitext;
      font-size: 14px;
      font-weight: 500;
      line-height: 20px;
      letter-spacing: 0px !important;
      text-align: left;
      color: $black;
    }
  
    .price_pvbv_container {
      margin-top: 0px !important;
    }
    .price_pvbv {
      font-family: $sukhmaitext;
      font-size: 16px;
      font-weight: 700;
      line-height: 24px;
      letter-spacing: 0px !important;
      text-align: left;
      color: $black ;
      justify-content: space-between;
      margin-top: 10px;
      .pv_bv {
          font-family: $sukhmaitext;
          font-size: 12px;
          font-weight: 500;
          line-height: 16px;
          letter-spacing: 0px !important;
          text-align: left;
          color: $gray8;
        }
    }
  }
}