@import "../commonConstant.scss";

.quiz-tab-single {
    .btn-white {
        height: 32px;
        width: 84px;
        padding: 0 10px;
        margin: 6px 0;
        font-weight: 700;
        font-family: 'Sukhumvit Tadmai';
        border: none;
        background: none;
        cursor: pointer;
        line-height: 24px;
        text-align: center;
        box-shadow: none;
    }
}



.quiz-tab-active {
    .btn-white {
        border-radius: 32px;
        display: inline-block;
        background:
            linear-gradient(107.74deg, $header-sticky-white 27.91%, $white1 98.01%) padding-box,
            linear-gradient(139.18deg, $bmi-slider-bg-2 16.07%, $white2 93.35%) border-box;
        border: 3px solid transparent;
        box-shadow: 2px 3px 6px 0px $challenge-tab-shadow;
    }
}