@import '../commonConstant.scss';

.hnw-input-box-wrapper {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 6px 0px;

  &__group {

    display: flex;
    justify-content: space-between;
    gap: 4px;

    &-label {
        font-family: $sukhmaitext;
        font-size: 16px;
        font-weight: 500;
        line-height: 22px;
        color: $black;
      }
  }
  

  &__value {
    font-family: $sukhmaitext;
    font-weight: 700;
    line-height: 20px;
    font-size: 16px;
    color: $theme-green;

    &.--no-value {
      font-size: 14px;
      color: $dark-red3;
    }
  }
}