@import '../../../../common/commonConstant.scss';

.my-dashboard-profile-wrapper {
    margin-inline: 16px;

    .profile-card-container-wrapper {
        z-index: 1;
        position: relative;
        background: $a-serviceCard;
        border-radius: 16px;
        box-shadow: 0px 4px 20px 0px $challenge-card-shadow;
        margin-top: 50px;
        margin-bottom: 16px;

        .profile--top-img {
            text-align: center;
            position: absolute;
            left: 50%;
            top: -70px;
            transform: translate(-50%, 10px);
            z-index: 9;
        }

        .profile-white-bg {
            background: $a-serviceCard;
            height: calc(100% - 100px);
            width: 100%;
            position: absolute;
            bottom: 34px;
            border-radius: 16px;
            box-shadow: 0px 4px 20px 0px $challenge-card-shadow;
        }

        .profile-img {
            position: absolute;
            top: -38px;
            left: 50%;
            transform: translateX(-50%);
            height: 80px;
            width: 80px;
            z-index: 9;
            border-radius: 50%;
        }

        .btn-icon-crown {
            height: 27px;
            width: 27px;
            border-radius: 50%;
            background: $bgwhite;
            position: absolute;
            left: 56%;
            top: 12px;
            box-shadow: 1px 2px 3px 0px $crown-icon-shadow;
            z-index: 9;
          }

        .crown-img {
            display: none;
            position: absolute;
            left: 60%;
            transform: translate(-58%);
            z-index: 9;
        }

        .profile-card-container-active {
            .profile-img {
                border: 2px solid #dbb67c;
                border-radius: 50%;
            }

            .crown-img {
                display: block;
            }
        }

        .profile-description-container {
            padding-top: 50px;
            padding-bottom: 4px;
            box-shadow: 0px 4px 20px 0px $challenge-card-shadow;
            border-radius: 16px;

            .card-ongoing-description {
                text-align: center;

                .card-ongoing-name {
                    font-size: 18px;
                    font-weight: 700;
                    font-family: $sukhmaitext;
                    line-height: 27px;
                }

                .card-ongoing-number {
                    font-size: 14px;
                    font-weight: 400;
                    color: $sku-text;
                    line-height: 21px;
                }
            }

            .card-ongoing-contact-details {
                display: flex;
                margin: 7px 0px 16px 26px;

                .card-ongoing-contactleft,
                .card-ongoing-contactright {
                    display: flex;
                    align-items: center;
                    gap: 4px;
                    width: 50%;
                }

                .card-ongoing-contactleft {
                    .card-ongoing-contact-name {
                        font-size: 14px;
                        font-weight: 400;
                        color: $black;
                        font-family: $gtwalsheimpro;
                        line-height: 21px;
                    }
                }

                .card-ongoing-contactright {
                    .card-ongoing-contact-name {
                        font-size: 14px;
                        font-weight: 400;
                        color: $black;
                        font-family: $sukhmaitext;
                        line-height: 21px;
                    }
                }
            }
        }
    }
}