@import "../../../../common/commonConstant.scss";

.home-exercise-log-container {
    .white-card-container {
        box-shadow: 0px 4px 20px 0px $challenge-card-shadow;
        background: linear-gradient(132.59deg, $challenge-slider-bg 38.77%, $challenge-slider-bg1 113.8%);
        border-radius: 16px;

        .white-card-title {
            font-size: 18px;
            font-weight: 700;
            font-family: $sukhmaitext;
        }

        .home-exercise-graph {
            display: flex;
            justify-content: flex-start;
            padding: 0 16px;
            height: 52px;
            .excercise-line-wrapper {
                width: 8px;
                position: relative;
                display: flex;
                height: 37px;
                justify-content: center;
                &.calorie {
                    align-items: flex-end;
                    .home-exercise-lines {
                        background:$home-exercise-circle2;
                        width: 6px;
                        position: relative;
                        border-radius: 24px;
                    }
                }

                .home-exercise-lines {
                    background: $home-exercise-gray;
                    height: 37px;
                    width: 1px;
                }

                .home-exercise-line-text {
                    position: absolute;
                    left: 8px;
                    font-size: 10px;
                    font-weight: 400;
                    color: $black;
                    bottom: -19px;
                }


                &:nth-child(1),
                &:nth-child(6),
                &:nth-child(11) {
                    .home-exercise-lines {
                        background: $home-exercise-circle2;
                        width: 1.5px;
                        height: 52px;
                    }
                }
            }
        }

        .excercise-details-container {
            display: flex;
            justify-content: space-between;
            align-items: end;
            margin-top: 8px;

            .progress-bar-circular {
                padding-left: 10px;

                .card {
                    background: none;
                    position: relative;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    border: none;

                    svg {
                        width: 46px;
                        height: 46px;
                        position: relative;
                        transform: rotate(-90deg);
                        display: flex;
                        justify-content: center;
                        align-items: center;

                        circle {
                            stroke-width: 5;
                            fill: transparent;

                            &:last-of-type {
                                stroke-dasharray: 109.3px;
                                stroke-linecap: round;
                                stroke-dashoffset: calc(109.3px - (109.3px * var(--percent)) / 100);
                            }

                            &:last-of-type[style*="--percent: 0"] {
                                stroke-width: 0;
                                opacity: 0;
                            }
                        }
                    }

                    .outer-circle {
                        height: 54px;
                        width: 53px;
                        background-image: url('/images/healthAndWellness/home-page-exercise-circle.png');
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        background-size: contain;

                        .percent {
                            display: flex;
                            justify-content: center;
                            align-items: center;
                            position: relative;

                            .number {
                                position: absolute;
                                top: 50%;
                                left: 50%;
                                transform: translate(-50%, -50%);

                                .progress-value {
                                    font-size: 12px;
                                    font-weight: 700;
                                    line-height: 18px;
                                    color: $black;
                                    font-family: $gtwalsheimpro;
                                    display: flex;

                                    .progress-value-percent {
                                            font-size: 6px;
                                            font-weight: 400;
                                            line-height: 10px;
                                            letter-spacing: -0.02em;
                                            align-self: center;
                                            padding-bottom: 1px;
                                        }
                                }
                            }
                        }

                    }
                }


            }

            .excercise-description {
                padding-right: 16px;
                text-align: right;
            }

            .excercise-description .used-value {
                font-size: 28px;
                font-weight: 700;
                font-family: $gtwalsheimbold;
                line-height: 24px;
            }

            .excercise-description .target {
                display: flex;
            }

            .excercise-description .total-value {
                font-size: 14px;
                font-weight: 500;
                font-family: $gtwalsheimpro;
                margin-right: 4px;
            }

            .excercise-description .unit {
                font-size: 14px;
                font-weight: 500;
                font-family: $sukhmaitext;
            }
        }
    }
}