@import "../../../../../common/commonConstant.scss";

.answer-card-modal-popup {
  .liff-panel {
    &.open {
      z-index: 99;

      .select-coupon-panel {
        height: auto;

        > :nth-child(1) {
          border-radius: 24px 24px 0 0;
          height: 124px;
          padding: 24px 0 0;
          background-repeat: no-repeat;
          background-size: cover;
          background-image: url("/images/assesment/answer-modal-health.svg");
        }

        > :nth-child(2) {
          padding: 20px;
        }
      }
    }
  }

  &.--healthinfo .liff-panel.open .select-coupon-panel {
    > :nth-child(1) {
      background-image: url("/images/assesment/answer-modal-health.svg");
    }
  }

  &.--activities .liff-panel.open .select-coupon-panel {
    > :nth-child(1) {
      background-image: url("/images/assesment/answer-modal-activity.svg");
    }
  }

  &.--attitude .liff-panel.open .select-coupon-panel {
    > :nth-child(1) {
      background-image: url("/images/assesment/answer-modal-attitude.svg");
    }
  }

  &.--foodhabits .liff-panel.open .select-coupon-panel {
    > :nth-child(1) {
      background-image: url("/images/assesment/answer-modal-food.svg");
    }
  }

  &.--sleep .liff-panel.open .select-coupon-panel {
    > :nth-child(1) {
      background-image: url("/images/assesment/answer-modal-sleep.svg");
    }
  }

  &.--stress .liff-panel.open .select-coupon-panel {
    > :nth-child(1) {
      background-image: url("/images/assesment/answer-modal-stress.svg");
    }
  }

  &-body {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
  }

  &-description {
    font-family: $sukhmaitext;
    font-weight: 500;
    font-size: 16px;
  }

  &-btn {
    height: 48px;
    padding: 6px 8px;
    border-radius: 38px;
    background: $bgwhite;
    font-size: 16px;
    line-height: 20px;
    font-weight: 700;
    border: none;
    background: $a-green;
    color: $bgwhite;
    width: 276px;
    margin: 20px 32px 0;
    font-family: $sukhmaitext;
  }
}