@import '../../../../common/commonConstant.scss';

/********************* DASHBOARD SWIPER SLIDER CSS START *********************/

.dashboard-slider-outer {
  padding-bottom: 40px;
  .dashboard-slider-wrapper {
    .swiper-wrapper {
      display: flex;
      align-items: center;
    }

    .dashboard-slider-title {
      font-size: 18px;
      font-weight: 700;
      font-family: $sukhmaitext;
      color: #2c2c2c;
      padding-bottom: 20px;

      .mission-days{
        font-family: $gtwalsheimbold;
      }
    }

    .dashboard-slider-container {
      display: flex;
    }
  }
  .challenge-tab-list {
    margin-bottom: 20px;
  }
}
