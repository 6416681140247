@import "../../../../common/commonConstant.scss";

.home-body-data-loader{
    .vdo-skeleton-container {
      display: flex;
      overflow: hidden;
      .vdo-skeleton-card-container {
        display: flex;
        gap: 8px;
        flex-shrink: 0;
        padding:8px;
        height: 184px;
        flex-direction: column;
      }

      .vdo-skeleton-card-container:first-child {
        flex: 0 0 50%;
      }

      .vdo-skeleton-card-container:last-child {
        flex: 0 0 50%;
      }

      .skeleton-card-image {
        height: 184px;
        border-radius: 16px;
      }

      .skeleton {
        animation: skeleton-loading 1s linear infinite alternate;
      }

      @keyframes skeleton-loading {
        0% {
          background-color: $challenge-carousel-loader-start;
        }
        100% {
          background-color: $challenge-carousel-loader-end;
        }
      }


    }
  }
