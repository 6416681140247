@import '../../../common/commonConstant.scss';

.challenge-auto-sync-step {
  &-header {
    padding: 12px 16px 12px 16px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    &-title {
      font-family: $sukhmaitext;
      font-size: 18px;
      font-weight: 700;
      line-height: 27px;
      text-align: center;
    }
  }

  &-body {
    margin-top: 40px;
    .take-step-today-right-section {
      .card {
        background: none;
        position: relative;
        display: flex;
        align-items: center;
        justify-content: center;
        border: none;

        svg {
          width: 281px;
          height: 280px;
          transform: rotate(-90deg);

          circle {
            stroke-width: 18;
            fill: transparent;

            &:last-of-type {
              stroke-dasharray: 527px;
              stroke-linecap: round;
              stroke-dashoffset: calc(527px - (527px * var(--percent)) / 100);
            }
          }
        }

        .percent {
          display: flex;
          justify-content: center;
          align-items: center;
        }

        .circle-progress-detail {
          position: absolute;
          text-align: center;
          .progress-value {
            font-family: $gtwalsheimbold;
            font-weight: 700;
            font-size: 35px;
            line-height: 48px;
            letter-spacing: -0.05em;
            text-align: center;
            color: $homegreen;
          }
          .progress-total {
            font-family: $gtwalsheimpro;
            font-size: 18px;
            font-weight: 400;
            line-height: 27px;
            text-align: left;
            color: $black;
          }
          .progress-unit {
            font-family: $sukhmaitext;
            font-size: 18px;
            font-weight: 500;
            line-height: 27px;
            text-align: left;
            color: $black;
          }
        }

        &.progress-card-green-1 {
          svg {
            circle {
              stroke: $a-circleStroke;
            }
          }
        }
      }

      .outer-circle {
        background-image: url('/images/challenge/outer-circular-bar.svg');
        width: 281px;
        height: 280px;
        background-position: center;
        background-repeat: no-repeat;
        display: flex;
        align-items: center;
        justify-content: center;
        background-size: contain;

        .inner-circle {
          
        }
      }
    }

    &-list {
      margin: 40px 0;
      display: flex;
      flex-direction: column;
      gap: 17px;
      align-items: center;
      padding-left: 30px;
      padding-right: 30px;
      .exercise-card {
        padding: 10px 16px 10px 16px;
        background-color: $white;
        border-radius: 16px;
        box-shadow: 0px 4px 20px 0px $challenge-dashboard-green-shadow;
        width: 100%;
        height: 94px;

        display: flex;
        flex-direction: column;

        &-title {
          font-family: $sukhmaitext;
          font-size: 18px;
          font-weight: 700;
          line-height: 27px;
          text-align: left;
        }
        &-value {
          font-family: $gtwalsheimbold;
          font-size: 18px;
          font-weight: 700;
          line-height: 27px;
          text-align: left;
          .green {
            color: $homegreen;
          }
          .black {
            color: $black;
          }
        }
        &-total {
          font-family: $gtwalsheimpro;
          font-size: 16px;
          font-weight: 400;
          line-height: 24px;
          text-align: left;
        }
        &-unit {
          font-family: $sukhmaitext;
          font-size: 16px;
          font-weight: 500;
          line-height: 24px;
          text-align: left;
        }
        &-percent {
          font-family: $gtwalsheimbold;
          font-size: 18px;
          font-weight: 700;
          line-height: 27px;
          text-align: left;
        }

        .progress-outer {
          box-shadow: 2px 2px 2px 0px #8394ed40 inset;
          background: linear-gradient(
            103.17deg,
            rgba(99, 121, 232, 0.16) 15.24%,
            rgba(131, 148, 237, 0.05) 88.44%
          );
          height: 20px;
          border-radius: 13px;
          display: flex;
          align-items: center;
          justify-content: center;
          position: relative;

          .progress {
            height: 8px;
            width: 100%;
            margin: 0 8px;
            border-radius: 24px;

            .progress-bar {
              background: linear-gradient(270deg, #9bda79 0%, #6bc948 100%);
              border-radius: 16px;
            }
          }
        }
      }

      .goal-card {
        margin-top: 40px;
        padding: 10px 16px 10px 16px;
        background-color: $white;
        border-radius: 16px;
        box-shadow: 0px 4px 20px 0px $challenge-dashboard-green-shadow;
        display: flex;
        justify-content: flex-start;
        align-items: center;
        gap: 10px;
        width: 100%;
        height: 44px;
        padding: 0 24px 0 24px;
        .goal-redirection-link {
          font-family: $sukhmaitext;
          font-size: 16px;
          font-weight: 700;
          line-height: 24px;
          text-align: center;
        }
      }
    }
  }
  .display-flex {
    display: flex;
    justify-content: space-between;
  }
}
