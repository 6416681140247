@import '../../../../common/commonConstant.scss';

.team-body-container {
    padding: 0 16px;

    .challenge-tab-list{
        margin-bottom: 20px;
    }

    .team-body-heading {
        font-family: $sukhmaitext;
        font-size: 18px;
        font-weight: 700;
        line-height: 27px;
        text-align: left;
        color: $a-black;
        margin-bottom: 8px;
    }

    .team-white-outline-nav {
        margin-inline: 16px;

        .nav-tabs {
            margin-bottom: 32px;
            border-bottom: none;
        }

        .nav-tabs .nav-link {
            height: 44px;
            width: 50%;
            color: #2c2c2c;
            font-weight: 700;
            font-family: 'Sukhumvit Tadmai';
            position: relative;
            padding: 6px 0;
        }

        .nav-tabs .nav-link.active {
            position: relative;
            background: linear-gradient(107.74deg, #E6E6E6 27.91%, #FEFEFE 98.01%) padding-box, linear-gradient(139.18deg, #FBFBFB 16.07%, #DCDCDC 93.35%) border-box;
            border: 4px solid transparent;
            border-radius: 32px;
            box-shadow: 4px 4px 18px 0px rgba(44, 44, 44, 0.25);
        }

        .nav-tabs .nav-link.active::after {
            content: '';
            position: absolute;
            top: -4px;
            bottom: -4px;
            left: -4px;
            right: -4px;
            background: linear-gradient(139.18deg, #FBFBFB 16.07%, #DCDCDC 93.35%);
            z-index: -1;
            border-radius: 32px;
        }

        .nav-tabs .nav-link.active::before {
            display: none;
        }
    }

    .team-body-subheading {
        font-family: $sukhmaitext;
        font-size: 16px;
        font-weight: 800;
        line-height: 24px;
        text-align: left;
        color: $a-black;
        height: 44px;
        margin-left: 3px;
        display: flex;
        align-items: center;
    }

    .challenge-card-body {
        box-shadow: 0px 4px 20px 0px $challenge-card-shadow;
        border-radius: 16px;
        padding: 16px;
        display: flex;
        justify-content: space-between;
        background: $a-serviceCard;

        .team-rect-card-container {
            .step-card-left {
                .step-card-left-col2 {
                    height: 48px;
                    display: flex;
                    align-items: center;
                    justify-content: flex-start;

                    .bmi-green {
                        font-size: 20px;
                    }
                }
            }
        }

        hr.rounded-dividing-line {
            width: 4px; 
            height: 94px;
            border: none;
            box-shadow: 1px 1px 4px 0px $card-divider-line inset;
            border-radius: 5px;
            margin: 0;
            margin-inline: 9.5px;
          }
    }
}