@import '../../../common/commonConstant.scss';

.upload-image-wrapper {
  margin-top: 16px;
  background: #fff;
  border-top-left-radius: 12px;
  border-top-right-radius: 12px;
  position: absolute;
  bottom: 0;
  height: auto;
  overflow: auto;
  width: 100%;

  .upload-image-container {
    padding-top: 8px;

    .upload-image-title {
      font-size: 16px;
      font-weight: 700;
      font-family: $sukhmaitext;
      line-height: 24px;
    }

    .upload-img-row {
      padding-top: 8px;
      padding-bottom: 20px;
    }

    // cropper properties
    .cropper-container-500 {
      width: 100%;
      border-radius: 16px;
      height: 500px;

      .reactEasyCrop_Container {
        border-radius: 16px;
        position: relative;
        height: 100%;
        width: 100%;

        img {
          position: relative;
          width: auto !important;
          max-width: fit-content;
          height: 500px
        }

        .reactEasyCrop_CropArea {
          box-shadow: none;
          border: none;
        }
      }
    }

    .cropper-container-650 {
      width: 100%;
      border-radius: 16px;
      height: 650px;

      .reactEasyCrop_Container {
        border-radius: 16px;
        position: relative;
        height: 100%;
        width: 100%;

        img {
          position: relative;
          width: auto !important;
          max-width: fit-content;
          height: 650px;
        }

        .reactEasyCrop_CropArea {
          box-shadow: none;
          border: none;
        }
      }
    }

    .ab-pic-bottom-wrapper {
      width: 100%;
      z-index: 9;
      box-shadow: 0px 4px 20px 0px $challenge-card-shadow;
      background: linear-gradient(132.59deg,
          $challenge-slider-bg 38.77%,
          $challenge-slider-bg1 113.8%);
      position: relative;
      margin-top: 20px;

      .ab-pic-bottom-footer {
        display: flex;
        padding: 16px 0px 32px 0px;

        .btn-outline-red,
        .btn-green {
          font-family: $sukhmaitext;
          width: 50%;
          font-weight: 700;
          color: $white;
        }

        .btn-outline-red {
          color: $challenge-btn-outline-red;
        }
      }
    }
  }
}