@import '../../../common/commonConstant.scss';

body:has(.food-overview-drink-container.--no_scroll) {
    overflow: hidden;
}

.food-overview-page {
    font-family: $sukhmaitext;
    display: flex;
    flex-direction: column;
    padding-bottom: 120px;

    .food-overview-banner-container {
        background: url('/images/healthAndWellness/foodLog/food-log-home-bg.svg');
        background-position: center;
        width: 100%;
        background-size: cover;
        background-repeat: no-repeat;
        height: 370px;
        overflow-x: hidden;

        .nutrition-carousel-container {
            overflow-x: auto;
            height: 400px;

            .nutrition-card-container {
                padding: 20px 0 0;

                .card {
                    display: flex;
                    flex-direction: column;
                    gap: 4px;
                }
            }
        }



        .header-container {
            background: $bgwhite;
            z-index: 1;
            position: relative;
            height: 52px;
            display: flex;
            align-items: center;
        }

        .food-overview-banner-img-lg {
            display: none;
        }

        .food-overview-banner-img,
        .food-overview-banner-img-lg {
            width: 100%;
        }

        .food-overview-link {
            display: inline-block;
        }

        .food-overview-banner-content {
            overflow: hidden;
            height: 100%;

            .food-overview-banner-header {
                display: flex;
                justify-content: flex-end;
                align-items: center;
                margin-top: 15px;

                &.justify-content-space-between {
                    justify-content: space-between;
                }

                .overview-text {
                    font-size: 18px;
                    font-weight: 700;
                    line-height: 26px;
                }

                .dropdown {
                    .select-styled {
                        background: $bgwhite;
                    }
                }
            }

            .progress-card-green-1 {
                &.card {
                    background: none;
                    position: relative;

                    svg {
                        width: 190px;
                        height: 190px;

                        circle {
                            stroke-width: 12;
                            stroke: $bgwhite;

                            &:last-of-type {
                                stroke: $fl-green;
                                stroke-dasharray: 560px;
                                stroke-dashoffset: calc(560px - (560px * var(--percent)) / 100);
                            }
                        }
                    }

                    .percent {
                        display: flex;
                        justify-content: center;
                        align-items: center;

                        .number {
                            height: 140px;
                            width: 140px;
                            background: $bgwhite;
                            box-shadow: 0px 4px 12px 0px $a-btnSalad;
                            border-radius: 50%;
                            display: flex;
                            align-items: center;
                            justify-content: center;
                            text-align: center;

                            .progress-value {

                                .progress-value-inner {
                                    font-size: 24px;
                                    font-weight: 800;
                                }

                                .progress-value-unit {
                                    font-size: 16px;
                                    font-weight: 700;
                                }
                            }
                        }
                    }
                }
            }

            .nutrition-bar-container {
                display: flex;
                gap: 24px;
                margin-top: 20px;

                .nutrition-bar-single,
                .nutrition-bar-header {
                    display: flex;
                    flex-direction: column;
                }

                .nutrition-bar-single {
                    gap: 2px;
                    width: 33.33%;

                    .nutrition-bar-header {
                        gap: 4px;

                        .nutrition-bar-name {
                            font-size: 12px;
                            font-weight: 500;
                            color: $black;
                            text-align: center;
                            line-height: 16px;
                        }

                        .progress {
                            width: 100%;
                            height: 6px;
                            border-radius: 50px;
                            background: $a-circleStroke;

                            .progress-bar {
                                background: linear-gradient(90deg, $flgreen2 0%, $flgreen1 99.62%);
                                border-radius: 16px;
                            }
                        }
                    }

                    .nutrition-bar-value-group {
                        display: flex;
                        justify-content: center;
                        margin-top: 4px;
                        gap: 4px;

                        .nutrition-bar-value,
                        .nutrition-bar-value-unit {
                            font-size: 12px;
                        }

                        .nutrition-bar-value {
                            font-weight: 700;
                        }

                        .nutrition-bar-value-unit {
                            font-weight: 500;
                        }
                    }

                }
            }
        }


        /* Progress bar css start */
        .progress-bar__wrapper {
            position: relative;
        }

        .progress-bar__value {
            position: absolute;
            display: flex;
            flex-direction: column;
            align-items: center;
            gap: 8px;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
        }

        .progress {
            background-color: $bgwhite;
        }
    }

    .food-overview-meal-container {

        .food-overview-meal-title {
            padding-top: 32px;
            font-size: 20px;
            font-weight: 800;
            font-family: "Sukhumvit Tadmai";
            line-height: 28px;
            margin-bottom: 16px;
        }

        .food-overview-meal-card-container {
            display: flex;
            flex-direction: column;
            gap: 16px;


            .food-overview-calc-progress {
                font-size: 14px;
                font-weight: 500;
                color: $homegrey;
                margin-bottom: 5px;
            }

            .dotted-separtor {
                border: none;
                height: 1px;
                background: repeating-linear-gradient(90deg,
                        $bmigrey,
                        $bmigrey 6px,
                        transparent 6px,
                        transparent 12px);
            }

            .food-overview-meal-list {
                background: $flwhite;
                padding: 6px;
                border-radius: 6px;
                margin-top: 6px;

                .food-overview-meal-list-single {
                    display: flex;
                    gap: 4px;
                    align-items: center;
                    justify-content: space-between;

                    .food-overview-meal-list-col-1-container {
                        display: flex;
                        gap: 6px;
                    }

                    .food-overview-meal-list-col-1 {
                        display: flex;

                        &--image {
                            width: 38px;
                            height: 38px;
                            border-radius: 50%;
                        }

                        &--no-image-food {
                            content: url("/images/healthAndWellness/foodCalories/food-details/food-default-suggest-card.svg");

                            @media (min-width: 325px) {
                                content: url("/images/healthAndWellness/foodCalories/food-details/food-default-suggest-card_small.svg");
                            }
                        }

                        &--no-image-supplement {
                            content: url("/images/healthAndWellness/foodCalories/food-details/supplement-default-suggest-card.svg");

                            @media (min-width: 325px) {
                                content: url("/images/healthAndWellness/foodCalories/food-details/supplement-default-suggest-card_small.svg");
                            }
                        }
                    }

                    .food-overview-meal-list-col-2 {
                        .food-overview-meal-list-title {
                            display: block;
                            display: -webkit-box;
                            margin: 0 auto;
                            color: $black;
                            -webkit-line-clamp: 1;
                            -webkit-box-orient: vertical;
                            overflow: hidden;
                            text-overflow: ellipsis;
                            font-size: 14px;
                            font-weight: 500;
                        }

                        .food-overview-meal-list-quantity {
                            font-size: 14px;
                            font-weight: 500;
                            color: $homegrey;
                        }
                    }

                    .food-overview-meal-list-col-3 {
                        width: 55px;
                        text-align: right;
                        font-size: 14px;
                        font-weight: 500;

                        .food-overview-meal-calories-value {
                            color: $black;
                        }

                        .food-overview-meal-calories-title {
                            color: $gray12;
                        }
                    }
                }
            }
        }
    }

    .food-overview-drink-container {
        padding: 32px 20px 0px;

        .food-overview-drink-heading-container {
            display: flex;
            justify-content: space-between;
            align-items: center;
            margin-bottom: 12px;

            .food-overview-drink-heading-left {
                font-size: 20px;
                font-weight: 800;
                font-family: "Sukhumvit Tadmai";
                line-height: 28px;
            }

            .food-overview-drink-heading-right {

                .food-overview-drink-heading-consume,
                .food-overview-drink-heading-separtor {
                    font-size: 16px;
                }

                .food-overview-drink-heading-overall-value,
                .food-overview-drink-heading-unit {
                    font-size: 14px;
                }

                .food-overview-drink-heading-consume {
                    color: $black;
                    font-weight: 700;
                }

                .food-overview-drink-heading-separtor,
                .food-overview-drink-heading-overall-value,
                .food-overview-drink-heading-unit {
                    color: $homegrey;
                    font-weight: 500;
                }
            }
        }

        .food-overview-drink-progress {
            height: 9px;
            width: 100%;
        }

        .food-overview-drink-card-container {
            margin-top: 16px;
            margin-bottom: 20px;
            display: flex;
            flex-direction: column;
            gap: 16px;

            .food-overview-drink-card {
                box-shadow: 1px 8px 18px 0px $flblack;
                padding: 12px;
                display: flex;
                gap: 8px;
                flex-direction: column;
                border-radius: 12px;

                .food-overview-drink-card-header {
                    display: flex;
                    justify-content: space-between;
                }

                .food-overview-drink-card-header-left {
                    display: flex;
                    flex-direction: column;
                    color: $black;

                    &-content {
                        display: flex;
                        gap: 6px;
                        font-weight: 500;
                        font-size: 14px;
                        color: $gray12;
                    }

                    .food-overview-drink-card-header-name {
                        font-size: 16px;
                        font-weight: 700;
                        line-height: 24px;
                        font-family: "Sukhumvit Tadmai";
                        color: $black;
                    }

                    .food-overview-drink-card-header-value {
                        font-size: 14px;
                        font-weight: 500;
                        line-height: 20px;
                        color: $black;
                    }
                }

                .food-overview-drink-card-header-right {
                    display: flex;
                    align-items: center;

                    .btn-edit {
                        padding: 0;
                    }
                }
            }

            .food-overview-drink-img-container {
                display: grid;
                box-sizing: border-box;
                gap: 10px;

                &.food-overview-drink-water-container,
                &.food-overview-drink-softDrink-container {
                    grid-template-columns: repeat(8, 1fr);
                    gap: 7px;

                    @media (max-width: 362px) {
                        grid-template-columns: repeat(6, 1fr);
                    }

                    @media (min-width: 363px) {
                        grid-template-columns: repeat(7, 1fr);
                    }

                    @media (min-width: 380px) {
                        grid-template-columns: repeat(8, 1fr);
                    }

                }

                &.food-overview-drink-coffee-container {
                    grid-template-columns: repeat(5, 1fr);
                    gap: 0 38px;

                    @media (max-width: 390px) {
                        grid-template-columns: repeat(4, 1fr);
                    }

                    @media (min-width: 390px) {
                        grid-template-columns: repeat(5, 1fr);
                    }
                }

                .btn {
                    &.btn-drink {
                        padding: 0;
                        display: flex;
                        justify-content: center;
                        align-items: center;

                        &:focus {
                            border: none;
                            outline: none;
                            box-shadow: none;
                        }

                        &:disabled {
                            opacity: 1;
                        }
                    }
                }
            }

            .food-overview-drink-card-suggestion {
                display: flex;
                gap: 4px;
                align-items: center;
                color: $flyellow;

                .food-overview-drink-card-suggestion-title {
                    font-size: 14px;
                    line-height: 20px;
                }
            }
        }
    }

    .progress-calories-table {
        box-shadow: 0px 4px 12px 0px $a-btnSalad;
        background: $bgwhite;
        padding: 12px;
        border-radius: 12px;
        display: flex;
        flex-direction: column;
        gap: 12px;
        margin-top: 20px;
        margin-bottom: 10px;

        .progress {
            background: $a-circleStroke;
            height: 9px;
        }
    }

    .progress-calories-card-header,
    .progress-calories-card-single {
        display: flex;
    }

    .progress-calories-card-body {
        display: flex;
        flex-direction: column;
        gap: 10px;
    }

    .progress-calories-name {
        width: 52%;
        color: $homegrey;
        font-weight: 500;
        width: 52%;
    }

    .progress-calories-eaten,
    .progress-calories-target,
    .progress-calories-stillleft {
        font-size: 14px;
        color: $homegrey;
        font-weight: 500;
    }

    .progress-calories-eaten {
        width: 15%;
    }

    .progress-calories-target {
        width: 18%;
    }

    .progress-calories-stillleft {
        width: 15%;
    }

    .progress-calories-card-body {

        .progress-calories-eaten,
        .progress-calories-target,
        .progress-calories-stillleft {
            font-weight: 700;
            color: $black;
            font-family: $sukhmaitext;
        }
    }

    .progress-calories-card-single {
        margin-bottom: 4px;
    }

    .slick-dots {
        display: flex;
        justify-content: center;
        margin: 0;
        padding: 0;
        list-style-type: none;

        li {
            margin: 0;

            &.slick-active {
                button {
                    background-color: $homegreen;
                }
            }
        }

        button {
            display: block;
            width: 6px;
            height: 6px;
            padding: 0;
            border: none;
            border-radius: 100%;
            background-color: $bgwhite;
            text-indent: -9999px;
        }
    }

    .nutrition-bar-container {
        .slick-track {
            display: flex !important;
            gap: 24px;
            left: -5px !important;
            margin-top: 16px;
        }
    }

    @media (max-width: 324px) {
        .food-overview-banner-container {
            .food-overview-banner-img {
                display: none;
            }

            .food-overview-banner-img-lg {
                display: block;
            }
        }

        .progress-calories-eaten,
        .progress-calories-target,
        .progress-calories-stillleft {
            font-size: 12px;
        }
    }

    .food-log-supplement-goal-container {
        display: flex;
        flex-direction: column;
        gap: 12px;
        padding: 15px 20px;
        margin-bottom: 80px;

        &__heading {
            font-size: 20px;
            font-family: $sukhmaibold;
        }
    }

    .input-modal-dialog__content {
        @media(max-width: 341px) {
            padding: 20px 15px;
        }
    }
}