@import "../../../../../common/commonConstant.scss";

.exercise-log-list-container {
    background-size: contain;
    background-repeat: no-repeat;
    height: 100vh;
    background-image: url('/images/healthAndWellness/exercise-log/exercise-bg.svg');
    background-position: left -118px;

    @media(max-width:376px) {
        background-position: left -93px;
    }

    .log-list-header {
        background-image: url('/images/healthAndWellness/exercise-log/bg-vector.svg');
        background-size: cover;
        background-repeat: no-repeat;
        height: 87px;
        width: 100%;
        display: flex;
        padding: 28px 20px;

        .log-list-header-img {
            width: 32px;
            height: 32px;
            margin-right: 8px;
        }

        .log-list-header-text {
            font-size: 20px;
            line-height: 28px;
            font-weight: 800;
            font-family: $sukhmaibold;
            color: $black;
            display: flex;
            align-items: center;
        }
    }

    .log-list-card-container {

        .log-list-card {
            padding: 0px 20px 3px 20px;
        }

        .log-list-card:nth-child(1) {
            padding-top: 25px;
        }
    }

}