@import '../../../common/commonConstant.scss';

.sop_options {
  display: flex;
  flex-direction: column;
  background: $shadowgrey;
  min-height: 100vh;
  .sop-options-header {
    float: right;
    position: absolute;
    width: 105px;
    right: 0px;
  }

  .sop-plp-container {
    padding: 16px;
    
    .basic_container {
     
      box-shadow: 4px 4px 8px 0px $shadowgrey;
      background: $bgwhite;
      border-radius: 10px;
      margin-bottom:16px;
      .bg_one {
        position: relative;
      }
      .basic_bg_one {
        background-image: url("/images/sop/basic_bg_1.svg"), url("/images/sop/basic_bg_2.svg");
        position: absolute;
        width: 100%;
        height: 100px;
        bottom: 0px;
      }
      .beyond_bg_one {
        background-image: url("/images/sop/beyong_bg_1.svg"), url("/images/sop/beyond_bg_2.svg");
        position: absolute;
        width: 100%;
        height: 100px;
        bottom: 0px;
      }
      .flex_bg_one {
        background-image: url("/images/sop/flex_bg_1.svg"), url("/images/sop/flex_bg_2.svg");
        position: absolute;
        width: 100%;
        height: 100px;
        bottom: 0px;
      }

      section {
        padding: 16px;
        z-index: 9;
        position: relative;
      }

      .header_one {
        font-family: $sukhmaitext;
        font-size: 18px;
        font-weight: 800;
        line-height: 26px;
        letter-spacing: 0px !important;
        text-align: left;
        color: $bggreen1;
      }
      .flex_header {
        color: $dark-green;
      }
      .beyond-header {
        color: $orange2;
      }

      .benefit_details,
      .description_details {
        margin-top: 10px;
        font-family: $sukhmaitext;
        font-size: 14px;
        font-weight: 500;
        line-height: 20px;
        letter-spacing: 0px !important;
        text-align: left;
        color: $black;

        img {
          width: 24px;
          height: 24px;
          margin-right: 10px;
        }
      }
      .profile-btn-purchase {
        font-family: $sukhmaitext;
        width: 100%;
        padding: 10px;
        background: $black;
        color: $bgwhite;
        border-radius: 40px;
        margin-top: 10px;
      }
    }
  }
}