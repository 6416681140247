@import '../../common/commonConstant.scss';

.health-summary-container {

    .health-summary-info-container {
        background: $white;
        border-radius: 16px;
        box-shadow: 0px 4px 12px 0px $shadowgrey3;
        display: flex;
        gap: 10px;
        padding: 11px 21px;
        align-items: flex-start;
        justify-content: flex-start;

        @media (min-width: 768px) {
            justify-content: space-evenly;
          }

        .health-summary-info-wrapper {
            &.carbs{
                width: 128px;
            }
            &.protein, &.food{
                width: 90px;
            }
            &.fat{
                width: 84px;
            }
            &.exercise{
                width: 110px;
            }
            &.time{
                width: 98px;
            }

            display: flex;

            .img-wrapper {
                display: flex;
                align-items: flex-start;
                margin-right: 4px;

                img {
                    object-fit: contain;
                    width: 22px;
                    height: 20px;
                }
            }

            .health-summary-wrapper {
                display: flex;
                flex-direction: column;

                .health-summary-header {
                    display: flex;
                    justify-content: center;
                    align-items: flex-start;
                    gap: 16px;

                    span {
                        margin-bottom: 0;
                    }


                }

                .health-summary-name {
                    font-family: $sukhmaitext;
                    font-size: 14px;
                    font-weight: 700;
                    line-height: 22.76px;
                    color: $gray12;
                }


                .health-summary-value {
                    font-family: $sukhmaitext;
                    font-size: 18px;
                    font-weight: 700;
                    line-height: 29.27px;
                    color: $black;
                }

                .health-summary-unit {
                    font-family: $sukhmaitext;
                    font-size: 14px;
                    font-weight: 500;
                    line-height: 22.76px;
                    color: $gray12;
                }
            }
        }
    }
}