@import "../../../../common/commonConstant.scss";

.asm-progress-ctn {
    border-radius: 12px;
    display: flex;
    flex-direction: column;
    height: 100%;
    margin-bottom: -80px;

    .progress-img {
        background-size: cover;
        background-repeat: no-repeat;
    }

    .progress-footer {
        display: flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;
        padding: 16px 32px;
        transform: translate(0px, -115%);

        .btn1 {
            width: 100%;
            height: 48px;
            display: flex;
            justify-content: center;
            align-items: center;
            padding: 6px 6px;
            border-radius: 38px;
            border: 1px;
            gap: 4px;
            background-color: $bgwhite;
            color: $black;
            text-align: center;
            font-family: $sukhmaitext;
            font-weight: 700;
            font-size: 16px;
            line-height: 20px;
            border: 1px solid $black;
            margin-right: 16px;
        }

        .btn2 {
            width: 100%;
            height: 48px;
            display: flex;
            justify-content: center;
            align-items: center;
            padding: 6px 6px;
            border-radius: 38px;
            border: 1px;
            gap: 4px;
            background-color: $a-green;
            color: $bgwhite;
            text-align: center;
            font-family: $sukhmaitext;
            font-weight: 700;
            font-size: 16px;
            line-height: 20px;
        }
    }
}

.asm-modal-ctn {
    &.retryModalClass {
        .liff-panel.open .select-coupon-panel {
            height: 345px;

            >:nth-child(1) {
                border-radius: 24px 24px 0 0;
                height: 60%;
                padding: 15px 0 0 0;
                background-image: url("/images/healthAndWellness/assessment/assessment_retry_modal.svg");
                background-repeat: no-repeat;
                background-size: cover;

                .hdr {
                    padding: 16px;
                    margin-top: 24px;
                    display: flex;
                    flex-direction: column;
                    justify-content: center;
                    align-items: center;

                    .header-text {
                        font-size: 18px;
                        line-height: 26px;
                        font-weight: 800;
                        text-align: center;
                        font-family: $sukhmaitext;
                        color: $black;
                    }

                    .subheader-text {
                        color: $dark-red2;
                        font-size: 16px;
                        line-height: 24px;
                        font-weight: 700;
                        text-align: center;
                        font-family: $sukhmaitext;
                        width: 300px;
                    }
                }

                >button {
                    display: flex;
                    background-color: $bgwhite;
                    border-radius: 38px;
                    padding: 6px 8px;

                    >svg {
                        width: 28px;
                        height: 28px;
                    }

                }

            }
        }
    }
}