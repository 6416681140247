@import '../../../common/commonConstant.scss';

.foa-register-container {
  display: flex;
  flex-direction: column;
  flex: 1;
  font-family: 'Sukhumvit Set', 'Helvetica', Arial, sans-serif;
  background: $bgpromo;

  .description {
    font-size: 13px;
    color: $gray;
  }

  .center {
    text-align: center;
    padding: 15px;
  }

  >.header {
    padding: 20px 0 10px;
    text-align: center;
  }

  >.body {
    display: flex;
    flex-direction: column;
    flex: 1;
    margin: 15px;
    padding: 15px;
    border-radius: 12px;
    background: $bgwhite;

    .away-button {
      width: 50%;
    }

    .title {
      text-align: center;
      font-weight: 700;
      font-size: 15px;
    }

    .map {
      color: #3a539b;
      border-color: #3a539b;
      background-color: #eef3ff;
      margin: 20px auto;
    }

    .foa-box {
      margin-bottom: 20px;

      .gender-option {
        width: 100%;
        text-align: center;

        .gender-button {
          margin: 0 5%;
          width: 30%;
          height: 44px;
          color: #ffffff;
          border-radius: 22px;
          border: 2px solid #3a539b;
          font-weight: bold;
          font-size: 16px;
          background-color: #3a539b;
        }

        .gender-button-inactive {
          margin: 0 5%;
          width: 30%;
          height: 44px;
          color: #ffffff;
          border-radius: 22px;
          font-weight: bold;
          font-size: 16px;
          border: 2px solid #67686c;
          background-color: #67686c;
        }
      }

      .birthday-option {
        display: inline-flex;
        justify-content: center;
        width: 100%;

        .selector {
          width: 30vw;
          margin: 2px;
        }
      }
    }

    .label-foa {
      font-size: 15px;
      color: #c5c9d3;
    }

    input {
      border-bottom: 1px solid #c5c9d3;
    }
  }

  >.footer {
    width: 100%;
    text-align: center;
    position: fixed;
    bottom: 0;
    padding: 15px;
  }

  .amway-foa-button {
    margin: 0 5%;
    height: 44px;
    color: #ffffff;
    border-radius: 22px;
    border: 2px solid #3a539b;
    font-weight: bold;
    font-size: 16px;
    background-color: #3a539b;

    &:disabled {
      border: 2px solid #53555d;
      background-color: #53555d;
    }
  }

  .amway-foa-button-inactive {
    margin: 0 5%;
    height: 44px;
    color: #ffffff;
    border-radius: 22px;
    border: 2px solid #53555d;
    font-weight: bold;
    font-size: 16px;
    background-color: #53555d;
  }
}

.alert-message {
  font-family: $sukhmaitext;
  color: red;
  margin-bottom: 10px;
}

.input-bars input[type="date"] {
  border: none;
  box-sizing: border-box;
  outline: 0;
  padding: .75rem;
  position: relative;
  width: 100%;
}

input[type="date"]::-webkit-calendar-picker-indicator {
  background: transparent;
  bottom: 0;
  color: transparent;
  cursor: pointer;
  height: auto;
  left: 0;
  position: absolute;
  right: 0;
  top: 0;
  width: auto;
}
.gender-option {
    margin-block: 10px;
  .radio-style {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: flex-start;
  }
  .gender-type {
    position: relative;
    display: flex;
    margin-right: 25px;
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
    text-align: center;
    align-items: center;
  }
  input[type='radio']{
    -webkit-appearance: none;
    display: block;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    border: 2px solid black;
    -webkit-border-radius: 50%;
    -moz-border-radius: 50%;
    border-radius: 50%;
    background-color: #fff;
    height: 22px;
    width: 22px;
  }
  input[type='radio']:checked {
    border: 6px solid #38539A;
  }
  label {
    padding: 5px;
    font-family: $sukhmaitext;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 20px;
    letter-spacing: 0px !important;
    // cursor: pointer;
    // z-index: 90;
    // display: flex;
    // flex-direction: row;
    // justify-content: space-around;
  }
}

.checkbox-cid {
  display: flex;
  align-items: center;
  margin-block: 15px;
  input[type='checkbox']{
    -webkit-appearance: checkbox;
    top: 0;
    left: 0;
    height: 18px;
    width: 18px;
    background-color: transparent;
    border: 2px solid #fff;
    -webkit-border-radius: 3px;
    -moz-border-radius: 3px;
    border-radius: 3px;
  }
  label {
    margin-left: 10px;
  }

}

.foa-create-address-container {
  padding-bottom: 15px;
  display: flex;
  flex-direction: column;
  flex: 1;
  background: $gray4;
  .title {
    font-family: $sukhmaitext;
    font-size: 20px;
    font-weight: 800;
    padding: 15px;
    letter-spacing: 0px !important;
  }
  .body {
    padding: 0px;
    .address-search-container {
      padding: 15px;
      display: flex;
      flex: 1;
      flex-direction: column;
      background-color: #ffffff;
      margin-bottom: 15px;
    }
    .address-search-title {
      display: flex;
      flex: 1;
      flex-direction: row;
      margin-bottom: 10px;
      .address-search-title-text {
        font-family: $sukhmaitext;
        margin-left: 4px;
        font-size: 14px;
        font-weight: 700;
        letter-spacing: 0px !important;
      }
    }
    .location-container {
      flex: 1;
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      margin-bottom: 10px;
    }
    .search-bar-container {
      width: 85%;
      justify-content: flex-start;
      border-width: 1px;
      border-style: solid;
      border-radius: 10px;
      border-color: #EDEDED;
      margin-right: 10px;
      .search-input-box::placeholder {
        font-family: $sukhmaitext;
        box-shadow: none;
        border-bottom: none;
        padding-top: 6px;
        text-align: left;
        font-weight: 700;
        font-size: 14px;
        letter-spacing: 0px !important;
        color: #949494;
        background-image: url('/images/promotion/icon_search_input_bar.png');
        background-repeat: no-repeat;
        background-position: right bottom 70%;
      }
      .search-input-box {
        font-family: $sukhmaitext;
        box-shadow: none;
        border-bottom: none;
        padding-left: 10px;
        padding-top: 6px;
        text-align: left;
        font-weight: 700;
        font-size: 14px;
        letter-spacing: 0px !important;
        color: #2C2C2C;
      }
    }
    .map-button-align {
      flex: 1;
      display: flex;
      flex-direction: row;
      .map-button {
        width: 100px;
        height: 40px;
        border-width: 1px;
        border-radius: 10px;
        border-style: solid;
        background-color: #2c2c2c;
        .map-button-text {
          font-family: $sukhmaitext;
          font-weight: 700;
          font-size: 14px;
          letter-spacing: 0px !important;
          text-align: center;
          color: #ffffff;
          padding-top: 8px;
        }
      }
    }
    .autofill-button-container {
      width: 100%;
      height: 48px;
      border-style: solid;
      border-radius: 40px;
      border-width: 2px;
      border-color: #2c2c2c;
      padding-top: 10px;
      text-align: center;
      .autofill-button-text {
        font-family: $sukhmaitext;
        font-weight: 700;
        font-size: 16px;
        letter-spacing: 0px !important;
      }
    }
    .address-fields-container {
      padding: 15px;
      display: flex;
      flex: 1;
      flex-direction: column;
      background-color: #ffffff;
      .address-fields-title {
        display: flex;
        flex: 1;
        flex-direction: row;
        margin-bottom: 10px;
        .address-fields-title-text {
          font-family: $sukhmaitext;
          margin-left: 4px;
          font-size: 14px;
          font-weight: 700;
          letter-spacing: 0px !important;
       }
     }
     .label-address {
      font-family: $sukhmaitext;
      font-weight: 700;
      font-size: 14px;
      letter-spacing: 0px !important;
     }
     .input-bars {
      height: 40px;
      border-width: 1px;
      border-style: solid;
      border-color: #EDEDED;
      border-radius: 10px;
      margin-block: 10px;
     }
     .input-address::placeholder {
      font-family: $sukhmaitext;
      box-shadow: none;
      border-bottom: none;
      text-align: left;
      height: 40px;
      font-weight: 700;
      font-size: 14px;
      letter-spacing: 0px !important;
      color: #949494;
     }
     .input-address {
      font-family: $sukhmaitext;
      box-shadow: none;
      border-bottom: none;
      padding-inline: 10px;
      text-align: left;
      height: 40px;
      font-weight: 700;
      font-size: 14px;
      letter-spacing: 0px !important;
      color: #2C2C2C;
     }
     .input-date::placeholder {
      font-family: $sukhmaitext;
      box-shadow: none;
      border-bottom: none;
      text-align: left;
      height: 40px;
      font-weight: 700;
      font-size: 14px;
      letter-spacing: 0px !important;
      color: #949494;
      background-image: url('/images/promotion/icon-calendar.svg');
      background-repeat: no-repeat;
      background-position: right top;
     }
     .input-date {
      font-family: $sukhmaitext;
      box-shadow: none;
      border-bottom: none;
      padding-inline: 10px;
      text-align: left;
      height: 40px;
      font-weight: 700;
      font-size: 14px;
      letter-spacing: 0px !important;
      color: #2C2C2C;
     }
    }
  }
    .address-panel-footer {
      background-color: #ffffff;
      width: 100%;
      padding: 20px;
      position: relative;
      box-shadow: 0 -4px 8px 0 rgba(0, 0, 0, 0.1);
    }
    .select-address-footer:disabled {
      width: 100%;
      height: 48px;
      border-style: solid;
      border-width: 1px;
      border-radius: 40px;
      background-color: #D9D9D9;
      text-align: center;
      border-color: #D9D9D9;
      .select-address-text-disabled {
        font-family: $sukhmaitext;
        font-weight: 700;
        font-size: 16px;
        color: #9EA0A3;
      }
    }
    .select-address-footer {
      width: 100%;
      height: 48px;
      border-style: solid;
      border-width: 1px;
      border-radius: 40px;
      background-color: #2C2C2C;
      text-align: center;
      border-color: #2C2C2C;
      .select-address-text-active {
        font-family: $sukhmaitext;
        font-weight: 700;
        font-size: 16px;
        color: #FAFAFA;
      }
    }
}