@import '../../../../common/commonConstant.scss';

.user-info-details {
    text-align: center;

    .user-info-name {
        font-size: 18px;
        font-weight: 800;
        color: $black;
        margin-bottom: 0;
        line-height: 26px;
    }

    .user-info-number {
        font-size: 14px;
        font-weight: 500;
        color: $homegrey1;
        padding: 0 0 5px 0;
        line-height: 20px;
        margin-bottom: 4px;
    }
}

.user-info-details-wrapper {
    display: flex;
    justify-content: center;

    .user-info-details-single-container {
        display: flex;
        align-items: center;
        padding: 0 16px;

        .user-info-details-heading,
        .user-info-details-value {
            font-size: 14px;
            font-weight: 500;
            padding-left: 5px;
        }

        .user-info-details-value {
            color: $homegreen;
        }

        .user-info-details-heading {
            color: $homegrey;
        }
    }

}