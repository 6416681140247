.calendar-loader {
  position: fixed;
  z-index: 9999;
  &.--hide-loading {
    display: none;
  }
  &-content{
    width: 184px;
    height: 184px;
  }
}