@import '../../../../../common/commonConstant.scss';

.goal-setup-linear-row-component {
  background: rgba(242, 247, 241, 1);
  padding: 8px;
  border-radius: 6px;
  text-align: right;
  height: 3.5rem;
  &-left {
    &-text {
      font-size: 14px;
      font-weight: 700;
      font-family: $sukhmaitext;
    }
    &-text-light {
      font-size: 14px;
      font-family: $sukhmaitext;
      color: rgba(113, 125, 150, 1);
    }
  }

  &-right {
    font-size: 14px;
    color: #2c2c2c;
    font-weight: 500;

    &-col1 {
      gap: 4px;

      &-value {
        font-size: 16px;
        font-weight: 700;
        font-family: $sukhmaitext;
      }

      &-unit {
        color: rgba(160, 171, 192, 1);
      }
    }
  }
}
