@import '../../../../../common/commonConstant.scss';

body:has(.goal-setup-wrapper.--no_scroll) {
  overflow: hidden;
}


.goal-setup-card {
  border-radius: 12px;
  margin-bottom: 16px;
  overflow: auto;
  box-shadow: 1px 8px 18px 0px rgba(44, 44, 44, 0.1);
  background: $bgwhite;
  font-family: $sukhmaitext;

  .goal-setup-header {
    background: $bgwhite;
    height: 50px;
    padding: 0 12px;
    gap: 8px;
    position: relative;
    font-size: 18px;
    font-weight: 700;
    font-family: $sukhmaitext;

    .goal-setup-header-left {
      display: flex;
      gap: 8px;
    }

    .goal-setup-header-right {
      display: flex;
    }

    .goal-settings-right-icon {
      position: absolute;
      bottom: 0;
    }
  }

  .goal-setup-body {
    padding: 12px;

    .goal-setup-description {
      font-family: $sukhmaitext;
      font-size: 16px;
      font-weight: 500;
      line-height: 22px;
      color: #a0abc0;
      margin-bottom: 12px;
    }

    .card-body-section-nutrition {
      gap: 12px;

      .card-body-section-nutrition-grid {
        display: grid;
        grid-template-columns: repeat(3, 1fr);
        grid-row-gap: 1px;
        grid-column-gap: 9px;
      }
    }

    .card-body-section-water {
      .goal-setup-linear-row-component {
        background-color: #edf5fa;
      }
    }

    .card-body-section-activity,
    .card-body-section-supplement {
      display: flex;
      flex-direction: column;
      gap: 0.75rem;
    }

    .card-body-section-activity,
    .card-body-section-exercise {
      display: flex;
      flex-direction: column;
      gap: 12px;

      .goal-setup-linear-row-component,
      .goal-setup-linear-row-thumbnail-component {
        background-color: #faf1ed;
      }
    }

    .goal-setup-btn {
      border: 1.5px solid #3c9d47;
      padding: 10px 0 5px 0;
      border-radius: 6px;
      width: 100%;
      background: #fff;
      height: 48px;

      &-typography {
        font-family: $sukhmaitext;
        font-size: 14px;
        font-weight: 700;
        line-height: 20px;
        text-align: left;
      }
    }
  }
}

.goal-setup-exerciseGoal-card {
  padding: 0;
  border-radius: 0;
  margin-bottom: 0;
  gap: 12px;
  overflow: auto;
  box-shadow: none;
  background: #fff;
}

.goal-setup-weightGoal-card {
  .goal-setup-header {
    background: rgba(219, 237, 194, 1);
  }
}

.goal-setup-foodGoal-card {
  .goal-setup-header {
    background: #c8e3a3;
  }
}

.goal-setup-supplementGoal-card {
  .goal-setup-header {
    background: rgba(170, 226, 193, 1);
  }
}

.goal-setup-waterGoal-card {
  .goal-setup-header {
    background: rgba(170, 219, 240, 1);
  }
}

.goal-setup-activityGoal-card {
  .goal-setup-header {
    background: rgba(254, 209, 183, 1);
  }
  .goal-setup-activityGoal-body{
    .goal-setup-description{
      width: 311px;
    }
  }
}

.position-right-12 {
  right: 12px;
}

.position-right-45 {
  right: 45px;
}

.card-body-section-exercise {
  display: flex;
  flex-direction: column;
  gap: 12px;

  .goal-setup-linear-row-component,
  .goal-setup-linear-row-thumbnail-component {
    background-color: #faf1ed;
  }
}