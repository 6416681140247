@import "../commonConstant.scss";

.map-goal-card-container{
    height: 108px;
    padding: 4px 16px;
    border-radius: 16px;
    background: $bgwhite;
    box-shadow: 0px 4px 20px 0px $shadowgrey3;
    display: flex;
    align-items: center;
    justify-content: space-between;

}