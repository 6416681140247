@import '../../../../common/commonConstant.scss';



.modal-open .reward-page {
    filter: blur(8px);
    -webkit-filter: blur(8px);
}

.challenge-reward-details-modal {
    bottom: 0;
    height: initial;
    top: initial;

    .modal-dialog {
        margin: 0;
        max-width: 100%;


        .modal-content {
            border-radius: 0;
            border-top-left-radius: 24px;
            border-top-right-radius: 24px;
            border: none;




            .product-img {
                position: relative;
                bottom: -60px;
            }

            .modal-heading {
                margin: 20px 0;
            }

            .modal-body {
                padding-bottom: 32px;
                position: relative;
                margin-top: 32px;
                padding: 0;

                .product-img {
                    width: 100%;
                    height: 178px;
                    object-fit: contain;
                    top: 50%;
                    left: 50%;
                    transform: translate(-48%, -42%);
                }

                .product-img-container {
                    position: absolute;
                    height: 218px;
                    width: 100%;
                    padding: 0 27px 0 14px;
                }
            }

            .modal-header {
                justify-content: flex-end;
                border-bottom: 0;
                position: absolute;
                width: 100%;
                top: 12px;
                right: 0;
                padding: 0;
            }

            .modal-header button {
                border: none;
                background: none;
                margin: 0;
                height: auto;
                width: auto;
                padding: 0;
            }

            .modal-header button img {
                height: 32px;
                width: 32px;
            }

            .product-card-container {
                box-shadow: 0px 4px 20px 0px $challenge-card-shadow;
                background: $bgwhite;
                padding: 64px 16px 16px 16px;
                border-radius: 16px;
                gap: 8px;
                display: flex;
                flex-direction: column;
                margin: 0 22px 32px 22px;


                .product-title {
                    font-size: 18px;
                    font-weight: 700;
                    font-family: $sukhmaitext;
                    color: $challenge-modal-grey;
                    height: 52px;
                    margin-bottom: 0;
                    margin-top: 12px;
                    overflow: hidden;
                    text-overflow: ellipsis;
                    display: -webkit-box;
                    -webkit-box-orient: vertical;
                    -webkit-line-clamp: 2;

                }

                .product-price {
                    display: flex;
                    font-size: 14px;
                    font-weight: 500;
                    align-items: center;
                    line-height: 21px;
                    gap: 16px;

                    .product-price-left {
                        font-size: 14px;
                        font-weight: 500;
                        color: $black;
                        font-family: $sukhmaitext;
                    }


                }

                .product-price .product-price-right {
                    display: flex;
                    gap: 4px;
                    color: $challenge-success-green;

                    img {
                        width: 18px;
                        height: 18px;
                    }

                    .reward-tag-title {
                        color: $black;

                        &.rejected {
                            color: $challenge-error-text;
                        }

                        &.approved {
                            color: $challenge-success-green;
                        }
                    }
                }

                .product-price-description {
                    font-size: 14px;
                    font-weight: 500;
                    line-height: 16.8px;
                    color: $challenge-modal-grey;
                    font-family: $sukhmaitext;
                }
            }
        }
    }
}