@import '../../../common/commonConstant.scss';

.foa-otp-container {
  display: flex;
  flex-direction: column;
  flex: 1;
  font-family: 'Sukhumvit Set', 'Helvetica', Arial, sans-serif;
  background: $bgpromo;
  > .body {
    display: flex;
    flex-direction: column;
    flex: 1;
    margin: 15px;
    padding: 15px;
    border-radius: 12px;
    background: $bgwhite;
    .away-button {
      width: 50%;
    }
    .title {
      text-align: center;
      font-weight: 700;
      font-size: 15px;
    }
  }
}

.otpinput-container input:placeholder-shown{
  height: 50px;
  border: solid grey 1px;
  border-radius: 5px;
}

.otpinput-container input {
  height: 50px;
  border: solid grey 1px;
  border-radius: 5px;
}
