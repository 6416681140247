@import "../../../common/commonConstant.scss";

.challenge-tag {
    
    border-radius: 8px;

    .tag-button {
        background: $challenge-detail-green;
        color: $challenge-success-green;
        font-size: 14px;
        font-weight: 700;
        font-family: $sukhmaitext;
        display: flex;
        align-items: center;
        padding: 3px 10px;
        border: none;
        border-radius: 8px;
        gap: 4px;

        img {
            width: 16px;
            height: 16px;
        }
    }
    .success-badge {
        background: $challenge-detail-green;
        color: $challenge-success-green;
    }
    .no-member-badge {
        background: $challenge-error-bg;
        color: $challenge-error-text;
        padding:3px 8px 3px 16px;
    }
    .warning-badge {
        background: $challenge-warning-bg;
        color: $challenge-warning-text;
    }
    .ongoing-badge {
        background: $challenge-carousel-bg;
        color: $challenge-ongoing-text;
    }
    .error-badge {
        background: $challenge-error-bg;
        color: $challenge-error-text;
    }
    
}