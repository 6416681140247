@import "../../common/commonConstant.scss";

.quantity-stepper {
	border: 2px solid $black;
	border-radius: 30px;
	width: 100%;
  .increment-container {
		height: 40px;
	}
	.btn-decrease,
	.btn-increase,
	.quanity-text {
		border: 0px;
		font-weight: 800;
		font-size: 20px;
		color: $black;
		display: flex;
		font-family: $sukhmaitext;
		&:disabled {
			border: 0px !important
		}
	}
}