@import "../../../../common/commonConstant.scss";

.btn-light-green {
    background: $quiz-answer-green;
    color: $quiz-answer-text;
    font-size: 16px;
    font-weight: 700;
    font-family: $sukhmaitext;
}

.btn-white,
.btn-light-green,
.btn-light-red {
    color: $homegreen;
    display: flex;
    gap: 16px;
    border-radius: 12px;
    padding: 10px 16px;
    align-items: center;
    line-height: 24px;
    border: 4px solid transparent;

    .answer-type,
    .answer-type-inner {
        border-radius: 50%;
        display: flex;
        align-items: center;
        justify-content: center;
    }

    @media (max-width: 376px) {
        gap: 8px;
    }

    @media (max-width: 324px) {
        gap: 8px;
    }

    .answer-type {
        box-shadow: 1px 1px 1px 0px $quiz-answertype-shadow inset;
        background: $quiz-answertype-bg;
        height: 24px;
        width: 25.34px;

        .answer-type-inner {
            box-shadow: 0px 1px 2px 0px $quiz-answertype-shadow;
            background: $a-serviceCard;
            height: 16.5px;
            width: 16.5px;
            color: $quiz-answer-inner;
            font-size: 14px;
            font-weight: 700;
            font-family: $sukhmaitext;
        }
    }

    .answer-type~span {
        text-align: left;
        width: calc(100% - 24px);
    }

    &.btn-light-green-active {
        background: linear-gradient(135deg, $challenge-success-green1 -39.29%, $challenge-success-green2 82.14%) padding-box,
            linear-gradient(132.38deg, $challenge-success-green3 -14.45%, $challenge-success-green4 122.44%) border-box;
        border: 4px solid transparent;
        box-shadow: 4px 4px 18px 0px $quiz-btn-light-active;
        color: $a-serviceCard;

        .answer-type {
                box-shadow: 1px 2px 2px 0px $quiz-answertype-shadow inset;
                background: $profile-white;
        
                .answer-type-inner {
                    background: $quiz-answertype-inner;
                    box-shadow: 1px 2px 2px 0px $quiz-answertype-shadow;
                    color: $a-serviceCard;
                }
            }
    }
}

.btn-light-red {
    background: linear-gradient(90.84deg, $quiz-btn-red 3.52%, $quiz-btn-red1 97.85%);
    font-size: 16px;
    font-weight: 700;
    font-family: $sukhmaitext;

    &.btn-light-red-active {
        background:
            linear-gradient(90.84deg, $quiz-btn-red 3.52%, $quiz-btn-red1 97.85%) padding-box,
            linear-gradient(91.16deg, $quiz-btn-light-red 2.21%, $quiz-btn-light-red1 101.42%) border-box;
        border: 4px solid transparent;
        box-shadow: 4px 4px 18px 0px $quiz-btn-light-active;
        color: $a-serviceCard;

        .answer-type {
            box-shadow: 1px 2px 2px 0px $quiz-brown inset;

            .answer-type-inner {
                background: $quiz-answer-red-inner;
                box-shadow: 1px 2px 2px 0px $quiz-brown;
                color: $a-serviceCard;
            }
        }
    }
}

.btn-white {
    color: $black;
    padding: 10px 16px 10px 16px;
    border-radius: 10px;
    background:
            linear-gradient(107.74deg, $header-sticky-white 27.91%, $white1 98.01%) padding-box,
            linear-gradient(139.18deg, $bmi-slider-bg-2 16.07%, $white2 93.35%) border-box;
    border: 2.5px solid transparent;
    box-shadow: 4px 4px 18px 0px $profile-square-boxshw;
    font-size: 16px;
    font-weight: 700;
    font-family: $sukhmaitext;

    .answer-type {
        .answer-type-inner {
            background: $quiz-answertype-inner;
            color: $a-serviceCard;
        }
    }
}