@import "../../../common/commonConstant.scss";

body::-webkit-scrollbar:has(.challenge-details) {
    display: none; // Safari and Chrome
}

.challenge-details {
    height: 50vh;
    position: fixed;
    width: 100%;

    @media (max-height: 860px) {
        height: 45vh;
    }

    @media (min-height: 861px) and (max-height: 932px) {
        height: 49vh;
    }

    @media (min-height: 933px) {
        height: 51vh;
    }

    @media screen and (device-width: 375px) and (device-height: 667px) {
        height: 47vh;
    }

    @media screen and (device-width: 414px) and (device-height: 736px) {
        height: 52vh;
    }

    &-image {
        object-fit: cover;
        width: 100%;
        height: 100%;
    }

    &-content {
        height: 100%;
        padding-top: 16px;
    }

    &-title {
        font-family: $sukhmaibold;
        font-size: 18px;
        font-weight: 700;
        line-height: 27px;
        display: -webkit-box;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
        text-overflow: ellipsis;
        white-space: normal;
        overflow: auto;
        overflow-wrap: break-word;

        &-wrap {
            white-space: nowrap;
            overflow: clip;
            text-overflow: ellipsis;
            display: block;
        }
    }

    &-description {
        font-family: $sukhmaitext;
        font-size: 14px;
        font-weight: 500;
        line-height: 21px;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        padding-top: 4px;
    }

    &-info {
        height: 85%;
        overflow-wrap: break-word;
        word-wrap: break-word;
        word-break: break-word;
        overflow: scroll;
        text-overflow: ellipsis;
        font-family: $sukhmaitext;

        ul,
        ol {
            list-style-position: inside;
        }
    }

    &-date-info {
        font-weight: 400;
    }

    &-footer {
        height: 10vh;
        position: fixed;
        bottom: 0;
        width: 100%;
        padding: 16px 16px 32px;
        background-color: $white;
        display: flex;
        justify-content: space-around;
        z-index: 800;

        div {
            flex-grow: 1;
            margin: 0 8px;
        }

        div:first-child {
            margin-left: 0;
        }

        div:last-child {
            margin-right: 0;
        }
    }

    &-seperator {
        img {
            width: 100%;
        }
    }
}