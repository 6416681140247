@import '../../../../common/commonConstant.scss';

.my-badges-cards-container {

    padding: 0px 16px 17px;

    .align-half-cards {
        display: flex;
        justify-content: space-between;
        margin-bottom: 15px;
        gap: 17px;

        .challenge-card-body {
            box-shadow: 0px 4px 20px 0px $challenge-card-shadow;
            border-radius: 16px;
            padding: 16px;
            display: flex;
            flex-direction: column;
            background: $a-serviceCard;
            .badge-rect-container {
                align-items: center;
                .badge-title-font {
                    width: 131px;
                }
            }
        }
    }

    .w-50 {
        width: 50%;
    }
}