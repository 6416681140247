@import '../../../../common/commonConstant.scss';

.home-steps-container {
  padding: 0 16px;

  .vertical-container {
    position: relative;

    ul.card-stacks.stacked {
      height: 460px;
      list-style-type: none;
      margin-top: 20px;
      padding: 0;
      position: relative;
      cursor: pointer;

      &.exercise-stack {
        height: 302px;
      }
    }
  }

  .vertical-container .steps-card-slider-container {
    background: $challenge-carousel-bg;
    box-shadow: 0px 4px 20px 0px $challenge-card-shadow;
    border-radius: 14px;
    margin-bottom: 0px;
    position: absolute;
    bottom: 0;
    left: 50%;
    transform: translate(-50%, 0);
    width: 100%;
    display: flex;
  }

  .vertical-container .steps-card-slider-container.steps-card-slider-container-active {
    position: relative;
    z-index: 4;
  }

  .vertical-container .steps-card-slider-container.steps-card-slider-container:nth-child(2) {
    bottom: -12px;
    z-index: 3;
    width: calc(100% - 20px);
    background: $flgreen1;
    box-shadow: 0px 4px 20px 0px $challenge-card-shadow;
    height: calc(100% - 32px);
  }

  .vertical-container .steps-card-slider-container.steps-card-slider-container:nth-child(3) {
    bottom: -24px;
    z-index: 2;
    width: calc(100% - 40px);
    background: $challenge-swiper-card-bg;
    box-shadow: 0px 4px 20px 0px $challenge-card-shadow;
    height: calc(100% - 48px);
  }

  .vertical-container .steps-card-slider-container .card-slider-left .card-slider-title {
    font-size: 18px;
    font-weight: 700;
    font-family: $sukhmaitext;
    overflow: hidden;
    text-overflow: ellipsis;
    margin-right: 20px;
    white-space: nowrap;
}

.vertical-container .steps-card-slider-container .card-slider-left .card-slider-title-wrapper{
  height: 30px;
}
.vertical-container .steps-card-slider-container .card-slider-left .card-slider-pace {
  height: 56px;
}

.vertical-container .steps-card-slider-container .card-slider-left .card-slider-pace span:first-child {
  font-size: 40px;
  font-weight: 700;
  font-family: $sukhmaitext;
  color: $homegreen;
}

.vertical-container .steps-card-slider-container .card-slider-left .card-slider-pace span:last-of-type {
  font-size: 14px;
  font-weight: 500;
}

.vertical-container .steps-card-slider-container .card-slider-left .card-slider-bottom {
  display: flex;
}

.vertical-container .steps-card-slider-container .card-slider-left .card-slider-bottom .card-slider-distance span:first-child,
.vertical-container .steps-card-slider-container .card-slider-left .card-slider-bottom .card-slider-calories span:first-child {
  font-size: 20px;
  font-weight: 700;
  color: $homegreen;
  font-family: $sukhmaitext;
}

.vertical-container .steps-card-slider-container .card-slider-left .card-slider-bottom .card-slider-distance span:last-of-type,
.vertical-container .steps-card-slider-container .card-slider-left .card-slider-bottom .card-slider-calories span:last-of-type {
  font-size: 14px;
  font-weight: 500;
}

.vertical-container .steps-card-slider-container.steps-card-slider-container:nth-child(2) .card-slider-left,
.vertical-container .steps-card-slider-container.steps-card-slider-container:nth-child(3) .card-slider-left,
.vertical-container .steps-card-slider-container.steps-card-slider-container:nth-child(2) .card-slider-right,
.vertical-container .steps-card-slider-container.steps-card-slider-container:nth-child(3) .card-slider-right {
  visibility: hidden;
}

.vertical-container .card {
  background: none;
  position: relative;
  display: flex;
  align-items: flex-end;
  justify-content: center;
}

.vertical-container .card svg {
  width: 73px;
  height: 73px;
}

.vertical-container .card svg circle {
  stroke-width: 10;
}

.vertical-container .card svg circle:last-of-type {
  stroke-dasharray: 195px;
  stroke-dashoffset: calc(195px - (195px * var(--percent)) / 100);
}

.vertical-container .card .percent {
  display: flex;
  justify-content: center;
  align-items: center;
}

.vertical-container .card .number .progress-value {
  font-size: 16px;
  font-weight: 700;
  font-family: $sukhmaitext;
}

.vertical-container .card .number .progress-value-inner {
  color: $black;
  font-weight: 800;
  font-family: $sukhmaitext;
  font-size: 24px;
  text-align: center;
  line-height: 26px;
}

.vertical-container .card .number .progress-value-unit-container {
  display: flex;
  font-size: 12px;
  font-weight: 500;
  color: $gray12;
  gap: 2px;
}

.vertical-container .card.progress-card-green-1 svg circle {
  stroke: $a-circleStroke;
}

.vertical-container.slide-bottom .steps-card-slider-container {
  position: relative;
  left: 0;
  transform: initial;
  backdrop-filter: none;
}

.vertical-container.slide-bottom .steps-card-slider-container.steps-card-slider-container:nth-child(2),
.vertical-container.slide-bottom .steps-card-slider-container.steps-card-slider-container:nth-child(3) {
  width: 100%;
  height: 100%;
  background: $bgwhite;
}

.vertical-container.slide-bottom .steps-card-slider-container.steps-card-slider-container:nth-child(2) .card-slider-left,
.vertical-container.slide-bottom .steps-card-slider-container.steps-card-slider-container:nth-child(3) .card-slider-left,
.vertical-container.slide-bottom .steps-card-slider-container.steps-card-slider-container:nth-child(2) .card-slider-right,
.vertical-container.slide-bottom .steps-card-slider-container.steps-card-slider-container:nth-child(3) .card-slider-right {
  visibility: visible;
}

.vertical-container .slide-up {
  box-shadow: 4px 4px 18px 0px $challenge-button-tab;
  background: $bgwhite;
  height: 38px;
  width: 38px;
  border-radius: 50%;
  position: absolute;
  bottom: 115px;
  z-index: 10;
  right: 16px;
  display: none;
}

ul.cards li.card {
  background: transparent;
  height: 200px;
  width: 200px;
  border-radius: 16px;
  position: absolute;
  left: 0px;
  box-shadow: 1px 2px 2px 0 #aaa;
  -webkit-transition: all 0.2s cubic-bezier(0.63, 0.15, 0.03, 1.12);
  transition: all 0.2s cubic-bezier(0.63, 0.15, 0.03, 1.12);
}

ul.cards li.card.card-2 {
  z-index: 9;
  -webkit-transition-delay: 0.015s;
  transition-delay: 0.015s;
}

ul.cards li.card.card-3 {
  z-index: 8;
  -webkit-transition-delay: 0.025s;
  transition-delay: 0.025s;
}

ul.card-stacks li.stack ul.cards-down li.card.card-2 .steps-card-slider-container,
ul.card-stacks li.stack ul.cards-down li.card.card-3 .steps-card-slider-container {
  display: none;
}

ul.card-stacks.stacked {}

ul.card-stacks.stacked li.stack ul.cards-down li.card.card-2 .steps-card-slider-container,
ul.card-stacks.stacked li.stack ul.cards-down li.card.card-3 .steps-card-slider-container {
  display: flex;
}

ul.cards.stacked li.card.card-1 {
  left: 440px;
}

ul.cards.stacked li.card.card-2 {
  left: 220px;
}

ul.card-stacks {
  list-style-type: none;
  margin: 20px 0;
  padding: 0;
  position: relative;
  cursor: pointer;
  height: 140px;

  &.exercise-stack {
    height: 156px;
  }

  &.exercise-stack-2 {
    height: 168px;
  }
}

.achievement-container ul.card-stacks {
  height: 132px;
}

ul.card-stacks li.stack ul.cards-down li.card {
  -webkit-transition: all 0.2s cubic-bezier(0.63, 0.15, 0.03, 1.22);
  transition: all 0.2s cubic-bezier(0.63, 0.15, 0.03, 1.22);
  left: 0px;
  -webkit-transition-delay: 0.4s;
  transition-delay: 0.4s;
  background: $challenge-carousel-bg;
  overflow: hidden;
  height: 138px;
  width: 100%;
  border-radius: 16px;
  position: absolute;
  top: 0px;
  backdrop-filter: blur(10px);
}

ul.card-stacks li.stack ul.cards-down li.card.card-1 {
  z-index: 10;
}

ul.card-stacks li.stack ul.cards-down li.card.card-2 {
  z-index: 9;
  transition-delay: 0.015s;
  background: $flgreen1;
  transform: translateY(48px);
  width: calc(100% - 20px);
  left: 10px;
  height: 106px;
}

ul.card-stacks li.stack ul.cards-down li.card.card-3 {
  z-index: 8;
  transition-delay: 0.025s;
  background: $challenge-swiper-card-bg;
  transform: translateY(78px);
  width: calc(100% - 40px);
  height: 90px;
  left: 20px;
}

.achievement-container ul.card-stacks li.stack ul.cards-down li.card {
  height: 94px;
}

.achievement-container ul.card-stacks li.stack ul.cards-down li.card.card-2 {
  height: 60px;
}

.achievement-container ul.card-stacks li.stack ul.cards-down li.card.card-3 {
  height: 44px;
}

ul.card-stacks.stacked li.stack ul.cards-down li.card {
  backdrop-filter: blur(0);
}

ul.card-stacks.stacked {
  height: 460px;
}

.achievement-container ul.card-stacks.stacked {
  height: 324px;
}

ul.card-stacks.stacked li.stack.stack-2 {
  left: 220px;
}

ul.card-stacks.stacked li.stack.stack-3 {
  left: 440px;
}

ul.card-stacks.stacked li.stack ul.cards-down li.card.card-3 {
  top: 320px;
  background: $bgwhite;
  width: 100%;
  transform: translateY(0);
  left: 0;
  height: 138px;
}

ul.card-stacks.stacked li.stack ul.cards-down li.card.card-2 {
  top: 160px;
  background: $bgwhite;
  width: 100%;
  transform: translateY(0);
  left: 0;
  height: 138px;
}

.achievement-container ul.card-stacks.stacked li.stack ul.cards-down li.card.card-2 {
  top: 118px;
}

.achievement-container ul.card-stacks.stacked li.stack ul.cards-down li.card.card-3 {
  top: 234px;
}

.achievement-container ul.card-stacks.stacked li.stack ul.cards-down li.card.card-2,
.achievement-container ul.card-stacks.stacked li.stack ul.cards-down li.card.card-3 {
  height: 94px;
}

ul.card-stacks.stacked~.slide-up {
  display: flex;
  align-items: center;
  justify-content: center;
}

.vertical-slider-container .steps-card-slider-container {
  background: transparent;
}



}