@import '../../../common/commonConstant.scss';

.promotion-create-address-container {
  display: flex;
  flex-direction: column;
  flex: 1;
  background: $bgpromo;
  .description {
    font-size: 13px;
    color: $gray;
  }
  .center {
    text-align: center;
    padding: 15px;
  }
  >.header {
    padding: 20px 0 10px;
    text-align: center;
  }
  >.body {
    display: flex;
    flex-direction: column;
    flex: 1;
    margin: 15px;
    padding: 15px;
    border-radius: 12px;
    background: $bgwhite;
    .away-button {
      font-family: $sukhmaitext;
      width: 50%;
    }
    .title {
      text-align: center;
      font-weight: 700;
      font-size: 15px;
    }
    .map {
      color: #3a539b;
      border-color: #3a539b;
      background-color: #eef3ff;
      margin: 20px auto;
    }
    .address-box {
      margin-bottom: 20px;
    }
    .label-address {
      font-family: $sukhmaitext;
      font-size: 15px;
      color: #c5c9d3;
    }
    input {
      border-bottom: 1px solid #c5c9d3;
    }
    .promotion-create-button-active {
      color: #3a539b;
      border-color: #3a539b;
      background-color: #eef3ff;
    }
    .promotion-create-button-inactive {
      color: #c5c9d3;
      border-color: #c5c9d3;
      background-color: #ffffff;
    }
    .addresses-wrapper {
      display: flex;
      flex-direction: column;
      flex: 1;
      .address-container {
        display: flex;
        padding: 10px 5px;
        margin: 10px 0;
        border-radius: 12px;
        box-shadow: 0px 5px 15px rgba(0, 0, 0, 0.04);
        background: $bgwhite;
      }
      .addresses-selector {
        margin-left: 5px;
      }
    }
  }

  >.footer {
    width: 100%;
    text-align: center;
    position: fixed;
    bottom: 0;
    padding: 15px;
  }
}

.alert-message {
  font-family: $sukhmaitext;
  color: red;
  margin-bottom: 10px;
}

.promotion-create-address-container-new {
  height: 85vh;
  display: flex;
  flex-direction: column;
  flex: 1;
  background: $gray4;
  .title {
    font-family: $sukhmaitext;
    font-size: 20px;
    font-weight: 800;
    letter-spacing: 0px !important;
    padding: 15px;
  }
  .body {
    .address-search-container {
      padding: 15px;
      display: flex;
      flex: 1;
      flex-direction: column;
      background-color: #ffffff;
      margin-bottom: 15px;
    }
    .address-search-title {
      display: flex;
      flex: 1;
      flex-direction: row;
      margin-bottom: 10px;
      .address-search-title-text {
        font-family: $sukhmaitext;
        margin-left: 4px;
        font-size: 14px;
        font-weight: 700;
        letter-spacing: 0px !important;
      }
    }
    .location-container {
      flex: 1;
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      margin-bottom: 10px;
    }
    .search-bar-container {
      width: 85%;
      justify-content: flex-start;
      border-width: 1px;
      border-style: solid;
      border-radius: 10px;
      border-color: #EDEDED;
      margin-right: 10px;
      .search-input-box::placeholder {
        font-family: $sukhmaitext;
        box-shadow: none;
        border-bottom: none;
        padding-top: 6px;
        text-align: left;
        font-weight: 700;
        font-size: 14px;
        letter-spacing: 0px !important;
        color: #949494;
        background-image: url('/images/promotion/icon_search_input_bar.png');
        background-repeat: no-repeat;
        background-position: right bottom 70%;
      }
      .search-input-box {
        font-family: $sukhmaitext;
        box-shadow: none;
        border-bottom: none;
        padding-left: 10px;
        padding-top: 6px;
        text-align: left;
        font-weight: 700;
        font-size: 14px;
        letter-spacing: 0px !important;
        color: #2C2C2C;
      }
    }
    .map-button-align {
      flex: 1;
      display: flex;
      flex-direction: row;
      .map-button {
        width: 100px;
        height: 40px;
        border-width: 1px;
        border-radius: 10px;
        border-style: solid;
        background-color: #2c2c2c;
        .map-button-text {
          font-family: $sukhmaitext;
          font-weight: 700;
          font-size: 14px;
          text-align: center;
          letter-spacing: 0px !important;
          color: #ffffff;
          padding-top: 8px;
        }
      }
    }
    .autofill-button-container {
      width: 100%;
      height: 48px;
      border-style: solid;
      border-radius: 40px;
      border-width: 2px;
      border-color: #2c2c2c;
      padding-top: 10px;
      text-align: center;
      .autofill-button-text {
        font-family: $sukhmaitext;
        font-weight: 700;
        font-size: 16px;
        letter-spacing: 0px !important;
      }
    }
    .address-fields-container {
      padding: 15px;
      display: flex;
      flex: 1;
      flex-direction: column;
      background-color: #ffffff;
      .address-fields-title {
        display: flex;
        flex: 1;
        flex-direction: row;
        margin-bottom: 10px;
        .address-fields-title-text {
          font-family: $sukhmaitext;
          margin-left: 4px;
          font-size: 14px;
          font-weight: 700;
          letter-spacing: 0px !important;
       }
     }
     .label-address {
      font-family: $sukhmaitext;
      font-weight: 700;
      font-size: 14px;
      letter-spacing: 0px !important;
     }
     .input-bars {
      height: 40px;
      border-width: 1px;
      border-style: solid;
      border-color: #EDEDED;
      border-radius: 10px;
      margin-block: 10px;
     }
     .input-address::placeholder {
      font-family: $sukhmaitext;
      box-shadow: none;
      border-bottom: none;
      text-align: left;
      height: 40px;
      font-weight: 700;
      font-size: 14px;
      letter-spacing: 0px !important;
      color: #949494;
     }
     .input-address {
      font-family: $sukhmaitext;
      box-shadow: none;
      border-bottom: none;
      padding-inline: 10px;
      text-align: left;
      height: 40px;
      font-weight: 700;
      font-size: 14px;
      letter-spacing: 0px !important;
      color: #2C2C2C;
     }
    }
  }
}

.address-panel-footer {
  background-color: #ffffff;
  width: 100%;
  padding: 20px;
  position: relative;
  box-shadow: 0 -4px 8px 0 rgba(0, 0, 0, 0.1);
}
.select-address-footer:disabled {
  width: 100%;
  height: 48px;
  border-style: solid;
  border-width: 1px;
  border-radius: 40px;
  background-color: #D9D9D9;
  text-align: center;
  border-color: #D9D9D9;
  .select-address-text-disabled {
    font-family: $sukhmaitext;
    font-weight: 700;
    font-size: 16px;
    color: #9EA0A3;
    letter-spacing: 0px !important;
  }
}
.select-address-footer {
  width: 100%;
  height: 48px;
  border-style: solid;
  border-width: 1px;
  border-radius: 40px;
  background-color: #2C2C2C;
  text-align: center;
  border-color: #2C2C2C;
  .select-address-text-active {
    font-family: $sukhmaitext;
    font-weight: 700;
    font-size: 16px;
    color: #FAFAFA;
    letter-spacing: 0px !important;
  }
}