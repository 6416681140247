@import "../commonConstant.scss";

.custom-calendar {
  &-header {
    font-family: $sukhmaitext;
    color: $black;
    font-size: 16px;
    font-weight: 700;
    line-height: 24px;
    text-align: center;
    margin-bottom: 8px;
  }

  &.--show-navigation {

    .react-calendar__navigation__next-button,
    .react-calendar__navigation__prev-button {
      display: block;
    }
  }

  .react-calendar {
    width: auto;
    font-family: $sukhmaitext;
    border: none;

    &__navigation {
      &__label {
        font-family: $sukhmaitext;
        font-size: 16px;
        font-weight: 700;
        color: $black;

        span:after {
          content: url('/images/common/Arrow-dropdown.svg');
          margin-left: 8px;
          vertical-align: text-top;
        }
      }

      button:enabled:hover,
      button:enabled:focus {
        background-color: transparent;
      }

      button:disabled {
        background-color: transparent;

        img {
          filter: grayscale(1) contrast(0.2) brightness(1.6);
        }
      }

      &__arrow {
        display: none;
      }
    }

    &__navigation &__month-view__weekdays {
      margin-bottom: 8px;

      abbr {
        text-decoration: none;
      }
    }

    &__month-view__days {
      margin-bottom: 8px;
    }

    &__month-view__days__day {
      abbr {
        position: relative;
        z-index: 1;
      }
    }

    &__month-view__weekdays__weekday {

      font-family: $sukhmaitext;
      font-size: 12px;
      font-weight: 700;
      color: $gray12;
    }

    &__tile {
      position: relative;
      font-family: $sukhmaitext;
      font-size: 12px;
      font-weight: 500;
      color: $gray12;
      padding-left: 0;
      padding-right: 0;
      padding-bottom: 12px;

      &:disabled {
        background-color: transparent;
      }

      &:has(.calendar-future-date-tile) {
        color: $gray13;
      }

      &:has(.circular-chart.--inverted) {
        color: $white;

        &:disabled {
          color: $gray12;
        }
      }

      .calendar-future-date-tile {
        height: 32px;
        width: 32px;
        border-radius: 50%;
        background-color: $a-neutral;
        position: absolute;
        top: 18px;
        left: 50%;
        transform: translate(-50%, -50%);
      }

      .calendar-data-pointer-list {
        margin-top: 12px;
        justify-content: center;
        display: flex;
        gap: 4px;

        .calendar-data-pointer {
          height: 6px;
          width: 6px;
          border-radius: 50%;
        }
      }

      &:enabled:focus,
      &:enabled:hover {
        background: none;
      }

      &--now {
        background: none;
        font-weight: 700;
        color: $a-darkgreen;

        .circular-chart svg circle:nth-of-type(2) {
          fill: $mild-green;
        }
      }

      &--active {
        background: none;

        &:enabled {

          &:hover,
          &:focus {
            background: none;
          }
        }
      }
    }
  }
}