@import "../../../common/commonConstant.scss";

body::-webkit-scrollbar:has(.challenge-list-main) {
    display: none; // Safari and Chrome
}

.challenge-list-main {
    display: flex;
    flex-direction: column;
    gap: 10px;
    padding: 30px 10px;
    background-image: url('/images/challenge/list-bg.svg');
    background-repeat: no-repeat;
    background-size: 100%;
    height: 100%;
    background-position: center -80px;
    height: 90vh;
    padding-bottom: 100px;
    overflow-x: hidden;
    overflow-y: scroll;
}