@import "../../../../common/commonConstant.scss";

.profile-card-big {
    display: flex;
    justify-content: space-between;
    align-items: center;
    border: 1px solid $homegrey4;
    background: $bgwhite;
    border-radius: 12px;
    padding: 8px;

    .profile-card-left-bmi-name {
        display: flex;
        align-items: center;

        .profile-card-name {
            font-family: $sukhmaitext;
            font-size: 14px;
            font-weight: 500;
            color: $homegrey;
        }

        img {
            margin-right: 5px;
        }
    }

    .profile-card-left-bmi-description {
        font-family: $sukhmaitext;
        font-size: 14px;
        font-weight: 500;
        color: $homegrey1;
        margin-top: 10px;
        margin-bottom: 16px;
    }
}

.profile-card-small {
    border: 1px solid $homegrey4;
    background: $bgwhite;
    border-radius: 12px;
    padding: 6px;
    height: 100%;

    .profile-card-name {
        font-family: $sukhmaitext;
        font-size: 14px;
        font-weight: 500;
        color: $homegrey;
    }

    .profile-card-middle {
        color: $homegreen1;
        font-family: $sukhmaibold;
        font-size: 18px;
        font-weight: 800;
    }

        .profile-card-middle-description {
            .profile-card-middle-right {
                font-family: $sukhmaitext;
                font-size: 14px;
                font-weight: 500;
                color: $homegrey;
            }
        }

    .profile-card-footer {
        font-family: $sukhmaitext;
        font-size: 14px;
        font-weight: 500;
        color: $homegrey;
    }

    .profile-card-top {
        img {
            margin-right: 5px;
        }
        display: flex;
    }
}

.profile-card-medium {
    border: 1px solid $homegrey4;
    border-radius: 12px;
    padding: 6px;
    background: $bgwhite;
    height: 100%;
    .profile-card-top {
        display: flex;
        align-items: center;

        img {
            margin-right: 5px;
        }
    }

    .profile-card-name {
        font-family: $sukhmaitext;
        font-size: 14px;
        font-weight: 500;
        color: $homegrey;
    }

    .profile-card-middle-description {
        .profile-card-middle-right {
            font-family: $sukhmaitext;
            font-size: 14px;
            font-weight: 500;
            color: $homegrey;
        }
    }

    .profile-card-waistline-img {
        width: 100%;
    }
}

.profile-card-middle-right {
    display: flex;
    align-items: center;
}

.profile-card-middle-description {
    font-weight: 800;
    display: flex;
    justify-content: space-between;
    font-family: $sukhmaibold;
    font-size: 20px;
    color: $homegreen1;
}

.profile-card-waist-center {
    display: flex;
    justify-content: center;
    align-items: center;
    padding-left: 4px;

    .profile-card-middle-right {
        width: 0;
        font-family: $sukhmaitext;
        font-size: 14px;
        font-weight: 500;
        padding-left: 4px;
        color: $homegrey;
    }
}

.first-row {
    display: flex;
    gap: 10px;
    margin-top: 10px;

    .first-col {
        width: 48%;
    }

    .second-col {
        width: 25%;
    }

    .third-col {
        width: 25%;
    }
}

.second-row {
    display: flex;
    gap: 6px;
    margin-top: 10px;
    align-items: stretch;

    .first-col {
        width: 28%;
    }
    .profile-card-small .profile-card-middle-description {
        display: flex;
        justify-content: space-between;
    }

    .second-col {
        width: 36%;
    }

    .third-col {
        width: 36%;
    }

    .waistline-col {
        width: 100%;
    }

    .profile-card-top {
        margin-bottom: 10px;
    }
}

.blue-card-container,
.green-card-container,
.yellow-card-container,
.orange-card-container,
.red-card-container {
    .progress {
        .progress-value {
            font-size: 18px;
            font-weight: 800;
        }
    }

    .first-row {
        .profile-card-middle {
            font-size: 18px;
            font-weight: 800;
        }
    }

    .second-row {
        .profile-card-middle-left {
            font-size: 18px;
            font-weight: 800;
        }

        .profile-card-middle {
            font-size: 20px;
            font-weight: 800;
            span {
                font-size: 12px;
            }
        }
    }
}

.blue-card-container {
    .progress {
        .progress-value {
            color: $profileblue;
        }
    }
}

.green-card-container {
    .progress {
        .progress-value {
            color: $homegreen1;
        }
    }
}

.yellow-card-container {
    .progress {
        .progress-value {
            color: $profileyellow;
        }
    }
}

.orange-card-container {
    .progress {
        .progress-value {
            color: $profileorange;
        }
    }
}

.red-card-container {
    .progress {
        .progress-value {
            color: $profilered;
        }
    }
}
