@import "../common/commonConstant.scss";

.typePage {
  display: flex;
  flex-direction: column;
}

.regisPage {
  &.no-bg {
    background: #fafafa !important;
  }

  &._pt-35 {
    padding-top: 35px !important;
  }

  h2 {
    font-size: 24px;
    color: #38539a;
  }

  small {
    font-size: 14px;
    color: #aaa;
  }

  .cancle-text {
    margin-bottom: 30px;

    >a {
      padding-bottom: 5px;
      border-bottom: 1px solid #707070;

      &:visited {
        color: #2c2c2c;
      }
    }
  }

  .question {
    font-weight: 500;
    font-size: 20px;
    max-width: 60%;
    margin-bottom: 40px;
  }

  &.home {
    background-size: 100% 240px !important;

    .title {
      margin-bottom: 5px;
    }

    .subtitle {
      font-size: 15px;
    }

    form .homeForm {
      // padding-left: 20px;

      label {
        font-size: 14px !important;
      }
    }

    .forget-password {
      margin-top: 10px;
      font-size: 13px;
      // padding-left: 10px;

    }

    .to-public {
      text-decoration: underline;
      color: #000;
      font-weight: bold;

    }
  }



}

.mt-32 {
  margin-top: 32px;
}

.mt-90 {
  margin-top: 90px;
}

.mb-20 {
  margin-bottom: 20px;
}

.mb-32 {
  margin-bottom: 32px;
}

.mb-40 {
  margin-bottom: 40px;
}

.mb-90 {
  margin-bottom: 90px;
}

.mb-120 {
  margin-bottom: 120px;
}

.text-center {
  text-align: center;
}

.top-100 {
  margin-top: 100%;
}

input,
select {
  -webkit-appearance: none;
  position: relative;
  border: none;
  border-bottom: 1px solid #2c2c2c;
  // border-radius: 8px;
  background-color: transparent;
  color: #2c2c2c;
  // padding: 2px 15px;
  font-family: "Sukhumvit Set", "Helvetica", Arial, sans-serif;
  font-size: 16px;
  height: 34px;
  width: 100%;

  &:placeholder-shown {
    border-bottom: 1px solid #e6e6e6;
  }

  &.invalid {
    border-bottom: 1px solid $red;
  }
}

input[type="date"],
input[type="time"] {
  -webkit-appearance: none;
  // text-align: center;
  width: 100%;
  background: url("/images/date.svg") no-repeat !important;
  background-position: 96% 7px !important;
}

input[type="date"]::-webkit-inner-spin-button {
  -webkit-appearance: none;
  display: none;
}

input[type="date"]::-webkit-calendar-picker-indicator {
  -webkit-appearance: none;
  display: none;
}

button {
  font-family: "Sukhumvit Set", "Helvetica", Arial, sans-serif;
}

.input-personal {
  background: #fff;
  border-radius: 8px;
  box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.05);
  padding: 15px;
  margin-bottom: 22px;

  ._header {
    color: #2c2c2c;
    margin-bottom: 16px;
    font-weight: bold;
    font-size: 16px;
    line-height: 24px;
  }
}

.register {
  font-family: "Sukhumvit Set";
  padding: 40px 0;
  // background-repeat: no-repeat;
  // background-position: right top;
  // background-image: url("/images/register-bg.svg");
  background-color: #fafafa;
  text-align: center;

  h1 {
    font-family: "Sukhumvit Set";
    margin-bottom: 15px;
    font-size: 18px;
    font-weight: bold;
  }

  .footer {
    padding: 0 10%;

    .input-checkbox {
      justify-content: center;
    }
  }

  .cancle-text {
    margin-bottom: 30px;

    >a {
      padding-bottom: 5px;
      border-bottom: 1px solid #707070;

      &:visited {
        color: #2c2c2c;
      }
    }
  }
}

.input-checkbox {
  position: relative;
  display: flex;
  align-items: center;
  text-align: left;
  margin-bottom: 32px;

  >._box-check {
    background: #fff;
    display: flex;
    align-items: center;
    justify-content: center;
    // margin-left: 16px;
    border-radius: 50%;
    width: 24px;
    height: 24px;
    border: 1px solid #2c2c2c;
    box-shadow: none;

    &._active {
      border-color: #2c2c2c;
      // background: #38539a;
      // box-shadow: 0px 0px 10px rgba(83, 124, 229, 0.6);
    }
  }

  >a {
    // text-decoration: underline;
    font-weight: bold;
    margin-left: 12px;
    font-size: 16px;
    color: #2c2c2c;
    padding-bottom: 5px;
    border-bottom: 1px solid #707070;
  }
}

.away-form-new {
  width: 100%;
  max-width: 86%;
  margin: 0 auto;
}

.away-form {
  >.form-group {
    text-align: left;
    margin-bottom: 16px;

    &._group {
      position: relative;
      display: flex;
      padding-top: 22px;

      label {
        position: absolute;
        top: 0;
        left: 0;
        z-index: 1;
      }

      input {
        text-align: center;
        margin-right: 10px;
        // max-width: 35px;
      }
    }

    >label {
      display: block;
      font-size: 12px;
    }

    >input {
      width: 100%;
      font-size: 14px;
      padding: 6px 0;
      border: none;
      border-radius: 0;
      border-bottom: 1px solid #2c2c2c;
      max-width: 100%;

      &:placeholder-shown {
        border-bottom: 2px solid #ededed;
      }
    }
  }
}

.away-button {
  display: block;
  margin: 0 auto;
  font-family: "Sukhumvit Set";
  width: 100%;
  height: 44px;
  color: #2c2c2c;
  border-radius: 22px;
  border: 2px solid #2c2c2c;
  font-weight: bold;
  font-size: 16px;
  background-color: #fafafa;

  &.active {
    background-color: #2c2c2c;
    border: 2px solid #2c2c2c;
    color: #fff;
  }

  &:disabled {
    background-color: $bgwhite;
    border: 2px solid $gray2;
    color: $gray2;
  }
}

.Success {
  display: flex;
  flex-direction: column;
  justify-content: center;
  background: url("/images/bg-register2.png") no-repeat right top;
  background-size: contain;
  width: 100%;
  min-height: 100vh;
  margin: 0 auto;
  padding: 0 10%;
  text-align: center;

  h1 {
    font-style: normal;
    font-weight: bold;
    font-size: 24px;
    line-height: 30px;
    margin-bottom: 20px;
  }

  h2 {
    font-size: 18px;
    margin-bottom: 20px;

    &.name {
      margin-bottom: 10px;
    }

    &.away-num {
      font-size: 16px;
      // letter-spacing: 0.08em;
      margin-top: 5px;
    }
  }

  small {
    font-size: 14px;
    color: #aaa;
  }

  >.logoAmway {
    width: 140px;
    margin: 0 auto 22px auto;
    padding-top: 32px;
  }

  >.picture {
    border-radius: 50%;
    overflow: hidden;
    width: 94px;
    height: 94px;
    margin: 0 auto 20px auto;

    >img {
      width: 100%;
    }
  }

  >.detail {
    margin-top: 16px;
    margin-bottom: 32px;
  }

  >.footer {
    margin-bottom: 32px;

    button {
      margin-bottom: 12px;
    }
  }
}

.Condition {
  background: #fafafa;
  width: 100%;
  min-height: 100vh;
  margin: 0 auto;
  padding: 32px 5%;

  .details {
    background: #ffffff;
    border-radius: 12px;
    box-shadow: 0px 1px 10px rgba(0, 0, 0, 0.1);
    padding: 30px;
    font-size: 12px;
    margin-bottom: 16px;
    color: #000;

    h1 {
      font-size: 18px;
      text-align: center;
    }

    h2 {
      font-size: 14px;
    }

    p {
      margin-bottom: 12px;
    }
  }
}

.list-interest {
  margin-top: 22px;
  display: grid;
  grid-template-columns: 1fr 1fr;
  max-width: 80%;
  margin: 0 auto;

  >.item {
    position: relative;
    display: inline-block;
    margin: 0 auto 15px auto;
    min-width: 120px;
    width: 70%;
    vertical-align: top;
    filter: drop-shadow(0px 5px 15px rgba(0, 0, 0, 0.04));

    &._active {
      &::before {
        content: "";
        display: block;
        position: absolute;
        width: 100%;
        height: 100%;
        background: rgba(44, 44, 44, 0.4) url("/images/Active.png") no-repeat;
        background-size: 24px 24px;
        background-position: center;
        z-index: 1;
        border-radius: 12px;
      }
    }

    >.picture {
      min-width: 120px;
      min-height: 120px;
      // border: 3px solid transparent;
      border-radius: 16px;

      // margin-bottom: 4px;
      img {
        height: auto;
        // box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1);
        border-radius: 16px;
        width: 100%;
      }
    }

    >.card-body {
      position: relative;
      margin-top: -15px;
      background-color: #ffffff;
      border-bottom-left-radius: 12px;
      border-bottom-right-radius: 12px;
      font-size: 13px;
      padding: 10px;
      height: 60px;
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }
}

// .detail-box {
//   background: #ffffff;
//   box-shadow: 0px 0.5px 10px rgba(0, 0, 0, 0.05);
//   border-radius: 15px;
//   padding: 24px;
// }

.interest {
  .detail-box {
    margin-bottom: 22px;

    >h1 {
      text-align: center;
      font-size: 18px;
      margin-bottom: 0;
    }

    >p {
      text-align: center;
      font-size: 14px;
    }
  }
}

.secForm {
  display: flex;
  align-items: flex-end;

  label.abo {
    position: relative;

    i {
      margin-left: 6px;
    }
  }

  >img {
    margin-bottom: 3px;
  }

  .inputForm {
    .relative {
      position: relative;

      .question-mark {
        position: absolute;
        right: 2px;
        top: 2px;
      }

      .card {
        position: absolute;
        z-index: 1;
        background: #fff;
        padding: 8px 8px 4px 8px;
        top: 24px;
        right: 24px;
        box-shadow: 0px 7px 20px rgba(0, 0, 0, 0.1);
        border-radius: 8px;
      }
    }
  }

}

.questions {
  margin-top: 22px;
  width: 100%;
  text-align: left;

  >.item {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 2px 16px;
    height: 48px;
    background: #ffffff;
    // box-shadow: 0px 0.5px 10px rgba(0, 0, 0, 0.05);
    border-radius: 25px;
    width: 80%;
    margin: 0 auto 16px auto;
    border: 1px solid #2c2c2c;

    span {
      margin: 0 auto 0 0;
    }

    &:hover,
    &:active {
      background-color: #ededed;
      border: 1px solid #929497;
    }

    // &._active {
    //   &::after {
    //     position: absolute;
    //     top: 0;
    //     right: 0;
    //   }
    // }
  }
}

.abo-text-center {
  width: 150px;
  margin: 32px auto 0 auto;
}

.register-home-button {
  width: 100%;
  background: #ffffff;
  border: none;
  padding: 10px;
  text-align: center;
  // box-shadow: 0px 0.5px 10px rgba(0, 0, 0, 0.05);
  border-radius: 25px;
  margin-bottom: 20px;
  font-size: 18px;
  font-weight: 500;
  border: 1px solid #2c2c2c;

  &:active {
    background: #ededed;
    // border: 2px solid #38539A;
    // box-shadow: 0px 0px 10px rgba(83, 124, 229, 0.6);
  }

  &:focus {
    background: #ededed;
    // border: 2px solid #38539A;
    // box-shadow: 0px 0px 10px rgba(83, 124, 229, 0.6);
  }
}

.hidden-date-input {
  max-height: 0;
  height: 0px;
  opacity: 0;
  padding: 0px !important;
  margin: 0px !important;
  border: none;
  overflow: hidden;
  display: block;
}

.font-size-14 {
  font-size: 14px !important;
}

.custom-date-label {
  height: 30px;
  width: 100%;
  background: url("/images/date.svg") no-repeat !important;
  background-position: 96% 7px !important;
  padding: 2px 15px;
  color: #c3c4c5;
}

.custom-date-label-secform {
  border-bottom: 1px solid #e6e6e6 !important;
  background-color: transparent;
  padding: 0;
  font-size: 16px !important;
  height: 30px;
}

.custom-date-label-awayform {
  width: 100%;
  padding: 0;
  border: none;
  border-radius: 0;
  border-bottom: 2px solid #ededed !important;
}

.custom-date-label {
  &:not(.active) {
    color: #c3c4c5 !important;
  }

  &.active {
    color: #2c2c2c !important;
  }
}

.coupon-list-page {
  height: 100vh;
  padding: 15px 15px;

  .empty-coupon-container {
    height: 100%;
    display: flex;
    flex-flow: column;
    justify-content: center;
    align-items: center;
  }
}

.coupon {
  min-height: 80px;
  font-family: "Sukhumvit Set";
  color: #a66c4a;
  display: flex;
  margin-bottom: 15px;
  // height: 80px;
  filter: drop-shadow(0px 2px 4px rgba(0, 0, 0, 0.1));

  .detail {
    background: #fff1e9;
    font-size: 14px;
    display: flex;
    align-items: center;
    padding: 10px 15px;
    border-radius: 8px;
    width: 65%;
    border-right: 0.6px dashed #38539a;
    mask: radial-gradient(circle 10px at top right, transparent, transparent 50%, transparent 75%, white 75%) intersect,
      radial-gradient(circle 10px at bottom right, transparent, transparent 50%, transparent 75%, white 75%);
  }

  .price {
    background: #fff1e9;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 10px 15px;
    border-radius: 8px;
    width: 35%;
    mask: radial-gradient(circle 10px at top left, transparent, transparent 50%, transparent 75%, white 75%) intersect,
      radial-gradient(circle 10px at bottom left, transparent, transparent 50%, transparent 75%, white 75%);

    .discount {
      font-weight: bold;
      font-size: 14px;
    }

    .valid {
      font-size: 12px;
    }
  }
}

.coupon {
  min-height: 80px;
  font-family: "Sukhumvit Set";
  color: #a66c4a;
  display: flex;
  margin-bottom: 15px;
  // height: 80px;
  filter: drop-shadow(0px 2px 4px rgba(0, 0, 0, 0.1));

  .detail {
    background: #fff1e9;
    font-size: 14px;
    display: flex;
    align-items: center;
    padding: 10px 15px;
    border-radius: 8px;
    width: 65%;
    border-right: 0.6px dashed #38539a;
    mask: radial-gradient(circle 10px at top right, transparent, transparent 50%, transparent 75%, white 75%) intersect,
      radial-gradient(circle 10px at bottom right, transparent, transparent 50%, transparent 75%, white 75%);
  }

  .price {
    background: #fff1e9;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 10px 15px;
    border-radius: 8px;
    width: 35%;
    mask: radial-gradient(circle 10px at top left, transparent, transparent 50%, transparent 75%, white 75%) intersect,
      radial-gradient(circle 10px at bottom left, transparent, transparent 50%, transparent 75%, white 75%);

    .discount {
      font-weight: bold;
      font-size: 14px;
    }

    .valid {
      font-size: 12px;
    }
  }
}

.amwayNumber-container {
  text-align: center;
}

.amwayNumber {
  margin-top: 0;
}

.container-fluid {
  padding-left: 20px;
  padding-right: 20px;
}