@import "../commonConstant.scss";

.rect-badge-container{
    height: 31px;
    border-radius: 8px;
    padding: 5px 14px;
    gap: 8px;
    background: $bgwhite;
    color: $black;
    box-shadow: 0 4px 20px 0 rgba(44, 44, 44, 0.1);
    display: flex;
    justify-content: center;

    .rect-badge-unit-section{
        font-size: 16px;
        line-height: 24px;
        font-weight: 700;
        font-family: $sukhmaitext;
    }

    .rect-badge-icon{
        display: flex;
        justify-content: center;
        align-items: center;
        img{
            width: 12px;
            height: 12px;
        }

    }

    &.header{
        background: $challenge-detail-green;
        color: $challenge-success-green;
    }
    &.error-badge{
        background: $challenge-error-bg;
        color: $challenge-error-text;
    }
    &.warning-badge{
        background: $challenge-warning-bg;
        color:$challenge-warning-text;
    }
    &.success-badge{
        background: $challenge-detail-green;
        color: $challenge-success-green;
    }
    &.no-member-badge{
        background: $challenge-nomember-bg;
        color:$black;
    }
    &.bmi-low{
        background-color: $bmi-low;
        color: $bmi-low-text;
    }
    &.bmi-normal{
        background-color: $bmi-normal;
        color: $bmi-normal-text;
    }
    &.bmi-fat-1{
        background-color: $bmi-fat-1;
        color: $bmi-fat-1-text;
    }
    &.bmi-fat-2{
        background-color: $bmi-fat-2;
        color: $bmi-fat-2-text;
    }
    &.bmi-fat-3{
        background-color: $bmi-fat-3;
        color: $bmi-fat-3-text;
    }
    &.weight-fat-inc{
        background-color: $light-red;
        color: $dark-red3;
    }
    &.weight-fat-dec{
        background-color: $bmi-normal;
        color: $bmi-normal-text;
    }
    &.muscle-inc{
        background-color: $bmi-normal;
        color: $bmi-normal-text;
    }
    &.muscle-dec{
        background-color: $light-red;
        color: $dark-red3;
    }
    &.inactive-badge{
        background-color: $inactive-badge;
        color: $inactive-badge-content;
    }
    &.points-badge{
        background-color: $square-float-color;
        color: $white;
    }
}