@import "../commonConstant.scss";

.custom-dropdown {
  position: relative;
  display: inline-block;

  select {
    display: none;
  }

  .select-styled {
    display: flex;
    justify-content: space-between;
    padding: 4px 4px 4px 8px;
    border: 1px solid $gray12;
    cursor: pointer;
    width: 100px;
    text-transform: uppercase;
    font-weight: 600;
    border-radius: 62px;
    font-size: 14px;
    color: $gray12;
    align-items: center;
    height: 32px;

    &-text {
      font-weight: 500;
      width: 64px;
      text-align: center;
    }

    &.--centered {
      justify-content: center;
    }
  }

  .select-options {
    display: block;
    position: absolute;
    top: 100%;
    left: 0;
    right: 0;
    border: 1px solid $gray12;
    background-color: $bgwhite;
    z-index: 9;
    list-style-type: none;
    padding-left: 0;
    margin-top: 5px;
    border-radius: 12px;
    color: $gray12;

    &.--hide {
      display: none;
    }

    li {
      padding: 5px 18px;
      cursor: pointer;
      font-size: 14px;
      text-transform: uppercase;
      color: $gray12;
      font-weight: 500;

      &:first-child {
        border-top-left-radius: 12px;
        border-top-right-radius: 12px;
      }

      &:last-child {
        border-bottom-left-radius: 12px;
        border-bottom-right-radius: 12px;
      }

      &.active,
      &:hover {
        background-color: $bmigrey;
        color: $gray12;
      }
    }
  }
}