@import "../../common/commonConstant.scss";

.checkbox-container {
  display: block;
  position: relative;
  padding-left: 30px;
  margin-bottom: 12px;
  cursor: pointer;
  // font-size: 22px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0;
    &:checked {
      & ~ .checkmark {
        // background-color: #2196f3;
        border: 2px solid $success;
        svg {
          display: block;
          position: relative;
          top: 4px;
          left: 2px;      
          right: 0;
          bottom: 0;
        }
      }
    }
  }
  .checkmark {
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    margin: auto;
    height: 20px;
    width: 20px;
    // background-color: #eee;
    border-radius: 50%;
    border: 2px solid $gray;
    svg {
      display: none;
    }
  }
  &:active{
    background: transparent;
  }
  &:hover {
    background: transparent;
    input {
      & ~ .checkmark {
        // background-color: #ccc;
      }
    }
  }
}
