@import '../../../common/commonConstant.scss';

.creditcard-pick-container{
  background: $bggray;
  .header{
    text-align: center;
    padding: 25px 0 15px;
    font-size: 20px;
  }
  .body{
    margin: 15px;
    padding: 25px 15px;
    background: $bgwhite;
    box-shadow: 0px 5px 15px rgba(0, 0, 0, 0.04);
    border-radius: 12px;
    .center{
      text-align: center;
      padding: 15px 0;
      margin-bottom: 15px;
    }
    .creditcard-choice {
      font-size: 14px;
      label{
        display: flex;
        .detail-left{
          margin-right: auto;
        }
      }
    }
    hr{
      background: $gray;
    }
    .buttons-container{
      display: flex;
      button{
        &:first-child{
          margin-right: 5px;
        }
      }
    }
  }
  .footer{
    position: fixed;
    bottom: 0;
    // background: $bgwhite;
    border-top-left-radius: 12px;
    border-top-right-radius: 12px;
    text-align: center;
    width: 100%;
    padding: 20px 10px;
    // box-shadow: 0px -5px 15px rgba(0, 0, 0, 0.04);
  }
}