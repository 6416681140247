@import "../../../common/commonConstant.scss";

.phonechange-container {
  max-width: 425px;
  margin: 0 auto;
  .modal-container{
    text-align: center;
  }
  > .header {
    position: relative;
    .header-img {
      width: 100%;
    }
    .header-text {
      position: absolute;
      width: 100%;
      height: 100%;
      top: 0;
      left: 0;
      text-align: center;
      padding-top: 10%;
    }
  }
  > .body {
    .desc {
      width: 65%;
      margin: 0 auto;
      text-align: center;
      margin-top: 20px;
    }

    .custom-input-container {
      max-width: 220px;
      margin: 20px auto;

      .input-label {
        max-width: 220px;
        margin: 0 auto;
        font-size: 13px;
        color: $gray;
      }
      .input-container {
        display: flex;
        font-size: 16px;
        > input {
          border: 2px solid $gray2;
          border-right: 0;
          border-top-left-radius: 8px;
          border-bottom-left-radius: 8px;
          padding: 0 16px;
          box-sizing: content-box;
          &.disable{
            color: $gray7;
          }
        }
        .suffix-button {
          height: auto;
          width: auto;
          border-radius: 0;
          border-top-right-radius: 10px;
          border-bottom-right-radius: 10px;
        }
      }
    }
  }
  > .footer {
    position: fixed;
    bottom: 0;
    left: 0;
    width: 100%;
    display: flex;
    justify-content: center;
    padding: 15px;
  }
}
