@import "../../../../common/commonConstant.scss";

.home-nutrition-bar-container {
    display: flex;
    gap: 12px;
    margin: 20px 0;
    background: linear-gradient(132.59deg, $challenge-slider-bg 38.77%, $challenge-slider-bg1 113.8%);
    box-shadow: 0px 4px 20px 0px $challenge-card-shadow;
    border-radius: 16px;
    padding: 16px;
    padding-bottom: 10px;
}