@import '../commonConstant.scss';

.food-overview-input-modal-container {
    display: flex;
    flex-direction: column;
    gap: 4px;
    padding: 20px;
    font-family: $sukhmaitext;

    @media(max-width:380px) {
        padding: 20px 0;
    }

    &-heading {
        font-family: $sukhmaibold;
        font-size: 20px;
        line-height: 28px;
    }

    &-description {
        font-weight: 700;
        font-size: 16px;
        margin-bottom: 0;

        @media(max-width:341px) {
            font-size: 15.5px;
        }
        @media(max-width:321px) {
            font-size: 15.3px;
        }
    }

    .description-container {
        margin: 8px 0;
        line-height: 24px;
    }
}

.hnw-weight-accordian-container {
    box-shadow: 0px 4px 12px 0px rgba(0, 0, 0, 0.15);
    border-radius: 12px;
    padding: 0 16px;
    background: $bgwhite;

    .hnw-weight-accordian-single {
        padding: 12px 0;
        height: 52px;
        border-bottom: 1px solid $gray13;

        &:last-of-type {
            border-bottom: none;
        }
    }
}