@import "../../../../../common/commonConstant.scss";


.dietary-supplement-no_data {
  display: flex;
  flex-direction: column;
  align-items: center;

  .empty-img-wrapper {
    width: 50%;

    svg {
      transform: none !important;
    }
  }

  &-heading {
    margin-top: 16px;
    font-family: $sukhmaitext;
    font-weight: 800;
    font-size: 20px;
    color: $black;
  }

  &-description {
    font-family: $sukhmaitext;
    font-weight: 500;
    font-size: 14px;
    color: $gray12;
  }
}