@import '../../common/commonConstant.scss';

.goal-card-container {
  .goal-excercise-card-single {
    box-shadow: 1px 8px 18px 0px rgba(44, 44, 44, 0.1);
    background: $white;
    padding: 12px;
    border-radius: 12px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 8px;
    margin-bottom: 16px;

    .goal-excercise-card-col1,
    .goal-excercise-card-col2,
    .goal-excercise-card-col3 {
      display: flex;
    }

    .goal-excercise-card-details {
      width: calc(100% - 96px);
    }

    .goal-excercise-card-col1,
    .goal-excercise-card-col2 {
      justify-content: space-between;
    }

    .goal-excercise-card-col1,
    .goal-excercise-burn-value,
    .goal-excercise-burn-unit {
      font-size: 18px;
      font-weight: 700;
      font-family: $sukhmaitext;
      color: $black;
      line-height: 26px;
    }
    .goal-excercise-card-col1{
      .goal-excercise-card-col1-left, .goal-excercise-card-col1-right{
      font-size: 18px;
      font-weight: 700;
      font-family: $sukhmaitext;
      color: $black;
      line-height: 26px;
      }
      .goal-excercise-card-col1-left{
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        display: inline-block;
        width: 75%;
      }
    }


    .goal-excercise-card-col2 {
      font-size: 14px;
      font-weight: 500;
      line-height: 20px;
      color: $gray12;
      font-family: $sukhmaitext;
      margin-bottom: 2px;
      &-right{
        font-family: $sukhmaitext;
        font-size: 14px;
        font-weight: 500;
        line-height: 20px;
        text-align: left;
        color: $gray12;
      }
      &-left {
        display: flex;
        flex-direction: column;
        color:  $black;
        .menu-name {
          font-family: $sukhmaitext;
          font-size: 18px;
          font-weight: 700;
          line-height: 26px;
          text-align: left;
        }
        .cat-name {
          font-family: $sukhmaitext;
          font-size: 14px;
          font-weight: 500;
          line-height: 20px;
          text-align: left;
          color: $gray12;
        }
      }
    }

    .goal-excercise-card-col3 {
      gap: 4px;
      align-items: center;
      margin-top: 6px;
      font-family: $sukhmaitext;

      img {
        width: 16px;
        height: 16px;
      }
    }

    .goal-excercise-burn-unit {
      color: $black;
      font-weight: 500;
      font-size: 14px;
      line-height: 20px;
      font-family: $sukhmaitext;
    }

    .progress {
      background: none;
      height: 9px;

      .progress-bar {
        background: linear-gradient(90deg, $exerciseDark 0%, $exerciseLight 100%);
        border-radius: 16px;
      }
    }
  }
}
