@import '../../../common/commonConstant.scss';

.justify-left {
  align-items: flex-start;
  justify-content: left !important;
}

.justify-space-between {
  align-items: center;
  justify-content: space-between !important;
}

.sop_profile {
  .empty_profile_image {
    img {
      width: 50%;
      height: 50%;
    }
  }
  .empty_profile_heading {
    font-family: $sukhmaitext;
    font-size: 20px;
    font-weight: 800;
    line-height: 28px;
    letter-spacing: 0px !important;
    text-align: center;
    margin-top: 30px;
    color: $black;
  }
  .empty_profile_subheading {
    font-family: $sukhmaitext;
    font-size: 16px;
    font-weight: 500;
    line-height: 22px;
    letter-spacing: 0px !important;
    text-align: center;
    margin-bottom: 30px;
    color: $black;
  }
  .empty_profile_btn {
    button {
      height: 50px;
    }
  }

  .sop-profile-container {
    padding: 16px;
    padding-bottom: 100px;
    display: block;
  }

  .element-details {
    font-family: $sukhmaitext;
    font-size: 14px;
    font-weight: 700;
    line-height: 20px;
    letter-spacing: 0px !important;
    text-align: left;
    color: $black;
    margin-top: 10px;
  }

  .subheader {
    font-family: $sukhmaitext;
    font-size: 14px;
    font-weight: 700;
    line-height: 20px;
    letter-spacing: 0px !important;
    text-align: left;
    color: $purple;
    display: flex;
    justify-content: center;
    margin-bottom: 10px;
  }

  .sop_profile_tab {
    padding: 0px 16px;
    text-align: center;
    background-color: $bgwhite;
    box-shadow: 4px 4px 8px 0px $shadowgrey;

    .active-tab {
      border-bottom: 5px solid $medium-blue;
      color: $medium-blue;
    }

    .col-5 {
      padding: 16px 0px;

      .profile_tabheader {
        font-family: $sukhmaitext;
        font-size: 16px;
        font-weight: 700;
        line-height: 24px;
        letter-spacing: 0px !important;
        text-align: center;
      }
    }
  }

  .profile-list {
    background: $bgwhite;
    padding: 10px 16px;
    border-radius: 10px;
    box-shadow: 4px 4px 8px 0px $shadowgrey;
    margin-bottom: 15px;

    .sku-status {
      font-family: $sukhmaitext;
      font-size: 12px;
      font-weight: 500;
      line-height: 16px;
      letter-spacing: 0px !important;
      text-align: left;
      color: $gray8;
      padding-left: 15px;

      .profile-status-tag {
        display: flex;
        align-items: center;
        padding: 2px 8px;
        background: $bglightblue;
        border-radius: 24px;
        font-family: $sukhmaitext;
        font-size: 10px;
        font-weight: 700;
        line-height: 14px;
        letter-spacing: 0px !important;
        text-align: left;
        color: $medium-blue;
        margin-bottom: 5px;
        justify-content: center;
      }

      .incart-status {
        color: $green1;
        background-color: $light-green2;
      }

      .payment-pending-status {
        background-color: $light-brown;
        color: $dark-orange;
      }
      .purchased-status {
        background-color: $light-green2;
        color: $dark-green1;
        justify-content: center;
        align-items: center;
        div {
          margin-right: 5px;
          align-items: center;
          display: flex;
          svg {
            width: 13px !important;
          }
        }
      }
      .postpone-status {
        background-color: $light-green;
        color: $dark-green;
        justify-content: center;
        align-items: center;
        div {
          margin-right: 5px;
          align-items: center;
          display: flex;
          svg {
            width: 13px !important;
          }
        }
      }
    }

    .profile-product-name {
      font-family: $sukhmaitext;
      font-size: 16px;
      font-weight: 700;
      line-height: 24px;
      letter-spacing: 0px !important;
      text-align: left;
      color: $black;
    }

    .profile-product-price {
      font-family: $sukhmaitext;
      font-size: 20px;
      font-weight: 800;
      line-height: 28px;
      letter-spacing: 0px !important;
      text-align: left;
      color: $black;

      .product-pvbv {
        font-family: $sukhmaitext;
        font-size: 12px;
        font-weight: 500;
        line-height: 16px;
        letter-spacing: 0px !important;
        text-align: left;
        color: $gray8;
        margin-bottom: 5px;
      }
    }

    .details-anchor {
      font-family: $sukhmaitext;
      font-size: 12px;
      font-weight: 500;
      line-height: 16px;
      letter-spacing: 0px !important;
      text-align: left;
      color: $black;
      display: flex;
      text-decoration: underline;
    }

    .sop-flex-image {
      span {
        position: absolute;
        padding: 5px 10px;
        background-color: $light-green;
        border-bottom-right-radius: 5px;
        border-bottom-left-radius: 5px;
        font-family: $sukhmaitext;
        font-size: 10px;
        font-weight: 700;
        line-height: 14px;
        letter-spacing: 0px !important;
        text-align: left;
        color: $dark-green;
      }
    }

    .solution-image-container {
      .image-box {
        position: relative;
        display: flex;

        img,
        .empty-contianer {
          height: 50px;
          width: 50px;
          margin-top: 5px;
          border-radius: 10px;
        }

        .empty-contianer {
          opacity: 4%;
          background: $gray8;
        }

        .extra-count-container {
          position: absolute;
          top: 0px;
          width: 100%;
          height: 100%;
          background-color: $gray10;
          border-radius: 10px;
          color: $bgwhite;
          font-family: $sukhmaitext;
          font-size: 16px;
          font-weight: 700;
          line-height: 24px;
          letter-spacing: 0px !important;
          text-align: center;
          display: flex;
          align-items: center;
          justify-content: center;
          opacity: 0.8;
        }
      }
    }

    .solution-tag {
      position: absolute;
      border-bottom-right-radius: 5px;
      border-bottom-left-radius: 5px;
      font-family: $sukhmaitext;
      font-size: 10px;
      font-weight: 700;
      line-height: 14px;
      letter-spacing: 0px !important;
      text-align: left;
      padding: 5px 10px;
      z-index: 1;
    }

    .basic-tag {
      background-color: #EEEEC6;
      color: #949434;
    }

    .beyond-tag {
      background-color: #FAE0AE;
      color: #9C5800;
    }
  }

  .profile-btn-purchase {
    padding: 10px 32px;
    background: $black;
    color: white;
    font-family: $sukhmaitext;
    font-size: 14px;
    font-weight: 700;
    line-height: 20px;
    letter-spacing: 0px !important;
    text-align: center;
    border-radius: 40px;
    width: 100%;

    &:disabled {
      background-color: $gray9;
      color: $gray5;
      border: 0px;
    }
  }

  .profile-stepper {
    .stepper-circle {
      width: 10px;
      height: 10px;
      border-radius: 50%;
      background-color: $bglightblue;
    }

    .stepper-circle.notPurchase,
    .stepper-circle.payment_pending {
      border: 2px solid $medium-blue;
    }

    .purchased,
    .compensation {
      background: $medium-blue !important;
    }

    .postpone {
      background-color: $dark-green !important;
    }

    .cancelOne {
      background: $purple !important;
    }

    .stepper-dash {
      width: 2px;
      height: 3px;
      background: $bglightblue;
      vertical-align: middle;
      display: flex;
      position: relative;
      top: 3.2px;
    }
  }

  .profile-list-footer {
    position: fixed;
    width: 100%;
    bottom: 0px;
    background: $bgwhite;
    padding: 10px 16px;
    box-shadow: 0px -4px 8px 0px $shadowgrey2;
    z-index: 1;
    justify-content: space-between !important;

    .footer-price {
      font-family: $sukhmaitext;
      font-size: 20px;
      font-weight: 800;
      line-height: 28px;
      letter-spacing: 0px !important;
      text-align: left;
      color: $black;
    }

    .footer-pvbv {
      font-family: $sukhmaitext;
      font-size: 12px;
      font-weight: 500;
      line-height: 16px;
      letter-spacing: 0px !important;
      text-align: left;
      color: $gray8;
    }

    .profile-footer-btn {
      display: flex;
      justify-content: center;
      align-items: end;
      padding: 10px;
    }
  }
}
.cart-profile-list {
  .sop-plp-container {
    overflow: auto;
  }
  .sop-flex-image {
    span {
      position: relative !important;
    }
  }
  .solution-tag {
    position: relative !important;
  }
}