@import '../commonConstant.scss';

.modal-picker {
  .liff-numpad-panel {
    background-color: $a-backgorund-blur;
    backdrop-filter: blur(5px);

    .container-fluid {
      padding: 0 8px 0 8px;
      height: 44px;
      display: flex;
      align-items: center;
      justify-content: flex-end;
    }

    .header-section {
      .header {
        font-size: 16px;
        margin-top: 20px;
        font-weight: 500;
        text-align: center;
        font-family: $sukhmaitext;
        color: $gray12;
      }
    }

    .modal-picker-wrapper {

      // mask-image: linear-gradient(to bottom, rgba(255, 255, 255, 0.2) 0%, #FFFFFF 20%, #FFFFFF 50%, #FFFFFF 80%, rgba(255, 255, 255, 0.2) 100%) !important;
      .modal-picker-item {
        font-family: $sukhmaitext;
        font-size: 16px;
        font-weight: 500;
        line-height: 22px;
        color: $black;
        text-wrap: nowrap;

        &.--disabled {
          color: $white;
        }
      }

      &>div:last-of-type {
        z-index: -1;
        background: $a-offwhite;

        &>div {
          background: none !important;
        }
      }

      &.--disabled>div:last-of-type {
        background: $gray2;
      }
    }
  }
}