@import "../../../common/commonConstant.scss";

.graph-style {
  padding: 0 8px;

  &.single-data-list .apexcharts-canvas .apexcharts-element-hidden {
    opacity: 1;
    display: inline;
  }

  &.--line {
    .apexcharts-text.apexcharts-xaxis-label:last-child {
      visibility: hidden;
    }
  }

  &.--two-series {
    .apexcharts-series[rel="1"] {
      .apexcharts-bar-area {
        translate: -1px;
      }
    }

    .apexcharts-series[rel="2"] {
      .apexcharts-bar-area {
        translate: 1px;
      }
    }
  }

  .apexcharts-bar-area {
    stroke: transparent;
    filter: none;

    &[val="0"] {
      visibility: hidden;
    }

  }

  .apexcharts-xaxis-tick {
    stroke: transparent;
  }

  .apexcharts-xaxistooltip {

    &::before,
    &::after {
      content: none;
    }

    border: 3px solid $graphgreen;
    border-radius: 50%;
    background-color: transparent;
    padding: 4px;
    transition: none;

    &.apexcharts-active {
      transition: none;
    }
  }

  .apexcharts-gridlines-vertical :last-child {
    display: none;
  }

  .apexcharts-tooltip.apexcharts-theme-light {
    top: 24px !important;
    border: 0;
    box-shadow: none;
    background: none;
  }
}