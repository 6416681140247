@import "./../commonConstant.scss";

.youtube-modal {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: $jet-black;
  box-sizing: border-box;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  z-index: 1000;

  .back-modal-button {
    position: absolute;
    top: 12px;
    left: 12px;
    background-color: $jet-black;
    align-self: flex-end;
    border: none;
    padding: 8px 12px;
    border-radius: 4px;
    cursor: pointer;
    margin-bottom: 10px;
  }

  iframe {
    width: 100%;
    height: 248px;
    border: none;

    @media(max-width:395px){
      height:220px;
    }
  }
}