@import "../commonConstant.scss";

.card-circle {
    background: none;
    position: relative;

    .percent-circle {
        display: flex;
        justify-content: center;
        align-items: center;
        position: relative;

        svg {
            width: 190px;
            height: 190px;
            position: relative;
            transform: rotate(-90deg);

            circle {
                fill: none;
                width: 100%;
                stroke-linecap: round;
                stroke-width: 12;
                stroke: $a-circle-progress-bg;
                height: 100%;

                &:last-of-type {
                    stroke-dasharray: 560px;
                    stroke-dashoffset: calc(560px - (560px * var(--percent)) / 100);
                    stroke: url(#cl1);
                }
            }
        }

        .number-circle {
            transform: translate(-50%, -50%);
            top: 50%;
            left: 50%;
            height: 140px;
            width: 140px;
            background: $a-circle-progress-bg;
            box-shadow: 0px 4px 12px 0px $a-btnSalad;
            border-radius: 50%;
            display: flex;
            align-items: center;
            justify-content: center;
            text-align: center;
            position: absolute;

            .progress-value-circle {

                .progress-value-unit-circle {
                    font-size: 16px;
                    font-weight: 700;
                    color: $homegrey;
                    font-family: $sukhmaitext;
                }

                img {
                    position: absolute;
                    top: 50%;
                    left: 50%;
                    transform: translate(-50%, -50%);
                }


                .progress-value-inner-circle {
                    font-size: 24px;
                    font-weight: 800;
                    color: $a-black;
                    font-family: $sukhmaitext;
                }
            }

        }
    }

    .svg-circle {
        display: flex;
        justify-content: center;
        align-items: center;
        position: relative;

        svg {
            width: 190px;
            height: 190px;
            position: relative;
            transform: rotate(0deg);

            circle {
                fill: none;
                width: 100%;
                stroke-linecap: round;
                stroke-width: 12;
                height: 100%;

                &:last-of-type {
                    stroke-dasharray: 560px;
                    stroke-dashoffset: calc(560px - (560px * var(--percent)) / 100);
                }
            }
        }

        .number-circle {
            transform: translate(-50%, -50%);
            top: 50%;
            left: 50%;
            height: 140px;
            width: 140px;
            background: $a-circle-progress-bg;
            box-shadow: 0px 4px 12px 0px $a-btnSalad;
            border-radius: 50%;
            display: flex;
            align-items: center;
            justify-content: center;
            text-align: center;
            position: absolute;

            .progress-value-circle {

                .progress-value-unit-circle {
                    font-size: 16px;
                    font-weight: 700;
                    color: $homegrey;
                    font-family: $sukhmaitext;

                }

                img {
                    position: absolute;
                    top: 50%;
                    left: 50%;
                    transform: translate(-50%, -50%);
                }


                .progress-value-inner-circle {
                    font-size: 24px;
                    font-weight: 800;
                    color: $greater-max-val;
                    font-family: $sukhmaitext;
                }
            }

        }
    }

    .outer-percent-circle {
        display: flex;
        justify-content: center;
        align-items: center;
        position: relative;

        svg {
            width: 200px;
            height: 200px;
            position: relative;
            transform: rotate(-90deg);

            circle {
                fill: none;
                width: 100%;
                stroke-linecap: round;
                stroke-width: 14;
                stroke: #E4E8F1;
                height: 100%;

                &:last-of-type {
                    stroke-dasharray: 560px;
                    stroke-dashoffset: calc(560px - (560px * var(--percent)) / 100);
                    stroke: var(--stroke-color);
                }
            }
        }

        .number-centered {
            display: flex;
            align-items: center;
            justify-content: center;
            text-align: center;
            position: absolute;
            transform: translate(-4%, -2%);

            .progress-value-circle {

                .progress-value-inner {
                    font-weight: 800;
                    font-family: $sukhmaitext;
                    font-size: 24px;
                    text-align: center;
                    line-height: 26px;
                    padding-top: 5px;
                }

                .progress-value-unit-container {
                    display: flex;
                    font-size: 14px;
                    font-weight: 500;
                    color: $a-black;
                    gap: 2px;

                    .health-log-food-card-nutrition-single-info-title,
                    .health-log-food-card-nutrition-single-info-goal,
                    .health-log-food-card-nutrition-single-info-unit {
                        color: $a-black;
                    }
                    .health-log-food-card-nutrition-single-info-unit {
                        margin-left: 3px;
                    }
                }

                .progress-value-unit-circle {
                    font-size: 16px;
                    font-weight: 700;
                    color: $homegrey;
                    font-family: $sukhmaitext;
                }

                .progress-value-inner-circle {
                    font-size: 24px;
                    font-weight: 800;
                    color: $a-black;
                    font-family: $sukhmaitext;
                }
            }

        }

        .color-green {
            color: $hnw-green;
        }

        .color-max {
            color: $greater-max-val;
        }
    }

    .inner-circle {
        display: flex;
        justify-content: center;
        align-items: center;
        position: absolute;
        transform: translate(-5.5%, 5.5%);

        svg {
            width: 180px;
            height: 180px;
            position: relative;
            transform: rotate(-90deg);

            circle {
                fill: none;
                width: 100%;
                stroke-linecap: round;
                stroke-width: 8;
                stroke: $white;
                height: 100%;

                &:last-of-type {
                    stroke-dasharray: 450px;
                    stroke-dashoffset: calc(450px - (450px * var(--innerpercent)) / 100);
                    stroke: $greater-max-val;
                }
            }
        }
    }
}