@import '../commonConstant.scss';

.input-modal-dialog {
    border: none;
    background: transparent;
    padding: 0;
    margin: 0;
    z-index: 100;

    &__content {
        position: fixed;
        bottom: 235px;
        background: $bgwhite;
        width: 100%;
        height: 335px;
        border-radius: 24px 24px 0 0;
        padding: 20px;
    }

    &__numpad-container {
        position: fixed;
        bottom: 0;
        height: 250px;
        background: $bgwhite;
        width: 100%;
        border-top-left-radius: 12px;
        border-top-right-radius: 12px;
        transition: height 0.2s ease-in-out;
        max-width: 768px;
        box-shadow: 1px 8px 18px 0px #2C2C2C33;

        &-blank {
          box-shadow: none;
          border-radius: 0;
        }

    }
  }
  
  .input-modal-dialog::backdrop {
    background: $gray16;
    backdrop-filter: blur(5px);
  }