@import "../commonConstant.scss";

.chip {
  background-color: $a-white;
  color: $a-black;
  padding: 0px 18px;
  border-radius: 24px;
  margin-right: 12px;
  margin-bottom: 12px;
  font-size: 16px;
  font-weight: 700;
  cursor: pointer;
  height: 48px;
  min-width: 90px;
  text-align: center;
  display: flex;
  font-family: $sukhmaitext;
  justify-content: center;
  align-items: center;
  line-height: 20px;
}

.green-chip {
  background-color: $a-green;
  color: $a-white;
}