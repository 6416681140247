@import '../../../../common/commonConstant.scss';

.home-composition-no-data-container {
    box-shadow: 0px 4px 20px 0px $challenge-card-shadow;
    border-radius: 16px;
    display: flex;
    justify-content: space-between;
    background: $a-serviceCard;
    width: 50%;
    height: 131px;

    .no-data-wrapper {
        text-align: center;

        &-header {
            display: flex;
            justify-content: center;
            align-items: center;

            &-title {
                font-size: 16px;
                line-height: 24px;
                font-weight: 700;
                color: $black;
                font-family: $sukhmaitext;
                margin-right: 4px;
            }

            &-unit {
                font-size: 14px;
                line-height: 21px;
                font-weight: 500;
                color: $black;
                font-family: $sukhmaitext;
            }
        }

        &-image {
            margin-bottom: 4px;

            img {
                width: 64px;
                height: 64px;
            }
        }
    }
}