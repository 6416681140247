@import "../../../../common/commonConstant.scss";

.shimmer-quiz-tab-single {
    .shimmer-btn-white {
      height: 32px;
      width: 84px;
      margin: 6px 0;
      border-radius: 32px;
      background-size: 200% 100%;
    }
  
    .shimmer {
        animation: shimmer-loading 1s linear infinite alternate;
      }
      
      @keyframes shimmer-loading {
        0% {
          background-color: $challenge-carousel-loader-start;
        }
        100% {
          background-color: $challenge-carousel-loader-end;
        }
      }
  }
  