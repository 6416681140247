@import "../../commonConstant.scss";

.badge-rect-container {
    display: flex;
    flex-direction: column;
    height: 100%;
    justify-content: space-between;

    .badge-title-font {
        font-family: $sukhmaitext;
        font-size: 18px;
        font-weight: 700;
        line-height: 27px;
        text-align: center;
    }

    .badge-img-container {
        width: 100%;
        display: flex;
        justify-content: center;

        .badge-img-bg {
            width: 80px;
            height: 80px;

        }

        .badge-img {
            width: 70px;
            height: 70px;
            position: absolute;
            transform: translate(0%, 7%);
        }
    }

    .badge-carousel-container {
        overflow: hidden;

        .slick-slide {
            width: 80px !important;
        }

        .slick-slide img {
            border: none;
        }

        .slick-track {
            width: 480px !important;
        }

        img {
            height: 80px;
        }
    }

    .badge-values {
        text-align: center;

        .badge-current-values {
            font-family: $gtwalsheimpro;
            font-size: 20px;
            font-weight: 700;
            line-height: 24px;
        }

        .badge-total-values {
            font-family: $gtwalsheimpro;
            font-size: 14px;
            font-weight: 400;
            line-height: 21px;
            margin-right: 4px;
        }

        .badge-unit {
            font-family: $sukhmaitext;
            font-size: 14px;
            font-weight: 500;
            line-height: 21px;
        }
    }

    .category-image-container {
        height: 80px;
        width: 80px;
    }

    .green-font {
        color: $hnw-green;
    }

    .challenge-btn-container {
        height: 31px;

        &.successBtn {
            height: 31px;
            width: 87px;

            .challenge-btn-container-title {
                color: $a-circleStroke;
                font-size: 14px;
                font-weight: 700;
                line-height: 21px;
                text-align: center;
                font-family: $sukhmaitext;
                align-content: center;
            }
        }

    }

    .my-all-badges-container {
        display: flex;
        flex-wrap: wrap;
        row-gap: 16px;
        padding-top: 16px;
        align-items: center;

        .my-badges-single {
            text-align: center;
            width: 33.3333%;
            display: flex;
            flex-direction: column;
            gap: 4px;
            align-items: center;
            height: 165px;

            .progress-count-container {
                display: flex;
                flex-direction: column;
                align-items: center;
                width: 100%;
                gap: 4px;
            }

            .img-badge-container {
                position: relative;

                .badge-img {
                    position: absolute;
                    top: 50%;
                    height: 84px;
                    left: 50%;
                    transform: translate(-50%, -50%);
                }
            }

            .progress-outer {
                width: 85%;

                .progress .progress-bar {
                    border-radius: 24px;
                }
            }


            .user-badge {
                align-self: center;
                width: 45%;
                padding: 2px 8px 0px 8px;
                gap: 2px;
                border-radius: 8px;
                box-shadow: 2px 3px 6px 0px $quiz-btn-green-shdw;
                display: flex;
                justify-content: center;
                align-items: flex-start;

                .user-icon {
                    width: 14px;
                    height: 14px;
                }

                .badge-count {
                    font-family: $gtwalsheimpro;
                    font-size: 12px;
                    font-weight: 400;
                    line-height: 18px;
                    text-align: center;
                }
            }

            img {
                height: 96px;
            }

            p {
                font-size: 14px;
                line-height: 21px;
                font-weight: 400;
                padding: 0 8px;
                font-family: $gtwalsheimpro;
                height: 42px;
                margin-bottom: 4px;
            }
        }
    }
}