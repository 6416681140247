@import '../../common/commonConstant.scss';


.no-result-found-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 29px;
    padding: 16px 20px;

    .empty-img-wrapper {
        width: 50%;

        svg {
            transform: none !important;
        }
    }

    .header {
        margin-top: 16px;
        font-family: $sukhmaibold;
        font-weight: 800;
        font-size: 20px;
        line-height: 28px;
        color: $black;
    }

    .description {
        font-family: $sukhmaitext;
        font-weight: 500;
        font-size: 14px;
        line-height: 20px;
        color: $gray12;
        margin-top: 8px;
    }
}