@import "../commonConstant.scss";

.health-log-empty-item-card {
  box-shadow: 1px 8px 18px 0px $a-card-box-shadow;
  padding: 8px;
  border-radius: 12px;
  background: $a-circle-progress-bg;
  position: relative;
  height: 200px;
  position: relative;
  overflow: hidden;

  .bg-empty-item{
    width: 100%;
    height: 200px;
    margin: 0;
    object-fit: cover;
  }

  &.--food {
    background-image: url('/images/healthAndWellness/foodLog/food-goal-empty.svg');
  }

  &.--water {
    background-image: url('/images/healthAndWellness/foodLog/water-goal-empty.svg');
  }

  &.--activity {
    background-image: url('/images/healthAndWellness/foodLog/activity-goal-empty.svg');
  }
  background-position: center;
}

.add-empty-item-content-section {
  padding: 0;
}

.inner-empty-item-description {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  text-align: center;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;

  h6 {
    font-size: 16px;
    font-weight: 700;
    font-family: $sukhmaitext;
    color: $a-black;
    line-height: 24px;
    padding: 0 24px;
    white-space: pre-wrap;
  }
}

.health-log-empty-item-card-header,
.health-log-empty-item-card-body {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.health-log-empty-item-card-header {
  margin-bottom: 8px;
}

.health-log-empty-item-card-header-left {
  display: flex;
  width: 50%;
  align-items: center;
  gap: 4px;

  h6 {
    font-size: 16px;
    font-weight: 700;
    line-height: 24px;
    font-family: $sukhmaitext;
    margin-bottom: 0;
  }
}

.btn-icon-round {
  height: 24px;
  width: 24px;
  background: $a-neutral;
  border: none;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.health-log-empty-item-card-body {
  gap: 8px;
}

.health-log-empty-item-card-body-left {}

.health-log-empty-item-card-nutrition-single-info-title,
.health-log-empty-item-card-nutrition-single-info-goal,
.health-log-empty-item-card-nutrition-single-info-unit {
  color: $a-gray;
}

.progress-card-green-1.card {
  svg {
    circle {
      stroke: $a-circleStroke;
    }
  }
}

.progress-card-green-2.card {
  svg {
    circle {
      stroke: $a-circleGreen2;
    }
  }
}

.progress-card-green-3.card {
  svg {
    circle {
      stroke: $a-circleGreen3;
    }
  }
}

.health-log-empty-item-card-body-right {
  width: 60%;
  display: flex;
  flex-direction: column;
}

.health-log-empty-item-card-nutrition-single-info {

  .health-log-empty-item-card-nutrition-single-info-inner-container,
  .health-log-empty-item-card-nutrition-single-info-right,
  .health-log-empty-item-card-nutrition-unit-container {
    display: flex;
    font-size: 14px;
  }

  .health-log-empty-item-card-nutrition-single-info-right,
  .health-log-empty-item-card-nutrition-single-info-inner-container,
  .health-log-empty-item-card-nutrition-unit-container {
    gap: 2px;
  }

  .health-log-empty-item-card-nutrition-single-info-inner-container {
    justify-content: space-between;
    margin-bottom: 4px;
    align-items: center;
  }

  .health-log-empty-item-card-nutrition-single-info-left {
    display: flex;
    align-items: center;
    gap: 2px;
  }
}