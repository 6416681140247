@import "../../../../common/commonConstant.scss";

.hnw-goals-supplements-container {
    background: url('/images/healthAndWellness/goals/supplements/supplements_goal_bg.svg');
    background-position: center;
    width: 100%;
    background-size: cover;
    background-attachment: fixed;
    background-repeat: repeat;
    background-position: 0 -100px;
    height: 90vh;
    overflow-y: auto;
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    &::-webkit-scrollbar {
      width: 0;
    }

    .hnw-goals-supplements-header {
      width: 100%;
    }

    .hnw-goals-supplements-details-container {
        padding: 20px;
        margin-top: 24px;
        flex-grow: 1;
        display: flex;
        flex-direction: column;
        gap: 40px;
        align-items: center;

        &__add-card-wrapper {
            width: 100%;
            margin-top: 24px;
        }

        &__suggestions-wrapper {
            display: flex;
            justify-content: center;
            align-items: center;
            gap: 12px;

            ul {
                margin: 0;
                font-size: 14px;
                font-weight: 500;
                font-family: $sukhmaitext;
                line-height: 20px;
                list-style: none;
            }
        }
    }

    .hnw-goals-supplements-footer {
        position: fixed;
        bottom: 0;
        width: 100%;

        &-img {
          width: 100%;
        }

        &-wrapper {
          position: absolute;
          top: 15%;
          bottom: 0;
          left: 0;
          right: 0;
        }

        &-btn {
          font-family: $sukhmaitext;
          width: calc(100% - 32px);
          height: 48px;
          background: $homegreen;
          margin: auto;
          display: block;
          border-radius: 38px;
          border: 1px solid $homegreen;
          font-size: 16px;
          padding: 6px 8px;
          color: $white;
          font-weight: 700;

          &:disabled {
            background-color: $gray13;
            border: 1px solid $gray13;
          }

      &:hover,
      &:active,
      &:focus {
        box-shadow: none;
      }
    }

        &__checkbox-wrapper {
          width: 100%;
          padding: 20px;
          align-items: center;
          display: flex;
          justify-content: flex-start;

          &-btn {
            background: $dark-red2;
            border: 1px solid $dark-red2;
            width: 100%;

        &:hover,
        &:active,
        &:focus {
          box-shadow: none;
        }
          }
        }
      }
}