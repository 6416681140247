@import '../../common/commonConstant.scss';

.search-container {
    position: relative;

    ul {
        &.search-list {
            list-style-type: none;
            padding: 0 10px;
            margin-top: 5px;
            position: absolute;
            z-index: 1;
            background-color: $white;
            border: 1px solid $homegrey3;
            border-radius: 15px;
            // box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.2);
            width: 100%;

            .list-content {
                display: flex;
                align-items: center;
                border-bottom: 1px solid $homegrey3;
            }

            li {
                padding: 8px;
                cursor: pointer;
                font-weight: 500;
                font-size: 16px;
                line-height: 24px;
                min-width: 90%;
                word-wrap: break-word;

                span {
                    font-weight: 700;
                }

                &.show-all {
                    color: $homegreen;

                    span {
                        font-weight: 700;
                        overflow-wrap: break-word;
                    }
                }
            }
        }
    }
}