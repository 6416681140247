@import "../../../common/commonConstant.scss";

body::-webkit-scrollbar:has(.my-dashboard-container) {
    display: none; // Safari and Chrome
}

.my-dashboard-container {
    background-image: url('/images/challenge/team-dashboard-bg.svg');
    background-repeat: no-repeat;
    background-size: 100%;
    height: 100%;

    .profile-img-container {
        position: relative;
        padding-top: 20px;

        .profile-bg {
            position: absolute;
            top: -18px;
            width: 100%;
        }
    }

    .pt-20 {
        padding-top: 20px;
    }

    .my-image-upload-card {
        padding-left: 16px;
        padding-right: 16px;
        padding-bottom: 40px;
    }

    .my-reward-card-container {
        margin-bottom: 108px;
    }

    .home-fixed-button {
        border-radius: 6px;
        z-index: 10;
    }

}