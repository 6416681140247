@import '../commonConstant.scss';

.numpad-container {
    display: flex;
    flex-direction: column;
    align-items: center;

    .container-fluid {
        padding: 0 8px 0 8px;
        height: 44px;
        display: flex;
        align-items: center;
        justify-content: flex-end;
    }
}

.header-btn {
    .btnClass {
        width: 75px;
        height: 28px;
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 6px 6px;
        border-radius: 38px;
        border: 1px;
        gap: 4px;
        background-color: $a-green;
        color: $white;
        text-align: center;
        font-family: $sukhmaitext;
        font-weight: 700;
        font-size: 16px;
        line-height: 20px;

        &:disabled {
            background-color: $homegrey3;
            color: $white;
        }
    }
}

.top-bar {
    display: flex;
    justify-content: center;
    background-color: #fff;
    width: 150px;
    padding: 10px;
}

.input-display {
    padding: 10px;
    width: 100%;
    border: 1px solid #ccc;
    background-color: #fff;
}

.ok-button {
    padding: 10px 20px;
    border: none;
    background-color: #007AFF;
    color: #fff;
    cursor: pointer;
}

.ok-button:hover {
    background-color: #0056b3;
}

.numpad {
    display: flex;
    flex-direction: column;
    background-color: #fff;
    width: 100%;
    border-top: 1px solid #cbd2e0;
    position: relative;
    padding: 16px;
}

.numpad-row {
    display: flex;
    justify-content: center;
    gap: 8px;
    margin-bottom: 8px;
}

.numpad-row:last-child {
    margin-bottom: 0px;
}

.numpad-row button {
    font-size: 24px;
    font-weight: 500;
    padding: 10px 20px;
    border: none;
    border-radius: 6px;
    height: 37.5px;
    background: #f7f9fc;
    cursor: pointer;
    flex: 1;
    display: flex;
    align-items: center;
    justify-content: center;
}