@import '../../../../../common/commonConstant.scss';

.fc-percent {
  position: relative;
  font-family: $sukhmaitext;

  .number,
  img {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);

    .progress-value {
      margin-top: 3px;

      span {
        font-size: 12px;
        text-transform: uppercase;
      }

      .progress-value-inner {
        font-size: 16px;
        color: $a-black;
        font-weight: 700;
        line-height: 14px;
      }

      .progress-value-unit {
        font-size: 14px;
        font-weight: 500;
        color: $homegrey;
        line-height: 16px;
      }
    }
  }
}