@import '../../../../../common/commonConstant.scss';

.add-menu-form-container {
  .dotted-divider {
    border: 1px dashed $gray13;
    margin-top: 24px;
  }

  .add-menu-row {
    gap: 24px;
    margin-bottom: 24px;
  }

  .add-img-section {
    width: 96px;
    height: 96px;
    border-radius: 6px;
    margin-bottom: 16px;
    ul {
      list-style: none;
    }
  }

  .add-menu-form {
    display: flex;
    flex-direction: column;
    gap: 16px;
  }

  // .input-field-section .error-input {
  //     border: 2px solid $dark-red2 !important;
  // }

  // .input-field-section .success-input {
  //     border: 2px solid $homegreen !important;
  // }

  .input-field-section {
    display: flex;
    flex-direction: column;
    gap: 4px;
  }

  .input-field-section label {
    font-size: 16px;
    font-weight: 500;
    font-family: $sukhmaitext;
    width: 100%;
    display: block;
    line-height: 22px;
    margin-bottom: 0px;
  }

  .input-field-section .required-field {
    color: $dark-red2;
  }

  .input:placeholder-shown,
  select:placeholder-shown {
    border-bottom: none;
  }

  .input-field-section input {
    border: 1px solid $gray13;
    padding: 8px;
    border-radius: 6px;
    font-size: 16px;
    font-weight: 500;
    width: 100%;
    font-family: $sukhmaitext;
    border: 1px solid $gray13;
    caret-color: transparent;
  }

  .input-field-section .select-styled {
    border: 1px solid $gray13;
    height: 40px;
    border-radius: 6px;
    width: 100%;
    padding-left: 8px;
  }

  .input-field-section .input-with-unit-section {
    display: flex;
    border: 1px solid $gray13;
    border-radius: 6px;
    align-items: center;
    justify-content: space-between;
    gap: 8px;
    padding: 2px 8px;

    &.error-input {
      border: 2px solid $dark-red2 !important;
    }

    &.success-input {
      border: 2px solid $homegreen !important;
    }
  }

  .input-field-section .input-with-unit-section input {
    border: none;
    padding: 0;
  }

  .input-field-section .input-with-unit-section .input-fixed-unit {
    font-size: 16px;
    font-weight: 500;
    color: $gray12;
    font-family: $sukhmaitext;
  }

  .input-group-section {
    display: flex;
    flex-wrap: nowrap;
    gap: 16px;
    width: 100%;
  }

  .input-group-section .input-field-section {
    width: 50%;
  }

  .add-menu-form-title {
    font-size: 20px;
    line-height: 28px;
    font-weight: 800;
    font-family: $sukhmaibold;
    color: $black;
    margin-top: 24px;
  }

  .nutrition-info-container {
    padding: 16px;
    border-radius: 12px;
    background-color: $homewhite;

    .nutrition-info-title {
      font-size: 16px;
      line-height: 24px;
      font-weight: 700;
      font-family: $sukhmaitext;
      color: $black;
      margin-bottom: 8px;
    }

    .nutrition-info {
      display: flex;
      justify-content: space-between;
      margin-bottom: 8px;

      &-left {
        img {
          width: 20px;
          height: 20px;
        }

        span {
          margin-left: 8px;
          font-size: 16px;
          line-height: 22px;
          font-weight: 500;
          font-family: $sukhmaitext;
          color: $gray12;
        }
      }

      &-right {
        font-size: 16px;
        line-height: 22px;
        font-weight: 500;
        font-family: $sukhmaitext;
        color: $black;
      }
    }

    .nutrition-info:last-child {
      margin-bottom: 0;
    }
  }

  .add-menu-btn {
    height: 48px;
    width: 100%;
    padding: 6px 8px;
    border-radius: 38px;
    font-size: 16px;
    font-weight: 700;
    line-height: 20px;
    font-family: $sukhmaitext;
    display: flex;
    justify-content: center;
    align-items: center;
    color: $white;
    background-color: $homegreen;
    border: none;
    margin: 84px 26px 24px 26px;

    &.disabled-btn {
      background-color: $gray13;
    }
  }

  .dropdown-content-items {
    img {
      max-width: 100px;
      height: 30px;
    }
  }
}

.ant-modal-body {
  background: $gray9;
}

.reactEasyCrop_Container {
  height: 100%;

  img {
    width: 100% !important;
  }
}

.ant-btn-default {
  height: 48px;
  width: 100%;
  padding: 6px 8px;
  border-radius: 38px;
  font-size: 16px;
  font-weight: 700;
  line-height: 20px;
  font-family: $sukhmaitext;
  display: flex;
  justify-content: center;
  align-items: center;
  color: $white;
  background-color: $dark-red2;
  border: none;

  span {
    display: none;
  }

  &::after {
    content: $btn-default;
  }
}

.ant-btn-primary {
  height: 48px;
  width: 100%;
  padding: 6px 8px;
  border-radius: 38px;
  font-size: 16px;
  font-weight: 700;
  line-height: 20px;
  font-family: $sukhmaitext;
  display: flex;
  justify-content: center;
  align-items: center;
  color: $white;
  background-color: $homegreen;
  border: none;

  span {
    display: none;
  }

  &::after {
    content: $btn-primary;
  }
}

.ant-modal-footer {
  display: flex;
  padding: 20px 16px;
  flex-shrink: 0;
  background-image: url('/images/healthAndWellness/foodLog/add-menu-btns-bg.svg');
  width: 100%;
  height: auto;
  align-items: center;
  padding-top: 30px;
  background-color: #6c6c6c;
  border: none;
  background-repeat: no-repeat;
  background-size: cover;
}

.ant-modal-body {
  padding: 0;
  flex: 1;
  background-color: $gray9;
  margin: 80px 29px;
}

//to do -- check action icons
.ant-modal-close-x,
.img-crop-control,
.ant-upload-list-item-actions,
.ant-slider-handle {
  display: none !important;
}

//to do
.ant-modal-content {
  height: calc(100vh + 7px);
  display: flex;
  flex-direction: column;
  background-color: #6c6c6c;
}

.ant-modal {
  position: unset;
  width: 100%;
  max-width: 100%;
  padding: 0;
  margin: 0;
}

.img-crop-container {
  height: 100% !important;
  overflow-y: auto;
  position: absolute;
  width: 100%;
}

.ant-upload-list-picture .ant-upload-list-item-error,
.ant-upload-list-picture-card .ant-upload-list-item-error {
  border-color: none;
  border: none;
  border-radius: 6px;
}

.ant-upload-list-item-info {
  border-radius: 6px;
}

.ant-upload-list-item:hover {
  pointer-events: none;
}

.add-menu-form-error {
  font-size: 12px;
  line-height: 16px;
  font-weight: 500;
  font-family: $sukhmaitext;
  color: $dark-red2;
}

.ant-modal-header {
  position: absolute;
  top: 15px;
  padding: 17px 24px;
  left: 35%;
  z-index: 999;
  background: none;
  color: $white !important;
  border-bottom: none;
  font-size: 20px;
  font-weight: 800;
  line-height: 28px;
  font-family: $sukhmaibold;

  &::after {
    content: $header;
  }

  .ant-modal-title {
    display: none;
  }
}

.ant-upload.ant-upload-select-picture-card {
  width: 96px;
  height: 96px;
  border-radius: 6px;
}

.android-dropdown {
  position: relative;

  .android-dropdown-menu {
    border-radius: 12px;
    box-shadow: 1px 0px 34px 0px #2c2c2c33;
    padding: 0;
    width: 244px;
    overflow: hidden;
    position: absolute;
    z-index: 1;
    .list-group-item {
      display: flex;
      align-items: center;
      justify-content: space-between;
      font-size: 17px;
      font-weight: 400;
      font-family: $sukhmaitext;
      color: $black;
    }
  }
}
