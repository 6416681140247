@import '../commonConstant.scss';

.hnw-picker-container {
  &__overlay-row {
    height: 36px;
    margin-top: -18px;
    position: absolute;
    top: 50%;
    left: 0px;
    width: 100%;
    pointer-events: none;
  }

  .container-fluid {
    padding: 0 8px 0 8px;
    height: 44px;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    border-bottom: 1px solid $jet-black;
  }

  .header-section {
    .header {
      font-size: 16px;
      line-height: 22px;
      font-weight: 500;
      text-align: center;
      font-family: $sukhmaitext;
      color: $gray12;
    }
  }

  .modal-picker-wrapper {

    .modal-picker-column:nth-child(1),
    .modal-picker-column:nth-child(2),
    .modal-picker-column:nth-child(3) {
      flex: initial !important;
    }

    .modal-picker-column:nth-child(1),
    .modal-picker-column:nth-child(3) {
      width: 30%;
    }

    .modal-picker-item {
      font-family: $sukhmaitext;
      font-size: 16px;
      font-weight: 500;
      line-height: 22px;
      color: $black;
    }

    &>div:last-of-type {
      z-index: -1;
      background: $a-offwhite;

      &>div {
        background: none !important;
      }
    }
  }
}