@import './commonConstant.scss';

.line-modal {
  .modal {
    position: fixed;
    top: 0;
    left: 0;
    z-index: 999;
    display: block;
    width: 100%;
    height: 100%;
    overflow-x: hidden;
    overflow-y: auto;
    outline: 0;
    backdrop-filter: blur(15px);
    background-color: $challenge-overlay-bg;
  }

  // Shell div to position the modal with bottom padding
  .modal-dialog {
    position: relative;
    width: auto;
    margin: 24px;
    // allow clicks to pass through for custom click handling to close modal
    pointer-events: none;

    // When fading in the modal, animate it to slide down
    .modal.fade & {
      transition: transform 0.3s ease-out;
      transform: translate(0, -50px);
    }

    .modal.show & {
      transform: none;
    }
  }

  .modal-dialog-scrollable {
    height: calc(100% - 0.5rem * 2);

    .modal-content {
      max-height: 100%;
      overflow: hidden;
    }

    .modal-body {
      overflow-y: auto;
    }
  }

  .modal-dialog-centered {
    display: flex;
    align-items: center;
    min-height: calc(100% - 0.5rem * 2);
  }

  // Actual modal
  .modal-content {
    position: relative;
    display: flex;
    flex-direction: column;
    width: 100%;
    color: $jet-black;
    pointer-events: auto;
    background-color: $white;
    background-clip: padding-box;
    border: 1px solid $modal-shadow;
    border-radius: 0.5rem;
    outline: 0;
  }

  // Title text within header
  .modal-title {
    margin-bottom: 0;
    line-height: 1.5;
  }

  .modal-body {
    position: relative;
    // Enable `flex-grow: 1` so that the body take up as much space as possible
    // when there should be a fixed height on `.modal-dialog`.
    flex: 1 1 auto;
    padding: 1rem;
  }
}
