@import "../commonConstant.scss";

.card-slider-container {
    gap: 15px;
    height: 100%;
    .card-slider-left {
        display: flex;
        padding: 7px 16px 7px 24px;
    }
    .card-slider-right {
        width: calc(100% - 80px);
        display: flex;
        flex-direction: column;
        justify-content: center;
        .card-slider-right-title {
            font-size: 16px;
            font-weight: 500;
        }
        .card-slider-right-subtitle {
            font-size: 20px;
            font-weight: 700;
            font-family: $sukhmaitext;
        }
    }

}