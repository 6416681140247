@import '../../../../common/commonConstant.scss';


.home-nutrition-loader-container{
    .steps-skeleton-container {
      display: flex;
      gap: 10px;
      overflow: hidden;
      .steps-skeleton-card-container {
        display: flex;
        gap: 8px;
        flex-shrink: 0;
        padding:10px;
        height: 120px;
        flex-direction: column;
      }
      .steps-skeleton-card-container:first-child {
        flex: 0 0 100%;
      }
      .skeleton-card-image {
        height: 120px;
        border-radius: 16px;
      }
      .skeleton {
        animation: skeleton-loading 1s linear infinite alternate;
      }
      @keyframes skeleton-loading {
        0% {
          background-color: $challenge-carousel-loader-start;
        }
        100% {
          background-color: $challenge-carousel-loader-end;
        }
      }
    }
  }