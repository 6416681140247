@keyframes spinner {
    0% {
        transform: rotate(0deg);
    }

    100% {
        transform: rotate(360deg);
    }
}

.loading-spinner {
    width: 50px;
    height: 50px;
    border: 10px solid #f3f3f3;
    /* Light grey */
    border-top: 10px solid #383636;
    /* Blue */
    border-radius: 50%;
    animation: spinner 1.5s linear infinite;
}

.spinner-container {
    display: grid;
    justify-content: center;
    align-items: center;
    height: 350px;
}

.loader-wrapper {
    position: fixed;
    /* position: absolute; */
    opacity: 0.7;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;

    display: flex;
    align-items: center;
    justify-content: center;

    background-color: rgba(255, 255, 255, 0.7);
}