@import '../../../../../common/commonConstant.scss';

.home-calendar-home-container {
  background: url('/images/healthAndWellness/foodLog/bg-profile.svg') no-repeat;
  background-position-y: -80px;
  background-size: contain;

  .profile-banner-container {
    position: relative;
    height: 156px;

    .profile-img {
      position: absolute;
      bottom: 0;
      left: 50%;
      transform: translate(-50%, 25%);

      .profile-img-dimensions {
        width: 100px;
        height: 100px;
        border-radius: 50%;
      }
    }
  }

  .home-calendar-home-container-card {
    background-color: $white;
    border-radius: 24px 24px 0 0;
    padding: 32px 20px 40px 20px;
    width: 100%;
    height: 80%;

    .graph-header {
      display: flex;
      justify-content: space-between;
      align-items: center;
    }

    .header-typography {
      font-family: $sukhmaitext;
      margin: 12px 0;
      font-size: 18px;
      font-weight: 700;
      line-height: 26px;
      color: $black;
    }

    .health-summary-info-card {
      margin-top: 16px;
    }

    .health-summary-info-card-header,
    .health-record-card-header,
    .health-summary-result-card-header {
      font-size: 18px;
      line-height: 26px;
      font-weight: 700;
      font-family: $sukhmaitext;
      color: $black;
      margin: 16px 0px;
    }

    .health-recording-card,
    .health-summary-result-card {
      display: flex;
      gap: 16px;
      margin-top: 16px;
    }

    .home-calendar-container {
      display: flex;
      margin: 12px 0;
      gap: 12px;

      &.two-cards>div {
        width: 50%;
      }

      &>div {
        flex-grow: 1;
      }
    }
  }
}