@import "../../../common/commonConstant.scss";

body:has(.food-list-page.--no_scroll) {
  overflow: hidden;
}

.food-calories-container {
  .home-title {
    font-weight: 700;
    font-size: 16px;
    align-items: center;
    display: flex;
    color: $black;
    font-family: $sukhmaitext;
    margin: 2px 0;
  }

  .home-title span {
    padding-left: 5px;
  }

  .home-title .green {
    color: $homegreen !important;
  }

  .home-header {
    background: $white;
    padding-top: 4px;
    padding-bottom: 2px;
  }

  .home-header h6 {
    font-family: $sukhmaitext;
    font-size: 17px;
    font-weight: 800;
    margin-bottom: 10px;
  }

  .home-header p {
    font-family: $sukhmaitext;
    font-size: 12px;
    color: $gray1;
    font-weight: 500;
    line-height: 10px;
  }

  .search-input {
    padding-left: 36px;
    height: 40px;
    border-radius: 12px;
    border: 1px solid $gray13;
  }

  .search-input:before {
    content: url("/images/healthAndWellness/foodCalories/icon-search.png");
    position: absolute;
    left: 50%;
    margin-left: -8px;
    top: -16px;
  }

  .search-input-container {
    position: relative;
  }

  .search-input-container input::placeholder {
    color: $gray13;
  }

  .search-input-container input:focus {
    outline: none;
    box-shadow: none;
  }

  .search-input-container .search-img,
  .find-nutritional-input-container .search-img {
    position: absolute;
    top: 50%;
    left: 8px;
    transform: translate(0px, -50%);
  }

  .food-list-page {
    background: url("/images/healthAndWellness/foodCalories/food-list/food-list-bg.svg");
    background-repeat: no-repeat;
    height: 100vh;
    width: 100%;
    background-position: 0px -100px;
    background-size: cover;

    &.--no_scroll {
      overflow: hidden;
    }

    .banner-container {
      position: relative;
      top: -20px;
    }
  }

  .header-wrapper {
    position: absolute;
    z-index: 1;
    top: 0;
    left: 0;
    backdrop-filter: blur(2px);
    padding-top: 10px;
    padding-bottom: 0px;
  }

  .food-header-curve-img {
    top: -30px;
    position: relative;
    opacity: 0.9;
  }

  .search-img-container .search-title {
    font-family: $sukhmaitext;
    font-weight: 800;
    margin-bottom: 16px;
    margin-top: 8px;
    font-size: 20px;
    line-height: 32.52px;
  }

  .category-container {
    overflow: hidden;
    margin-top: 18px;

    &.slick-slider {
      margin-top: 22px;

      .slick-slide {
        padding: 0 4px;
      }
    }

    .category-title {
      font-size: 24px;
      font-weight: 800;
      font-family: $sukhmaitext;
    }
  }

  .supplement-list-container {
    background: $white;
    box-shadow: 0px -8px 24px 0px $bggreytranslucent;
    padding: 16px 8px;
    border-radius: 24px 24px 0px 0px;
    padding-top: 0;

    .supplement-list-nav.sticky~.tab-content {
      padding-top: 50px;
    }
  }

  .supplement-only-list-container {
    background: url('/images/healthAndWellness/goals/supplements/supplements_goal_bg.svg');
    background-position: 0 -65px;
    background-repeat: repeat-y;
    display: flex;
    flex-direction: column;
    gap: 12px;
    height: auto;

    &__header {
      background-image: url('/images/healthAndWellness/goals/supplements/supplements_list_header.svg');
      background-size: cover;
    }

    &__details {
      display: flex;
      flex-direction: column;
      gap: 24px;

      &-items {
        padding: 8px;
      }
    }
  }

  .tab-content {
    padding: 0 12px;
  }

  .tab-content .tab-pane .dietary-supplement-section {
    background: $white;
    box-shadow: 0px 4px 12px 0px $shadowgrey3;
    border-radius: 8px;
    margin-bottom: 16px;

    @media (max-width: 324px) {
      padding: 0;
    }
  }

  .btn-light {
    color: $white;
    border-radius: 24px;
    padding: 2px 8px 2px 8px;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 62px;
    font-size: 12px;
    font-weight: 500;
    float: right;
  }

  .btn-salad {
    background: $a-btnSalad;
  }

  .btn-meat {
    background: rgba(235, 165, 135, 1);
  }

  .btn-boiled {
    background: rgba(240, 199, 121, 1);
  }

  .btn-curry {
    background: rgba(255, 141, 116, 1);
  }

  .btn-beverage {
    background: rgba(144, 191, 205, 1);
  }

  .btn-fried {
    background: rgba(212, 138, 85, 1);
  }

  .btn-grilled {
    background: rgba(228, 84, 84, 1);
  }

  .btn-steak {
    background: rgba(150, 119, 102, 1);
  }

  .btn-soup {
    background: rgba(244, 169, 153, 1);
  }

  .btn-seafood {
    background: rgba(150, 211, 200, 1);
  }

  .btn-plant {
    background: rgba(148, 204, 174, 1);
  }

  .btn-other {
    background: rgba(150, 154, 175, 1);
  }

  .btn-light span {
    padding-left: 4px;
  }

  .line {
    height: 1px;
    background: linear-gradient(to right, transparent 50%, $gray13 50%);
    background-size:
      6px 2px,
      100% 2px;

    @media (max-width: 324px) {
      display: none;
    }
  }

  @media (max-width: 324px) {
    .dietary-supplement-section-wrapper {
      display: flex;
      flex-wrap: wrap;
      gap: 10px;
      justify-content: space-between;
    }

    .tab-content .tab-pane .dietary-supplement-section {
      width: 47%;
      flex-direction: column;
      justify-content: space-between;
    }

    .service-size-card-single {
      padding: 10px 8px;
    }

    .service-size-card-title span {
      font-size: 14px;
    }
  }

  .card .percent {
    position: relative;
  }

  .card .number {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }

  .card .number h3 {
    font-weight: 200;
    font-size: 10px;
  }

  .card .number h3 span {
    font-size: 14px;
  }
}

.search-input-container {
  input {
    border: 1px solid $gray13 !important;
  }
}