@import '../../../../../common/commonConstant.scss';


.log-progress-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 12px;

  .log-progress-left {
    font-size: 16px;
    font-weight: 800;
    font-family: $sukhmaitext;
    line-height: 28px;
    display: flex;
    align-items: center;
  }

  .log-progress-right {
    display: flex;
    gap: 12px;
    align-items: center;

    &__value_wrapper {
      display: flex;
      flex-direction: column;

      &-consume {
        font-weight: 700;
        font-size: 18px;
        line-height: 26px;
        text-align: right;
      }

      &-target {
        display: flex;
        gap: 6px;
        align-items: center;
      }
    }

    .log-progress-overall-value,
    .log-progress-unit {
      font-family: $sukhmaitext;
      font-size: 14px;
      color: $homegrey;
      font-weight: 500;
    }
  }
}