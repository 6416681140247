@import "../commonConstant.scss";

/********************* DASHBOARD SWIPER SLIDER CSS START *********************/

.dashboard-slider-outer {
  .dashboard-slider-wrapper {
    .swiper-wrapper {
      display: flex;
      align-items: center;
    }

    .dashboard-slider-container {
      display: flex;
      .dashboard-slider-sidebar {
        background: #fff;
        box-shadow: 0px 4px 20px 0px rgba(50, 100, 76, 0.15);
        border-radius: 0 16px 16px 0;
        width: 150px;
        padding: 20px 0;
        z-index: 2;

        .dashboard-slider-sidebar-title {
          font-size: 18px;
          font-weight: 700;
          font-family: $sukhmaitext;
          color: #2c2c2c;
          padding-bottom: 16px;
        }

        .dashboard-slider-sidebar-profile-wrapper {
          display: flex;
          flex-direction: column;
          gap: 16px;

          .dashboard-slider-sidebar-profile {
            text-align: center;
            padding: 0 26px;

            .dashboard-slider-image-container{
              position: relative;
              display: inline-block;

              .profile-circle-img {
                display: block;
                height: 60px;
                width: 60px;
                border-radius: 50%;

              }

              .dashboard-slider-leader-img {
                position: absolute;
                top: 50%;
                left: 50%;
                transform: translate(-50%, -50%);

                .profile-img {
                  height: 60px;
                  width: 60px;
                  border-radius: 50%;
                }

                .btn-icon-crown {
                  height: 27px;
                  width: 27px;
                  border-radius: 50%;
                  background: $bgwhite;
                  position: absolute;
                  left: 38px;
                  bottom: -4px;
                  box-shadow: 1px 2px 3px 0px $crown-icon-shadow;
                }
              }
            }



            .dashboard-slider-sidebar-profile-name {
              font-size: 14px;
              font-weight: 700;
              font-family: $sukhmaitext;
              color: $black;
              display: flex;
              flex-direction: column;
            }
          }
        }
      }

      .dashboard-slider-parent {
        .dashboard-slider-content {
          box-shadow: 0px 4px 20px 0px rgba(50, 100, 76, 0.15);
          text-align: center;
          display: flex;
          flex-direction: column;
          justify-content: center;
          width: 124px;
          border-radius: 0 16px 16px 0;
          height: calc(100% - 10px);
          backdrop-filter: blur(6px);
          background: rgba(255, 255, 255, 0.9);

          @media (min-width: 400px) {
            width: 134px;
          }

          @media (max-width: 324px) {
            width: 119px;
          }

          .dashboard-slider-weight-title,
          .dashboard-slider-steps-title {
            font-size: 18px;
            font-weight: 700;
            font-family: $sukhmaitext;
            margin-bottom: 20px;

            span:last-of-type {
              font-size: 14px;
              font-weight: 500;
            }
          }

          .dashboard-slider-weight-wrapper,
          .dashboard-slider-steps-wrapper {
            display: flex;
            flex-direction: column;
            gap: 0;

            &.dashboard-slider-steps-wrapper {
              gap: 0;
            }

            .dashboard-slider-weight,
            .dashboard-slider-steps {
              font-size: 20px;
              font-weight: 700;
              font-family: $sukhmaitext;

              &:last-of-type {
                margin-bottom: 30px;
              }
            }

            .dashboard-slider-steps {
              color: #3c9d47;

              &:last-of-type {
                margin-bottom: 0;
              }

              .badge-total img {
                height: 18px;
                margin-right: 4px;
              }
            }
          }

          &.swiper-slide-next {
            background: #6bc948;
            box-shadow: 0px 4px 20px 0px rgba(50, 100, 76, 0.15);

            .dashboard-slider-weight-title,
            .dashboard-slider-steps-title,
            .dashboard-slider-weight-wrapper,
            .dashboard-slider-steps-wrapper {
              display: none;
            }

            ~.swiper-slide {
              background: #578741;
              box-shadow: 0px 4px 20px 0px rgba(50, 100, 76, 0.15);

              .dashboard-slider-weight-title,
              .dashboard-slider-steps-title,
              .dashboard-slider-weight-wrapper,
              .dashboard-slider-steps-wrapper {
                display: none;
              }
            }
          }

          &.swiper-slide-active {
            width: 153px;
            height: calc(100% - 50px);

            @media (min-width: 400px) {
              width: 162px;
            }

            @media (max-width: 324px) {
              width: 136px;
            }
          }
        }
      }
    }
  }
}