@import "../commonConstant.scss";

.badge-carousel-list{
    height: 80px !important;
    width: 80px !important;
    position: relative;
    .badge-img {
        position: absolute;
        top: 50%;
        height: 70px !important;
        left: 50%;
        transform: translate(-50%, -50%);
    }
}