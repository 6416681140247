@import "../../common/commonConstant.scss";

.square-float-button {
  justify-content: center;
  align-items: center;
  font-family: $sukhmaitext;
  font-size: 14px;
  font-weight: 700;
  text-align: center;
  color: $square-float-color;
  padding: 0 16px;
  gap: 8px;
  opacity: 1;
  background: linear-gradient(107.74deg, $gray2 27.91%, $white1 98.01%);
  background-clip: padding-box, border-box;
  position: relative;
  display: inline-flex;
}

.home-square-float-btn {
  width: 40px;
  height: 40px;
  border-radius: 10px;
  border: 3px solid transparent;
  box-shadow: 2px 3px 6px 0px $quiz-btn-green-shdw;

  .button-content img {
    max-width: 24px;
    max-height: 24px;
  }
}

.profile-square-float-btn {
  width: 68px;
  height: 68px;
  border-radius: 16px;
  border: 5px solid transparent;
  box-shadow: 4px 4px 18px 0px $profile-square-boxshw;

  .button-content img {
    width: 56px;
    height: 56px;
    border-radius: 11px;
    top: 6px;
    left: 6px;
    box-shadow: 0px 1px 2px 0px $shadowgrey2 inset;
    box-shadow: 0px 1px 0px 0px $white;
    background-size: cover;
    object-fit: cover;
  }
}

.square-float-button::before {
  content: '';
  position: absolute;
  top: -3px;
  left: -3px;
  right: -3px;
  bottom: -3px;
  border-radius: inherit;
  background: linear-gradient(287.74deg, $gray2 27.91%, $white1 98.01%);
  z-index: -1;
}