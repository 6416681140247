@import '../../common/commonConstant.scss';

.hnw-add-item-card-container {
  display: flex;
  justify-content: space-between;
  width: 100%;
  height: 72px;
  align-items: center;
  border-radius: 12px;
  background-color: $bgwhite;
  box-shadow: 1px 8px 18px 0px $flblack;
  padding: 12px;
  gap: 12px;

  &__details {
    display: flex;
    justify-content: center;
    gap: 12px;
    align-items: center;

    &-img {
      border-radius: 50px;
      height: 40px;
      width: 40px;
    }

    &-text {
      font-weight: 700;
      font-family: $sukhmaitext;
      font-size: 18px;
      line-height: 26px;
      text-overflow: ellipsis;
      overflow: hidden;
      white-space: nowrap;
      width: auto;
      max-width: 14rem;
    }
  }
}