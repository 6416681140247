@import "../commonConstant.scss";

.liff-picker-panel {
    left: 0;
    bottom: 0;
    width: 100%;
    height: 0px;
    float: right;
    z-index: 2000;
    position: fixed;
    margin-top: 200px;
    margin-right: 34px;

    .picker-panel {
        left: 0;
        bottom: 0;
        width: 100%;
        height: 0px;
        position: fixed;
        background-color: $bgwhite;
        border-top-left-radius: 20px;
        border-top-right-radius: 20px;
        transition: height 0.2s ease-in-out;
        max-width: 768px;

        .modal-content {
            border-radius: 0;
            border: none;
            border-top-left-radius: 24px;
            border-top-right-radius: 24px;
            padding: 0px;

            .modal-header {
                display: inline-block;
                padding: 8px;
                margin-bottom: 4px;
                border-bottom: 1px solid $a-modal-lightgrey;

                .btn-green-rounded {
                    padding: 6px 20px;
                    float: right;
                    background: $a-green;
                    color: $a-serviceCard;
                    border-radius: 38px;
                    font-size: 14px;
                    font-weight: 700;
                    font-family: $sukhmaitext;
                    line-height: 20px;
                    height: 28px;
                }
            }

            .modal-body {
                padding: 0;
                padding-bottom: 16px;
                display: flex;

                .picker-window {
                    position: absolute;
                    background: $a-picker-bg;
                    height: 42px;
                    left: 0;
                    top: 50%;
                    transform: translateY(-65%);
                    width: 100%;
                    pointer-events: none;
                    z-index: 0;
                }

                .picker {
                    display: inline-grid;
                    border-radius: 15px;
                    background: transparent;
                    font-size: calc(0.5rem + 2.4vw);
                    position: relative;
                    font-weight: 400;
                    width: 100%;
                    text-align: center;
                    justify-content: center;
                }

                .picker:before,
                .picker:after {
                    content: "";
                    position: absolute;
                    width: 100%;
                    height: 2.2em;
                    z-index: 2;
                }

                .picker:before {
                    top: 0em;
                    background: linear-gradient($a-picker-white, $a-picker-dark);
                }

                .picker:after {
                    bottom: 0em;
                    height: 2.4em;
                    background: linear-gradient($a-picker-dark, $a-picker-white);
                }

                .picker ul {
                    -ms-overflow-style: none;
                    max-height: 12em;
                    overflow-y: scroll;
                    width: 100%;
                    margin: 0;
                    -ms-scroll-snap-type: y mandatory;
                    scroll-snap-type: y mandatory;
                    padding-bottom: 3em;
                    padding-top: 2em;
                    z-index: 1;
                }

                .picker ul::-webkit-scrollbar {
                    display: none;
                }

                .picker li {
                    scroll-snap-align: center;
                    height: 42px;
                    line-height: 42px;
                    text-align: right;
                    word-spacing: 0.2em;
                }

                .picker-day {
                    padding-right: 0.5em;
                }

                .picker-day span {
                    padding-left: 0.56em;
                }
            }
        }

        .btn-close {
            font-family: $sukhmaitext;
            top: 15px;
            right: 20px;
            border: none;
            position: absolute;
            font-size: larger;
            background-color: $bgwhite;
            letter-spacing: 0px !important;
            border-radius: 38px;
            display: flex;
            padding: 6px 8px;
        }
    }
}

.liff-picker-panel.open {
    height: 100vh;
    z-index: 1;
    background-color: $a-backgorund-blur;
    backdrop-filter: blur(5px);

    .picker-panel {
        height: 280px;
    }
}