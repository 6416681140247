@import "../../../../../common/commonConstant.scss";

.chips-container {
    .chip-question {
        margin-bottom: 16px;
        margin-top: 30px;
        font-size: 16px;
        font-weight: 700;
        color: $a-black;
        font-family: $sukhmaitext;
        line-height: 24px;
    }

    .chip-question-value {
        display: flex;
        align-items: center;
        flex-wrap: wrap;
        line-height: 20px;
    }
}