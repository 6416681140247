@import "../../../../common/commonConstant.scss";

.hnw-goals-nutrition-container {
  background: url('/images/healthAndWellness/goals/nutrition/bgGoalSetting.svg');
  background-position: center;
  width: 100%;
  background-size: cover;
  background-attachment: fixed;
  background-repeat: no-repeat;
  height: 90vh;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  overflow: scroll;

  &::-webkit-scrollbar {
    width: 0;
  }

  .hnw-goals-nutrition-header {
    width: 100%;
  }

  .hnw-goals-nutrition-details-container {
    padding: 16px;
    margin-top: 25px;
    flex-grow: 1;

    .hnw-goals-nutrition-details-wrapper {
      display: flex;
      flex-direction: column;
      gap: 12px;
    }

    .hnw-goals-nutrition-details-suggestion {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      align-items: center;
      gap: 24px;
      margin: 35px 20px 10px;

      &-description {
        img {
          margin: 0 5px;
        }
      }
    }
  }
}
.hnw-goals-nutrition-footer {
  position: fixed;
  bottom: 0;
  width: 100%;

  img {
    width: 100%;
  }

  .btn {
    font-family: $sukhmaitext;
    width: calc(100% - 32px);
    height: 48px;
    position: absolute;
    top: 15%;
    bottom: 0;
    left: 0;
    right: 0;
    background: $homegreen;
    margin: auto;
    display: block;
    border-radius: 38px;
    font-size: 16px;
    padding: 6px 8px;
    color: $white;
    font-weight: 700;

    &:disabled {
      background-color: $gray12;
    }
    &:hover,
    &:active,
    &:focus {
      box-shadow: none;
    }
  }
}