@import "../commonConstant.scss";

.calendar-button-container {
    width: 123px;
    height: 32px;
    border-radius: 62px;
    display: flex;
    justify-content: space-between;
    padding: 4px 8px 4px 12px;
    border: 1px solid $gray12;
    align-items: center;
    background-color: $a-progress-bar-bg;
    .calendar-date-font {
        font-family: $sukhmaitext;
        font-weight: 500;
        font-size: 14px;
        line-height: 20px;
        color: #A0ABC0;
    }

    .dropdown-icon-align {
        width: 22px;
        height: 22px;
        background: $homewhite;
        border-radius: 50%;
        display: flex;
        align-items: center;
        justify-content: center;
    }
}