@import '../commonConstant.scss';

.picker-window {
    position: absolute;
    background: $a-picker-bg;
    height: 42px;
    left: 0;
    top: 50%;
    transform: translateY(-65%);
    width: 100%;
    pointer-events: none;
    z-index: 0;
}

.picker {
    display: inline-grid;
    border-radius: 15px;
    background: transparent;
    font-size: calc(0.5rem + 2.4vw);
    position: relative;
    font-weight: 400;
    width: 100%;
    text-align: center;
    justify-content: center;
}

.picker:before,
.picker:after {
    content: "";
    position: absolute;
    width: 100%;
    height: 2.2em;
    z-index: 2;
}

.picker:before {
    top: 0em;
    background: linear-gradient($a-picker-white, $a-picker-dark);
}

.picker:after {
    bottom: 0em;
    height: 2.4em;
    background: linear-gradient($a-picker-dark, $a-picker-white);
}

.picker ul {
    -ms-overflow-style: none;
    max-height: 12em;
    overflow-y: scroll;
    width: 100%;
    margin: 0;
    -ms-scroll-snap-type: y mandatory;
    scroll-snap-type: y mandatory;
    padding-bottom: 3em;
    padding-top: 2em;
    z-index: 1;
}

.picker ul::-webkit-scrollbar {
    display: none;
}

.picker li {
    display: flex;
    justify-content: center;
    scroll-snap-align: center;
    height: 42px;
    line-height: 42px;
    text-align: right;
    word-spacing: 0.2em;
}

.picker-day {
    padding-right: 0.5em;
}

.picker-day span {
    padding-left: 0.56em;
}
