@import "../../../../../common/commonConstant.scss";

.food-calories-container {
  .liff-panel {
    backdrop-filter: blur(2px);
    &.open {
      z-index: 99;
      .select-coupon-panel {
        height: 60%;
        & > :nth-child(2) {
          height: 100%;
          .modal-body {
            height: 100%;
            .modal-title {
              height: 100%;
            }
          }
        }
      }
    }
  }

  #welcomeModal {
    bottom: 0;
    height: initial;
    top: initial;
    &.show {
      display: block;
    }
  }

  #welcomeModal .modal-dialog {
    margin: 0;
    max-width: 100%;
  }

  #welcomeModal .modal-content {
    border-radius: 0;
    border-top-left-radius: 24px;
    border-top-right-radius: 24px;
    border: none;
  }

  #welcomeModal .modal-header {
    justify-content: flex-end;
    border-bottom: 0;
    padding-bottom: 0;
  }

  #welcomeModal .modal-header button {
    border: none;
    height: 24px;
    width: 24px;
    background: none;
  }

  .modal-category-container {
    display: flex;
    flex-wrap: wrap;
    height: calc(100% - 80px);
    overflow: auto;
  }

  .modal-category-single {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 25%;
    padding: 0 4px;
  }

  .modal-category-single img {
    height: 68px;
    width: 68px;
    border-radius: 50%;
  }

  .modal-category-title {
    font-size: 16px;
    font-weight: 700;
    font-family: $sukhmaitext;
    margin-top: 5px;
    margin-bottom: 10px;
    overflow-wrap: anywhere;
    text-align: center;
  }

  .modal-heading {
    font-size: 24px;
    font-weight: 800;
    font-family: $sukhmaitext;
    margin-bottom: 12px;
  }
}
