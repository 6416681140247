@import '../../../common/commonConstant.scss';

.solution-header {
	box-shadow: 4px 4px 8px 0px $shadowgrey;
}

.solution-plp-overlay {
	height: 100vh;
	width: 100%;
	position: fixed;
	top: 0px;
	background-color: $shadowgrey2;
}

.sop-product-box {
	.solution-price {
		h2 {
			margin-bottom: 0px;
		}
	}

	.new-solution-title-sku {
		font-family: $sukhmaitext;
		font-size: 12px;
		font-weight: 500;
		line-height: 16px;
		letter-spacing: 0px !important;
		text-align: left;
		color: $gray8;
		margin-left: 16px;
	}

	.new-solution-title-name {
		font-family: $sukhmaitext;
		font-size: 14px;
		font-weight: 700;
		line-height: 20px;
		letter-spacing: 0px !important;
		text-align: left;
		padding: 0px 16px;
		color: $black;
		display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 2;
    overflow: hidden;
		height: 40px;
	}

	.solution-pvbv {
		font-family: $sukhmaitext;
		font-size: 12px;
		font-weight: 500;
		line-height: 16px;
		letter-spacing: 0px !important;
		text-align: left;
		margin-left: 16px;
		color: $gray8;
	}

	.add-sop-btn {
		justify-content: center;
		padding: 10px;

		.sop-btn-purchase {
			border: none;
			color: white;
			padding: 10px 60px;
			border-radius: 10px;
			border-radius: 30px;
			background-color: $black;
			font-family: $sukhmaitext;
			height: 45px;
			font-size: 14px;
			font-weight: 700;
			line-height: 20px;
			letter-spacing: 0px !important;
			text-align: center;


			&:disabled {
				background-color: $gray9;
				color: $gray5;
			}
		}
	}
}

.solution-footer {
	width: 100%;
	position: fixed;
	bottom: 0px;
	z-index: 9;
	background-color: $bgwhite;
	border-top-left-radius: 12px;
	border-top-right-radius: 12px;
	box-shadow: 0px -4px 8px 0px $shadowgrey;

	.solution-subfooter {
		padding: 10px 16px;
		border-bottom: 1px solid $shadowgrey1;

		.total-count {
			font-family: $sukhmaitext;
			font-size: 18px;
			font-weight: 800;
			line-height: 40px;
			letter-spacing: 0px !important;
			text-align: left;
			color: $black;
			margin-right: 10px;
		}

		.sop-tag {
			background-color: $orange1;
			border-radius: 24px;
			padding: 5px 10px;
			color: $orange2;
			font-family: $sukhmaitext;
			font-size: 14px;
			font-weight: 700;
			line-height: 30px;
			letter-spacing: 0px !important;
			text-align: left;
		}

		.sop-tag-basic {
			color: $bggreen1;
			background-color: $bggreen2;
		}

		.qty-icon {
			justify-content: end;
			display: flex;
		}
	}

	.footer-shadow {
		box-shadow: 0px -4px 8px 0px $shadowgrey2;
	}

	.price-footer {
		padding: 10px 0px 20px 0px;
		;

		.checkout-section {
			justify-content: left;
			padding: 0px 10px 0px 16px;

			.price-section {
				.solution-price {
					font-family: $sukhmaitext;
					font-size: 20px;
					font-weight: 800;
					line-height: 28px;
					letter-spacing: 0px !important;
					text-align: left;
					color: $black;
				}

				.solution-pvbv {
					font-family: $sukhmaitext;
					font-size: 12px;
					font-weight: 500;
					line-height: 16px;
					letter-spacing: 0px !important;
					text-align: left;
					color: $purple;
				}
			}

			.solution-btn-purchase {
				font-family: $sukhmaitext;
				padding: 12px 40px;
				border-radius: 40px;
				color: $bgwhite;
				background-color: $black;

				&:disabled {
					background-color: $gray9;
					color: $gray5;
					border: 0px;
				}
			}
		}
	}

	.price-footer-shadow {
		box-shadow: 0px -4px 8px 0px $shadowgrey2;
	}

	.product-section {
		background-color: $shadowgrey2;
		padding: 10px 16px;
		height: 70vh;
		overflow-y: auto;

		.product {
			background-color: $bgwhite;
			padding: 10px 16px;
			margin-bottom: 10px;
			border-radius: 10px;
			.description-section {
				padding-left: 10px;

				.footer-sku {
					justify-content: left;
					font-family: $sukhmaitext;
					font-size: 12px;
					font-weight: 500;
					line-height: 16px;
					letter-spacing: 0px !important;
					text-align: left;
					color: $gray8;
				}

				.footer-name {
					justify-content: left;
					font-family: $sukhmaitext;
					font-size: 14px;
					font-weight: 500;
					line-height: 20px;
					letter-spacing: 0px !important;
					text-align: left;
					margin-top: 5px;
					margin-bottom: 5px;
					color: $black;
				}

				.footer-price-stepper {
					justify-content: left;
					.footer-price {
						display: flex;
						align-self: flex-end;
						h2{
							font-family: $sukhmaitext;
							font-size: 16px;
							font-weight: 700;
							line-height: 24px;
							letter-spacing: 0px !important;
						}
					}
				}
			}
		}
	}
}