@import "../../../../common/commonConstant.scss";

.quiz-video-title-wrapper {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-bottom: 20px;

    &.title-padding {
        padding-bottom: 0;
    }

    .quiz-video-title {
        font-size: 18px;
        font-family: $sukhmaitext;
        font-weight: 700;
    }

    .btn-sm {
        height: 32px;
        padding: 0 16px;
    }
    
    .btn-light-green {
        background: $quiz-answer-green;
        color: $quiz-answer-text;
        font-size: 16px;
        font-weight: 700;
        font-family: $sukhmaitext;
        line-height: 24px;
        text-align: center;
    }
    
    .btn-rounded {
        border-radius: 48px;
    }
}