@import "../../../../../common/commonConstant.scss";

.home-nutrition-bar-single {
        display: flex;
        flex-direction: column;
        gap: 2px;
        width: 33.33%;

        .home-nutrition-bar-header {
            display: flex;
            flex-direction: column;
            gap: 4px;

            .home-nutrition-bar-name {
                font-size: 16px;
                font-weight: 700;
                color: $a-black;
                text-align: left;
                line-height: 24px;
                font-family: $sukhmaitext;
            }

            .progress-outer {
                box-shadow: 2px 2px 4px 0px $progress-bar-shadow inset;
                background: linear-gradient(107.74deg, $background-progres-dark 27.91%, $bggray 98.01%);
                height: 20px;
                border-radius: 13px;
                display: flex;
                align-items: center;
                justify-content: center;
                position: relative;

                .progress {
                    height: 8px;
                    width: 100%;
                    margin: 0 8px;
                    border-radius: 24px;

                    .progress-bar {
                        background: linear-gradient(270deg, $challenge-progress-gradient1 0%, $flgreen1 100%);
                        border-radius: 16px;
                    }
                }

            }
        }

        .home-nutrition-bar-value-group {
            display: flex;
            align-items: center;
            margin-top: 4px;

            .home-nutrition-bar-value-used {
                font-size: 18px;
                font-weight: 700;
                font-family: $gtwalsheimbold;
                line-height: 27px;
            }

            .home-nutrition-bar-value-total {
                font-size: 16px;
                font-weight: 400;
                line-height: 24px;
                font-family: $gtwalsheimpro;
            }

            .home-nutrition-bar-value-unit {
                padding-left: 4px;
                font-weight: 500;
                font-size: 16px;
                line-height: 24px;
                font-family: $sukhmaitext;
            }
        }
    }