@import '../../../../common/commonConstant.scss';

.my-dashboard-header-wrapper {
    padding-top: 0;
    transition: all 0.3s ease;

    &.sticky {
        position: fixed;
        width: 100%;
        z-index: 99;
        top: 0;
        padding-top: 0;
        background: $header-sticky-transparent;
        margin-bottom: 0;
        box-shadow: 0px 4px 20px 0px $challenge-card-shadow;
    }
    .challenge-header-container {
        position: relative;
    }
}