@import '../../../../common/commonConstant.scss';

.bmi-banner-container {
    background: $bmiwhite;
    padding-top: 16px;
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;

    .half-circle-center {
        position: absolute;
        bottom: -4px;
        left: 50%;
        transform: translate(-50%, 0);

        .outer-half-circle {
            height: 93px;
            width: 186px;
            background: $bgwhite;
            display: inline-block;
            border-top-left-radius: 186px;
            border-top-right-radius: 186px;
        }

        .inner-half-circle {
            display: flex;
            position: absolute;
            left: 0;
            bottom: 0;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            width: 100%;
            font-family: $sukhmaitext;

            .circle-value {
                font-size: 14px;
                line-height: 18px;
                color: $homegrey2;
                font-weight: 500;
                z-index: 9;
                margin-bottom: 14px;
            }

            .circle-heading,
            .circle-value {
                text-align: center;
            }

            .circle-heading {
                font-weight: 800;
                font-size: 20px;
                line-height: 30px;
                color: $homegrey1;
                z-index: 9;
            }

            img {
                position: absolute;
                bottom: 12px;
                left: 50%;
                transform: translate(-50%, 0px);
            }
        }

    }
}