@import '../../../common/commonConstant.scss';

.promotion-address-list-container {
  display: flex;
  flex-direction: column;
  flex: 1;
  background: $bgpromo;

  .description {
    font-size: 13px;
    color: $gray;
  }

  .center {
    text-align: center;
    padding: 15px;
  }

  >.header {
    padding: 20px 0 10px;
    text-align: center;
  }

  >.body {
    display: flex;
    flex-direction: column;
    flex: 1;
    margin: 15px;
    padding: 15px;
    border-radius: 12px;

    .away-button {
      font-family: $sukhmaitext;
      width: 50%;
    }

    .promotion-create-button-active {
      color: #3a539b;
      border-color: #3a539b;
      background-color: #eef3ff;
    }

    .promotion-create-button-inactive {
      color: #c5c9d3;
      border-color: #c5c9d3;
      background-color: $bgwhite;
    }

    .addresses-wrapper {
      display: flex;
      flex-direction: column;
      flex: 1;

      .address-container {
        display: flex;
        padding: 10px 5px;
        margin: 10px 0;
        border-radius: 12px;
        box-shadow: 0px 5px 15px rgba(0, 0, 0, 0.04);
        background: $bgwhite;
      }

      .addresses-count {
        font-weight: 700;
        margin: 10px;
      }

      .addresses-selector {
        margin-left: 5px;
      }

      .address-primary-tag {
        color: #38539a;
      }

      .modern-radio-container {
        margin: 24px 0;
        display: flex;
        cursor: pointer;
        user-select: none;
      }

      .radio-outer-circle {
        width: 18px;
        height: 18px;
        min-width: 18px;
        min-height: 18px;
        border: 2px solid #0d5bc6;
        border-radius: 50%;
        display: flex;
        justify-content: center;
        align-items: center;
        margin-right: 12px;
        transition: all 0.1s linear;
      }

      .radio-inner-circle {
        width: 8px;
        height: 8px;
        border-radius: 50%;
        background-color: #0d5bc6;
        transition: all 0.1s linear;
      }

      .unselected {
        border: 2px solid #666;
      }

      .unselected-circle {
        width: 0;
        height: 0;
      }
    }
  }

  >.footer {
    width: 100%;
    text-align: center;
    position: fixed;
    bottom: 0;
    padding: 15px;
  }
}

.promotion-summary-address-list-container {
  display: flex;
  flex-direction: column;
  flex: 1;
  background: $gray4;
  height: 85vh;
  .title {
    font-family: $sukhmaitext;
    font-size: 20px;
    font-weight: 800;
    letter-spacing: 0px !important;
    padding: 15px;
  }

  .description {
    font-size: 13px;
    color: $gray;
  }

  .center {
    text-align: center;
    padding: 15px;
  }

  >.header {
    padding: 20px 0 10px;
    text-align: center;
  }

  >.body {
    display: flex;
    flex-direction: column;
    flex: 1;
    background-color: $bgwhite;

    .address-fields-title {
      display: flex;
      flex: 1;
      flex-direction: row;
      justify-content: space-between;
      margin-bottom: 10px;

      .address-fields-title-text {
        font-family: $sukhmaitext;
        font-size: 14px;
        font-style: normal;
        font-weight: 700;
        letter-spacing: 0px !important;
        padding-left: 4px;
      }
    }

    .address-title-alignment {
      display: flex;
      flex: 1;
      flex-direction: row;
    }

    .plus-icon {
      letter-spacing: 0px !important;
      font-family: $sukhmaitext;
      font-weight: 500;
    }

    .plus-icon:disabled {
      letter-spacing: 0px !important;
      font-family: $sukhmaitext;
      font-weight: 500;
      color: #9EA0A3;
    }

    .add-address {
      font-family: $sukhmaitext;
      text-decoration: underline;
      font-size: 12px;
      font-style: normal;
      letter-spacing: 0px !important;
      font-weight: 500;
    }

    .add-address:disabled {
      font-family: $sukhmaitext;
      text-decoration: underline;
      color: #9EA0A3;
      font-size: 12px;
      font-style: normal;
      letter-spacing: 0px !important;
      font-weight: 500;
    }

    .address-phone-title {
      font-family: $sukhmaitext;
      font-size: 14px;
      font-style: normal;
      letter-spacing: 0px !important;
      font-weight: 700;
    }

    .phone-number-container {
      display: flex;
      flex: 1;
      flex-direction: row;
      justify-content: space-between;
    }

    .phone-input {
      padding-left: 10px;
      width: 85%;
      height: 40px;
      border-width: 2px;
      border-style: solid;
      border-color: #EDEDED;
      border-radius: 10px;
      margin-block: 10px;
      margin-right: 10px;

      .alert-message {
        font-family: $sukhmaitext;
        color: red;
        margin-bottom: 10px;
      }

      .input-phone::placeholder {
        font-family: $sukhmaitext;
        box-shadow: none;
        border-bottom: none;
        text-align: left;
        font-weight: 700;
        font-size: 14px;
        color: #949494;
        letter-spacing: 0px !important;
        padding-bottom: 20px;
      }

      .input-phone {
        font-family: $sukhmaitext;
        box-shadow: none;
        border-bottom: none;
        text-align: left;
        font-weight: 700;
        font-size: 14px;
        letter-spacing: 0px !important;
        color: #2C2C2C;
      }
    }

    .change-phone {
      display: flex;
      width: 100px;
      height: 40px;
      border-radius: 10px;
      // border-color: #D9D9D9;
      // background-color: #D9D9D9;
      justify-content: center;

      .change-phone-text {
        font-family: $sukhmaitext;
        padding-top: 9px;
        font-size: 14px;
        font-style: normal;
        font-weight: 700;
        letter-spacing: 0px !important;
        // color: #9EA0A3;
      }

      .change-phone-text-active {
        font-family: $sukhmaitext;
        padding-top: 9px;
        font-size: 14px;
        font-style: normal;
        font-weight: 700;
        letter-spacing: 0px !important;
        color: $bgwhite;
      }
    }

    .active-text {
      color: $bgwhite
    }

    .inactive-text {
      color: #9EA0A3;
    }

    .active-button {
      background-color: #2C2C2C;
      border-color: #2C2C2C;
    }

    .inactive-button {
      background-color: #D9D9D9;
      border-color: #D9D9D9;
    }

    .away-button {
      font-family: $sukhmaitext;
      width: 50%;
    }

    .promotion-create-button-active {
      color: #3a539b;
      border-color: #3a539b;
      background-color: #eef3ff;
    }

    .promotion-create-button-inactive {
      color: #c5c9d3;
      border-color: #c5c9d3;
      background-color: $bgwhite;
    }

    .addresses-wrapper {
      display: flex;
      flex-direction: column;
      flex: 1;

      .address-container-new {
        display: flex;
        padding: 15px 15px;
        margin: 10px 0;
        // border-radius: 10px;
        box-shadow: 0px 5px 15px rgba(0, 0, 0, 0.04);
        // background: $bgwhite;
      }

      .box {
        border-radius: 10px;
        border-width: 2px;
        border-color: #E4E4E4;
        border-style: solid;
      }

      .unselected-box {
        border-radius: 10px;
        border-width: 2px;
        border-color: #E4E4E4;
        border-style: solid;
      }

      .contact-person-name {
        font-family: $sukhmaitext;
        font-size: 16px;
        font-style: normal;
        letter-spacing: 0px !important;
        font-weight: 700;
      }

      .address-fields-text {
        font-family: $sukhmaitext;
        font-size: 14px;
        font-style: normal;
        letter-spacing: 0px !important;
        font-weight: 500;
      }

      .addresses-count {
        font-weight: 700;
        margin: 10px;
      }

      .addresses-selector {
        margin-left: 5px;
      }

      .address-primary-tag {
        color: #38539a;
      }

      .modern-radio-container {
        margin: 24px 0;
        display: flex;
        cursor: pointer;
        user-select: none;
      }

      .radio-outer-circle-new {
        margin-top: 2px;
        width: 20px;
        height: 20px;
        min-width: 20px;
        min-height: 20px;
        border: 6px solid #38539A;
        border-radius: 50%;
        display: flex;
        justify-content: center;
        align-items: center;
        margin-right: 12px;
        transition: all 0.1s linear;
      }

      .radio-inner-circle-new {
        width: 9px;
        height: 9px;
        border-radius: 50%;
        background-color: $bgwhite;
        transition: all 0.1s linear;
      }

      .unselected {
        border: 2px solid #2C2C2C;
      }

      .unselected-circle {
        width: 0;
        height: 0;
      }
    }
  }

  .fixed-text-bottom {
    font-family: $sukhmaitext;
    font-size: 12px;
    font-style: normal;
    font-weight: 500;
    color: #949494;
    letter-spacing: 0px !important;
    text-align: center;
    margin-block: 15px;
  }
}

.address-panel-footer {
  background-color: #ffffff;
  width: 100%;
  padding: 15px 20px;
  position: absolute;
  box-shadow: 0 -4px 8px 0 rgba(0, 0, 0, 0.1);
}

.order-summary-panel-footer {
  background-color: #ffffff;
  width: 100%;
  padding: 10px 20px 20px;
  // position: fixed;
  box-shadow: 0 -4px 8px 0 rgba(0, 0, 0, 0.1);
}

.footer-alignment {
  display: flex;
  flex: 1;
  flex-direction: row;
}

.price-container {
  display: flex;
  flex: 1;
  flex-direction: column;
}

.price-text {
  font-family: $sukhmaitext;
  font-size: 20px;
  font-style: normal;
  letter-spacing: 0px !important;
  font-weight: 800;
}

.link-order-summary-text {
  font-family: $sukhmaitext;
  text-decoration: underline;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  letter-spacing: 0px !important;
}

.select-address-footer {
  justify-content: right;
  width: 150px;
  height: 48px;
  border-style: solid;
  border-width: 1px;
  border-radius: 40px;
  background-color: #2C2C2C;
  text-align: center;
  border-color: #2C2C2C;
}

.select-address-text {
  font-family: $sukhmaitext;
  font-weight: 700;
  font-size: 16px;
  letter-spacing: 0px !important;
  color: #FAFAFA;
}

.select-order-summary-footer {
  bottom: 20px;
  justify-content: right;
  width: 100%;
  height: 48px;
  border-style: solid;
  border-width: 1px;
  border-radius: 40px;
  background-color: #2C2C2C;
  text-align: center;
  border-color: #2C2C2C;
}