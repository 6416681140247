@import "../../../../common/commonConstant.scss";

body::-webkit-scrollbar:has(.challenge-map-detail-wrapper) {
  display: none; // Safari and Chrome
}

.challenge-map-detail-wrapper {

  .challenge-map-main {
    .lg-image.zoom-view-lg-image.--no-image {
      visibility: hidden;
    }

    .react-transform-wrapper {
      width: 100%;
    }

    .react-transform-component {
      height: 100vh;
    }

    &-header {
      position: absolute;
      z-index: 2;
    }

    &-image {
      height: 100vh;
      overflow: hidden;

      img {
        height: 100%;
        top: 0;
        position: relative;
      }
    }
  }

  .challenge-map-detail {
    display: flex;
    flex-direction: column;
    gap: 16px;

    &-grabber {
      display: flex;
      justify-content: center;
      padding: 10px;
      height: 30px;
      cursor: move;

      img {
        width: 32px;
        height: 4px;
      }
    }

    &-title {
      font-size: 18px;
      font-weight: 700;
      font-family: $sukhmaitext;
      color: $black;
    }

    &-tabs {

      .challenge-tab-list__nav-link {
        font-weight: 800;
        font-size: 16px;
        font-family: $sukhmaibold;
        min-width: 150px;
        width: calc(50% - 20px)
      }
    }

    &-unlock {

      .map-goal-card-left {
        display: flex;
        flex-direction: column;
        font-size: 14px;
        font-family: $sukhmaitext;
        color: $black;
        font-weight: 500;

        &-wrapper {
          margin: 12px 0;
        }

        &-header {
          margin-bottom: 16px;
        }

        &-date {
          font-family: $sukhmaitext;
          font-weight: 400;
          margin-left: 8px;
        }
      }

      .map-goal-card-right {
        position: relative;

        .badge-img-bg {
          position: absolute;
          width: 96px;
          translate: -50% -50%;
          margin: auto;
          top: 50%;
          left: 50%;
          filter: drop-shadow(0px 2px 4px $white);
        }

        img {
          position: relative;
          width: 84px;
        }
      }
    }

    &-goal {
      .map-goal-card-container {
        background-image: url('/images/challenge/white-bg.svg');
        background-position: center;
      }

      .map-goal-card-left {
        display: flex;
        flex-direction: column;
        font-size: 14px;
        font-family: $sukhmaitext;
        color: $black;
        font-weight: 500;

        &-title {
          &-steps {
            margin: 0 4px;
            font-family: $gtwalsheimpro;
            font-weight: 700;
          }
        }
      }

      .map-goal-card-right {
        img {
          height: 84px;
        }
      }
    }

    &-list {
      margin-top: 16px;
      display: flex;
      flex-direction: column;

      &-users {
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 13.5px 0;
        gap: 20px;

        &-profile {
          img {
            width: 32px;
            height: 32px;
          }

          position: relative;
          display: inline-block;

          .profile-circle-img {
            display: block;
            height: 32px;
            width: 32px;
            border-radius: 50%;

          }

          .dashboard-slider-leader-img {
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);

            .profile-img {
              height: 32px;
              width: 32px;
              border-radius: 50%;
            }

            .btn-icon-crown {
              height: 20px;
              width: 20px;
              border-radius: 50%;
              background: $bgwhite;
              position: absolute;
              left: 20px;
              bottom: -4px;
              box-shadow: 1px 2px 3px 0px $crown-icon-shadow;
              display: flex;
              align-items: center;
              justify-content: center;

              img {
                width: 16px;
                height: 16px;
              }
            }
          }
        }

        &-name {
          font-size: 16px;
          font-family: $sukhmaitext;
          color: $black;
          font-weight: 500;
          flex: 3;
          word-break: break-all;
        }

        &-date {
          flex: 1;
          font-size: 14px;
          font-family: $sukhmaitext;
          color: $black;
          font-weight: 400;
          width: 50px;
        }
      }
    }

  }
}