$color-op : #439f461a;
$color-d : #439F46;

@mixin ellipsis($value) {
    display: -webkit-box;
    -webkit-line-clamp: $value;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
}

.color_title {
    color: #2C2C2C;
}

:root {
    --widthProcess: 0%;
}

.pt-30 {
    margin-top: 30px;
}

.title {
    font-size: 24px;
    font-family: 'Sukhumvit Set Bold';
}

.color_d {
    color: $color-d
}

.subtitle {
    font-family: "Sukhumvit Set Medium";
    font-size: 14px;
    line-height: 22px;
}

.group_nav_menu {
    // display: flex;
    position: relative;

    .group_menu {
        display: grid;
        grid-template-columns: repeat(3, 1fr);
        gap: 20px 15px;

        .menu_item {
            a {
                color: #000;
            }
        }
    }
}

.group_bar_status {
    position: relative;
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 15px 10px;

    .status {
        font-family: 'Sukhumvit Set SemiBold';
        padding: 4px 16px;
        background: #F2F2F2;
        border-radius: 20px;
        color: #000;
        text-align: center;
        font-size: 14px;

        &.active {
            background: #439F46;
            color: #fff;
        }
    }
}

.hide {
    display: none;
}

.page_subdealer {
    margin-top: 6px;
}

.group_subdealer {
    position: relative;
    display: grid;
    gap: 15px 0;

    .subdealer_item {
        display: grid;

        .group_title_subdealer {
            display: grid;
            grid-template-columns: 1fr auto;

            .name {
                font-size: 18px;
                line-height: 28px;
                font-family: 'Sukhumvit Set Bold';
                color: #114B33;
                @include ellipsis(1);
            }

            .count {
                color: #114B33;
                font-size: 18px;
                font-family: 'Sukhumvit Set Bold';

                span {
                    font-family: 'Sukhumvit Set';
                    color: #8F8F8F;
                }
            }
        }

    }

    .group_order_status {
        position: relative;

        .id_order {
            font-family: 'Sukhumvit Set SemiBold';
            color: #000;
        }
    }
}

.update_date_point {
    position: absolute;
    bottom: 4px;
}

.process {
    position: relative;
    width: 100%;
    height: 4px;
    border-radius: 2px;
    background-color: #B2DBB6;

    &::after {
        content: '';
        position: absolute;
        width: var(--widthProcess);
        height: 4px;
        border-radius: 2px;
        background-color: $color-d;
    }

}

.group_slide_point {
    position: relative;

    .group_point_PVL {
        display: grid;
        grid-template-columns: repeat(3, 1fr);
        position: relative;
        display: flex;
        column-gap: 15px;

        .g_point {
            flex: 1;
            background-color: $color-op;
            padding: 10px 15px;
            font-size: 14px;
            font-family: 'Sukhumvit Set SemiBold';
        }

        .section_point {
            position: relative;
            padding-bottom: 20px;
        }

        .bg_cl {
            background-color: #DCEDDC;
            width: 32px;
            height: 32px;
            position: absolute;
            border-radius: 50px;
            top: 0;
            left: 0;
            text-align: center;
            align-items: center;
            display: flex;
            justify-content: center;
        }

        small {
            font-family: 'Sukhumvit Set';
            color: #636363;
        }
    }
}



.checkTimeStock-page {
    .MuiInputBase-input {
        height: 12px;
    }

    font-family: Sukhumvit Set;
    margin-top: 50px;

    .checkTimeStock-page-title {
        >span {
            font-family: Sukhumvit Set;
            font-style: normal;
            font-weight: bold;
            font-size: 26px;
            line-height: 41px;
            color: #000000;
        }
    }

    .checkTimeStock-detail-title {
        font-family: Sukhumvit Set;
        font-style: normal;
        font-weight: 600;
        font-size: 16px;
        line-height: 25px;
        color: #000000;
        margin-top: 10px;
        margin-bottom: 10px;
    }

    .text-size {
        font-style: normal;
        font-weight: 600;
        font-size: 16px;
        line-height: 19px;
        text-align: center;
        color: #000000;
    }

    .text-sizes {
        margin-top: -10px;
        font-family: Sukhumvit Set;
        font-style: normal;
        font-weight: 600;
        font-size: 12px;
        line-height: 19px;
        text-align: center;
        color: #696969;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
    }

    .status {
        background: #007aff;
        border-radius: 15px;
        justify-content: center;
        align-items: center;
        text-align: center;
        height: 26px;

        .textsa {
            font-family: Sukhumvit Set;
            font-style: normal;
            font-weight: 600;
            font-size: 12px;
            line-height: 19px;
            color: #fff;
        }
    }
}


.section {
    display: flex;
    overflow-x: auto;
    scroll-snap-type: x mandatory;
}

.colorback {
    color: #000;
}

.section__item {
    scroll-snap-align: start;
}

.g_point1 {
    flex: 1;
    background-color: $color-op;
    padding: 0px 15px;
    font-size: 14px;
    font-family: 'Sukhumvit Set SemiBold';
    border-radius: 10px;
}

.imgwa {
    width: '80%';

}



.tab_tap .pendingUpdate {
    background: #B29530;
}

.g_point1 img {
    width: 30px;
    /* กำหนดความกว้างของรูปภาพ */
    height: 50px;
    /* กำหนดความสูงของรูปภาพ */
    margin-top: 0px;

    /* กำหนดระยะห่างด้านบนของภาพ */
    display: block;
}

.g_point1 .section_point {
    height: 100px;
    /* กำหนดความสูงของกล่อง */
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.g_point1 .subtitle {
    font-size: 12px;
}

.g_point1 .title {
    font-size: 15px;
}

.g_point1 .col-3 .bg_cl {
    margin-left: -25px;
    /* กำหนดระยะห่างด้านซ้ายของรูปภาพ */
}

.g_point1 .process {
    width: var(--widthProcess, 0%);
    // height: 10px;
    // background-color: #f0c93e;
    border-radius: 10px;
}

@media screen and (max-width: 768px) {

    /* ปรับขนาดและการจัดตำแหน่งของตัวอักษรสำหรับหน้าจอขนาดไม่เกิน 768px */
    .subtitle {
        font-size: 12px;
    }
}

@media screen and (min-width: 768px) {

    /* ปรับขนาดและการจัดตำแหน่งของตัวอักษรสำหรับหน้าจอขนาดมากกว่า 768px */
    .subtitle {
        font-size: 14px;
    }
}




@media only screen and (device-width: 320px) and (device-height: 568px) and (-webkit-device-pixel-ratio: 2) {
    .img1 {
        max-width: 70%;
        /* ลดขนาดภาพเหลือครึ่งหนึ่งของพื้นที่ที่มันอยู่ */
    }

    .bg_cl2 {
        display: none;

    }

}

.button-container {
    position: absolute;
    bottom: 50px;
    left: 50%;
    transform: translateX(-50%);
}

.button {
    border-radius: 20px;
    width: 100%;
}

.imgicon {
    margin-right: 20px;
    width: 32px;
}

.fonticon {
    font-weight: bold;
    font-size: 20px;

}

.fonticon2 {
    font-size: 20px;
    color: #83C346;
    margin-left: 14px;
}

.fonticon3 {
    margin: 1px;
    font-weight: bold;
    font-size: 20px;
    margin-left: 6px;
}

.speedometer-container {
    display: flex;
    justify-content: center;
    align-items: center;

}

.divider {
    border: none;
    border-top: 2px solid #cecece;
    margin: 0px 0;
}

.containerAssesment {
    /* height: 75vh; */
    /* background-color: #ededed; */
    width: 100%;
    width: 100%;
    padding-right: 15px;
    padding-left: 15px;
    margin-right: auto;
    margin-left: auto;
}

.row {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
}

.col {
    flex: 1;
}

.btn {
    display: inline-block;
    font-weight: 400;
    color: #212529;
    text-align: center;
    vertical-align: middle;
    -webkit-user-select: none;
    user-select: none;
    background-color: transparent;
    border: 1px solid transparent;
    padding: 0.375rem 0.75rem;
    font-size: 1rem;
    line-height: 1.5;
    border-radius: 0.25rem;
    transition: color .15s ease-in-out, background-color .15s ease-in-out, border-color .15s ease-in-out, box-shadow .15s ease-in-out;
}

@font-face {
    font-family: "Sukhumvit Set";
    src: local("Sukhumvit Set"), url(./components/font/SukhumvitSet-Text.ttf) format("truetype");
}

@font-face {
    font-family: "Sukhumvit Set SemiBold";
    src: local("Sukhumvit Set SemiBold"), url(./components/font/SukhumvitSet-Bold.ttf) format("truetype");
}

@font-face {
    font-family: "Sukhumvit Set Medium";
    src: local("Sukhumvit Set Medium"), url(./components/font/SukhumvitSet-Medium.ttf) format("truetype");
}

@font-face {
    font-family: "Sukhumvit Set tadmai bold";
    src: local("Sukhumvit Set Bold"), url(./components/font/SukhumvitTadmai_Bol.ttf) format("truetype");
}

@font-face {
    font-family: "Sukhumvit Set Extra Bold";
    src: local("Sukhumvit Set Bold3"), url(./components/font/SUKHUMVITTADMAI-EXTRABOLD.OTF) format("truetype");
}

@font-face {
    font-family: "Sukhumvit Set Extra Medium";
    src: local("Sukhumvit Set Medium"), url(./components/font/SukhumvitTadmai-Medium.otf) format("truetype");
}

.btn-block {
    display: block;
    width: 100%;
}

.btn-group-lg>.btn,
.btn-lg {
    padding: 0.5rem 1rem;
    font-size: 1.25rem;
    line-height: 1.5;
    border-radius: 0.3rem;
}

.btn-primary {
    color: #fff;
    background-color: #007bff;
    border-color: #007bff;
}

.segment-value {
    display: none;
}