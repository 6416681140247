@import '../../../../common/commonConstant.scss';

body:has(.challenge-my-reward-container.--no_scroll) {
    overflow: hidden;
}

.challenge-my-reward-container {
    .liff-panel.open {
        z-index: 1000;

        .select-coupon-panel {
            height: auto !important;

            .font-stock {
                margin-bottom: 12px;
            }

            .header {
                .btn-close {
                    width: 32px;
                    height: 32px;
                    color: $black;
                }
            }
        }
    }

    &-header {
        margin-bottom: 17px;
        padding: 0 16px;
        font-size: 20px;
        font-weight: 700;
        font-family: $sukhmaitext;
    }

    &-nav {
        margin-bottom: 20px;
        padding: 0 16px;

        button {
            width: 50%;
            gap: 0;
        }
    }

    &-section {
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        gap: 16px;
        box-sizing: border-box;
        padding: 0 16px;
        margin-bottom: 17px;
    }

    .no-result-found-container {
        padding: 40px;
        margin-top: 24px;
        margin-bottom: 32px;

        .empty-img-wrapper {
            width: 74.85px;
            height: 77.33px;
            object-fit: contain;
        }

        .header {
            font-family: $sukhmaibold;
            font-size: 16px;
            font-weight: 700;
            line-height: 24px;
            text-align: center;
            margin-top: 20px;
        }
    }
}