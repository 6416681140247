@import '../../../../../common/commonConstant.scss';

.assessment-footer-vector {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  position: fixed;
  bottom: 0;

  .footer-parent {
    display: inline-block;
    width: 100%;
    background: $a-white;
    padding: 16px 20px 22px 20px;

    .footer-btn {
      background: $a-healthValue;
      border: 1px solid $a-healthValue;
      cursor: not-allowed;
      border-radius: 38px;
      padding: 6px 8px;
      display: flex;
      justify-content: center;
      width: 48%;
      align-items: center;
      text-align: center;
      color: $a-white;
      height: 48px;
      font-size: 16px;
      line-height: 20px;
      font-weight: 700;
      font-family: $sukhmaitext;
    }

    .footer-btn-disable {
      background: $a-darkgray;
      border: 1px solid $a-darkgray;
    }

    .footer-text {
      text-align: center;
      color: $a-white;
      font-size: 16px;
      font-family: $sukhmaitext;
      font-weight: 700;
      line-height: 20px;
    }
  }

  .footer-curve-bg {
    position: relative;
    bottom: -2px;
    width: calc(100vw + 20px) !important;
  }

}