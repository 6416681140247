@import "../../../../common/commonConstant.scss";

.react-calendar {
  &__year-view__months__month.react-calendar__tile--now {
    color: $gray12;
  }

  &__month-view__weekdays__weekday {
    abbr[title] {
      text-decoration: none;
    }
  }

  &__navigation__label {
    &__labelText {
      &--from {
        display: flex;
        align-items: center;
        justify-content: center;
      }
    }
  }
}