@import '../../commonConstant.scss';

.auto-sync-floating-widget-container {
  .default-modal-size-loader,
  .default-modal-size-success {
    .modal-content {
      width: fit-content;
      margin: auto;
      border-radius: 16px;
      border: none;
    }
  }
  .default-modal-size-auto-sync {
    .modal-content {
      border-radius: 16px;
      border: none;
    }
  }
  .hnw-success-animation-overlay {
    .hnw-success-animation-container {
      border-radius: 16px;
      border: none;
      display: flex;
      align-items: center;
      flex-direction: column;
      justify-content: center;
      box-shadow: none;
      div:first-of-type {
        width: 48px;
        height: 48px;
      }
      .body-key-loader-details {
        margin-top: 20px;
        font-family: $sukhmaitext;
        font-size: 20px;
        font-weight: 700;
        line-height: 30px;
        text-align: left;
      }
    }
  }
}