@import "../commonConstant.scss";

.steps-card-slider-container{
    gap: 15px;
    height: 138px;
    padding: 16px;
    &.collapse{
        background: transparent !important;
    }

    .card-slider-left {
        width: 70%;
        display: flex;
        flex-direction: column;
        .card-slider-calories{
            margin-left: 4px;
        }
        .card-slider-pace{
            &-value{
                margin-right: 4px;
            }

        }
        .card-slider-distance{
            margin-right: 8px;
            &-value{
                margin-right: 4px;
            }
        }
        .card-slider-calories{
            &-value{
                margin-right: 4px;
            }
        }
    }

    .card-slider-right {
        display: flex;
        align-items: end;
        justify-content: center;

        .card {
            background: none;
            position: relative;
            display: flex;
            align-items: center;
            justify-content: center;
            border: none;

            svg {
                width: 73px;
                height: 73px;
                transform: rotate(-90deg);
                position: relative;
                display: flex;
                justify-content: center;
                align-items: center;;

                circle {
                    stroke-width: 10;
                    fill: transparent;

                    &:last-of-type {
                        stroke-dasharray: 195px;
                        stroke-linecap: round;
                        stroke-dashoffset: calc(195px - (195px * var(--percent)) / 100);
                    }

                    &:last-of-type[style*="--percent: 0"] {
                        stroke-width: 0;
                        opacity: 0;
                    }
                }
            }

            .percent {
                display: flex;
                justify-content: center;
                align-items: center;
            }

            .number {
                position: absolute;

                .progress-value-inner {
                    color: $jet-black;
                    font-weight: 800;
                    font-family: $sukhmaitext;
                    font-size: 24px;
                    text-align: center;
                    line-height: 26px;
                }

                .progress-value-unit-container {
                    display: flex;
                    font-size: 12px;
                    font-weight: 500;
                    color: $gray12;
                    gap: 2px;
                }
            }

            &.progress-card-green-1 {
                svg {
                    circle {
                        stroke: $a-circleStroke;
                    }
                }
            }
        }

        .outer-circle {
            background-image: url('/images/challenge/outer-circular-bar.svg');
            height: 104px;
            width: 104px;
            background-position: center;
            background-repeat: no-repeat;
            display: flex;
            align-items: center;
            justify-content: center;
            background-size: contain;
        }

        .progress-value {
            font-size: 16px;
            font-weight: 700;
            font-family: $sukhmaitext;
            color: $black;
        }

    }

}