@import '../../common/commonConstant.scss';

.food-overview-meal-card {
    box-shadow: 1px 8px 18px 0px $flblack;
    background: $bgwhite;
    padding: 12px;
    border-radius: 12px;
    display: flex;
    justify-content: space-between;
    font-family: $sukhmaitext;

    .food-overview-meal-card-wrapper {
        width: 100%;

        .food-overview-meal-card-header-container {
            display: flex;
            justify-content: space-between;
            margin-bottom: 8px;


            .food-overview-meal-card-left {
                display: flex;
                align-items: center;
                gap: 6px;

                .food-overview-meal-card-left-title {
                    font-size: 16px;
                    font-weight: 700;
                    line-height: 24px;
                }

                .food-overview-meal-card-left-value {
                    font-size: 14px;
                    font-weight: 500;
                    color: $homegrey;
                    display: flex;
                    gap: 2px;
                }
            }

            .food-overview-meal-card-right {
                display: flex;
                align-items: center;

                .btn-icon-round,
                .btn-icon-round:active,
                .btn-icon-round:hover,
                .btn-icon-round:focus {
                    height: 32px;
                    width: 32px;
                    background: $a-offwhite;
                    border-radius: 50%;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    box-shadow: none;
                }
            }
        }
    }
}