@import '../../../../common/commonConstant.scss';

.my-composition-card-container {

    padding: 0px 16px 40px;

    .nav-buttons-margin {
        margin-bottom: 20px;
    }

    .my-composition-heading {
        font-family: $sukhmaitext;
        font-weight: 700;
        font-size: 18px;
        line-height: 27px;
        margin-bottom: 20px;
        color: $black;
    }

    .challenge-card-body {
        box-shadow: 0px 4px 20px 0px $challenge-card-shadow;
        border-radius: 16px;
        padding: 16px;
        display: flex;
        justify-content: space-between;
        background: $a-serviceCard;
        flex-direction: column;
        gap: 8px;

        .my-composition-body {
            display: flex;
            justify-content: space-between;
            width: 100%;

            .my-body-heading {
                font-family: $sukhmaitext;
                font-size: 18px;
                font-weight: 700;
                line-height: 27px;
                color: $black;
            }

            .my-body-values-container {
                display: flex;
                align-items: flex-end;

                .my-body-value {
                    font-family: $gtwalsheimbold;
                    font-size: 20px;
                    font-weight: 700;
                    line-height: 24px;
                    margin-right: 4px;
                }

                .my-body-unit {
                    font-family: $sukhmaitext;
                    font-size: 14px;
                    font-weight: 500;
                    line-height: 21px;
                    color: $black;
                    width: 20px;
                }
            }
        }
    }

    .bmi-green {
        color: $hnw-green  !important;
    }

    .active-color {
        color: $black  !important;
    }

    .disable-color {
        color: $disabled-color;
    }

    .dividing-line {
        border-bottom: 2px solid $card-divider-line1;
    }
}