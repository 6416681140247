@import "../commonConstant.scss";

/********************* DASHBOARD SWIPER SLIDER CSS START *********************/


.dashboard-slider-outer {
  .dashboard-slider-wrapper {
    .dashboard-slider-container {

      .swiper-3d,
      .swiper-3d.swiper-css-mode .swiper-wrapper {
        perspective: 25cm;

        @media only screen and (max-width: 445px) and (min-width: 430px) {
          perspective: 20cm;
        }
      }

      .swiper-carousel {
        width: calc(100% - 150px);
        margin: 0;
        left: -150px;
        transform: translateX(calc(153px - 25%));

        @media only screen and (max-width: 376px) and (min-width: 360px) {
          transform: translateX(calc(163px - 25%));
        }

        .dashboard-slider-carousel {
          display: flex;
          align-items: center;
        }
      }

      .dashboard-slider-green-wrapper {
        height: auto;
        width: 6%;
        z-index: 14;
        position: relative;
        display: flex;
        align-items: center;

        .dashboard-slider-card-green {
          width: 100%;
          background: $flgreen1;
          height: calc(100% - 100px);
          border-radius: 0 16px 16px 0;
          box-shadow: 0px 4px 20px 0px $challenge-card-shadow;

          &.sm {
            height: calc(100% - 140px);
          }
        }
      }

      .dashboard-slider-sidebar {
        background: $bgwhite;
        box-shadow: 0px 4px 20px 0px $challenge-card-shadow;
        border-radius: 0 16px 16px 0;
        width: 150px;
        padding: 20px 0;
        z-index: 2;

        .dashboard-slider-sidebar-title {
          font-size: 18px;
          font-weight: 700;
          font-family: $sukhmaitext;
          color: $black;
          padding-bottom: 16px;
        }

        .dashboard-slider-sidebar-profile-wrapper {
          display: flex;
          flex-direction: column;
          gap: 16px;

          .dashboard-slider-sidebar-profile {
            text-align: center;

            .dashboard-slider-sidebar-profile-name {
              font-size: 14px;
              font-weight: 700;
              font-family: $sukhmaitext;
              color: $black;
            }
          }
        }
      }

      &.swiper-transition {
        .dashboard-slider-content {
          &.swiper-slide-active {
            left: 8px;
          }

          &.swiper-slide-next {
            left: 5px;
          }

          &.swiper-slide-next~.swiper-slide {
            left: 5px;
          }
        }

        @media screen and (min-width: 375px) {
          .dashboard-slider-content {
            &.swiper-slide-active {
              left: 18px;
            }
          }
        }

        @media screen and (min-width: 390px) {
          .dashboard-slider-content {
            &.swiper-slide-active {
              left: 16px;
            }
          }
        }

        @media screen and (min-width: 410px) {
          .dashboard-slider-content {
            &.swiper-slide-active {
              left: 12px;
            }
          }
        }

        @media screen and (min-width: 414px) {
          .dashboard-slider-content {
            &.swiper-slide-active {
              left: 8px;
            }
          }
        }
      }

      .dashboard-slider-parent {
        .dashboard-slider-content {
          box-shadow: 0px 4px 20px 0px $challenge-card-shadow;
          text-align: center;
          display: flex;
          flex-direction: column;
          justify-content: center;
          width: 124px;
          border-radius: 0 16px 16px 0;
          height: calc(100% - 10px);
          backdrop-filter: blur(6px);
          background: $challenge-slider-bg;

          @media only screen and (max-width: 460px) and (min-width: 430px) {
            height: calc(100% - 72px);
          }

          @media only screen and (max-width: 429px) and (min-width: 400px) {
            height: calc(100% - 60px);
          }

          @media only screen and (max-width: 399px) and (min-width: 360px) {
            height: calc(100% - 48px);
          }

          @media only screen and (max-width: 359px) and (min-width: 340px) {
            height: calc(100% - 32px);
          }

          @media (max-width: 324px) {
            width: 119px;
          }

          &.swiper-slide-next {
            background: $flgreen1;
            box-shadow: 0px 4px 20px 0px $challenge-card-shadow;

            .dashboard-slider-weight-title,
            .dashboard-slider-steps-title,
            .dashboard-slider-weight-wrapper,
            .dashboard-slider-steps-wrapper {
              display: none;
            }
          }

          &.swiper-slide-next~.swiper-slide {
            background: transparent;
            box-shadow: none;
          }

          &.swiper-slide-next+.swiper-slide {
            background: #578741;
            box-shadow: 0px 4px 20px 0px $challenge-card-shadow;

            .dashboard-slider-weight-title,
            .dashboard-slider-steps-title,
            .dashboard-slider-weight-wrapper,
            .dashboard-slider-steps-wrapper {
              display: none;
            }

            @media only screen and (max-width: 399px) and (min-width: 360px) {
              height: calc(100% - 48px);
            }

            @media only screen and (max-width: 430px) and (min-width: 400px) {
              height: calc(100% - 80px);
              left: 8px;
            }

            @media only screen and (max-width: 460px) and (min-width: 430px) {
              height: calc(100% - 94px);
              left: 5px;
            }
          }

          &.swiper-slide-active {
            width: 153px;
            height: calc(100% - 50px);

            @media (max-width: 324px) {
              width: 136px;
            }
          }

          .dashboard-slider-weight-title,
          .dashboard-slider-steps-title {
            font-size: 18px;
            font-weight: 700;
            font-family: $sukhmaitext;
            margin-bottom: 20px;

            span:last-of-type {
              font-size: 14px;
              font-weight: 500;
            }
          }

          .dashboard-slider-weight-wrapper,
          .dashboard-slider-steps-wrapper {
            display: flex;
            flex-direction: column;
            gap: 0px;
            justify-content: space-between;
            height: calc(100% - 92px);

            .dashboard-slider-weight,
            .dashboard-slider-steps {
              font-size: 20px;
              font-weight: 700;
              font-family: $sukhmaitext;
            }

            .dashboard-slider-steps {
              &-total {
                &.bodyDataClass {
                  color: $black;
                }

                &-outof {
                  font-size: 14px;
                  font-weight: 400;
                  font-family: $sukhmaitext;
                  color: $black;

                }

                &.black-text {
                  color: $black;
                }
              }

              .diff-value-container {
                .rect-badge-container {
                  width: 78px;
                  margin-inline: 26%;
                }

                .rect-badge-description {
                  display: flex;
                  align-items: center;
                  justify-content: flex-start;
                  width: 100%;
                  gap: 4px;

                  .rect-badge-title {
                    font-family: $gtwalsheimpro;
                    font-size: 14px;
                    font-weight: 700;
                  }

                  .rect-badge-value {
                    font-family: $sukhmaitext;
                    font-size: 14px;
                    font-weight: 500;
                  }
                }
              }
            }

            .dashboard-slider-weight:last-of-type,
            .dashboard-slider-steps:last-of-type {
              margin-bottom: 30px;
            }

            &.dashboard-slider-steps-wrapper {
              gap: 0px;

              .dashboard-slider-steps {
                color: $homegreen;

                .rect-badge-container {
                  width: 100px;
                }

                &:last-of-type {
                  margin-bottom: 0px;
                }

                .badge-total img {
                  height: 18px;
                  margin-right: 4px;
                }
              }
            }
          }
        }
      }


    }
  }
}

/******************** SWIPER SLIDER CSS START *********************/

.swiper-button-prev {
  display: none;
}

.swiper-button-next {
  right: 15px;

  @media only screen and (max-width: 460px) and (min-width: 430px) {
    right: 38px;
  }

  @media only screen and (max-width: 400px) and (min-width: 376px) {
    right: 8px;
  }

  @media (max-width: 376px) {
    right: 0;
  }

  &,
  .swiper-button-prev {
    color: $black;
    width: 48px;
    height: 48px;
    background: $bgwhite;
    box-shadow: 4px 4px 18px 0px $challenge-button-tab;
    border-radius: 50%;

    @media only screen and (max-width: 362px) and (min-width: 324px) {
      width: 32px;
      height: 32px;
    }

    @media (max-width: 324px) {
      width: 24px;
      height: 24px;
    }

    &:after {
      font-size: 14px;
      font-weight: 900;

      @media only screen and (max-width: 362px) and (min-width: 324px) {
        font-size: 10px;
      }

      @media (max-width: 324px) {
        font-size: 8px;
      }
    }
  }
}