@import "../../../common/commonConstant.scss";

.create-address-container {
  display: flex;
  flex-direction: column;
  flex: 1;
  max-height: 100vh;
  background-color: $bggray;
  .away-button {
    min-height: 44px;
  }
  .modal-container {
    text-align: center;
    width: unset;
  }
  > .body {
    display: flex;
    flex-direction: column;
    flex: 1;
    padding: 15px;
    margin: 15px;
    background-color: $bgwhite;
    border-radius: 12px;
    overflow: scroll;
    .address-form {
      label {
        font-size: 13px;
        color: #727272 !important;
      }
      label:not(:first-child) {
        display: block;
        margin-top: 10px;
      }
      .input-line-bottom {
        border-bottom: solid 1px #ededed !important;
      }
    }
    > .header {
      font-weight: bold;
      text-align: center;
      padding-bottom: 20px;
    }
    > .map-button {
      margin-bottom: 20px;
    }
  }
  > .footer {
    display: flex;
    flex-direction: column;
    bottom: 0;
    width: 100%;
    padding: 15px;
    .submit-button {
      background-color: #2c2c2c;
      padding: 10px 30px;
      color: #ffffff;
      border-radius: 100px;
    }
    .submit-button.disabled {
      border: 0;
      background-color: #ededed;
      color: #2c2c2c;
    }
  }
}
