@import '../../../../common/commonConstant.scss';

.row {
    .bmi-progress-bar-arrow {
        display: flex;
        margin-top: 5px;

        .bmi-progress-blue-img,
        .bmi-progress-green-img,
        .bmi-progress-yellow-img,
        .bmi-progress-orange-img,
        .bmi-progress-red-img {
            width: 20%;
            text-align: center;
            visibility: hidden;
        }
    }

    .bmi-progress-bar {
        width: 100%;
        height: 8px;
        margin: 0;
        display: flex;
        margin-bottom: 20px;
        border-radius: 10px;
        overflow: hidden;
        justify-content: space-between;

        .bmi-progress-blue,
        .bmi-progress-green,
        .bmi-progress-yellow,
        .bmi-progress-orange,
        .bmi-progress-red {
            width: 19.6%;
        }

        .bmi-progress-blue {
            background-color: $bmiblue;
        }

        .bmi-progress-green {
            background-color: $bmigreen;
        }

        .bmi-progress-yellow {
            background-color: $bmiyellow;
        }

        .bmi-progress-orange {
            background-color: $bmiorange;
        }

        .bmi-progress-red {
            background-color: $bmired;
        }
    }
}