@import "../../../common/commonConstant.scss";

.challenge-map-loading {
  &-wrapper {
    background-image: url('/images/challenge/cloud_opening.svg');
    background-size: cover;
    height: 100vh;
    position: fixed;
    width: 100%;
    z-index: 1000;
    background-color: $challenge-white-shadow;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  &-content {
    text-align: center;

    img {
      width: 160px;
    }

    &_text {
      font-family: $sukhmaitext;
      font-size: 22px;
      font-weight: 800;
      line-height: 33px;
      color: $black;
      margin-top: 8px;
    }

    .progress-container {
      width: 343px;
      background: linear-gradient(107.74deg, $gray17 27.91%, $gray18 98.01%);
      border-radius: 10px;
      overflow: hidden;
      box-shadow: 4px 4px 5.3px 0px $challenge-progress-shadow inset;
      height: 20px;
      padding: 6px;
      margin-top: 12px;
      position: relative;
    }

    .progress-bar {
      width: var(--loading-percent);
      max-width: calc(100% - 12px);
      height: 8px;
      background: linear-gradient(270deg, $challenge-progress-gradient1 0%, $challenge-progress-gradient2 100%);
      border-radius: 10px;
      position: absolute;
    }

    .progress-bar-bg {
      position: absolute;
      background: $white;
      width: calc(100% - 12px);
      z-index: 0;
      height: 8px;
      border-radius: 4px;
    }
  }
}