@import '../../../../common/commonConstant.scss';


.target-weight-graph {
  background-color: $white;
  border-radius: 16px;
  box-shadow: 0px 4px 20px 0px #32644C26;
  padding: 10px;
  position: relative;

  &-overlay {
    position: absolute;
    height: 100%;
    width: 100%;
    top: 0;
    left: 0;
    border-radius: 16px;
  }

  &-heading {
    font-family: $sukhmaitext;
    font-weight: 700;
    font-size: 18px;
    color: $black;
    margin-left: 16px;
  }

  &-wrapper {
    position: relative;

    &.--monthly .apexcharts-xaxis-texts-g {

      :not(:first-child) {
        visibility: hidden;
      }
    }
  }

  &-value {
    position: absolute;
    right: 10px;
    bottom: 38px;
    font-size: 40px;
    font-family: $gtwalsheimbold;
    font-weight: 700;
    color: $black;
  }

  &-unit {
    font-family: $sukhmaitext;
    font-weight: 400;
    font-size: 14px;
  }

  &_footer {
    display: flex;
    align-items: center;
    gap: 8px;

    &_text {
      font-family: $sukhmaitext;
      font-size: 14px;
      font-weight: 500;
      color: $black;

      span {
        font-family: $gtwalsheimpro;
        font-weight: 700;
        margin: 0 4px;
      }
    }
  }

  &_title {
    padding: 4px 8px;
    color: $homegreen2;
    font-size: 14px;
    display: flex;
    align-items: center;
    gap: 4px;
    background-color: $arrow-bg;
    border-radius: 8px;

    &.--different {
      background-color: $homeMaroonLight2;
    }

    &-arrow {
      &.--same {
        width: 12px;
        border-top: 4px solid $arrow-up;
        border-left: none;
        border-right: none;
      }

      &.--upward {
        border-top: none;
        border-left: 6px solid transparent;
        border-right: 6px solid transparent;
        border-bottom: 12px solid $arrow-up;

        .--different & {
          // border-top: none;
          border-bottom-color: $homeMaroon1;
        }
      }

      &.--downward {
        border-bottom: none;
        border-left: 6px solid transparent;
        border-right: 6px solid transparent;
        border-top: 12px solid $arrow-up;

        .--different & {
          // border-bottom: none;
          border-top-color: $homeMaroon1;
        }
      }
    }

    &-value {
      font-family: $gtwalsheimpro;
      font-weight: 700;

      &.--thai {
        font-family: $sukhmaitext;
      }

      .--different & {
        color: $challenge-error-text;
      }
    }

    &-unit {
      font-family: $sukhmaitext;
      font-weight: 400;

      .--different & {
        color: $challenge-error-text;
      }
    }
  }
}