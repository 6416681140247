@import "../../../../common/commonConstant.scss";

.white-card-container {
    height: 163px;
    box-shadow: 0px 4px 20px 0px $challenge-card-shadow;
    background: linear-gradient(132.59deg, $challenge-slider-bg 38.77%, $challenge-slider-bg1 113.8%);
    border-radius: 16px;
    padding-bottom: 10px;

    .white-card-title {
        font-size: 18px;
        font-weight: 700;
        font-family: $sukhmaitext;
        padding-left: 16px;
        padding-top: 11px;
    }

    .drink-water-details-container {
        display: flex;
        align-items: flex-end;
        justify-content: space-between;
        padding-bottom: 5px;
        position: relative;

        .glass-image-levels {
            position: absolute;
            bottom: 19px;
            left: 34px;
            padding: 0;
            margin: 0;
            display: flex;
            flex-direction: column;
            width: 46px;

            .water-head-img {
                width: 47.14px;
                margin-left: -3px;
            }

            .water-level-img {
                width: 46px;
                margin-left: -2px;
                margin-bottom: 0px;
                object-fit: cover;
            }

            .water-bottom-img {
                width: 47.14px;
                margin-left: -3px;
                margin-bottom: 0px;
            }
        }

        .drink-glass-img-container {
            height: 100%;
            position: relative;
            padding-left: 3px;

            .glass-logo {
                position: absolute;
                bottom: 0;
                left: 67%;
                top: 50%;
                transform: translate(-50%, -50%);
                z-index: 9;
            }



            .scale-value-wrapper {
                height: 93px;
                display: flex;
                flex-direction: column;
                justify-content: space-between;
                position: absolute;
                top: 0;
                text-align: right;
                left: 4px;
            }
        }

        .drink-glass-description {
            padding-right: 16px;
            padding-bottom: 5px;

            .used-value {
                font-size: 28px;
                font-weight: 700;
                font-family: $gtwalsheimpro;
                line-height: 48px;
                margin-bottom: 0;
                text-align: right;
                letter-spacing: -0.02em;
            }

            .total-value {
                font-size: 14px;
                font-weight: 500;
                font-family: $sukhmaitext;
                line-height: 21px;
            }
        }
    }
}