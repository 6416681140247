@import "../../../common/commonConstant.scss";

.otp-container {
  text-align: center;
  .header {
    position: relative;
    img {
      width: 100%;
    }
    h2 {
      position: absolute;
      top: 0;
      left: 0;
      display: flex;
      justify-content: center;
      align-items: center;
      width: 100%;
      height: 100%;
      margin: 0;
    }
  }
  .otp-content {
    margin-bottom: 30px;
    a {
      text-decoration: underline;
      font-size: 12px;
    }
    .ref-text {
      color: $gray;
    }
    .otpinput-container {
      width: fit-content;
      margin: 0 auto;
      input {
        border-radius: 4px;
        border-style: solid;
        padding: 5px 0;
        box-sizing: content-box;
        font-weight: bold;
      }
    }
  }
  .otp-footer {
    position: absolute;
    bottom: 5px;
    width: 100%;
    padding: 15px;
    z-index: 9999;
    clear: both;
    .link {
      display: block;
      margin-bottom: 10px;
      text-decoration: underline;
    }
  }
}
