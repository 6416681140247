@import '../../common/commonConstant.scss';

.log-card-info {
    display: flex;
    height: 92px;
    border-radius: 12px;
    padding: 12px;
    gap: 4px;
    background-color: $white;
    box-shadow: 1px 8px 18px 0 rgba(44, 44, 44, 0.1);
    margin-bottom: 16px;


    .log-card-info-img {
        img {
            width: 68px;
            height: 68px;
            margin-right: 8px;
            border-radius: 50%;
            object-fit: cover;
        }
    }

    .exercise-log-card-info-img {
        img {
            width: 68px;
            height: 68px;
            margin-right: 8px;
            border-radius: 10px;
        }
    }

    .log-card-info-content {
        display: flex;
        flex-direction: column;
        gap: 2px;
        width: 80%;

        .log-title {
            font-size: 16px;
            line-height: 24px;
            font-weight: 700;
            color: $black;
            font-family: $sukhmaitext;
            text-overflow: ellipsis;
            white-space: nowrap;
            overflow: hidden;
            width: 90%;
        }

        .log-description-wrapper {
            display: flex;


            .log-description {
                display: flex;
                gap: 4px;
                margin-right: 16px;

                .food-log-img {
                    width: 16px;
                    height: 16px;
                }

                .food-log-value {
                    font-size: 14px;
                    line-height: 20px;
                    font-weight: 700;
                    color: $black;
                    font-family: $sukhmaitext;
                }

                .food-log-unit {
                    font-size: 14px;
                    line-height: 20px;
                    font-weight: 500;
                    color: $gray12;
                    font-family: $sukhmaitext;
                    display: flex;
                    align-items: center;
                }
            }
        }



        .log-date {
            font-size: 14px;
            line-height: 20px;
            font-weight: 500;
            font-family: $sukhmaitext;
            color: $gray12;

            span {
                margin-left: 8px;
            }
        }
    }
}