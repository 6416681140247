@import "../../../../common/commonConstant.scss";

.quiz-footer-bottom {
    .btn-container {
        display: flex;
        justify-content: space-between;
        align-items: center;

        .btn-green {
            font-size: 16px;
            font-weight: 700;
            border-radius: 38px;
            padding: 6px 8px;
            margin-top: 10px;
            display: flex;
            justify-content: center;
            width: 48%;
            align-items: center;
        }

        .btn-green-linear {
            border-radius: 48px;
            display: inline-block;
            background:
                linear-gradient(135deg, $challenge-success-green1 -39.29%, $challenge-success-green2 82.14%) padding-box,
                linear-gradient(132.38deg, $challenge-success-green3 -14.45%, $challenge-success-green4 122.44%) border-box;
            border: 4px solid transparent;
            box-shadow: 2px 3px 6px 0px $quiz-btn-green-shdw;
            height: 48px;
            color: $a-circleStroke;
            font-size: 16px;
            font-weight: 700;
            font-family: $sukhmaitext;
        }

        .btn-white-linear {
            border-radius: 48px;
            display: inline-block;
            background:
                linear-gradient(107.74deg, $challenge-nomember-bg 27.91%, $challenge-nomember-bg 98.01%) padding-box,
                linear-gradient(139.18deg, $bmi-slider-bg-2 16.07%, $white2 93.35%) border-box;
            border: 2px solid transparent;
            height: 48px;
            color: var(--color-neutral-neutral-400, $anstype-inner-bg);
            ;
            font-size: 16px;
            font-weight: 700;
            font-family: $sukhmaitext;
        }

        .btn-back-white {
            font-family: $sukhmaitext;
            background: linear-gradient(107.74deg, $header-sticky-white 27.91%, $white1 98.01%);
            border: 2.5px solid $bmi-slider-bg-4;
            box-shadow: 4px 4px 18px 0px $profile-square-boxshw;
        }
    }
}