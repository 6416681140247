@import "../../../common/commonConstant.scss";

$max-users: 4;

body::-webkit-scrollbar:has(.challenge-users-container) {
    display: none; // Safari and Chrome
}

.challenge-users-container {
    display: flex;

    .challenge-users-profile {
        width: 32px;
        height: 32px;
        border-radius: 50%;
        transition: all 0.3s ease;
        position: relative;

        &-image {
            margin-left: 0;
            border-radius: 50%;
            height: 100%;
            width: 100%;

            &-leader {
                border: 1.5px solid $challenge-crown-img-border;
                background: $challenge-crown-img-border;
            }

            &-normal {
                border: 1.5px solid $white;
                background: $white;
            }
        }

        &:nth-child(1) {
            margin-left: 0;
            z-index: $max-users;
        }

        @for $i from 2 through $max-users {
            &:nth-child(#{$i}) {
                z-index: #{$max-users - $i + 1};
                margin-left: -8px;
            }
        }
    }

    .challenge-users-crown {
        position: absolute;
        right: -7px;
        bottom: -7px;
    }
}

.challenge-users-loader-container {
    display: flex;

    .challenge-users-loader-img {
        width: 32px;
        height: 32px;
        border-radius: 50%;
        background-color: $challenge-users-loader-bg;
        animation: skeleton-loading 1.5s infinite ease-in-out;

        &:not(:first-child) {
            margin-left: -8px;
        }
    }

    @keyframes skeleton-loading {
        0% {
            background-color: $challenge-users-loader-bg;
        }

        50% {
            background-color: $challenge-users-loader-middle;
        }

        100% {
            background-color: $challenge-users-loader-bg;
        }
    }
}

.challenge-users-tag {
    display: flex;
    align-items: center;
    margin-left: -10px;
}