@import "../../common/commonConstant.scss";

.collapse-container {
  hr {
    margin: 0;
    background: $gray3;
  }
  .collapse-header {
    display: flex;
    &.standalone {
      background-color: $bgwhite;
      box-shadow: 0px 5px 15px rgba(0, 0, 0, 0.04);
      padding: 15px;
    }
    .title {
      margin-right: auto;
    }
    .suffix {
      img {
        transition: all 1s cubic-bezier(0.075, 0.82, 0.165, 1);
      }
      &:not(&.custom) {
        &.active {
          img {
            transform: rotateZ(180deg);
            transition: all 1s cubic-bezier(0.075, 0.82, 0.165, 1);
          }
        }
      }
    }
  }
  .collapse-content {
    overflow: hidden;
    max-height: 0;
    transition: all 1s cubic-bezier(0.075, 0.82, 0.165, 1);
    height: auto;
    .content-container {
      margin: 15px;
    }
    &.standalone {
      background-color: $bggray;
    }
    &.active {
      max-height: var(--max-height);
      transition: all 1s cubic-bezier(0.075, 0.82, 0.165, 1);
    }
  }
}
