@import '../commonConstant.scss';

.health-log-section {
    position: relative;
    width: 100%;
    top: -40px;

    .food-log-container {
        display: flex;
        gap: 12px;

        &.two-cards>div {
            width: 50%;
        }

        &>div {
            flex-grow: 1;
        }
    }
}

@media (min-width: 324px) {
    .homelog-mobile-lg {
        display: block;
    }

    .homelog-mobile-sm {
        display: none;
    }
}

@media (max-width: 324px) {
    .homelog-mobile-lg {
        display: none;
    }

    .homelog-mobile-sm {
        display: block;
    }

    .health-log-section {
        top: -45px;
    }

    .health-log-content-heading {
        margin-top: 30px;
    }

    .health-log-content-subheading {
        padding: 0 24px;
    }
}

.health-log-content-subheading {
    font-size: 16px;
    font-weight: 500;
    line-height: 20px;
    padding: 0 50px;
    margin-bottom: 16px;
}

.health-log-content-wrapper {
    position: absolute;
    top: 50%;
    transform: translate(-50%, -50%);
    left: 50%;
    width: calc(100% - 32px);
}

.health-log-content-food {
    margin-bottom: 16px;
}

.health-log-content-heading {
    font-size: 24px;
    font-weight: 800;
    font-family: $sukhmaitext;
    line-height: 26px;
    margin-bottom: 8px;
}

.health-log-content-heading,
.health-log-content-subheading {
    text-align: center;
}

@media (max-width: 324px) {
    .health-log-content-heading {
        margin-top: 30px;
    }
}

.health-log-water-card-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.health-log-water-card-container {
    gap: 16px;
}