@import "../../commonConstant.scss";

.profile-image-frame {
    position: absolute;
    top: -32px;
    left: 50%;
    transform: translate(-50%);
    color: $bmi-bar-gradient-green;

    .user-image {
        position: absolute;
        left: 7%;
        top: 4.9%;
        width: 55.5px;
        height: 55.5px;
        border-radius: 50%;
    }

    .btn-icon-crown {
        height: 27px;
        width: 27px;
        border-radius: 50%;
        background: $bgwhite;
        position: absolute;
        left: 56%;
        top: 38px;
        box-shadow: 1px 2px 3px 0px $crown-icon-shadow;
        z-index: 9;
    }

    .crown-img {
        position: absolute;
        left: 60%;
        transform: translate(-58%);
        z-index: 9;
    }

}