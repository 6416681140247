@import '../../../../common/commonConstant.scss';

.row {

    .bmi-card {
        background: $homewhite;
        border-radius: 10px;
        margin-top: 15px;
        overflow: hidden;

        .bmi-card-single {
            padding: 8px;
            display: flex;
            justify-content: space-between;
            align-items: center;
            height: 36px;

            .bmi-card-single-left {
                display: flex;

                .bmi-details-dot-icon {
                    width: 18px;
                    height: 18px;
                    border-radius: 50%;
                    margin-right: 16px;
                }

                .bmi-details-dot-blue-icon {
                    background: $bmiblue;
                }

                .bmi-details-dot-green-icon {
                    background: $bmigreen;
                }

                .bmi-details-dot-yellow-icon {
                    background: $bmiyellow;
                }

                .bmi-details-dot-orange-icon {
                    background: $bmiorange;
                }

                .bmi-details-dot-red-icon {
                    background: $bmired;
                }
            }
        }
    }

    .bmi-card-total {
        display: flex;
        justify-content: space-between;
        padding: 10px;
        padding-bottom: 0;
        font-family: $sukhmaibold;

        .bmi-card-total-left,
        .bmi-card-total-right {
            font-size: 16px;
            font-weight: 700;
        }

        .bmi-card-total-left {
            color: $homegrey1;
        }

        .bmi-card-total-right {
            color: $homegreen;
        }
    }
}