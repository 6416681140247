@import "../../../common/commonConstant.scss";

.payment-success-container {
  background-color: $bggray;
  .header {
    text-align: center;
    margin: 20px 0;
  }
  .body {
    background-color: $bgwhite;
    box-shadow: 0px 5px 15px rgba(0, 0, 0, 0.04);
    border-radius: 12px;
    margin: 15px;
    text-align: center;
    padding: 15px;
    hr {
      background: $gray3;
    }
    .bold {
      font-weight: bold;
      font-size: 18px;
    }
    .bottom{
      .amount{
        color: $medium-blue;
      }
    }
  }
  .footer{
    position: fixed;
    bottom: 0;
    width: 100%;
    padding: 20px 10px;
  }
}
