@import "../commonConstant.scss";

.bmi-range-slider {
    position: relative;

    .bmi-slider-value {
        display: flex;
        align-items: center;
        gap: 4px;

        .bmi-slider-count {
            font-size: 28px;
            font-weight: 700;
            font-family: $gtwalsheimbold;
        }

        .bmi-slider-unit {
            font-size: 14px;
            font-weight: 500;
            font-family: $sukhmaitext;
        }
    }

    .slider-container {
        --label: 2.75rem;
        --width: 100%;
        --thumb: clamp(3rem, calc(2.28rem + 3.18vw), 3.88rem);
        inline-size: var(--width);
        aspect-ratio: 10.665 / 1;
        display: grid;
        position: relative;
        padding-inline: var(--space);
        border: 0;
        border-radius: 100vh;
        box-shadow: 2px 2px 4px 0px $bmi-bar-box-shadow inset;
        height: 20px;
        background: linear-gradient(107.74deg, $bmi-bar-bg-1 27.91%, $bmi-bar-bg-2 98.01%);
        margin: 12px 0;

        .input-slider {
            place-self: center;
            border-radius: 100vh;
            width: calc(var(--width) - 1rem);
            height: 8px;
            box-shadow: inset 0 2px 3px $bmi-bar-input-shadow-1, inset 0 -2px 2.5px 0.5px $bmi-bar-input-shadow-2;
            -webkit-appearance: none;
            appearance: none;
            outline: none;
            border-bottom: none;
            bottom: 4px;
            pointer-events: none;
        }

        .input-slider::-webkit-slider-thumb {
            border-radius: 50%;
            width: 14px;
            aspect-ratio: 1;
            -webkit-appearance: none;
            appearance: none;
            background: linear-gradient(107.74deg, $bmi-slider-bg-1 27.91%, $a-progress-bar-bg 98.01%) padding-box, linear-gradient(139.18deg, $bmi-slider-bg-2 16.07%, $bmi-slider-bg-3 93.35%) border-box;
            border: 2.5px solid transparent;
            box-shadow: 1px 1px 2px 0px $bmi-slider-shadow;
        }
    }

    .active-slider {
        background: linear-gradient(90deg, $bmi-bar-gradient-blue 0%, $bmi-bar-gradient-green 28%, $bmi-bar-gradient-yellow 51%, $bmi-bar-gradient-orange 75%, $bmi-bar-gradient-peach 100%);
    }

    .disable-slider {
        background: $disabled-color;
    }

    .bmi-green {
        color: $bmigreen;
    }

    .disable-color {
        color: $disabled-color;
    }
    .bmi-slider-count{
        &.bmi-low{
            color: $bmiblue;
        }
        &.bmi-normal{
            color: $bmigreen;
        }
        &.bmi-fat-1{
            color: $bmiyellow;
        }
        &.bmi-fat-2{
            color: $bmiorange;
        }
        &.bmi-fat-3{
            color: $bmired;
        }
    }


}