@import "../../../../../common/commonConstant.scss";

.asm-des-ctn {
    padding: 0 20px 24px 20px;
    display: flex;
    flex-direction: column;
    min-height: 90vh;

    &-w1 {
        &-hdr {
            display: flex;
            flex-direction: column;
            margin-top: 64px;
            top: 0px;
            overflow: hidden;
            position: absolute;
            padding-right: 20px;

            &-h1,
            &-h2 {
                font-size: 20px;
                line-height: 28px;
                font-weight: 800;
                color: $a-pri-green;
                font-family: $sukhmaitext;
            }
        }

        &-cnt {
            display: flex;
            flex-direction: column;
            padding: 12px;
            border-radius: 12px;
            background-color: $a-neutral;
            margin-top: 100px;
            overflow-y: scroll;
            position: absolute;
            height: 65%;
            margin-right: 18px;

            &-c1 {
                font-size: 16px;
                line-height: 24px;
                font-weight: 700;
                color: $a-neutral-h1;
                font-family: $sukhmaitext;

            }

            &-c2 {
                font-size: 14px;
                line-height: 20px;
                font-weight: 500;
                color: $a-neutral-h1;
                font-family: $sukhmaitext;
            }
        }
    }

    &-w2 {
        margin-top: auto;
        overflow: hidden;

        &-btn {
            margin-top: 16px;
            display: flex;
            justify-content: center;

            .btnClass {
                width: 276px;
                height: 48px;
                display: flex;
                justify-content: center;
                align-items: center;
                padding: 6px 8px 6px 8px;
                border-radius: 38px;
                border: 1px;
                gap: 4px;
                background-color: $a-green;
                color: $bgwhite;
                text-align: center;
                font-family: $sukhmaitext;
                font-weight: 700;
                font-size: 16px;
                line-height: 20px;
            }
        }

    }
}