@import '../../../common/commonConstant.scss';

.assessment-container {
  background-repeat: no-repeat;
  background-size: 100%;
  height: 100%;
  background-attachment: fixed;
  .assessment-body {
    height: 100vh;
  }

  .template-wrapper {
    margin-top: 20vh;
    margin-bottom: 20vh;
  }

  .assessment-wrapper {
    padding: 0 6px;
  }

  @media(max-height: 765px){
    .template-wrapper {
      margin-top: 22vh !important;
    }
  }
}



@media(min-height: 712px){
  .assessment-container{
    overflow-y: hidden;
  }
}

.w-h-100 {
  width: 100%;
  height: 100%;
}

.w-h-24 {
  width: 24px;
  height: 24px;
}

.word-wrap-break {
  word-wrap: break-word;
}

.scroll-auto {
  overflow-y: auto;
}

.no-border {
  border: none !important;
}

.template-parent {
  background-image: url("/images/healthAndWellness/assessment/bg_health.svg");
  background-repeat: no-repeat;
  height: 100vh;
}
