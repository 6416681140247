@import '../../../common/commonConstant.scss';

.w-50 {
    width: 50%;
}

.w-100 {
    width: 100%;
}

.cart-icon {
    position: absolute;
    top: 20px;
    right: 0px;
}

.sop-header {
    margin-top: 5px;
    margin-left: 20px;
    position: absolute;
}

.sop-header-image {
    box-shadow: 4px 4px 8px 0px $shadowgrey;
}
.sop-header {
    .new-h2 {
        font-family: $sukhmaitext;
        font-size: 20px;
        font-weight: 800;
        line-height: 28px;
        letter-spacing: 0px !important;
        text-align: left;
        margin-bottom: 0px;
        margin-top: 15px;
        color: $black;
        word-spacing: 0px !important;
    }

    .subheading {
        font-family: $sukhmaitext;
        font-size: 14px;
        font-weight: 500;
        line-height: 20px;
        letter-spacing: 0px !important;
        text-align: left;
        color: $gray8;
    }
}

.sop-product-box {
    border-radius: 10px;
    margin-left: 8px;
    border: none;
    background-color: #ffffff;
}

.sop-plp-container {
    padding: 16px 16px 16px 8px;
    justify-content: left !important;
}

.sop-product-content {
    padding-top: 20px;
    margin-bottom: 10px;
}

.display-flex {
    display: flex;
}

.option-field {
    padding: 10px;
}

.sop-pdp-plans {
  background-color: $shadowgrey;
  padding-bottom: 150px;
}

.sop-scroll-bottom {
    position: absolute;
    padding: 15px;
    color: $bgwhite;
    top: 0px;
    background-color: $black;
    opacity: 0.8;
    width: 100%;
    font-family: $sukhmaitext;
    font-size: 14px;
    font-weight: 700;
    line-height: 20px;
    letter-spacing: 0px !important;
    text-align: left;
    display: flex;
    justify-content: center;
    span{
        margin-top: 2px;
    }
}

.sop-plans-title {
    label {
        font-family: $sukhmaitext;
        font-size: 16px;
        font-weight: 700;
        line-height: 24px;
        letter-spacing: 0px !important;
        text-align: left;
    }
    input:checked ~ .checkmark {
        border: 0px !important;
        margin: -2px !important;
    }
}

.sop-pdp-cart-icon {
    position: absolute;
    top: 20px;
    right: 0px;
}
.option-detail-box {
    width: 100%;
    background-color: #ffffff;
    padding-bottom: 20px;
    border-radius: 10px;
    border: 2px solid $shadowgrey1;
}

.sop-active-plans {
    border: 2px solid $medium-blue;
}

.option-content {
    padding-top: 10px;
    margin-left: 20px;
}

.new-option-content {
    padding-top: 10px;
    margin-left: 20px;
}

.floating-right {
    float: right;
}

.mr-20 {
    margin-right: 20px;
}

.mb-5 {
    margin-bottom: 5px;
}

.mb-10 {
    margin-bottom: 10px;
}

.mb-15 {
    margin-bottom: 15px;
}

.mt-5 {
    margin-top: 5px;
}

.mt-15 {
    margin-top: 15px;
}

.mt-30 {
    margin-top: 30px;
}

.product-image-container {
    border-radius: 10px;
}

.card-product-img {
    height: initial;
    width: initial;
    margin-top: 2px;
    margin-right: 0px;
    background-color: transparent;
}

.sop-price-container {
    margin-top: 10px;
    padding: 0px 16px;
    h2{
        font-family: $sukhmaitext;
        font-size: 20px;
        font-weight: 800;
        line-height: 28px;
        letter-spacing: 0px !important;
        text-align: left;
    }
}

.new-sop-title-name {
    font-family: $sukhmaitext;
    font-size: 14px;
    font-weight: 700;
    line-height: 20px;
    letter-spacing: 0px !important;
    text-align: left;
    margin-left: 16px;
    color: $black;
    height: 48px;
}

.new-sop-title-sku {
    margin-left: 16px;
    font-family: $sukhmaitext;
    font-size: 12px;
    font-weight: 500;
    line-height: 16px;
    letter-spacing: 0px !important;
    text-align: left;
    color: $gray8;
}

.sop-plp-benefit {
    .option-box {
        padding: 4px 16px;

        .new-option-content {
            padding: 0px 9px;
            background-color: $bglightblue;
            margin-top: 5px;
            margin-left: 0px;
            
            span {
                font-family: $sukhmaitext;
                font-size: 10px;
                font-weight: 500;
                line-height: 14px;
                letter-spacing: 0px !important;
                text-align: left;
                color: $medium-blue;
                word-wrap: break-word;
            }
        }

        .hidden-content {
            visibility: hidden;
        }
    }
}

.footer {
    bottom: 0;
    width: 100%;
    padding: 30px 20px;
    position: relative;
    background-color: white;
}

.image-detail {
    width: 100%;
    text-align: center;
}

.w-h-100 {
    width: 100%;
    height: 100%;
}

.autoSub-box {
    background-color: $bglightblue;
    width: 100%;
}

.autocontinue-checkbox {
    input:checked ~ .checkmark {
        border: 0px !important;
        margin: -2px !important;
    }
    .checkmark {
        border-radius: 28%;
        border: 2px solid $black;
    }
    label {
        font-family: $sukhmaitext;
        font-size: 14px;
        font-weight: 500;
        line-height: 20px;
        letter-spacing: 0px !important;
        text-align: center;
    }
}

.autoSub-content {
    margin-left: 16px;
    padding-top: 15px;
    padding-bottom: 1px;
}

.justify-center {
    justify-content: center;
}

.align-item-center {
    align-items: center;
}

.text-right {
    text-align: right;
}

.sop-pdp-details {
    padding: 0px 16px;
    background-color: $bgwhite;
}

.sop-name-box {
    background: $bgwhite;
    margin-bottom: 20px;

    .sop-pdp-title {
        font-family: $sukhmaitext;
        font-size: 20px;
        font-weight: 800;
        line-height: 28px;
        letter-spacing: 0px !important;
        text-align: left;
        color: $black;
        margin-left: 16px;
    }

    .sop-pdp-price {
        span {
            font-family: $sukhmaitext;
            font-size: 20px;
            font-weight: 800;
            line-height: 28px;
            letter-spacing: 0px !important;
            text-align: left;
            color: $black;
            margin-left: 16px;
        }
    }

    .sop-pdp-pvbv {
        margin-left: 16px;
        font-family: $sukhmaitext;
        font-size: 12px;
        font-weight: 500;
        line-height: 16px;
        letter-spacing: 0px !important;
        text-align: left;
        color: $gray8;
    }
}

.wrapperAll {
    width: 100% !important;
}

.pt-10 {
    padding-top: 5px;
}

.sopPlan-tag-start-image {
    display: 'flex';
    align-items: center;
    justify-content: 'center';
    width: 25px;
    height: 25px;
    color: black;
    background-image: url("/images/sop/subscription/flag.svg");
    background-repeat: round;
    font-size: 12px;
    text-align: center;
}

.sopPlan-tag-benefit-image {
    display: 'flex';
    align-items: center;
    justify-content: 'center';
    width: 25px;
    height: 25px;
    color: black;
    background-image: url("/images/sop/subscription/tag_new.svg");
    background-repeat: round;
    font-size: 12px;
    text-align: center;
}

.sopPlan-tag-period-image {
    display: 'flex';
    align-items: center;
    justify-content: 'center';
    border-radius: '50%';
    width: 10px;
    height: 10px;
    background: black;
    border-radius: 10px;
    background-repeat: round;
    font-size: 12px;
    text-align: center;
}

.month-position {
    margin-top: 8px;
}

.month-position-benefits {
    margin-top: 7.5px;
    margin-left: 6px;
}

.box {
    margin: 20px;
}

.gray-font {
    font-family: $sukhmaitext;
    font-size: 12px;
    font-weight: 500;
    line-height: 16px;
    letter-spacing: 0px !important;
    text-align: left;
    margin-left: 16px;
}

.benefits-box {
    background-color: #d6dfff;
    padding: 5px;
    color: $medium-blue;
    text-align: left;
    padding-left: 10px;
}

.font-bold {
    font-weight: bold;
}

.price-style {
    font-size: 25px;
}

.css-df17o1 {
    position: fixed !important;
}

.title-style {
    line-height: 40px;
}

.sop-footer {
    bottom: -5px;
    padding: 0px;
    position: fixed;
    .autocontinue-checkbox {

    }
    .sop-btn-purchase{
        border: none;
        color: white;
        padding: 10px 50px;
        border-radius: 10px;
        border-radius: 30px;
        background-color: $black;
        &:disabled{
            background-color: $gray9;
            color: $gray5;
        }
    }
    .sop-pdp-price {
        font-family: $sukhmaitext;
        font-size: 20px;
        font-weight: 800;
        line-height: 28px;
        letter-spacing: 0px !important;
        text-align: left;
        color: $black;
    }
    .sop-pdp-pvbv {
        font-family: $sukhmaitext;
        font-size: 12px;
        font-weight: 500;
        line-height: 16px;
        letter-spacing: 0px !important;
        text-align: left;
        color: $gray8;
    }
    .sop-sub-footer {
        padding: 16px;
        box-shadow: 0px -4px 8px 0px $shadowgrey2;
    }
    .sop-price-button-align {
        display: flex;
        justify-content: space-between;
        align-items: center;
    }
}