@import '../../../../common/commonConstant.scss';

.my-badges-cards-container {

    padding: 0px 16px 40px;
    display: flex;
    flex-direction: column;
    gap: 17px;

    .align-half-cards-container {
        border-radius: 16px;
        padding: 16px;
        background: linear-gradient(132.59deg, $challenge-slider-bg 38.77%, $challenge-slider-bg1 113.8%);
        box-shadow: 0px 4px 20px 0px $challenge-dashboard-green-shadow;

        .my-badges-card-title {
            font-family: $sukhmaitext;
            font-size: 18px;
            font-weight: 700;
            line-height: 27px;
            text-align: center;
            color: $black;
            margin-bottom: 16px;
        }


        .align-half-cards {
            display: flex;
            justify-content: space-between;
            gap: 16px;

            .challenge-card-body {
                border-radius: 16px;
                padding: 16px;
                display: flex;
                flex-direction: column;
                background: linear-gradient(132.59deg, $challenge-slider-bg 38.77%, $challenge-slider-bg1 113.8%);
                box-shadow: 0px 4px 20px 0px $challenge-dashboard-green-shadow;

                .badge-rect-container {
                    align-items: center;
                    gap: 6px;

                    .badge-title-font {
                        width: 131px;
                        font-size: 14px;
                        font-weight: 700;
                        line-height: 21px;
                    }
                }
            }
        }
    }

    .w-50 {
        width: 50%;
    }
}