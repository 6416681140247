@import '../../../common/commonConstant.scss';

.common-android-picker-height {
  .liff-numpad-panel.open .numpad-panel {
    height: 200px;
    .modal-dialog {
      pointer-events: auto;
    }
  }
}
.common-android-preview-height {
  .liff-numpad-panel.open {
    backdrop-filter: blur(15px);
    background-color: $challenge-overlay-bg;
  }
}
