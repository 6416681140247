@import "../../../common/commonConstant.scss";

.hnw-weight-details-wrapper {
  background-image: url(/images/healthAndWellness/heightAndWeight/background.svg);
  background-position: center;
  width: 100%;
  background-size: cover;
  background-attachment: fixed;
  background-repeat: no-repeat;
  height: 100vh;

  .hnw-weight-accordian-title-heading {
    font-family: "Sukhumvit Set SemiBold", Arial, Helvetica, sans-serif;
    font-size: 20px;
    font-weight: 800;
    color: $black;
    margin-top: 16px;
    padding: 0 20px;
  }

  .hnw-weight-accordian-container {
    box-shadow: 0px 4px 12px 0px rgba(0, 0, 0, 0.15);
    margin: 14px 20px;
    border-radius: 12px;
    padding: 0 8px;
    background: $bgwhite;
    margin-bottom: 115px;
  }

  .hnw-weight-bottom-footer {
    background: $bgwhite;
    position: fixed;
    bottom: 0;
    width: 100%;
    max-width: 768px;
    height: 50px;

    .btn-green {
      width: 75px;
      float: right;
      padding: 3px 8px;
      font-size: 14px;
      border: 1px solid $theme-green;
      background: $theme-green;
      color: $bgwhite;
      font-weight: 700;
      border-radius: 38px;
      padding: 6px 8px;
      margin-top: 10px;
      display: flex;
      justify-content: center;
    }
  }

  .hnw-weight-accordian-title {
    color: $gray12;
    font-weight: 500;
    font-size: 16px;
  }

  .hnw-weight-accordian-single {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 12px 8px;
    height: 48px;
    border-bottom: 1px solid $gray13;

    &:last-of-type {
      border-bottom: none;
    }

    .hnw-weight-accordian-title,
    .hnw-weight-accordian-right-section {
      font-size: 16px;
      color: $gray12;
      font-weight: 500;
    }

    .hnw-weight-accordian-right-section {
      font-family: $sukhmaitext;
      flex-grow: 1;
      text-align: right;

      .green {
        color: $a-pri-green;
      }

      .font-weight800 {
        font-weight: 800;
      }
    }
  }

  .health-container-modal {
    .liff-panel.open .select-coupon-panel {
      height: 300px;
      border-radius: 12px 12px 0 0;
    }
  }

  .hnw-weight-chart {
    background: $bgwhite;
    padding: 16px 20px;

    .apexcharts-text {

      &.apexcharts-xaxis-label:last-child,
      &.apexcharts-xaxis-label:not(:nth-child(10n+1)),
      &.apexcharts-xaxis-label:nth-child(31) {
      visibility: hidden;
    }
    }

    .apexcharts-bar-area[val="0"] {
      transform: translate(0, -1px);
    }

    .apexcharts-bar-area:not([val="0"]) {
      transform: translate(0, 1px);
    }

    .apexcharts-xaxis-tick {
      stroke: transparent;
    }

    .apexcharts-gridline:last-child,
    .apexcharts-gridline:first-child,
    .apexcharts-gridline:not(:nth-child(10n+1)),
    .apexcharts-gridline:nth-child(31) {
      display: none;
    }

    .--previous .apexcharts-series-markers:last-of-type {
      .apexcharts-marker:last-of-type {
        display: none;
      }
    }

    .--first-absent .apexcharts-series-markers:first-of-type {
      .apexcharts-marker:first-of-type {
        display: none;
      }
    }

    .apexcharts-xaxistooltip {

      &::before,
      &::after {
        content: none;
      }

      border: 3px solid $graphgreen;
      border-radius: 50%;
      background-color: transparent;
      padding: 4px;
      transition: none;

      &.apexcharts-active {
        transition: none;
      }
    }

    .apexcharts-tooltip.apexcharts-theme-light {
      top: 24px !important;
      border: 0;
      box-shadow: none;
      background: none;

      .custom-tooltip {
        color: $graphgreen;
        font-size: 14px;
        font-family: $sukhmaitext;
        font-weight: 700;
      }
    }

    &-header {
      display: flex;
      justify-content: space-between;
      align-items: center;
    }

    &-header-left {
      display: flex;
      align-items: center;

      &-title {
        font-family: $sukhmaitext;
        font-size: 20px;
        font-weight: 800;
        padding-left: 10px;
      }
    }

    &-value {
      &-1 {
        font-size: 40px;
        font-weight: 800;
        line-height: 52px;
        display: flex;

        span {
          font-family: $sukhmaitext;
          font-size: 16px;
          color: $gray12;
          padding-left: 5px;
          font-weight: 500;
        }
      }

      &-2 {
        font-family: $sukhmaitext;
        font-size: 16px;
        font-weight: 700;
        color: $gray12;
        line-height: 24px;
      }
    }
  }
}

@media (max-width: 376px) {
  .mb-100 {
    margin-top: -150px;
  }

  .set-transition {
    transition: 0.2s ease-in-out;
  }
}

@media (max-width: 415px) and (max-height: 737px) {
  .mb-100 {
    margin-top: -150px;
  }

  .set-transition {
    transition: 0.2s ease-in-out;
  }
}