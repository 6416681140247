@import '../../common/commonConstant.scss';

.w-100 {
  width: 100%;
}

.w-80 {
  width: 80%;
}

.w-50 {
  width: 50%;
}

.w-40 {
  width: 40%;
}

.w-5 {
  width: 5%;
}

.w-h-100 {
  width: 100%;
  height: 100%;
}

.line-through {
  text-decoration: line-through;
}

.underline {
  text-decoration: underline;
}

.pd-5 {
  padding: 5px;
}

.pd-10 {
  padding: 10px;
}

.pd-15 {
  padding: 15px;
}

.pd-16 {
  padding: 16px;
}

.pd-20 {
  padding: 20px;
}

.pr-5 {
  padding-right: 5px;
}

.divider {
  border-bottom: 1px solid $gray4;
}

.lh-15 {
  line-height: 15px;
}

.text-end {
  text-align: end;
}

.m-auto {
  margin: auto;
}

.mt-5 {
  margin-top: 5px;
}

.mt-10 {
  margin-top: 10px;
}

.mt-30 {
  margin-top: 30px;
}

.ml-10 {
  margin-left: 10px;
}

.ml-4 {
  margin-left: 4px;
}

.mb-0 {
  margin-bottom: 0px;
}

.mb-10 {
  margin-bottom: 10px;
}

.mb-16 {
  margin-bottom: 16px;
}

.pb-10 {
  padding-bottom: 10px;
}

.border-panel {
  border-top: 2px solid $gray4;
  border-bottom: 2px solid $gray4;
  height: 70vh;
  margin-bottom: 10px;
}

.font-larger {
  font-size: larger;
}

.font-x-small {
  font-size: x-small;
}

.font-small {
  font-size: small;
}

.font-smaller {
  font-size: smaller;
}

.font-xsmall {
  font-size: x-small;
}

.font-medium {
  font-size: medium;
}

.font-bold {
  font-weight: bold;
}

.font-red {
  color: $red;
}

.font-gray {
  color: $gray3;
}

.font-dark-gray {
  color: $gray8
}

.medium-blue {
  color: $medium-blue;
}

.light-blue {
  color: $light-blue !important;
}

.font-black {
  color: $black !important;
}

.bg-gray {
  background-color: $gray3;
}

.bg-white {
  background-color: $bgwhite;
}
.dark-red {
  color: $dark-red;
}
.dark-blue {
  color: $dark-blue;
}

.cart-wrapper {
  height: 75vh;
  background-color: $gray4;
}

.promotion-container {
  height: 85vh;
  width: 100%;
  position: relative;
}

.promotion-header-container{
  width: 100%;
  position: relative;
  .promotion-header {
    width: 100%;
    position: absolute;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    top: 0px;
    padding: 15px 0px 15px 15px;
  }
}

.product-panel-promotion {
  overflow-x: hidden;
  height: 85vh;
  .promotion-banner {
    height: 50%;
    width: 100%;
    position: relative;
  }
  .icon-button {
    top: 20px;
    left: -15px;
    position: absolute;
    right: -5px;
  }
}

.product-panel {
  padding: 15px;
  height: 80vh;
  background-color: $gray4;
}


.promotion-product-panel {
  padding: 15px;
  min-height: 80vh;
  background-color: $gray4;
}


.promotion-content {
  max-width: 70%;
  align-items: flex-start;
  justify-content: flex-start;
  .promotion-title {
    font-family: $sukhmaitext;
    font-size: 20px;
    font-weight: 800;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    letter-spacing: 0px !important;
  }
  
  .promotion-description {
    font-family: $sukhmaitext;
    font-size: 14px;
    font-weight: 500;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    color: #707070;
    letter-spacing: 0px !important;
  }
}

.icon-button {
  display: flex;
  position: relative;
  justify-content: flex-end;
  align-items: center;
}

.icon-button .circle-notification {
  position: relative;
  background-color: #D91734;
  border-radius: 50%;
  display: inline-block;
  bottom: 16px;
  right: 16px;
  justify-content: center;
  align-items: center;
  display: flex;
  height: 26px;
  width: 26px;
}

.icon-button .circle-notification .notification-text{
 line-height: 15px;
 color: #FFFFFF;
 font-weight: 700;
 font-size: 14px;
}

.scroll {
  overflow-y: scroll;
}

.scroll-auto {
  overflow-y: auto;
}

.flex {
  display: flex;
}

.flex-between {
  display: flex;
  justify-content: space-between;
}

.btn-transparent {
  border: none;
  padding: 10px 10px;
  color: $medium-blue;
  border-radius: 10px;
  background-color: #cfe9df;
  width: 130px;
  text-align: left;
}

.btn-purchase {
  border: none;
  color: white;
  padding: 10px 50px;
  border-radius: 10px;
  background-color: $medium-blue;
}

.btn-purchase-inactive {
  border: none;
  color: white;
  padding: 10px 50px;
  border-radius: 10px;
  opacity: 0.5;
  background-color: $medium-blue;
}

.btn-remove {
  font-family: $sukhmaitext;
  width: 35%;
  height: 44px;
  border: none;
  color: white;
  border-radius: 40px;
  background-color: $dark-red2;
}

.btn-cart {
  width: 66%;
  border: none;
  color: white;
  padding: 23px 0px 10px 0px;
  border-radius: 20px;
}

.disabled {
  filter: grayscale(1);
}

.select-product-footer {
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.select-promotion-footer {
  height: 100%;
  align-items: center;
  justify-content: space-between;
}

.select-prmlist-btn {
  width: 124px;
  height: 100%;
  align-items: center;
  justify-content: space-between;
  .away-button {
    font-family: $sukhmaitext;
  }
}

.order {
  width: 100%;
  height: 100px;
  margin-bottom: 10px;
  background-color: white;
}

.footer {
  bottom: 0;
  width: 100%;
  padding: 30px 20px;
  position: relative;
  background-color: white;
}

.address-panel-footer {
  background-color: #ffffff;
  width: 100%;
  padding: 15px 20px;
  position: relative;
  box-shadow: 0 -4px 8px 0 rgba(0, 0, 0, 0.1);
}

.list-address-panel-footer {
  height: 15vh;
  background-color: #ffffff;
  width: 100%;
  padding: 15px 20px;
  box-shadow: 0 -4px 8px 0 rgba(0, 0, 0, 0.1);
}

.list-footer-alignment {
  display: flex;
  flex: 1;
  flex-direction: row;
  justify-content: space-between;
}

.footer-alignment {
  margin-top: 5px;
  display: flex;
  flex: 1;
  flex-direction: row;
  justify-content: space-between;
}

.price-summary-alignment {
  display: flex;
  flex: 1;
  flex-direction: column;
}

.select-address-footer {
  justify-content: right;
  width: 150px;
  height: 48px;
  border-style: solid;
  border-width: 1px;
  border-radius: 40px;
  background-color: #2C2C2C;
  text-align: center;
  border-color: #2C2C2C;
}

.list-select-address-footer {
  justify-content: right;
  width: 150px;
  height: 48px;
  border-style: solid;
  border-width: 1px;
  border-radius: 40px;
  background-color: #2C2C2C;
  text-align: center;
  border-color: #2C2C2C;
}

.select-address-text {
  font-family: $sukhmaitext;
  font-weight: 700;
  font-size: 16px;
  color: #FAFAFA;
}

.price-text {
  font-family: $sukhmaitext;
  font-size: 20px;
  font-style: normal;
  font-weight: 800;
}

.product-panel-footer {
  width: 100%;
  height: 15vh;
  padding: 20px;
  box-shadow: 0 -4px 8px 0 rgba(0, 0, 0, 0.1);
  .away-button {
    font-family: $sukhmaitext;
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    letter-spacing: 0px !important;
  }
}

.cart-panel-container {
  .link-text {
    font-family: $sukhmaitext;
    font-weight: 500;
    font-size: 12px;
    letter-spacing: 0px !important;
  }
  .link-text-icon {
    padding-left: 5px;
  }
  .cart-coupon-container {
    margin-bottom: 16px;
    background-color: white;
    padding: 0px 5px;
  }
}

.cart-panel-footer {
  width: 100%;
  padding: 20px;
  box-shadow: 0 -4px 8px 0 rgba(0, 0, 0, 0.1);
}

.product-panel-footer-variant {
  width: 100%;
  padding: 10px 20px 0px 20px;
  position: absolute;
  box-shadow: 0 -4px 8px 0 rgba(0, 0, 0, 0.1);
}

.product-panel-footer-price {
  font-family: $sukhmaitext;
  font-size: 20px;
  font-weight: 800;
  line-height: 28px;
  word-wrap: break-word;
  letter-spacing: 0px !important;
}

.pvbv-total{
  font-family: $sukhmaitext;
  color: #707070;
  font-size: 12px;
  font-weight: 500;
  line-height: 16px;
  word-wrap: break-word;
  letter-spacing: 0px !important;
}

.price-pvbv-container {
  display: flex;
  flex-direction: column;
}

.icon-cart-disable-img {
  margin-right: 5px;
}

.icon-cart-enable-img {
  margin-right: 6px;
  padding-bottom: 4px;
}

.add-to-cart-disabled {
  width: 150px;
  height: 48px;
  border-radius: 40px;
  padding-top: 10px;
  text-align: center;
  border-color:#D9D9D9;
  background-color:#D9D9D9
}

.add-to-cart-active {
  width: 150px;
  height: 48px;
  border-radius: 40px;
  padding-top: 10px;
  text-align: center;
  border-color:$black;
  background-color:$black
}

.add-to-cart-text-disabled {
  font-family: $sukhmaitext;
  color: $gray5;
  font-size: 16px;
  font-weight: 700;
  line-height: 20px;
  word-wrap: break-word;
  letter-spacing: 0px !important;
}

.add-to-cart-text-enabled {
  font-family: $sukhmaitext;
  color: white;
  font-size: 16px;
  font-weight: 700;
  line-height: 20px;
  word-wrap: break-word;
  letter-spacing: 0px !important;
}

.add-to-cart-text-active {
  font-family: $sukhmaitext;
  color: #FAFAFA;
  font-size: 16px;
  letter-spacing: 0px !important;
}

.cart-item-price-bold {
  font-family: $sukhmaitext;
  font-size: 16px;
  font-weight: 700;
  line-height: 24px;
  letter-spacing: 0px !important;
}

.cart-item-price-cut {
  font-family: $sukhmaitext;
  color: $gray8;
  font-size: 10px;
  font-weight: 500;
  text-decoration: line-through;
  line-height: 14px;
  letter-spacing: 0px !important;
}

.cart-item-price-div {
  align-items: center;
  display: inline-flex;
  justify-content: flex-start;
}

.cart-card {
  justify-content: flex-start;
  display: flex;
  align-items: flex-start;
  gap: 8px;
  width: 100%;
  height: 100%;
}

.cart-sop-card {
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  gap: 8px;
  display: inline-flex;

  .line {
    align-self: stretch;
    height: 0px;
    border: 1px #E4E4E4 solid
  }

  .totalEntryCount {
    align-self: stretch;
    gap: 37px;
    display: inline-flex;
    align-items: center;
    justify-content: flex-start;
    
    .txtDiv {
      font-family: $sukhmaitext;
      flex: 1 1 0;
      color: black; 
      font-size: 14px; 
      font-weight: 500; 
      line-height: 20px; 
      word-wrap: break-word;
      letter-spacing: 0px !important;
    }
  }
}

.sop-child-entry {
  justify-content: flex-start;
  align-items: center;
  gap: 8px;
  display: inline-flex;
  align-self: stretch;

  .sop-child-info-div {
    flex: 1 1 0;
    height: 32px;
    justify-content: flex-start;
    align-items: center;
    gap: 8px;
    display: flex;

    .child-info {
      flex: 1 1 0;
      flex-direction: column;
      display: inline-flex;
    }
  }
}

.info-text {
  font-family: $sukhmaitext;
  color: $black;
  font-size: 12px;
  font-weight: 500;
  line-height: 16px;
  word-wrap: break-word;
  letter-spacing: 0px !important;
}

.cart-item-details-div {
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  display: inline-flex;
  gap: 4px;
  width: 100%;
}

.cart-item-price-qty-card {
  justify-content: space-between;
  align-items: flex-end;
  gap: 4px;
  display: inline-flex;
  width: 100%;
}

.cart-item-price-card {
  flex-direction: column; 
  justify-content: center; 
  align-items: flex-start; 
  display: inline-flex
}

.free-gift-tab {
  background-color: #fbefef;
  font-size: small;
  padding: 5px;
  border-radius: 5px;
  letter-spacing: 0px !important;
}

.free-gift-quantity-tab {
  font-size: small;
  padding: 5px 20px;
  border-radius: 25px;
  background-color: $gray4;
}

.card-order-container {
  border-radius: 20px;
  background-color: white;
  padding: 20px 20px 20px 20px;
}

.card {
  border-radius: 20px;
  background-color: white;
}

.coupon-cart-panel {
  padding: 20px 20px 20px 20px;
}

.card-product-container {
  background-color: white;
  border-radius: 10px;
}

.card-product-body {
  padding: 20px;
  margin-bottom: 10px;
  background-color: white;
  border-radius: 10px;
}

.card-prm-body {
  padding:16px;
  border-radius: 10px;
  background-color: white;
  margin-bottom: 16px;
}

.card-product-body-cart {
  font-family: $sukhmaitext;
  padding: 16px;
  background-color: white;
  border-radius: 10px;
  height: auto;
  width: 100%;
  flex-direction: column; 
  justify-content: flex-start; 
  align-items: flex-start; 
  gap: 16px; 
  display: inline-flex;
}


.product-image-container {
  background-color: white;
  border-radius: 10px;
  display: flex;
  flex-direction: column;
}

.product-image-container-cart {
  background-color: white;
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.cart-product-img-container {
  background-color: white;
  border-radius: 10px;
}

.card-product-img {
  height: 100px;
  width: 100px;
  margin-top: 2px;
  margin-right: 10px;
  background-color: lightgray;
}

.card-product-img-variant {
  height: 72px;
  width: 72px;
  margin-top: 2px;
  background-color: lightgray;
}

.card-product-img-variant-size {
  width: 100%;
  height: 100%;
}

.card-product-img-promotion {
  height: 130px;
  width: 130px;
  margin-top: 2px;
  margin-right: 10px;
  background-color: lightgray;
}

.card-product-img-promotion img {
  width: 100%;
  height: 100%;
}

.card-product-img-cart {
  height: 80px;
  width: 80px;
  margin-top: 2px;
  margin-right: 10px;
}

.card-product-img-cart img {
  width: 100%;
  height: 100%;
}

.card-kit-img {
  width: 15%;
  height: 15%;
  padding: 5px;
  margin-right: 20px;
  border-radius: 10px;
  border: 1px solid $gray4;
}

.card-kit-img-kit {
  width: 48px;
  height: 48px;
  margin-right: 5px;
}

.card-kit-img-size-kit {
  width: 48px;
  height: 48px;
}

.kit-entry-identifier {
  font-family: $sukhmaitext;
  width: 65%;
  font-size: 12px;
  letter-spacing: 0px !important;
}

.bundle-product-view {
  width: 90%;
  position: relative;
  font-size: 12px;
  font-weight: 500;
  line-height: 16px;
}

.stocklevel-status-view {
  width: 60%;
  word-break: break-all;
}

.card-product-sop-tag {
  font-family: $sukhmaitext;
  min-height: 10px;
  font-size: small;
  word-break: break-word;
  color: #6d7b7b;
  font-weight: bold;
  background-color: #ffff91;
  padding: 2px 2px 2px;
  width: 80px;
  text-align: center;
  letter-spacing: 0px !important;
}

.cart-product-sop-tag {
  font-family: $sukhmaitext;
  padding: 3px 8px;
  border-bottom-left-radius: 4px;
  border-bottom-right-radius: 4px;
  overflow: hidden; 
  justify-content: space-around;
  align-items: center;
  gap: 10px;
  display: flex;
  font-size: 10px;
  font-weight: 700; 
  line-height: 14px; 
  word-wrap: break-word;
  margin-bottom: -5px;
  z-index: 1;
  letter-spacing: 0px !important;
}

.beyond-clr {
  color: $orange2;
  background-color: $orange1;
}

.basic-clr {
  color: $bggreen1;
  background-color: $bggreen2;
}

.flex-clr {
  color: $dark-green;
  background-color: $light-green;
}

.card-product-img img {
  width: 100%;
  height: 100%;
}

.card-product-title {
  min-height: 80px;
  width: 100%;
  font-size: small;
  word-break: break-word;
  letter-spacing: 0px !important;
}

.card-product-title-remove {
  width: 100%;
  font-size: small;
  word-break: break-word;
  letter-spacing: 0px !important;
}

.card-product-title-cart {
  width: 100%;
  font-size: small;
  word-break: break-word;
  letter-spacing: 0px !important;
}

.card-product-title-variant {
  font-family: $sukhmaitext;
  min-height: 80px;
  width: 100%;
  font-size: 14px;
  word-break: break-word;
  letter-spacing: 0px !important;
}

.product-variant-identifier {
  width: 100%;
  position: relative;
}

.card-product-title-text {
  font-family: $sukhmaitext;
  font-size: 16px;
  font-weight: 700;
  letter-spacing: 0px !important;
}

.font-stock {
  font-family: $sukhmaitext;
}

.cart-product-info-cart {
  font-family: $sukhmaitext;
  font-size: 12px;
  line-height: 16px;
  font-weight: 500;
  letter-spacing: 0px !important;
}

.card-product-title-text-cart {
  font-family: $sukhmaitext;
  font-size: 14px;
  font-weight: 500;
  line-height: 20px;
  letter-spacing: 0px !important;
}

.increment-container {
  display: flex;
  margin: auto 0;
  justify-content: space-between;
  align-items: center;
}

.qty-stepper-active {
  border-color: $black !important;
}

.qty-stepper-inactive {
  border-color: $gray5 !important;
}

.increment-container-box {
  display: flex;
  margin: auto 0;
  justify-content: space-around;
  border: solid;
  border-width: 2px;
  border-radius: 48px;
  min-width: 120px;
  height: 40px;
  align-items: center;
  .decrease-btn-active {
    font-size: 40px;
    font-weight: 200;
  }
  
  .decrease-btn-inactive{
    color: $gray5;
    font-size: 40px;
    font-weight: 200;
    pointer-events: none;
  }
  
  .increase-btn-active {
    font-size: 35px;
    font-weight: 200;
  }

  .increase-btn-inactive {
    color: $gray5;
    font-size: 35px;
    font-weight: 200;
    pointer-events: none;
  }
  .stepper-qty {
    font-family: $sukhmaitext;
    margin: auto 5px;
    font-size: 20px;
    font-weight: 800;
    letter-spacing: 0px !important;
  }
  .stepper-qty-inactive{
    color: $gray5;
  }
  .stepper-qty-active {
    color: $black;
  }
}

.btn-increase {
  color: #537ce5;
  padding: 3px 10px;
  text-align: center;
  border-radius: 60px;
  background-color: white;
  text-align: -webkit-center;
  border: 1px solid lightgray;
}

.btn-decrease {
  color: #537ce5;
  padding: 0px 11.5px;
  text-align: center;
  border-radius: 60px;
  text-align: -webkit-center;
  background-color: white;
  border: 1px solid lightgray;
}

.btn-increase:disabled {
  color: lightgray;
  border: 1px solid $gray4;
}

.btn-decrease:disabled {
  border: 1px solid $gray4;
  color: lightgray;
}

.product-container {
  display: flex;
  justify-content: space-between;
  padding: 20px 20px;
  width: 100%;
  border-bottom: solid 1px #f1f1f1;
}

.product-container-variant {
  display: flex;
  justify-content: space-between;
  padding: 20px 20px;
  width: 100%;
}

.product-container-cards {
  display: flex;
  justify-content: space-between;
  padding: 20px 20px;
  width: 100%;
  border-bottom: solid 10px $gray4;
  // border-radius: 20px;
}

.liff-panel .product-container:last-child {
  display: flex;
  justify-content: space-between;
  padding: 20px 20px;
  border-bottom: solid 1px #f1f1f1;
}

.product-bundle-container {
  background-color: white;
  border-radius: 10px;
  padding: 16px;
  margin-bottom: 16px;
  justify-content: space-between;
  // border-bottom: solid 1px #f1f1f1;
  width: 100%;
  // height: 132px;
}

.product-container.free-gift {
  padding: 0 0 10px 0;
}

.product-container .free-gift {
  padding: 10px;
  border-radius: 10px;
  border: 1px solid $red;
  background-color: white;
}

.product-container .free-gift .text {
  margin: auto 15px;
  font-size: small;
}

.product-container:last-child {
  border-bottom: 0;
}

.ReactModal__Overlay {
  background-color: #64676433 !important;
}

.ReactModal__Content {
  height: fit-content;
  top: 20vh !important;
  border: none !important;
  position: fixed !important;
  overflow: hidden !important;
  border-radius: 38px !important;
}

.btn-close-modal {
  border: 0;
  float: right;
  font-size: 1.5em;
  background-color: white;
}

.footer-price {
  width: '100%';
  word-break: 'break-word';
}

.footer-price-bold {
  font-family: $sukhmaitext;
  width: '100%';
  word-break: 'break-word';
  font-weight: 800;
  font-size: 20px;
  letter-spacing: 0px !important;
}

.price-selection-panel {
  bottom: 0;
  width: 100%;
  display: flex;
  justify-content: space-between;
}

.liff-panel {
  left: 0;
  bottom: 0;
  width: 100%;
  height: 0px;
  float: right;
  z-index: 2000;
  position: fixed;
  margin-top: 200px;
  margin-right: 34px;
  background: #131313d6;
}

.liff-prm-panel {
  left: 0;
  bottom: 0;
  width: 100%;
  height: 0px;
  float: right;
  z-index: 2000;
  position: fixed;
  margin-top: 200px;
  margin-right: 34px;
  background: #131313d6;
}

.liff-panel.open {
  height: 100vh;
  z-index: 1;
}

.liff-prm-panel.open {
  height: 100vh;
  z-index: 1;
}

.liff-panel .select-coupon-panel {
  left: 0;
  bottom: 0;
  width: 100%;
  height: 0px;
  position: fixed;
  background-color: white;
  border-top-left-radius: 20px;
  border-top-right-radius: 20px;
  transition: height 0.8s ease-in-out;
  max-width: 768px;
}

.liff-prm-panel .select-prm-panel {
  left: 0;
  bottom: 0;
  width: 100%;
  height: 0px;
  position: fixed;
  border-top-left-radius: 12px;
  border-top-right-radius: 12px;
  transition: height 0.8s ease-in-out;
  max-width: 768px;
}

.liff-panel.open .select-coupon-panel {
  height: 90%;
}

.liff-prm-panel.open .select-prm-panel {
  height: 85%;
}

.select-coupon-panel .header {
  font-family: $sukhmaitext;
  padding: 15px;
  text-align: center;
  // border-bottom: 1px solid $gray4;
  div {
    font-size: 18px;
    font-weight: 800;
    letter-spacing: 0px !important;
  }
}

.select-prm-panel .header {
  font-family: $sukhmaitext;
  font-size: 18px;
  font-weight: 800;
  line-height: 26px;
  padding: 15px;
  text-align: center;
  height: 50px;
  border-top-left-radius: 12px;
  border-top-right-radius: 12px;
  background-color: white;
  letter-spacing: 0px !important;
}

.select-coupon-panel .btn-close {
  font-family: $sukhmaitext;
  top: 15px;
  right: 20px;
  border: none;
  position: absolute;
  font-size: larger;
  background-color: white;
  letter-spacing: 0px !important;
}

.select-prm-panel .btn-close {
  font-family: $sukhmaitext;
  top: 15px;
  right: 20px;
  border: none;
  position: absolute;
  font-size: larger;
  background-color: white;
  letter-spacing: 0px !important;
}

.select-prm-panel .btn-close-disabled{
  font-family: $sukhmaitext;
  top: 15px;
  right: 20px;
  border: none;
  position: absolute;
  font-size: larger;
  background-color: white;
  pointer-events: none;
  opacity: 0.7;
  letter-spacing: 0px !important;
}

.select-coupon-panel .content {
  font-family: $sukhmaitext;
  padding: 10px;
  .label {
    font-size: 14px;
    font-weight: 700;
    letter-spacing: 0px !important;
  }
  
}

.select-prm-panel .content {
  padding: 16px;
}

.liff-panel-variant {
  left: 0;
  bottom: 0;
  width: 100%;
  height: 0px;
  float: right;
  z-index: 2000;
  position: fixed;
  margin-top: 200px;
  margin-right: 34px;
  background: #131313d6;
}

.liff-panel-variant.open {
  height: 100vh;
  z-index: 1;
}

.liff-panel-variant .select-coupon-panel-variant {
  left: 0;
  bottom: 0;
  width: 100%;
  height: 0px;
  position: fixed;
  background-color: white;
  border-top-left-radius: 20px;
  border-top-right-radius: 20px;
  transition: height 0.8s ease-in-out;
  max-width: 768px;
}

.liff-panel-variant.open .select-coupon-panel-variant {
  height: 96%;
}

.product-count-container {
  display: flex;
  justify-content:center;
  align-items:center;
  padding-bottom: 10px;
}

.product-count {
  text-align: center;
  min-width: 61px;
  height: 24px;
  border-radius: 12px;
  padding: 5px 16px 5px 16px;
  background-color: #F1F2FF;
}

.product-count .product-count-text {
  font-family: $sukhmaitext;
  min-width: 61px;
  font-size: 14px;
  font-weight: 500;
  line-height: 16px;
  color: $medium-blue;
  letter-spacing: 0px !important;
}

.select-coupon-panel-variant .header-variant {
  padding: 20px 20px 10px 20px;
  text-align: center;
}

.select-coupon-panel-variant .header-variant-text {
  font-family: $sukhmaitext;
  font-size: 18px;
  font-weight: 800;
  letter-spacing: 0px !important;
}

.select-coupon-panel-variant .btn-close-variant {
  font-family: $sukhmaitext;
  top: 15px;
  right: 20px;
  border: none;
  position: absolute;
  font-size: larger;
  background-color: white;
  letter-spacing: 0px !important;
}

.coupon-code {
  .input-coupon-code {
    font-family: $sukhmaitext;
    width: 72%;
    border-radius: 8px;
    border: 1px solid $gray4;
    height: 40px;
  }
  
  .btn-coupon-code {
    font-family: $sukhmaitext;
    width: 25%;
    font-size: x-small;
    padding: 0 10px;
    border-radius: 8px;
    height: 40px;
    border: none;
    letter-spacing: 0px !important;
  }

  .coupon-code-active {
    
    color: white;
    background-color: $black;
  }
  .coupon-code-disable {
    color: $gray5;
    background-color: $gray9;
  }
}


.input-coupon-code:placeholder-shown {
  font-family: $sukhmaitext;
  border-bottom: 1px solid #e6e6e6;
  font-size: small;
  padding-left: 10px;
  letter-spacing: 0px !important;
}

.disable-coupon {
  opacity: 0.4;
}

.disable-coupon-button {
  font-family: $sukhmaitext;
  background-color: $gray9 !important;
  border: 0px !important;
  color: $gray5;
}

.away-button {
  &:disabled {
    font-family: $sukhmaitext;
    background-color: $gray9 !important;
    border: 0px !important;
    color: $gray5;
  }
}

.disable-label {
  padding-top: 15px;
}

.coupon-container {
  display: flex;
  width: 100%;
  height: 100px;
  margin-top: 10px;
  justify-content: space-around;
  .coupon-detail {
    width: 60%;
    margin: auto 9px auto 9px;
    font-family: $sukhmaitext;
    .coupon-title {
      height: auto;
      max-height: 39px;
      display: -webkit-box;
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;
      overflow: hidden;
      text-overflow: ellipsis;
      font-weight: 500;
      font-size: 12px;
      line-height: 16px;
      letter-spacing: 0px !important;
    }
    .coupon-desc {
      font-size: 20px;
      font-weight: 800;
    }
  }
  .box-right {
    width: 26%;
    display: flex;
    justify-content: center;
    flex-direction: column;
    font-family: $sukhmaitext;
    .coupon-date {
      font-size: 10px;
      font-weight: 500;
      text-align: center;
      padding-top: 10px;
    }
    .coupon-button {
      font-family: $sukhmaitext;
      height: 40px;
    }
    .coupon-button-active {
      font-family: $sukhmaitext;
      background-color: $dark-red;
      color: $gray6;
      border: 0px;
    }
    .coupon-button-inactive {
      font-family: $sukhmaitext;
      color: $dark-red;
      background-color: $light-red;
      border: 2px solid $dark-red;
    }
  }
}

.input-redio {
  top: 25%;
  left: 30%;
  width: 30%;
  background-size: 50% 50%;
  cursor: default !important;
  border: initial !important;
  padding: initial !important;
  appearance: auto !important;
  box-sizing: border-box !important;
  margin: 3px 3px 0px 5px !important;
}

.dotted {
  width: 1px;
  height: 72%;
  margin: auto;
  background-color: #e0e7f6;
  border-right: 2px dotted #bdd0ef;
}


.box-right .checkbox-container {
  top: 50%;
  left: 35%;
  width: 0;
}

.box-right .checkbox-container input:checked ~ .checkmark {
  background-color: $medium-blue;
  border: 2px solid $medium-blue;
}

.box-right .checkbox-container .checkmark {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  width: 20px;
  margin: auto;
  height: 20px;
  border-radius: 50%;
  background-color: white;
  border: 2px solid white;
}

footer.coupon .btn-purchase {
  width: 40%;
  border-radius: 20px;
  margin: auto;
}

.footer.coupon {
  left: 0;
  filter: none;
  height: 15vh;
  display: flex;
  position: relative;
  background-color: white;
  border-top: 1px solid $gray4;
}

.bg-coupon {
  background-repeat: round;
  background-image: url('/images/promotion/coupon-bg.svg');
}


.border {
  border-radius: 10px;
  border: 1px solid $gray4;
}

.free-gift2-img {
  width: 20%;
  height: auto;
  padding: 10px;
}

.free-gift2-text {
  color: $red;
  margin: auto 10px;
  font-weight: bold;
  font-size: x-small;
}

.header-bundle {
  padding: 10px 0 10px 20px;
}

.header-bundle .header-heading {
  bottom: 0;
  width: 100%;
  display: flex;
  justify-content: 'space-between';
}

.header-bundle .icon {
  width: 4%;
  height: 4%;
  min-width: 4%;
}

.header-bundle .icon img {
  width: 100%;
  height: 100%;
}

.header-bundle .text-configure-qty {
  font-family: $sukhmaitext;
  font-weight: 500;
  color: black;
  width: max-content;
  font-size: 14px;
  min-width: max-content;
  background-color: white;
  letter-spacing: 0px !important;
}

.header-bundle .text-btn {
  font-size: 12px;
  font-weight: 500;
  padding-right: 20px;
  letter-spacing: 0px !important;
}

.divider-header-bundle {
  margin-inline: 20px;
  border-bottom: 1px solid $gray4;
}

.kit-container {
  display: flex;
  padding: 0 20px 10px 20px;
  justify-content: space-between;
}

.kit-container-item {
  display: flex;
  padding: 0 10px 10px 10px;
  justify-content: space-between;
  align-items: center;
}

.kit-container.free-gift {
  align-items: center;
}

.kit-container .btn-edit {
  margin: auto 0;
  color: $light-blue;
  font-size: x-small;
  letter-spacing: 0px !important;
}

.kit-container-item .btn-edit-kit {
  color: black;
  font-size: 12px;
  font-weight: 500;
  letter-spacing: 0px !important;
}

.btn-edit-kit-text {
  font-family: $sukhmaitext;
  text-decoration-line: underline;
}

.conf-qty-text{
  font-family: $sukhmaitext;
}

.remove-promotion .product-bundle-container {
  border-bottom: unset;
}


.remove-promotion.bundle .checkbox-container .checkmark {
  border-radius: 4px;
  border: 2px black solid;
}

.remove-promotion .checkbox-container .checkmark {
  border-radius: 4px;
  border: 2px black solid;
}

.remove-promotion .checkbox-container input:checked ~ .checkmark {
  background: $medium-blue;
  border: 2px solid $medium-blue;
}


.remove-promotion .checkbox-container.remove-all .checkmark {
  border-radius: 4px;
  border: 2px black solid;
}

.remove-promotion .checkbox-container.remove-all input:checked ~ .checkmark {
  background: $medium-blue;
  border: 2px solid $medium-blue;
}

.remove-promotion
  .checkbox-container.remove-all
  input:checked
  ~ .checkmark
  svg {
  top: 1px;
  left: -1px;
  display: block;
}
.checkbox-container.remove-all{
  margin: auto;
}

.remove-promotion.footer {
  bottom: 0;
  width: 100%;
  height: 15vh;
  padding: 8px 16px 8px;
  position: absolute;
  background-color: white;
  box-shadow: 0px -4px 8px rgba(0, 0, 0, 0.10); 
}

.free-gift-container {
  padding: 20px;
  border-radius: 10px;
  background-color: white;
}

.free-gift-container .text {
  font-size: small;
  margin: auto 10px;
}

.applied-coupon-container {
  display: flex;
  position: relative;
  flex: 1;
  width: 100%;
  margin-block: 16px;
}

.applied-coupon-left {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  position: absolute;
  top: 25%;
  left: 3%;
  width: 60%;
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  letter-spacing: 0px !important;
}

.applied-coupon-right-new {
  position: absolute;
  display: flex;
  flex: 1;
  flex-direction: row;
  justify-content: space-between;
  top: 25%;
  left: 78%;
  width: 20%;
}

.coupon-value-new {
  text-align: left;
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  letter-spacing: 0px !important;
}

.coupon-cross {
  margin-top: 1px;
  text-align: right;
}

.appliedCoupon {
  display: flex;
  width: 100%;
  align-items: center;
  height: 50px;
  margin-top: 10px;
  justify-content: space-between;
  background-repeat: round;
  background-image: url('/images/promotion/applied-coupon-red.svg');
  .applied-coupon {
    display: flex;
    align-items: center;
    padding-left: 15px;
    .applied-coupon-name {
      font-family: $sukhmaitext;
      font-weight: 700;
      font-size: 14px;
      letter-spacing: 0px !important;
    }
  }
  .applied-coupon-right {
    display: flex;
    align-items: center;
    font-family: $sukhmaitext;
    font-weight: 700;
    font-size: 14px;
    letter-spacing: 0px !important;
    padding-right: 10px;
    .applied-coupon-cancel {
      margin: 0px 5px;
      display: flex;
    }
  }
}

.coupon-flex {
  display: flex;
  justify-content: space-between;
}

.cancel-coupon {
  height: 20px;
  width: 20px;
}

.cancel-img {
  width: 50px;
}

.coupon-value {
  text-align: right;
  width: 120px;
}

.liff-panel .btn-bottom {
  font-family: $sukhmaitext;
  width: 90%;
  padding: 10px;
  margin: 20px 5%;
  bottom: 10px;
  border-radius: 20px;
  background-color: $black;
  border: none;
  color: white;
}

.liff-panel .btn-bottom-prm {
  font-family: $sukhmaitext;
  width: 90%;
  padding: 10px;
  margin: 20px 5%;
  bottom: 10px;
  border-radius: 20px;
  background-color: $black;
  border: none;
  color: white;
}

.liff-panel .btn-bottom:disabled {
  font-family: $sukhmaitext;
  width: 90%;
  padding: 10px;
  margin: 20px 5%;
  bottom: 10px;
  border-radius: 20px;
  background-color: $gray3;
  border: none;
  color: white;
}

.liff-panel .btn-bottom-prm:disabled {
  font-family: $sukhmaitext;
  width: 90%;
  padding: 10px;
  margin: 20px 5%;
  bottom: 10px;
  border-radius: 20px;
  background-color: $gray3;
  border: none;
  color: white;
}

.liff-panel-variant .product-panel-footer-variant .btn-bottom-new {
  font-family: $sukhmaitext;
  width: 100%;
  height: 48px;
  border-radius: 40px;
  background-color: black;
  border: none;
  color: white;
}

.liff-panel-variant .product-panel-footer-variant .btn-bottom-new:disabled {
  font-family: $sukhmaitext;
  width: 95%;
  height: 48px;
  border-radius: 40px;
  background-color: $gray3;
  border: none;
  color: white;
}

.expander {
  padding-top: 10px;
  border-bottom: 2px solid $gray4;
}

.expander .img {
  width: 3%;
  height: 3%;
  margin: 0 10px;
}

.expander.desc {
  padding: 0 30px 20px 50px;
}

.expander .product.text-overflow {
  width: 50%;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.desc-product-container {
  padding: 5;
  // width: 250px;
  // height: 160px;
  margin: 15px;
  border-radius: 10px;
  background-color: white;
  // border: 1px solid lightgray;
  .away-button {
    font-family: $sukhmaitext;
    font-weight: 700;
    font-size: 14px;
    letter-spacing: 0px !important;
  }
}

.desc-product-div {
  height: 100%;
  padding: 10px;
}

.promotion-name {
  font-size: 20px;
  font-weight: 800;
  line-height: 28px;
  margin-left: 10;
  letter-spacing: 0px !important;
}



.desc-product-container .img {
  // width: 80%;
  height: auto;
}

.desc-product-id {
  width: 70%;
  font-family: 'Sukhumvit Tadmai';
  letter-spacing: 0px !important;
  .product-id {
    font-weight: 500;
    font-size: 12px;
    letter-spacing: 0px !important;
  }
  .image-detail-name {
    max-height: 125px;
    overflow: hidden;
    font-weight: 700;
    font-size: 16px;
    letter-spacing: 0px !important;
  }
  .product-price {
    font-weight: 800;
    font-size: 20px;
    letter-spacing: 0px !important;
  }
  .product-pvbv {
    font-weight: 500;
    font-size: 12px;
    letter-spacing: 0px !important;
  }
}

.pb_5 {
  padding-bottom: 5px;
}

.pb-20 {
  padding-bottom: 20px
}


.font-16 {
  font-size: 16px;
}

.font-14 {
  font-size: 14px;
}

.promotion-section {
  background-color: $gray4;
  .product-section-heading img{
    margin-right: 10px; 
  }
  .product-section-div {
    background: #FFFFFF; 
    padding: 5px 15px;
    margin-bottom: 10px;
    color: $black;
    font-family: $sukhmaitext; 
    letter-spacing: 0px !important;
    .promotion-heading {
      padding: 5px 0px; 
      margin-top: 10px; 
      font-size: 20px;
      font-weight: 800;
      letter-spacing: 0px !important;
    }
    .product-section-heading {
      display: flex;
      align-items: center;
      padding: 5px 0px; 
      margin-top: 10px; 
      font-size: 14px;
      font-weight: 700;
      letter-spacing: 0px !important;
    }
    .product-section-summary {
      font-family: $sukhmaitext;
      font-size: 12px;
      font-weight: 500;
      letter-spacing: 0px !important;
    }
    .product-section-promo {
      font-family: $sukhmaitext;
      background: #F1F2FF; 
      padding: 5px 0px;
      margin-bottom: 10px; 
      text-align: center; 
      font-size: 12px; 
      font-weight: 500;
      color: $medium-blue;
      letter-spacing: 0px !important;
    }
  }
  .product-section-recommend {
    display: flex;
    align-items: center;
    margin: 0px 15px; 
    padding-top: 15px; 
    color: #A65523;
    font-weight: 700;
    font-size: 14px;
    letter-spacing: 0px !important;
  }
  .product-section-recommend span {
    margin: 0px 15px;
    font-size: 12px;
    font-weight: 500;
    letter-spacing: 0px !important;
  }
}

.product-section-recommend img{
  margin-right: 10px; 
}

.payment-option-container {
  padding: 15px;
  border-radius: 10px;
  border: 1px solid $gray4;
  background-color: white;
}

.payment-option-container.select {
  border: 1px solid $light-blue;
}

.payment-option-container .checkbox-container {
  margin-bottom: 0;
}

.payment-option-container .checkbox-container .checkmark {
  border: 2px solid $gray4;
  background: $bgwhite;
}

.payment-option-container .checkbox-container input:checked ~ .checkmark {
  border: 2px solid $light-blue;
  background: $medium-blue;
  border: 2px solid #537ce5;
  padding: 3px;
  background-image: linear-gradient(to bottom, #537ce5, #537ce5),
    linear-gradient(to bottom, #fafafa 0%, #fafafa 100%);
  background-clip: content-box, padding-box;
}

.payment-option-container .checkbox-container input:checked ~ .checkmark svg {
  display: none;
}

.empty-cart-text {
  height: 100vh;
  color: $medium-blue;
  font-size: 1.5em;
  margin-top: -11px;
  text-align: center;
  background-color: #f2f2f2;
}

._loading_overlay_overlay.css-df17o1 {
  height: 100vh;
}

.cart-phone-input {
  border: 1px solid $gray4;
  border-radius: 20px;
  font-size: small;
  margin-top: 5px;
}

.cart-phone-input .label {
  width: 50%;
  padding: 7px 5px;
  text-align: center;
  border-bottom-left-radius: 20px;
  border-top-left-radius: 20px;
  background-color: $gray4;
  font-weight: bold;
}

.cart-phone-input input {
  border-bottom: unset;
  margin: auto 10px;
}

.cart-phone-input input:placeholder-shown,
select:placeholder-shown {
  border-bottom: unset;
  margin: auto 10px;
}

.description-box-container {
  background-color: #FFF7F2;
  padding: 15px;
}

.short-description-box {
  color: #A65523;
  font-size: small;
  border-radius: 10px;
  letter-spacing: 0px !important;
  font-family: $sukhmaitext;
  font-size: 14px;
  font-weight: 500;
}

.short-description-box img {
  width: 10px;
  margin-right: 5px;
}

.amway-value {
  color: $gray3;
  font-size: small;
}

.amway-value-dark {
  font-family: $sukhmaitext;
  color: #707070;
  font-size: small;
  font-weight: 500;
}

.code-color {
  font-family: $sukhmaitext;
  color: #707070;
  font-size: 12px;
  font-weight: 500;
  letter-spacing: 0px !important;
}

.backOrder {
  color: #428c7b;
  border-radius: 2px;
  width: fit-content;
  font-size: smaller;
  padding: 1px 5px 0 5px;
  background-color: #b3e4d9;
  letter-spacing: 0px !important;
}

.outOfStock {
  color: white;
  border-radius: 2px;
  width: fit-content;
  font-size: smaller;
  padding: 1px 5px 0 5px;
  background-color: $red;
  letter-spacing: 0px !important;
}

.notYetAvailable {
  color: #b94a4a;
  border-radius: 2px;
  width: fit-content;
  font-size: smaller;
  padding: 1px 5px 0 5px;
  background-color: #eec1c1;
  letter-spacing: 0px !important;
}

.noLongerAvailable {
  color: white;
  border-radius: 2px;
  width: fit-content;
  font-size: smaller;
  padding: 1px 5px 0 5px;
  background-color: sienna;
  letter-spacing: 0px !important;
}

.temporaryNotAvailable {
  color: white;
  border-radius: 2px;
  width: fit-content;
  font-size: smaller;
  padding: 1px 5px 0 5px;
  background-color: $red;
  letter-spacing: 0px !important;
}

.notAvailableAtWarehouse {
  color: white;
  border-radius: 2px;
  width: fit-content;
  font-size: smaller;
  padding: 1px 5px 0 5px;
  background-color: $medium-blue;
  letter-spacing: 0px !important;
}

.font-price {
  color: red;
  text-decoration: line-through;
}

.font-targetPrice {
  color: blue;
}

.cartTabBar {
  width: 100%; 
  // height: 100%; 
  padding: 0px 16px; 
  gap: 16px; 
  display: flex; 
  background-color: $bgwhite;
  box-shadow: 4px 4px 8px rgba(0, 0, 0, 0.04);
  justify-content: flex-start;
  align-items: center;
}

.cartPaymentSelectionTab {
  flex: 1 1 0;
  height: 100%;
  padding: 15px 16px 0px;
  justify-content: flex-start;
  align-items: center;
  gap: 10px;
  display: flex;
  flex-direction: column;
}

.cartPaymentSelectionText {
  font-family: $sukhmaitext;
  font-size: 16px;
  text-align: center;
  color: $black;
  line-height: 24px;
  word-wrap: break-word;
  font-weight: 700;
  letter-spacing: 0px !important;
}

.selectedCartTabBar { 
  width: 120px;
  height: 5px;
  background: $medium-blue;
}

.empty-cart-div {
  display: flex;
  justify-content: center;
  align-items: center;
  height: calc(100vh - 52px);
}

.empty-cart-container {
  width: 361px;
  height: 382px;
  flex-direction: column; 
  justify-content: flex-start; 
  align-items: center; 
  gap: 24px; 
  display: inline-flex
}

.empty-cart-img {
  width: 236px;
  height: 236px;
}

.empty-cart-msg-div {
  flex-direction: column; 
  justify-content: flex-start; 
  align-items: center; 
  display: inline-flex;
}

.empty-cart-label {
  font-family: $sukhmaitext;
  text-align: center; 
  color: black; 
  font-size: 20px; 
  font-weight: 800; 
  line-height: 28px; 
  word-wrap: break-word;
  letter-spacing: 0px !important;
}

.empty-cart-productmsg {
  font-family: $sukhmaitext;
  text-align: center; 
  color: black; 
  font-size: 16px; 
  font-weight: 500; 
  line-height: 22px; 
  word-wrap: break-word;
  letter-spacing: 0px !important;
}

.btn-shop-now {
  font-family: $sukhmaitext;
  background-color: $black;
  height: 48px;
  width: 150px;
  border-radius: 40px;
  font-size: 16px;
  line-height: 20px;
  font-weight: 700;
  word-wrap: break-word;
  color: white;
  border-color: black;
  justify-content: space-around;
  display: inline-flex;
  align-items: center;
  letter-spacing: 0px !important;
}

.gift-product-container {
  width: 100%; 
  height: 100%; 
  flex-direction: column; 
  justify-content: flex-start; 
  gap: 8px; 
  display: inline-flex;
}

.gift-label-div {
  gap: 37px;
  flex-direction: row; 
  justify-content: flex-start; 
  align-items: center; 
  display: inline-flex;
}

.gift-info-container {
  align-self: stretch; 
  justify-content: flex-start; 
  align-items: center; 
  gap: 8px; 
  display: flex;
}

.info-div {
  flex: 1 1 0;
  height: 16px;
  display: flex;
  justify-content: flex-start; 
  align-items: center; 
  gap: 8px;
  height: 100%;
}

.gift-title-container {
  flex: 1 1 0; 
  flex-direction: column; 
  align-items: flex-start; 
  align-self: stretch; 
  justify-content: flex-start; 
  gap: 8px; 
  display: inline-flex;
}

.gift-img {
  width: 48px; 
  height: 48px; 
  border: 1px solid white;
}

.footer-coupon {
  margin-bottom: 5px;
  padding: 10px 20px;
  background-color: white;
}

.cart-summary {
  margin-bottom: 16px;
  padding: 16px;
  background-color: white;
  gap: 4px;
  flex-direction: column;
}

.cart-summary-label {
  font-size: 14px;
  font-family: $sukhmaitext;
  font-weight: 500;
  line-height: 20px;
  word-wrap: break-word;
  letter-spacing: 0px !important;
}

.cart-summary-price {
  font-size: 16px;
  font-family: $sukhmaitext;
  font-weight: 700;
  line-height: 22px;
  word-wrap: break-word;
  letter-spacing: 0px !important;
}

.cart-summary-value {
  font-size: 16px;
  font-family: $sukhmaitext;
  font-weight: 500;
  line-height: 22px;
  word-wrap: break-word;
  letter-spacing: 0px !important;
}

.sop-btn {
  font-family: $sukhmaitext;
  flex: 1 1 0;
  padding: 14px 16px;
  background-color: $light-green2;
  color: $secondary-green;
  font-size: 14px;
  font-weight: 700;
  line-height: 20px;
  word-wrap: break-word;
  letter-spacing: 0px !important;
}

.prm-btn {
  font-family: $sukhmaitext;
  flex: 1 1 0;
  padding: 14px 16px;
  background-color: $light-orange;
  color: $dark-brown;
  font-size: 14px;
  font-weight: 700;
  line-height: 20px;
  word-wrap: break-word;
  letter-spacing: 0px !important;
}

.prm-current-qty {
  width: auto;
  height: auto;
  padding: 5px 16px;
  background: #F1F2FF; 
  border-radius: 12px; 
  overflow: hidden; 
  justify-content: flex-start;
  align-items: center;
  gap: 10px;
  display: inline-flex;

  .qtyValue {
    color: $medium-blue;
    font-size: 14px;
    font-weight: 500;
    line-height: 20px;
    word-wrap: break-word;
    letter-spacing: 0px !important;
  }
}

.prm-choice {
  align-items: center;
  flex-direction: column;
  display: flex;
  gap: 16px;
}

.prm-choice-footer {
  padding: 8px 16px 0px 16px;
  box-shadow: 0px -4px 8px rgba(0, 0, 0, 0.10);
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;

  .footer-btn {
    border-radius: 40px; 
    overflow: hidden; 
    align-items: center; 
    gap: 10px; 
    display: inline-flex;
    justify-content: space-around;
    background-color: $black;
    width: 100%;
    padding: 0px 32px;
    height: 48px;
  }

  .btn-text {
    font-family: $sukhmaitext;
    color: $bggray;
    font-size: 16px;
    font-weight: 700;
    line-height: 20px;
    word-wrap: break-word;
    letter-spacing: 0px !important;
  }

  .btn-text-disabled {
    pointer-events: none;
    color: $gray5;
    font-size: 16px;
    font-weight: 700;
    line-height: 20px;
    word-wrap: break-word;
    letter-spacing: 0px !important;
  }
}

.prm-choice-item {
  justify-content: flex-start; 
  align-items: center; 
  gap: 8px; 
  display: inline-flex;

  .description {
    flex-direction: column; 
    align-items: flex-start; 

    .identifier {
      font-family: $sukhmaitext;
      align-self: stretch; 
      font-size: 14px;
      font-weight: 500;
      line-height: 20px;
      word-wrap: break-word;
      letter-spacing: 0px !important;
    }

    .price-row {
      width: 112px;
      align-items: center;
      gap: 4px;
      word-wrap: break-word;
      display: inline-flex;
      align-items: center;

      .disc-price {
        font-family: $sukhmaitext;
        font-size: 16px;
        font-weight: 700;
        line-height: 24px; 
        letter-spacing: 0px !important;
      }

      .actual-price {
        font-family: $sukhmaitext;
        color: $gray8; 
        font-size: 10px; 
        font-weight: 500;
        text-decoration: line-through;
        line-height: 14px;
        letter-spacing: 0px !important;
      }
    }

  }
}

.cart-sop-solution-img {
  flex-direction: column; 
  justify-content: flex-start; 
  align-items: flex-start; 
  gap: 2px; 
  display: inline-flex;
  height: 80px;
  width: 80px;


  .img-container {
    align-self: stretch;
    flex: 1 1 0; 
    justify-content: flex-start; 
    align-items: flex-start; 
    gap: 2px; 
    display: inline-flex;

    img {
      flex: 1 1 0; 
      align-self: stretch; 
      border: 1px solid white;
      height: 40px;
      width: 40px;
    }

    .last-img {
      position: relative;
    }

    .empty-img {
      flex: 1 1 0;
      align-self: stretch;
      opacity: 0.04; 
      background: $gray8; 
      border-radius: 10px
    }

    .overlay {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      opacity: 0.80; 
      background: $gray10; 
      border-radius: 10px;
    }

    .overlay-txt {
      position: absolute;
      top: 7px;
      right: 11px;
      text-align: center; 
      color: white; 
      font-size: 16px; 
      font-weight: 700; 
      line-height: 24px; 
      word-wrap: break-word;
      letter-spacing: 0px !important;
    }
  }
}

.customer-pay-select-div {
  width: 100%;
  height: 48px;
  padding: 15px 16px;
  background: $bgwhite; 
  box-shadow: 4px 4px 8px rgba(0, 0, 0, 0.04); 
  justify-content: flex-start; 
  align-items: center; 
  gap: 16px; 
  display: inline-flex;

  .pay-div {
    flex: 1 1 0; 
    height: 48px;
    padding: 15px 16px;
    gap: 10px;
    justify-content: flex-start; 
    align-items: center; 
    display: flex;

    .pif-text {
      flex: 1 1 0; 
      text-align: center; 
      color: $black; 
      font-size: 16px; 
      font-weight: 700; 
      line-height: 24px; 
      word-wrap: break-word;
      letter-spacing: 0px !important;
    }
  }
}

.coupon-label {
  font-size: 14px;
  font-family: $sukhmaitext;
  font-weight: 700;
  line-height: 20px;
  word-wrap: break-word;
  letter-spacing: 0px !important;
}

.coupon-link {
  font-size: 12px;
  font-family: $sukhmaitext;
  font-weight: 500;
  text-decoration: underline;
  line-height: 16px;
  word-wrap: break-word;
  letter-spacing: 0px !important;
}

.product-list-label {
  font-size: 14px;
  font-family: $sukhmaitext;
  font-weight: 700;
  line-height: 20px;
  word-wrap: break-word;
  letter-spacing: 0px !important;
}

.remove-all-product {
  font-size: 16px;
  font-family: $sukhmaitext;
  font-weight: 700;
  line-height: 24px;
  word-wrap: break-word;
  letter-spacing: 0px !important;
}
