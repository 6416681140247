@import '../../../../common/commonConstant.scss';

.card-ongoing-outer {

    .card-ongoing-title-wrapper {
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 23px 16px 24px 16px;

        .rect-badge-container{
            .rect-badge-title{
                font-size: 14px;
                font-weight: 700;
                font-family: $sukhmaitext;
            }
        }

        .card-ongoing-title {
            font-size: 18px;
            font-weight: 700;
            font-family: $sukhmaitext;
            line-height: 27px;
            color: $a-black;
        }
    }

    .team-info-name {
        font-family: $sukhmaitext;
        font-size: 18px;
        font-weight: 700;
        line-height: 27px;
        text-align: left;
        color: $a-black;
        padding: 0 16px;
    }

    .slick-slide img {
        border: none;
    }

    .slick-list {
        margin-right: 5px;
    }
}