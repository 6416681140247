@import '../../common/commonConstant.scss';

.image-upload-container {
  label {
    display: inline-flex;
    justify-content: center;
    align-items: center;
    margin: 0 5px 10px;
    background: $bgwhite;
    border: 1px solid $gray4;
    border-radius: 8px;
    height: 30vw;
    width: 30vw;
    transition: 1s all cubic-bezier(0.075, 0.82, 0.165, 1);
    &:hover,&:active{
      border: 1px solid $medium-blue;
      transition: 1s all cubic-bezier(0.075, 0.82, 0.165, 1);
    }
  }
  .preview-container {
    display: flex;
    width: 100%;
    height: 100%;
    justify-content: center;
    align-items: center;
  }
  .preview-image {
    width: calc(30vw - 20px);
    height: calc(30vw - 20px);
  }
  .preview-image-container {
    background: $bgwhite;
    border-radius: 8px;
    height: 30vw;
    width: 30vw;
    display: inline-flex;
    justify-content: center;
    align-items: center;
    position: relative;
    border: 1px solid $gray4;
    transition: 1s all cubic-bezier(0.075, 0.82, 0.165, 1);
    &:hover,&:active{
      border: 1px solid $medium-blue;
      transition: 1s all cubic-bezier(0.075, 0.82, 0.165, 1);
      .del-image{
        opacity: 1;
        transition: 1s all cubic-bezier(0.075, 0.82, 0.165, 1);
        backdrop-filter: blur(3px) grayscale(0.6) brightness(0.8);
      }
    }
    .del-image {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      display: flex;
      opacity: 0;
      color: white;
      justify-content: center;
      align-items: center;
      transition: 1s all cubic-bezier(0.075, 0.82, 0.165, 1);
      i{
        font-size: 30px;
      }
    }
  }

  .preview-images-container {
    .preview-image-container {
      margin: 0 5px 10px;
      vertical-align: top;
    }
  }
  > input {
    display: none;
  }
}